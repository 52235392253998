<template>
	<ion-page id="main-content">
		<app-header customBackButton="/home" />
		<progress-message
			:icon="alarm"
			color="warning"
			:message="`You have ${eLearningDaysFromLockDate} day${eLearningDaysFromLockDate === 1? '' : 's'} to complete your course before it locks on ${format(classroomUserProgress.lockDate, 'MMM do, yyyy')}`"
			:isButton="true"
			type="eLearningCountdownWarning"
			v-if="showeLearningExtensionWarning"
		/>
		<progress-message
			:icon="alarm"
			color="warning"
			:message="`You may only complete ${dailyLimitMet} of lessons per day. The next topic would take you over that limit. Take a well earned break and come back tomorrow!`"
			v-if="dailyLimitMet && !classroomUserProgress.completedDate"
		/>
		<progress-message
			:icon="alarm"
			color="warning"
			:message="'Nice work so far! You will need to wait until ' + format(this.eLearningMinimumFinishDate, 'MMMM d, yyyy') + ' to complete your final lesson to meet Wisconsin DOT guidelines.'"
			v-if="eLearningMinimumFinishDate && completedAllButLastLesson"
		/>
		<ion-loading v-if="extensionLoading" show-backdrop />
		<progress-message
			:icon="lockClosed"
			color="danger"
			:message="`You have exceeded your ${store.getters.selectedSyllabus?.duration ? `${store.getters.selectedSyllabus?.duration} day ` : ''}time limit to complete your course and your course is now locked.`"
			v-if="eLearningExtensionNeeded"
		>
			<template v-slot>
				<ion-button
					color="tertiary"
					@click="promptExtension()"
				>
					Learn More
				</ion-button>
			</template>
		</progress-message>
		<progress-message
			:icon="medal"
			color="success"
			message="You have successfully completed your classroom course!"
			v-if="classroomUserProgress.completedDate"
		/>
		<NotificationCard
			:notification="notification"
			v-for="(notification, index) in notificationsForLessons"
			:key="index"
		/>
		<div class="notes-container">
			<alert-bar
				v-for="note in currentUser?.notes.userAppLessons || []"
				:key="note.noteID"
				:htmlText="note.note"
				:type="note.type"
			/>
		</div>

		<ion-content fullscreen scrollY="true" v-if="isParent">
			<lesson-card
				:disabled="lessonDisabled(lesson)"
				:lesson="lesson"
				:lessonProgress="lessonProgressMap.get(lesson.lessonID)"
				:numRetakes="syllabus.testSettings?.attemptsAllowed"
				v-for="lesson in lessonsCompleted.all"
				:showParentDetails="true"
			/>
		</ion-content>
		<ion-content fullscreen scrollY="true" v-else>
			<lesson-card
				:disabled="lessonDisabled(lesson)"
				:lesson="lesson"
				:lessonProgress="lessonProgressMap.get(lesson.lessonID)"
				:numRetakes="syllabus.testSettings?.attemptsAllowed"
				v-for="lesson in lessonsCompleted.incomplete"
			/>
			<heading
				class="completed-lessons-heading"
				mdiIcon="mdiBookCheck"
				:title="'Completed Lessons'"
			/>
			<lesson-card
				:disabled="lessonDisabled(lesson)"
				:lesson="lesson"
				:lessonProgress="lessonProgressMap.get(lesson.lessonID)"
				:numRetakes="syllabus.testSettings?.attemptsAllowed"
				v-for="lesson in lessonsCompleted.complete"
			/>
		</ion-content>
	</ion-page>
</template>

<script>
import AppHeader from '@/view/components/AppHeader.vue';
import AlertBar from "@/view/components/AlertBar.vue";
import LessonCard from '@/view/components/LessonCard.vue';
import ProgressCircle from '@/view/components/ProgressCircle.vue';
import PastQuizTestCard from '@/view/components/PastQuizTestCard.vue';
import ProgressMessage from '@/view/components/ProgressMessage.vue';
import Heading from '@/view/components/Heading.vue';
import { useStore } from "vuex";
import {
	IonButton,
	IonIcon,
	IonToolbar,
	IonLoading,
	useIonRouter,
	alertController
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
	alarm,
	lockClosed,
	caretDownOutline,
	medal
} from 'ionicons/icons';
import { api } from '@/core/api/api';
import { format } from 'date-fns';
import NotificationCard from "@/view/components/cards/NotificationCard.vue";

export default defineComponent({
	name: "lessons",
	components: {
		AppHeader,
		AlertBar,
		Heading,
		IonButton,
		IonIcon,
		IonToolbar,
		IonLoading,
		LessonCard,
		PastQuizTestCard,
		ProgressCircle,
		ProgressMessage,
		NotificationCard
	},
	data() {
		return {
			extensionLoading: false
		}
	},
	computed: {
		completedAllButLastLesson() {
			return this.store.getters.completedAllButLastLesson;
		},
		classroomUserProgress() {
			return this.store.getters.selectedClassroomProgress ?? {};
		},
		syllabus() {
			return this.store.getters.selectedSyllabus;
		},
		lessons() {
			return this.classroomUserProgress?.syllabus?.lessons ?? [];
		},
		lessonsCompleted() {
			if(!this.lessons) { return []; }

			let complete = [];
			let incomplete = [];
			let all = [];

			this.lessons.forEach((l, i) => {
				l.number = i+1;
				if (this.classroomUserProgress.lessons.find((lp) => lp.lessonID === l.lessonID)?.completeDate) {
					complete.push(l);
				} else {
					incomplete.push(l)
				}
				// Added an all option in order to prevent a bug with the lesson card showing undefined for the lesson number.
				// When trying to just utilize just the lessons computed value in the the lesson card list it would show undefind for the number
				// even though the data exists. Using the computed value here does resolve the issue.
				// TODO - Figure out why the lessons array does not show lesson number immediately but all other data correctly.
				all.push(l);
			})

			return { complete, incomplete, all }
		},
		isLessonUnlocked() {
			// Unlock lessons if one of:
			// - Its the first lesson
			// - The previous lesson is unlocked
			// - The lesson has progress data
			const syllabusLessons = this.lessons;

			let lessonsLockedUnlocked = {
				locked: [],
				unlocked: []
			};

			lessonsLockedUnlocked.unlocked.push(this.lessons[0].lessonID);

			for (let i = 1; i < this.lessons.length; i++) {
				if (this.classroomUserProgress?.lessons.find((lp) => lp.lessonID === this.lessons[i-1].lessonID)?.completeDate) {
					lessonsLockedUnlocked.unlocked.push(this.lessons[i].lessonID);
				} else if (this.classroomUserProgress?.lessons.find((lp) => lp.lessonID === this.lessons[i].lessonID)) {
					lessonsLockedUnlocked.unlocked.push(this.lessons[i].lessonID);
				} else {
					lessonsLockedUnlocked.locked.push(this.lessons[i].lessonID);
				}
			}

			return lessonsLockedUnlocked;
		},
		currentUser() {
			return this.store.getters.students.find(s => s.userID === this.store.getters.selectedUser);
		},
		isParent() {
			return this.store.getters.user.isParent ?? false;
		},
		eLearningMinimumFinishDate() {
			return this.store.getters.eLearningMinimumFinishDate ?? false;
		},
		eLearningExtensionNeeded() {
			return this.store.getters.eLearningExtensionNeeded;
		},
		eLearningDaysFromLockDate() {
			return this.store.getters.eLearningDaysFromLockDate;
		},
		showeLearningExtensionWarning() {
			return this.store.getters.showeLearningExtensionWarning
				|| this.store.getters.eLearningHasBeenExtended;
		},
		eLearningExtensionWarningMessage() {
			let message = '';

			if (this.store.getters.eLearningHasBeenExtended) {
				message += 'Your course is currently extended. '
			}

			message += `You have ${this.eLearningDaysFromLockDate} day${this.eLearningDaysFromLockDate === 1 ? '' : 's'} to complete your course before it locks on ${format(this.classroomUserProgress.lockDate, 'MMM do, yyyy')}`;

			return message;
		},
		dailyLimitMet() {
			return this.store.getters.dailyELearningLimitMet?.formatted || false;
		},
		lessonProgressMap() {
			const map = new Map();
			for (const lp of this.classroomUserProgress?.lessons) {
				map.set(lp.lessonID, lp);
			}
			return map;
		},
		promptMessage() {
			let message = `<p>You did not complete the course within the ${this.store.getters.selectedSyllabus.duration} day course period.</p>`;

			if (this.store.getters.selectedSyllabus.extensionSettings?.appExtensionAllowed) {
				let priceString = this.store.getters.selectedSyllabus.extensionSettings.price.toFixed(2);
				if (priceString.endsWith(".00")) priceString = priceString.slice(0, -3);

				message += `<p>You may extend your course by an additional ${this.store.getters.selectedSyllabus.extensionSettings.additionalDuration} days but this will cost an extra $${priceString}.</p><div><span>+ Add ${this.store.getters.selectedSyllabus.extensionSettings.additionalDuration} days </span><span>$ ${priceString}</span></div>`;
			} else {
				message += `<p>Please contact ${this.store.getters.institutionSettings.name} to extend your eLearning course</p>`
			}

			return message;
		},
		notificationsForLessons() {
			return this.store.getters.notifications.filter(({showOnLessons}) => {
				return showOnLessons
			}).sort((a, b) => {
				return a.startDate - b.startDate;
			}).map((notification) => {
				const {startDate, ...rest} = notification;

				return rest;
			});
		}
	},
	methods: {
		format,
		lessonDisabled(lesson) {
			// Never allow viewing of in-person only lessons
			if (lesson.customOnly) return true;

			// Otherwise, if the course is complete, allow access to everything
			if (this.classroomUserProgress.completedDate) return false;

			// ...or allow access if the lesson is complete
			if (this.lessonProgressMap.get(lesson.lessonID)?.completeDate) return false;

			// If inPerson but not in-person only (i.e. has app resources, but was completed in person), disable access
			// Allows access to viewing the lessons resources after the lesson is complete,
			//     even if the lesson was completed in-person
			if (lesson.inPerson) return true;

			// Disable the last lesson if its under the 21 day minimum
			if(lesson.lessonID === this.lessons[this.lessons.length - 1].lessonID && this.eLearningMinimumFinishDate) return true;

			// ...or if the two hour limit is met
			if (this.dailyLimitMet) return true;

			// ...or if the user needs to extend their eLearning
			if (this.eLearningExtensionNeeded) return true;

			// Otherwise allow access if first lesson or after a completed lesson
			return !this.isLessonUnlocked.unlocked.find(l => l === lesson.lessonID);
		},
		async promptExtension() {
			let handler;

			if (this.store.getters.selectedSyllabus?.extensionSettings?.immediatePaymentRequired && this.store.getters.featureFlags?.inAppBilling) {
				handler = () => {
					this.store.commit('showSettingsModal', {type: 'billing', requirePayment: true, paymentAmount: this.store.getters.selectedSyllabus.extensionSettings.price, paymentCallback: ({id}) => this.store.dispatch('extendELearning', id), addCorrespondingFee: {type: 'FEE_ELEARNING_EXTENSION', description: 'eLearning Extension'}})
				}
			} else {
				handler = async () => {
					this.extensionLoading = true;
					await this.store.dispatch('extendELearning', 'n/a')
					.finally(() => {
						this.extensionLoading = false;
					})
				}
			}

			const buttons = [{
				text: 'Cancel',
				role: 'cancel'
			}]

			if (this.store.getters.selectedSyllabus.extensionSettings?.appExtensionAllowed) {
				buttons.push({
					text: 'Extend',
					handler
				});
			}

			const alert = await alertController.create({
				backdropDismiss: true,
				buttons,
				cssClass: 'eLearning-extension-dialog',
				message: this.promptMessage
			});
			await alert.present();
		}
	},
	setup() {
		const queryLoading = ref(false);
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		return {
			alarm,
			lockClosed,
			caretDownOutline,
			medal,
			route,
			router,
			store,
			useIonRouter
		};
	},
	ionViewWillEnter() {
		this.store.dispatch('getDailyELearningLimit', this.store.state.selectedUser)
	}
});
</script>

<style lang="scss" scoped>
.notes-container {
	z-index: 1;
	color: var(--ion-color-dark);
}

.lesson-card {
	margin-bottom: 30px;
}

.completed-lessons-heading {
	margin: 30px 10px 10px 10px;
}

.eLearning-extension {
	display: flex;
	p {
		align-self: center;
		color: var(--ion-color-secondary);
	}
}
</style>
