<template>
	<ion-card :class="'balance-card'">
		<ion-card-content class="balance-card__content">
			<ion-text class="balance-card__amount-required">
				<span>{{ balance > 0 ? `(${formatDollarAmount(balance)})` : `${formatDollarAmount(Math.abs(balance) || 0)}` }}</span>
			</ion-text>
			<m-d-i-cash class="ion-text-end heading__icon heading__icon--mdi"></m-d-i-cash>
		</ion-card-content>
		<ion-card-title class="balance-card__title">
			<p>{{ courseName }}</p>
		</ion-card-title>
		<ion-card-content class="ion-no-padding">
			<ion-button
				@click="goTo()"
				color="tertiary"
				class="balance-card__action-btn"
				expand="block"
			>{{ allowCreditCardPayment ? 'Pay Now' : 'View Transactions' }}</ion-button>
		</ion-card-content>
	</ion-card>
</template>

<script>
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonIcon
} from '@ionic/vue';
import { useStore } from 'vuex';
import envConfig from "@/core/config/env.config";
import { api } from "@/core/api/api";
import MDICash from '@/view/components/icons/MDICash.vue';

export default {
	name: 'Balance Card',
	props: {
		mdiIcon: {
			default: '',
			type: String
		},
		balance: {
			default: 0,
			type: Number
		},
		studentCourseID: {
			default: '',
			type: String
		},
		courseName: {
			default: '',
			type: String
		},
	},
	components: {
		IonButton,
		IonCard,
		IonCardContent,
		IonCardHeader,
		IonCardTitle,
		IonIcon,
		MDICash
	},
	methods: {
		formatDollarAmount(amount) {
			return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amount);
		},
		logData() {
			api.logMobileActivity({
				institution: this.store.getters.institutionSettings.slug,
				event: 'View Billing',
				affectedUserID: this.store.getters.user.userID,
				additionalData:{
					email: this.store.getters.user.email,
					activeUser: this.store.getters.selectedUser,
					currentAppVersions: this.store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Navigation'],
					status: 'Success'
				}
			});
		},
		goTo(){
			this.logData();
			this.store.commit('showSettingsModal', {type: 'billing', studentCourseID: this.studentCourseID});
		}
	},
	computed: {
		allowCreditCardPayment () {

			return ((this.$store.state?.institutionSettings?.registrationInstitutionConfig?.payment.allowCreditCardPayment || null)
				&& this.store.getters.featureFlags?.inAppBilling)
		}
	},
	setup() {
		const store = useStore();

		return {
			store
		};
	}
}
</script>

<style lang="scss" scoped>

.balance-card {
	box-shadow: var(--box-shadow);
	color: var(--ion-color-dark);
	display: flex;
	flex-direction: column-reverse;
	margin: 0;
	padding: 15px;

	&__title {
		margin-bottom: 0;
		order: 2;
		padding: 0;
		text-align: left;
	}

		ion-card-title {
			font-size: 1em;
			font-weight: 700;
			padding: 0 30px 0 0;
		}

	&__content {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		order: 3;
		padding: 0;
		position: relative;
	}

		&__amount-required {
			align-items: flex-end;
			display: flex;
			font-weight: 700;
			justify-content: flex-end;

			span {
				display: block;
				font-size: 2em;
				line-height: 1.05em;

				&:first-child {
					font-size: 3.25em;
					line-height: 0.75em;
					margin-right: 2px;
				}

				&:last-child {
					font-size: 2.5em;
					line-height: 0.75em;
				}
			}
		}

		&__amount-icon {
			color: rgba( var(--ion-color-dark-rgb), 0.75);
		}

		&__action-btn {
			padding: 0;
			margin-top: 10px;
		}
}

.heading {
	align-items: center;
	display: flex;
	margin-top: 20px;

	&__icon {
		color: rgba( var(--ion-color-dark-rgb), 0.75);
		position: absolute;
		right: -4px;
		top: -2px;

		&--mdi {
			fill: rgba( var(--ion-color-dark-rgb), 0.75);
			height: 32px;
			width: 32px;
		}
	}
}
</style>