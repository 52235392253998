<template>
	<ion-header v-if="!loading">
		<ion-toolbar class="instructor-toolbar" color="primary">
			<ion-buttons slot="start" v-if="backButton">
				<ion-button onclick="$router.go(-1)">
					<ion-icon :ios="chevronBackOutline" :md="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>

			<ion-buttons slot="end">
				<ion-button
					v-if="!isConnectedToInternet"
					fill="solid"
					color="danger"
					class="tool-bar-button"
					@click="showConnectionModal()"
				>
					<MaterialDesignIcon color="light" class="wifi-alert-icon" icon="wifi-alert" />

				</ion-button>
			</ion-buttons>
			<ion-select
				:interface-options="{ header: 'Instructor' }"
				slot="end"
				:value="store.getters.activeInstructor?.userID"
				@ionChange="switchInstructor"
				class="instructor-select"
				v-if="instructors?.length > 1 && routeIsHome"
			>
				<ion-select-option
						:key="instructor.userID"
						:value="instructor.userID"
						v-for="instructor in instructors"
					>
					{{instructor.firstName}} {{instructor.lastName}}
				</ion-select-option>
			</ion-select>
			<ion-text
				slot="end"
				v-if="!routeIsHome && instructors?.length > 1"
			>
				{{store.getters.activeInstructor?.firstName}} {{store.getters.activeInstructor?.lastName}}
			</ion-text>
			<ion-avatar slot="end" class="user-thumbnail" @click="showSettingsModal()">
				<img :src="gravatarUrl" />
			</ion-avatar>
		</ion-toolbar>
	</ion-header>
</template>

<script>
import { chevronBackOutline, settings, logOut, notifications, menu } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import MaterialDesignIcon from "../MaterialDesignIcon.vue";
import { md5 } from "js-md5";
import { IonAvatar } from '@ionic/vue';

export default defineComponent({
	data() {
		return {
			loading: false
		}
	},
	name: "Header",
	components: {
		IonAvatar,
		MaterialDesignIcon
	},
	props: {
		backButton: {
			type: Boolean
		}
	},
	setup() {
		const isOpenRef = ref(false);
		const route = useRoute();

		return {
			chevronBackOutline,
			isOpenRef,
			menu,
			notifications,
			settings,
			route,
			logOutIcon: logOut,
			store: useStore(),
			router: useRouter(),
		};
	},
	computed: {
		isConnectedToInternet() {
			return this.store.getters.connectionStatus?.connected;
		},
		routeIsHome() {
			return this.route.name === "Instructor Home";
		},
		gravatarUrl() {
			const userEmail = this.store.getters.user?.email;

			return `https://www.gravatar.com/avatar/${md5(userEmail)}?d=identicon`;
		},
		instructors() {
			if (!this.store.getters.instructors) {
				return [];
			}
			return this.store.getters.instructors;
		}
	},
	methods: {
		switchInstructor(event) {
			const foundInstructor = this.store.getters.instructors?.find((instructor) => {
				return instructor.userID === event.detail.value;
			});
			if (foundInstructor) {
				this.store.commit('setActiveInstructor', foundInstructor);
			}
		},
		showSettingsModal() {
			this.store.commit('showSettingsModal');
		},
		showConnectionModal() {
			this.store.commit('showConnectionModal');
		},
		hideConnectionModal() {
			this.store.commit('hideConnectionModal')
		}
	}
});
</script>

<style lang="scss" scoped>
.instructor-toolbar {
	border: none;
	--max-height: 50px !important;
	--padding-top: 0;
	--padding-bottom: 0;
	--border-width: 0 !important;
}

.user-thumbnail {
	height: 56px;
	width: 56px;
	padding: 10px;
}

.tool-bar-button {
	--border-radius: 0 !important;
	height: 56px;
	width: 43px;
}

.wifi-alert-icon {
	height: 25px;
	width: 25px;
	color: #FFFFFF;
}

.header-help-settings-buttons {
	height: 56px !important;
	align-items: stretch !important;
	margin: 0 !important;
}

ion-select.instructor-select::part(text) {
	color: #ffffff;
}

ion-select.instructor-select::part(icon) {
	color: #ffffff;
	opacity: 1;
}

ion-thumbnail.md {
	--size: 2.5rem;
	--border-radius: 2rem;
}
</style>