<template>
	<ion-card class="update-availabe-card">
		<ion-card-content class="update-availabe-card__content">
			<car-on-lift />
			<ion-text>
				<p class="update-availabe-card__message">There is a new version of the DriveTrak App available!</p>
				<ion-button
					:href="store.getters.appUpdateURL"
					color="tertiary"
				>
					UPDATE
				</ion-button>
			</ion-text>
		</ion-card-content>
	</ion-card>
</template>

<script>
import {
	IonCard,
	IonCardContent
} from '@ionic/vue';
import { useStore } from "vuex";
import CarOnLift from './graphics/CarOnLift.vue';

export default {
	name: 'Update Available Card',
	components: {
		CarOnLift,
		IonCard,
		IonCardContent
	},
	setup() {
		const store = useStore();

		return {
			store
		}
	}
}
</script>

<style lang="scss" scoped>
.update-availabe-card {
	text-align: center;

	* {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	p {
		font-size: 1em;
		font-weight: 700;
		line-height: 1.5em;
	}
}
</style>