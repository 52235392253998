<template>
	<ion-modal class="skill-summary-modal" :is-open="show" ref="skill-summary-dialog" @willDismiss="$emit('closeModal')">
		<ion-header color="primary">
			<ion-toolbar>
				<ion-title>{{ skill.name }}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="skill-summary-modal__totals">
				<div>
					<DataBar color="danger" icon="closeThick" title="Failed" :value="skill.failures" />
					<DataBar color="success" icon="checkBold" title="Success" :value="skill.successes" />
				</div>
				<DataBar
					icon="checkBold"
					:key="index"
					:title="feedback.message"
					:value="feedback.quantity"
					:valueIcon="true"
					v-for="(feedback, index) in feedbackTotals"
				/>
			</div>
			<div class="ion-padding">
				<Heading
					class="ion-margin-bottom"
					mdiIcon="listStatus"
					:noTopMargin="true"
					title="Attempts"
				/>
				<AttemptCard
					:attempt="attempt"
					:attemptNumber="orderedAttempts.length - index"
					class="ion-margin-bottom"
					:key="index"
					@editAttempt="$emit('switchEdit', attempt.id)"
					v-for="(attempt, index) in orderedAttempts"
				/>
			</div>
			<ion-button
				@click="$emit('closeModal')"
				expand="full"
				slot="fixed"
			>
				Close
			</ion-button>
		</ion-content>
	</ion-modal>
</template>

<script>
import { defineComponent } from 'vue';
import { informationCircle } from "ionicons/icons";
import AttemptCard from '@/view/components/cards/AttemptCard.vue';
import DataBar from '@/view/components/content/DataBar.vue';
import Heading from '@/view/components/Heading.vue';
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";

export default defineComponent({
	components: {
		AttemptCard,
		DataBar,
		Heading,
		MaterialDesignIcon
	},
	computed: {
		feedbackTotals() {
			if (!this.skill.attempts) { return []; }

			let feedbackTotals = [];

			this.skill.attempts.forEach((attempt) => {
				attempt.feedbackItems.forEach((feedback) => {

					let feedbackIndex = feedbackTotals.findIndex((total) => total.id === feedback.id);

					if (feedbackIndex !== -1) {
						feedbackTotals[feedbackIndex].quantity++;
					} else {
						feedbackTotals.push({
							id: feedback.id,
							message: feedback.feedbackMessage,
							quantity: 1
						});
					}
				});
			});

			feedbackTotals.sort((a, b) => {
				return b.quantity - a.quantity;
			});

			return feedbackTotals;
		},
		orderedAttempts() {
			if (!this.skill.attempts) { return []; }

			let orderedAttempts = [...this.skill.attempts];

			orderedAttempts.sort((a, b) => {
				return b.date - a.date;
			});

			return orderedAttempts;
		}
	},
	data() {
		return {
		}
	},
	methods: {
	},
	props: {
		show: {
			default: false,
			type: Boolean
		},
		skill: {
			default: {},
			type: Object
		}
	},
	setup() {

		return {
			informationCircle
		}
	}
});
</script>

<style lang="scss" scoped>
.skill-summary-modal {
	&__totals {
		display: flex;
		flex-direction: column;
		gap: 1px;
		width: 100%;

		div {
			width: 100%;

			&:first-child {
				display: flex;
				flex-direction: row;
			}
		}
	}
}

ion-toolbar {
	--background: var(--ion-color-primary);
	border: none;
	--color: #FFFFFF;
}

ion-content {
	height: calc(100% - 117px);
	padding-bottom: 10px;
}

ion-button[slot='fixed'] {
	--background: var(--ion-color-primary-tint);
	bottom: -61px;
	--color: var(--ion-color-primary-contrast, #FFFFFF);
	height: 60px;
	left: 0;
	margin: 0;
	width: 100%;
}

</style>