import { createRouter, createWebHistory } from '@ionic/vue-router';
import { alertController } from '@ionic/vue';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { store } from './store/store';
import { nextTick } from 'vue';
import { api } from './api/api';
import envConfig from './config/env.config';

// Instructor Pages: Static Import to Allow offline use
// TODO: use service worker for browser offline
import InstructorHome from '../view/pages/instructor/InstructorHome.page.vue';
import InstructorSession from '../view/pages/instructor/InstructorSession.page.vue';
import SessionInProgress from '../view/pages/instructor/SessionInProgress.page.vue';
import HomePage from '../view/pages/Home.page.vue';
import LoginPage from '../view/pages/Login.page.vue';
import LessonsPage from '../view/pages/lessons/Lessons.page.vue';
import LessonPage from '../view/pages/lessons/Lesson.page.vue';
import SummaryPage from '../view/pages/lessons/Summary.page.vue';
import QuizTestPage from '../view/pages/lessons/QuizTest.page.vue';
import ResultsPage from '../view/pages/lessons/Results.page.vue';
import SchedulerPage from '../view/pages/scheduler/Scheduler.page.vue';
import MinimumVersionErrorPage from '../view/pages/MinimumVersionError.page.vue';
import NotificationsPage from '@/view/pages/Notifications.page.vue';


const routes: Array<RouteRecordRaw> = [
	{
		path: '/undefined',
		redirect: '/home',
		meta: { studentOnly: true, parentAccess: true }
	},
	{
		path: '/minimumVersionError',
		name: 'Minimum Version Error',
		meta: { parentAccess: true },
		component: MinimumVersionErrorPage
	},
	{
		path: '/home',
		name: 'Home',
		meta: { studentOnly: true, parentAccess: true },
		component: HomePage
	},
	{
		path: '/login',
		name: 'Login',
		meta: { parentAccess: true },
		component: LoginPage
	},
	{
		path: '/scheduler/:instanceDate?',
		name: 'Scheduler',
		meta: { parentAccess: true },
		component: SchedulerPage,
		beforeEnter: [checkBTWCourseOnHold, checkSchedulerAccess],
	},
	{
		path: '/lessons',
		name: 'Lessons',
		meta: { studentOnly: true, parentAccess: true },
		component: LessonsPage,
	},
	{
		path: '/lessons/:lessonID/:topicID?',
		name: 'Lesson',
		meta: { studentOnly: true, parentAccess: false },
		component: LessonPage,
		beforeEnter: [checkDailyTwoHourLimitMet]
	},
	{
		path: '/lessons/:lessonID/:topicID?/:resourceID?',
		name: 'Lesson',
		meta: { studentOnly: true, parentAccess: false },
		component: LessonPage,
		beforeEnter: [checkDailyTwoHourLimitMet]
	},
	{
		path: '/quiztest/:lessonID',
		name: 'Test',
		meta: { studentOnly: true, parentAccess: false },
		component: QuizTestPage,
		beforeEnter: [checkDailyTwoHourLimitMet]
	},
	{
		path: '/quiztest/:lessonID/:topicID',
		name: 'Quiz',
		meta: { studentOnly: true, parentAccess: false },
		component: QuizTestPage,
		beforeEnter: [checkDailyTwoHourLimitMet]
	},
	{
		path: '/summary/:lessonID',
		name: 'Lesson Summary',
		meta: { studentOnly: true, parentAccess: false },
		component: SummaryPage
	},
	{
		path: '/summary/:lessonID/:topicID',
		name: 'Topic Summary',
		meta: { studentOnly: true, parentAccess: false },
		component: SummaryPage
	},
	{
		path: '/results/:lessonID/:progressItemID',
		name: 'Test Results',
		meta: { studentOnly: true, parentAccess: false },
		component: ResultsPage
	},
	{
		path: '/results/:lessonID/:topicID/:progressItemID',
		name: 'Quiz Results',
		meta: { studentOnly: true, parentAccess: false },
		component: ResultsPage
	},
	{
		path: '/instructor/home',
		name: 'Instructor Home',
		meta: { instructorOnly: true },
		component: InstructorHome,
		beforeEnter: [checkInstructorAppAccess],
	},
	{
		path: '/instructor/session',
		name: 'Instructor Session',
		meta: { instructorOnly: true },
		component: InstructorSession,
		beforeEnter: [checkInstructorAppAccess],
	},
	{
		path: '/instructor/session/inProgress',
		name: 'Session In Progress',
		meta: { instructorOnly: true },
		component: SessionInProgress,
		beforeEnter: [checkInstructorAppAccess],
	},
	{
		path: '/notifications',
		meta: { parentAccess: true },
		name: 'Notifications',
		component: NotificationsPage
	},
];

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
});

function checkSchedulerAccess(path) {
	if(!institutionHasAccess('schedulerAccess')){
		logActivity(path, 'Failure', 'No Scheduler Access');
		return '/home';
	}
}

async function checkInstructorAppAccess(path) {
	if(!institutionHasAccess('instructorApp')){
		await store.dispatch('logOut');
		return '/login';
	}
}

function checkELearningAccess(path) {
	if(!institutionHasAccess('eLearning') || !selectedUserHasELearningCourse()) {
		const details = !institutionHasAccess('eLearning') ? 'No Classroom Access' : 'Student Missing eLearning Course';
		logActivity(path, 'Failure', details);
		router.replace('/home');
	}
}

async function checkELearningInPersonDate(path) {
	if(store.getters.eLearningInPersonCourseDateLock && !store.getters.user.isParent){
		logActivity(path, 'Failure', 'eLearning Locked for In Person Class')
		router.replace('/home');
		const alert = await alertController.create({
			backdropDismiss: false,
			buttons: [
				{
					text: 'Close',
					handler: () => {
						alert.dismiss()
					}
				}
			],
			cssClass: 'ion-text-center',
			header: 'Course Locked',
			message: `<p class="ion-margin-bottom">This course is locked today due to an in person course being scheduled.</p>
			<p>Contact ${store.getters.institutionSettings.name} at <br />${store.getters.institutionSettings.phone}</p>`
		});
		alert.present();
	}
}

function selectedUserHasELearningCourse() {
	return Boolean(store.getters.selectedUserClassroomStudentCourseID);
}

function institutionHasAccess(accessArea: string) {
	return Boolean(store.getters.institutionSettings?.moduleSettings?.[accessArea]);
}

async function checkBTWCourseOnHold() {
	if (store.getters.btwCourseOnHold) {
		logActivity('/scheduler', 'Failure', 'BTW Course On Hold');
		router.replace('/home');
		const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Close',
						handler: () => {
							alert.dismiss()
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'Administrative Hold',
				message: store.getters.onHoldMessage
			});
		alert.present();
	}
}

async function checkELearningCourseOnHold(path) {
	if (store.getters.eLearningCourseOnHold) {
		logActivity(path, 'Failure', 'eLearning Course On Hold');
		router.replace('/home');
		const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Close',
						handler: () => {
							alert.dismiss()
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'Administrative Hold',
				message: store.getters.onHoldMessage
			});
		alert.present();
	}
}

async function checkELearningCourseExtensionNeeded(path) {
	if (store.getters.eLearningExtensionNeeded) {
		logActivity(path, 'Failure', 'eLearning Extension Needed');
		const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Close',
						handler: () => {
							alert.dismiss()
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'eLearning Extension',
				message: 'eLearning Extension Needed'
			});
		alert.present();

		return '/lessons';
	}
}

function hasELearningDateSet() {
	const oStudent = store.getters.students.find(s => s.userID === store.getters.selectedUser)
	const selectedUserClassroomStudentCourseIDSplit = store.getters.selectedUserClassroomStudentCourseID.split('::');
	const studentCourseID = selectedUserClassroomStudentCourseIDSplit[2];
	const studentCourse = oStudent.courses.find(sc => sc.studentCourseID === studentCourseID);
	return studentCourse.dates.eLearningStart;
}

function checkDailyTwoHourLimitMet() {
	if (store.getters.dailyELearningLimitMet?.formatted) {
		logActivity('/lessons', 'Failure', 'Daily Two Hour Limit Met');
		return '/lessons';
	} else {
		return null;
	}
}

function comparePathRootBeforeLog(toPath, fromPath, status, details) {
	if(fromPath !== '/'){
		const getPathRoot = (path) => path.split('/').slice(0, 2).join('/');
		const toPathRoot = getPathRoot(toPath);
		const fromPathRoot = getPathRoot(fromPath);

		if(toPathRoot !== fromPathRoot) logActivity(toPathRoot, status, details);
	}
}

function logActivity(path, status, details) {
	api.logMobileActivity({
		institution: store.getters.institutionSettings.slug,
		event: path,
		affectedUserID: store.getters.user.userID,
		additionalData:{
			email: store.getters.user.email,
			activeUser: store.getters.selectedUser,
			currentAppVersions: store.getters.featureFlags.appVersions,
			mobileAppVersion: envConfig.version,
			tags: ['Navigation'],
			status: status,
			details: details
		}
	});
}

router.beforeEach(async (to, from, next) => {
	// Check for the minimum version and bail if not meeting requirement.
	if ((!store.getters.hasMinimumVersion || !store.getters.isCompatibleBrowser) && to?.path !== '/minimumVersionError') {
		logActivity(to.path, 'Failure', !store.getters.hasMinimumVersion ? 'Minimum Version Error' : 'Browser Out of Date');
		return next('/minimumVersionError')
	} else if (store.getters.hasMinimumVersion && store.getters.isCompatibleBrowser && to?.path === '/minimumVersionError') {
		return next('/home');
	}

	if (store.getters.isAuthenticated) {
		if (to?.path === '/login') {
			if (store.getters.user.isInstructor || store.getters.user.isOwner || store.getters.user.isAdmin) {
				return next('/instructor/home');
			}
			return next('/home');
		}
	}

	if (to?.path === '/login' && store.getters.isAuthenticated) {
		if (store.getters.user.isInstructor || store.getters.user.isOwner || store.getters.user.isAdmin) {
			return next('/instructor/home');
		}
		return next('/home');
	}
	if (to?.path !== '/login' && !store.getters.isAuthenticated) {
		store.dispatch('setAttemptedRoute', to?.path);
		return next('/login');
	}

	if (store.getters.user.isParent && !to.meta?.parentAccess) {
		router.replace('/home');
	}

	if ((store.getters.user.isInstructor || store.getters.user.isOwner || store.getters.user.isAdmin) && ['/','/home', '/undefined'].includes(to?.path)) {
		return next('/instructor/home')
	}

	// Prevent students from accessing instructor-only pages and vice-versa
	if (to.meta?.studentOnly) permitNavigation: {
		if (store.getters.user.isDev) break permitNavigation;
		if (store.getters.user.isOwner) break permitNavigation;
		if (store.getters.user.isAdmin) break permitNavigation;
		if (store.getters.user.isParent) break permitNavigation;
		if (store.getters.user.isInstructor) return next('/instructor/home');
	}

	if (to.meta?.instructorOnly) permitNavigation: {
		if (store.getters.user.isDev) break permitNavigation;
		if (store.getters.user.isOwner) break permitNavigation;
		if (store.getters.user.isAdmin) break permitNavigation;
		if (!store.getters.user.isInstructor) return next('/home');
	}

	// Redirect to the role-specific landing pages if navigating to the base route
	if (to?.path === '/') {
		if (store.getters.user.isInstructor || store.getters.user.isOwner || store.getters.user.isAdmin) return next('/instructor/home');

		return next('/home');
	}

	if (to?.path.includes('/lessons')) {
		checkELearningAccess('/lessons')
		checkELearningCourseOnHold('/lessons')
		checkELearningInPersonDate('/lessons')

		if(!store.getters.user.isParent && store.getters.selectedSyllabus.id && !Boolean(hasELearningDateSet()) && !store.getters.eLearningCourseOnHold){
			await store.dispatch('setELearningStartDate')
		}

		if(to?.path !== '/lessons') {
			const redirect = checkDailyTwoHourLimitMet();
			if (redirect) return next(redirect);

			const extRedirect = await checkELearningCourseExtensionNeeded('/lessons');
			if (extRedirect) return next(extRedirect);
		}
	}

	if (to?.path.includes('/quiztest') || to?.path.includes('/summary') || to?.path.includes('/results')){
		const paths = ['/quiztest', '/summary', '/results'];
		const path = paths.find(path => to?.path.includes(path))
		checkELearningAccess(path)
		checkELearningCourseOnHold(path)
		await checkELearningCourseExtensionNeeded(path)
		checkELearningInPersonDate(path)

		if(!store.getters.user.isParent && store.getters.selectedSyllabus.id && !Boolean(hasELearningDateSet()) && !store.getters.eLearningCourseOnHold){
			await store.dispatch('setELearningStartDate')
		}
	}

	if (to?.path.includes('/scheduler')) {
		checkSchedulerAccess('/scheduler')
		checkBTWCourseOnHold()
	}

	comparePathRootBeforeLog(to.path, from.path, 'Success', null);

	next(true);
});

router.afterEach((to, from) => {
    nextTick(() => {
        document.title = String(to.name) || 'Drivetrak Mobile';
    });
});

export default router;
