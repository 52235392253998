<template>
	<div class="add-student-modal">
		<ion-button
			class="add-student-modal__button"
			@click="showStudentAddModal = true;"
			:disabled="loading"
		>
			<template v-if="!loading">
				<MaterialDesignIcon icon="plus" color="green" />
				<span>Add {{ sessionType === 'driver' ? 'Driver' : 'Observer' }}</span>
			</template>
			<ion-spinner color="success" name="circular" v-else></ion-spinner>
		</ion-button>
		<!-- TODO: work on the animation for this modal close; The default leaveAnimation was causing a flash of the modal to 100% of the vh. -->
		<ion-modal
			animated="false"
			class="add-student-modal__modal"
			:handle="false"
			:is-open="showStudentAddModal"
			@didDismiss="showStudentAddModal = false"
		>
			<ion-content class="modal-bottom">
				<ion-searchbar
					class="add-student-modal__search"
					placeholder="Search Students"
					v-model="searchTerm"
				></ion-searchbar>
				<NoticeMessage
					icon="information"
					text="Begin searching to see a list of students."
					v-if="!searchTerm"
				/>
				<NoticeMessage
					icon="information"
					text="No students found."
					v-if="searchTerm && searchedStudents.length === 0"
				/>
				<div class="add-student-modal__student-list" v-if="searchTerm && searchedStudents.length">
					<StudentSelector
						:student="student"
						v-bind:key="student.userID"
						v-for="student in searchedStudents"
						:disabled="student.classroomInstances.some(({start, end}) => areIntervalsOverlapping(
							{start: new Date(start), end: new Date(end)},
							{start: new Date(session.sessionStart), end: new Date(session.sessionEnd)}
						)) ? 'Unavailable (Classroom)' : null"
						@studentAdd="showStudentAddModal = false; $emit('addStudent', student, sessionType);"
					/>
				</div>
			</ion-content>
		</ion-modal>
	</div>
</template>

<script>
import NoticeMessage from '@/view/components/content/NoticeMessage.vue';
import { useStore } from "vuex";
import {
	IonAlert,
	IonButton,
	IonInput,
	IonModal,
	IonSearchbar
} from "@ionic/vue";
import { ref, onMounted, computed } from "vue";
import { useQuery } from "@vue/apollo-composable";
import {
	USERS_FOR_SCHEDULER_QUERY
} from "@/core/api/drivingApi";
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";
import StudentSelector from "@/view/pages/instructor/StudentSelector.components.vue";
import { areIntervalsOverlapping } from 'date-fns';

export default {
	components: {
		IonAlert,
		IonButton,
		IonInput,
		IonModal,
		IonSearchbar,
		MaterialDesignIcon,
		NoticeMessage,
		StudentSelector
	},

	props: {
		sessionType: {
			type: String,
			required: true
		},
		session: {
			type: Object,
			required: true
		}
	},
	methods: {
		areIntervalsOverlapping
	},
	computed: {
		loading() {
			return this.studentsForSchedulerQueryLoading;
		},
		searchedStudents() {
			// Requiring a search of at least 2 characters due to a delay in rendering when all students are returned.
			if (!this.searchTerm || this.searchTerm.length < 2) {
				return [];
			}

			const lowerCaseSearchTerm = this.searchTerm.toLowerCase();

			return this.students.filter((student) => {
				return (
					student?.firstName
						?.toLowerCase()
						.includes(lowerCaseSearchTerm) ||
					student?.lastName
						.toLowerCase()
						.includes(
							lowerCaseSearchTerm ||
								student?.email
									?.toLowerCase()
									.includes(lowerCaseSearchTerm)
						)
				);
			});
		},
	},
	data() {
		return {
			addStudentModalOpening: false,
			searchTerm: '',
			showStudentAddModal: false
		}
	},
	name: 'AddStudentModal',
	setup() {
		const store = useStore();
		const studentsForSchedulerQuery = useQuery(
			USERS_FOR_SCHEDULER_QUERY,
			() => ({
				institution: store.getters.user.institutions[0],
				studentCourseStatus: ['registered']
			}),
			{
				fetchPolicy: (store.getters.connectionStatus.connected) ? 'cache-and-network' : 'cache-only',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => !!store.getters.isAuthenticated)
			}
		);

		studentsForSchedulerQuery.onResult(({data}) => {
			if (data?.usersForScheduler?.length) {
				store.commit('setSchedulerStudents', data.usersForScheduler);
			}
		});

		const students = computed(() => store.getters.schedulerStudents);

		return {
			studentsForSchedulerQueryLoading: studentsForSchedulerQuery.loading,
			students,
		}
	}
}
</script>

<style lang="scss" scoped>
.add-student-modal {

	&__button {
		--background: transparent;
		border: 2px dashed var(--ion-color-success, #00b494);
		border-radius: 3px;
		--box-shadow: none;
		color: var(--ion-color-success);
		font-size: 16px;
		font-weight: 700;
		height: auto;
		--padding-bottom: 20px;
		--padding-top: 20px;
		width: 100%;

		span {
			text-transform: initial;
		}
	}

	&__modal,
	ion-modal.add-student-modal__modal {
		padding-top: 170px;

		ion-content {
			--background: var(--ion-color-secondary);
		}
	}

		&__search {
			--background: var(--ion-color-light);
			border-bottom: 1px solid var(--ion-color-primary-lighten-3);
			padding: 0;
		}

		&__information-text {
			margin-top: 30px;
			text-align: center;

			.material-design-icon {
				margin-bottom: 15px;
			}
		}

		&__student-list {
			height: calc(100% - 43px);
			overflow-y: scroll;
			padding: 15px;

			ion-item {
				box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
			}
		}
}
</style>