<template>
	<ion-button class="session-status-button" @click="presentStatusActionSheet" :color="statusStyles.color">
		<ion-icon :icon="statusStyles.icon" slot="icon-only"></ion-icon>
	</ion-button>
</template>

<script>
import { checkmarkCircle, closeCircle, closeCircleOutline, removeCircle, removeCircleOutline } from "ionicons/icons";
import { defineComponent, ref } from 'vue';
import { IonButton, actionSheetController } from '@ionic/vue';

export default defineComponent({
	components: {
		IonButton
	},
	computed: {
		statusStyles() {
			switch(this.sessionStatus) {
				case 'NO_SHOW_WITH_FEE':
					return {
						color: 'danger',
						icon: closeCircle
					};
				case 'NO_SHOW_WITHOUT_FEE':
					return {
						color: 'danger',
						icon: closeCircleOutline
					};
				case 'CANCELED':
					return {
						color: 'warning',
						icon: removeCircleOutline
					};
				case 'CANCELED_WITH_FEE':
					return {
						color: 'warning',
						icon: removeCircle
					};
				default: // COMPLETED and SCHEDULED
					return {
						color: 'success',
						icon: checkmarkCircle
					};
			}
		}
	},
	name: "SessionStatusButton",
	props: {
		sessionStatus: {
			default: 'COMPLETED',
			type: String
		},
	},
	setup(props, context) {
		const result = ref('');

		// TODO: After upgrading to Ionic 7 update this to be inline.
		const presentStatusActionSheet = async() => {
			const actionSheet = await actionSheetController.create({
				buttons: [
					{
						cssClass: 'action-sheet-button--success',
						data: 'COMPLETED',
						icon: checkmarkCircle,
						text: 'Complete',
					},
					{
						cssClass: 'action-sheet-button--danger',
						data: 'NO_SHOW_WITH_FEE',
						icon: closeCircle,
						text: 'No Show (With Fee)',
					},
					{
						cssClass: 'action-sheet-button--danger action-sheet-button--top-spacer',
						data: 'NO_SHOW_WITHOUT_FEE',
						icon: closeCircleOutline,
						text: 'No Show (No Fee)',
					},
					{
						cssClass: 'action-sheet-button--warning',
						data: 'CANCELED_WITH_FEE',
						icon: removeCircle,
						text: 'Canceled (With Fee)',
					},
					{
						cssClass: 'action-sheet-button--warning',
						data: 'CANCELED',
						icon: removeCircleOutline,
						text: 'Canceled (No Fee)',
					}
				],
				cssClass: 'status-action-sheet'
			});

			await actionSheet.present();
			let newSessionStatus = await actionSheet.onDidDismiss();
			context.emit('update', newSessionStatus.data);
		}

		return {
			checkmarkCircle,
			closeCircle,
			closeCircleOutline,
			presentStatusActionSheet,
			removeCircle,
			removeCircleOutline,
			result
		}
	}
});
</script>

<style lang="scss">
ion-button.session-status-button {
	--border-radius: 6px 6px 0 0;
	height: 100%;
	margin: 0;
}

ion-action-sheet.status-action-sheet {

	.action-sheet-container {
		padding-bottom: 15px;
		padding-left: 5%;
		width: 95%;

		.action-sheet-group {
			border-radius: 6px;
			overflow: hidden;

			.action-sheet-button {
				border-top: 1px solid var(--ion-color-grey-darken-1);

				&:first-child {
					border-top: none;
				}

				&:hover {
					color: var(--ion-color-light);

					ion-icon {
						color: var(--ion-color-light);
					}
				}

				&--top-spacer {
					border-top: 6px solid var(--ion-color-grey-darken-1);
				}

				&--success {
					color: var(--ion-color-success);

					ion-icon {
						color: var(--ion-color-success);
					}

					&:hover {
						background: var(--ion-color-success);
					}
				}

				&--warning {
					color: var(--ion-color-warning);

					ion-icon {
						color: var(--ion-color-warning);
					}

					&:hover {
						background: var(--ion-color-warning);
					}
				}

				&--danger {
					color: var(--ion-color-danger);

					ion-icon {
						color: var(--ion-color-danger);
					}

					&:hover {
						background: var(--ion-color-danger);
					}
				}
			}
		}
	}
}
</style>