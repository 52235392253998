<template>
	<ion-modal id="instructor-modal" :is-open="showInstructorModal" @willDismiss="hideInstructorModal()">
		<ion-content scroll-y="false" class="instructor-modal-content">
            <span class="instructor-modal-content__title">Confirm Student Addition</span>
			<ion-text color="light">
				<p class="flex-align"><MaterialDesignIcon icon="account" color="white" /> <span>{{ data.firstName }} {{ data.lastName }}</span></p>
                <p class="flex-align"><MaterialDesignIcon icon="school" color="white" /> <span>{{ data.school.name }}</span></p>
                <p class="flex-align"><MaterialDesignIcon icon="phone" color="white" /> <span>{{ data.phone }}</span></p>
			</ion-text>
            <div class="instructor-modal-content__buttons">
                <ion-button
                    class="ion-margin-vertical modal-button cancel-button"
                    expand="block"
                    part="native"
                    @click="hideInstructorModal()"
                >
                    CANCEL
                </ion-button>
                <ion-button
                    class="ion-margin-vertical modal-button add-button"
                    color="success"
                    expand="block"
                    part="native"
                    @click="confirmStudentAdd()"
                >
                    ADD
                </ion-button>
            </div>
		</ion-content>
	</ion-modal>
</template>

<script>
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { defineComponent } from 'vue';
import { IonButton, IonButtons, IonCard, IonIcon, IonTextarea } from "@ionic/vue";

export default defineComponent({
	components: {
		IonButton,
		IonButtons,
		IonCard,
		IonIcon,
		IonTextarea,
		MaterialDesignIcon,
	},
    props: {
		showInstructorModal: {
			type: Boolean,
			default: false
		},
        data: {
            type: Object,
            default: {}
        }
    },
	methods: {
		hideInstructorModal() {
            this.$emit('dismissInstructorModal');
        },
        confirmStudentAdd() {
            this.$emit('confirmStudentAdd', this.data);
        }
	},
	setup() {
		return {}
	}
});
</script>


<style lang="scss" scoped>
#instructor-modal {
	--background: var(--ion-color-primary-tint, #405362);
	--max-height: 225px;
	--max-width: 80%;
	--border-radius: 6px;
}

ion-content {
    --background: var(--ion-color-primary-tint, #405362);
}

.instructor-modal-content {
	align-items: center;
	display: flex;
	flex-direction: row;


    &__title {
        display: flex;
        justify-content: center;
        padding: 10px;
        font-size: 20px;
        font-weight: 700;
        color: #FFFFFF;
    }

    &__buttons {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 10px;
        gap: 10px;

        .modal-button {
            margin:0;
            width: 100%;
            span {
                color: #FFFFFF;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .cancel-button::part(native)  {
            font-weight: 700;
            background-color: var(--ion-color-primary-lighten-3, #6B7985);
        }
    }
}

ion-content::part(scroll) {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 15px;
}
ion-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 700;
    padding: 10px;
}

.flex-align {
    display: flex;
    align-items: center;
    gap: 10px;
}

</style>