<template>
	<ion-card
		class="session-card-skeleton"
	>
		<ul>
			<li>
				<ion-icon :icon="time" /> <IonSkeletonText :animated="true" style="height: 18px; width: 150px;" />
			</li>
			<li>
				<ion-icon :icon="location" />
				<span>
					<IonSkeletonText :animated="true" style="height: 18px; width: 150px;" />
				</span>
			</li>
		</ul>
		<ul>
			<li>
				<MaterialDesignIcon icon="gauge" />
				<span><IonSkeletonText :animated="true" style="height: 18px; width: 150px;" /></span>
			</li>
			<li>
				<MaterialDesignIcon icon="eye" />
				<span><IonSkeletonText :animated="true" style="height: 18px; width: 150px;" /></span>
			</li>
		</ul>
	</ion-card>
</template>

<script>
import {
	location,
	time,
} from "ionicons/icons";
import { IonSkeletonText } from "@ionic/vue";
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";

export default {
	components: {
		IonSkeletonText,
		MaterialDesignIcon
	},
	setup() {
		return {
			location,
			time
		};
	}
}
</script>

<style lang="scss">
.session-card-skeleton {

	background: var(--ion-color-light);
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	border-left: 10px solid var(--ion-color-primary);
	color: var(--ion-color-primary);
	font-size: 16px;
	margin: 0 0 20px 0;

	ul {
		display: flex;
		flex-direction: column;
		gap: 5px;
		list-style: none;
		margin: 0;
		padding: 10px;

		&:first-child {
			font-weight: 700;
			background: var(--ion-color-primary-lighten-9);
			border-bottom: 1px solid var(--ion-color-primary);
		}

		li {
			align-items: center;
			display: flex;
			flex-direction: row;
			gap: 5px;

			ion-icon,
			.material-design-icon {
				color: var(--ion-color-primary);
				height: 20px;
				width: 20px;
			}
		}
	}

	&__address {
		font-weight: 400;
	}
}
</style>