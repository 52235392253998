<template>
	<ion-modal id="ion-help-modal" :is-open="showHelpModal" @willDismiss="hideHelpModal()">
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="hideHelpModal()">
						<ion-icon color="light" :ios="arrowBackOutline" :md="arrowBackOutline"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title color="light" >Help and Support</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			<heading
				:icon="helpBuoy"
				:noTopMargin="true"
				title="Questions? / Report An Issue"
			/>
			<ion-button
				class="ion-margin-vertical support-button"
				color="tertiary"
				expand="block"
				:href="'tel:' + supportContactInfo.phone"
				part="native"
			>
				<ion-icon color="light" slot="start" :ios="call" :md="call"></ion-icon>
				{{ supportContactInfo.phoneFormatted }}
			</ion-button>
			<ion-card class="ion-no-margin support-form">
				<ion-button
					:class="[
						'ion-no-margin support-button',
						(formActive) ? 'support-button--active' : ''
					]"
					@click="toggleForm()"
					:color="(formActive || supportMessageForm.sent) ? 'light' : 'tertiary'"
					expand="full"
					part="button"
					style="box-shadow: none;"
				><ion-icon :color="(formActive || supportMessageForm.sent) ? 'primary' : 'light'" slot="start" :ios="mail" :md="mail"></ion-icon>{{ supportContactInfo.email }}</ion-button>
				<ion-card-content v-if="formActive && !supportMessageForm.sent">
					<ion-label color="primary">Subject</ion-label>
					<ion-select placeholder="Subject" v-model="supportMessageForm.type">
						<ion-select-option value="QUESTION">Question</ion-select-option>
						<ion-select-option value="ISSUE">Report An Issue</ion-select-option>
					</ion-select>
					<ion-label color="primary">Message</ion-label>
					<ion-textarea
						class="ion-margin-bottom"
						color="primary"
						placeholder="Write your message"
						v-model="supportMessageForm.message"
					></ion-textarea>
					<ion-button color="success" @click="sendEmail" expand="block">Send</ion-button>
				</ion-card-content>
				<ion-card-content class="ion-text-center" v-if="supportMessageForm.sent">
					<m-d-i-email-fast style="fill: var(--ion-color-primary); height: 80px; width: 80px;"></m-d-i-email-fast>
					<p style="color: var(--ion-color-primary); margin-bottom: 15px;">Thank you for your message.</p>
					<p style="color: var(--ion-color-primary); margin-bottom: 15px;">A member of our team will be in touch.</p>
					<ion-button color="tertiary" @click="resetForm" expand="block">Send Another Message</ion-button>
				</ion-card-content>
			</ion-card>
		</ion-content>
	</ion-modal>
</template>

<script>
import { arrowBackOutline, call, helpBuoy, mail } from "ionicons/icons";
import { defineComponent, computed } from 'vue';
import Heading from "@/view/components/Heading.vue";
import { IonButton, IonButtons, IonCard, IonCardContent, IonHeader, IonIcon, IonLabel, IonModal, IonNav, IonSelect, IonSelectOption, IonTextarea, IonToolbar } from "@ionic/vue";
import { useStore } from 'vuex';
import { isPlatform, toastController } from '@ionic/vue';
import { api } from '../../../src/core/api/api';
import MDIEmailFast from '@/view/components/icons/MDIEmailFast.vue';
import envConfig from "@/core/config/env.config";

export default defineComponent({
	components: {
		Heading,
		IonButton,
		IonButtons,
		IonCard,
		IonCardContent,
		IonHeader,
		IonIcon,
		IonLabel,
		IonModal,
		IonNav,
		IonSelect,
		IonSelectOption,
		IonTextarea,
		IonToolbar,
		MDIEmailFast
	},
	computed: {
		supportContactInfo() {
			if (!this.store.getters.institutionSettings) {
				return {
					phone: '',
					phoneFormatted: '',
					email: ''
				}
			}

			let instSettings = this.store.getters.institutionSettings;

			return {
				phone: instSettings.phone.replace(/\D/g,''), // Remove all characters that are non-digits.
				phoneFormatted: instSettings.phone,
				email: instSettings.email
			}
		}
	},
	data() {
		return {
			supportMessageForm: {
				sent: false,
				message: '',
				type: 'QUESTION'
			},
			formActive: false
		}
	},
	methods: {
		resetForm() {
			this.supportMessageForm = {
				sent: false,
				message: '',
				type: 'QUESTION'
			};
			this.formActive = true;
		},
		async sendEmail() {
			if (!this.supportMessageForm?.message) {
				const toast = await toastController.create({
					color: 'warning',
					duration: 2000,
					message: 'You must fill out the message in order to send'
				});

				toast.present();

				return '';
			}

			let instSettings = this.store.getters.institutionSettings;

			let supportDetails = '<ul><li><b>StudentID:</b> ' + this.store.getters.selectedUser + '</li>';

			if(this.store.getters.selectedUser !== this.store.getters.user.userID) {
				supportDetails += '<li><b>UserID:</b> ' + this.store.getters.user.userID + '</li>';
			}
			
			supportDetails += `<li><b>App Version:</b> ${envConfig.version}</li>`;
			supportDetails += `<li><b>User Agent:</b> ${navigator.userAgent}</li>`;
			if (navigator.userAgentData?.brands?.length) {
				const {brand, version} = navigator.userAgentData.brands[navigator.userAgentData.brands.length-1];
				supportDetails += `<li><b>Device/Browser Version:</b> ${brand} ${version}</li>`;
			}
			supportDetails += '</ul>'

			try {
				await api.triggerSupportEmail({
					institutionSlug: instSettings.slug,
					type: this.supportMessageForm.type,
					supportDetails,
					message: this.supportMessageForm.message
				});
				this.supportMessageForm.sent = true;
			}
			catch(error) {
				const toast = await toastController.create({
					color: 'danger',
					duration: 2000,
					message: 'Email failed to send!'
				});
				console.error(error);

				toast.present();
			}
			finally {
				this.sending = false;
			}
		},
		toggleForm() {
			this.formActive = !this.formActive;
		},
		hideHelpModal() {
			this.store.commit('hideHelpModal')
		}
	},
	setup() {
		const store = useStore();

		return {
			arrowBackOutline,
			call,
			helpBuoy,
			mail,
			showHelpModal: computed(() => store.state.showHelpModal),
			store
		}
	}
});
</script>

<style lang="scss" scoped>
ion-select,
ion-textarea {
	border: 1px solid #CCCCCC;
	color: var(--ion-color-primary);
	margin-bottom: 15px;
}

	ion-textarea {
		padding: 0 10px 10px 10px;
	}

.support-button {
	margin-left: 0;
	margin-right: 0;

	&--active {
		box-shadow: none;
	}

	span {
		left: 0;
		text-align: left;
		width: 100%;
	}
}

.support-form {
	box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);

	.support-button::part(button) {
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
	}

	ion-label {
		display: inline-block;
		margin-bottom: 5px;
	}

	ion-select,
	ion-textarea {
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
}
</style>