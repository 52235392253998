<template>
	<div class="contact-student">
		<a
			class="contact-student__button"
			@click="showStudentContactModal = true;"
		>
			<MaterialDesignIcon icon="dotsVertical" color="primary" />
		</a>
		<ion-modal
			animated="false"
			class="contact-student__modal"
			:handle="false"
			:is-open="showStudentContactModal"
			@didDismiss="showStudentContactModal = false"
		>
			<ion-header>
				<ion-title color="white" class="contact-student__title">
					{{ user.firstName }} {{ user.lastName }}
				</ion-title>
			</ion-header>
			<ion-content class="contact-student__content">
				<ion-list lines="full" class="ion-no-padding">
					<ion-item :href="`tel:${user.phone}`">
						<div class="contact-student__item">
							<div class="contact-student__icon">
								<MaterialDesignIcon icon="phone" color="primary" />
							</div>
							<p class="contact-student__text">
								Call
							</p>
						</div>
					</ion-item>
					<ion-item :href="`sms:${user.phone}`">
						<div class="contact-student__item">
							<div class="contact-student__icon">
								<MaterialDesignIcon icon="messageText" color="primary" />
							</div>
							<p class="contact-student__text">
								Text
							</p>
						</div>
					</ion-item>
					<ion-item target="_blank" :href="`https://maps.google.com/?q=${user.address}+${user.city}+${user.state}+${user.zip}`" >
						<div class="contact-student__item">
							<div class="contact-student__icon">
								<MaterialDesignIcon icon="mapMarker" color="primary" />
							</div>
							<p class="contact-student__text">
								Map
							</p>
						</div>
					</ion-item>
					<ion-item button @click="copyToClipboard(user.permitNumber)" :disabled="!user.permitNumber" lines="none">
						<div class="contact-student__item contact-student__item--multiline">
							<div class="contact-student__icon">
								<MaterialDesignIcon icon="accountDetails" color="primary" />
							</div>
							<p class="contact-student__text">
								Copy Permit Number<br />
								<span v-if="user.permitNumber">{{ user.permitNumber }}</span>
							</p>
						</div>
					</ion-item>
				</ion-list>
			</ion-content>
		</ion-modal>
	</div>
</template>

<script>
import { useStore } from "vuex";
import {
	IonAlert,
	IonButton,
	IonInput,
	IonModal,
	IonSearchbar
} from "@ionic/vue";
import deviceActions from "@/mixins/deviceActions.js";
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";

export default {
	components: {
		IonAlert,
		IonButton,
		IonInput,
		IonModal,
		IonSearchbar,
		MaterialDesignIcon,
	},
	mixins: [
		deviceActions
	],
	props: {
		user: {
			type: Object,
			required: true
		}
	},
	computed: {},
	data() {
		return {
			showStudentContactModal: false
		}
	},
	name: 'ContactUserModal',
	setup() {
		const store = useStore();

		return {
		}
	}
}
</script>

<style lang="scss" scoped>
.contact-student {
	&__button {
		cursor: pointer;
	}

	&__modal {
		--max-height: 268px;
		--max-width: 300px;
		--border-radius: 6px;
		background-color: rgba(0, 0, 0, 0.5);
	}

	&__title {
		height: 50px;
		text-align: center;
		font-weight: 700;
		background-color: var(--ion-color-primary);
		color: var(--ion-color-light);
	}


	&__list {
		margin: 0;
		padding: 0;
	}

	&__item {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__icon {
		font-size: 20px;
		display: flex;
		align-items: center;
	}

	&__text {
		font-size: 16px;
		font-weight: 700;
	}
}
</style>

<style lang="scss">
.contact-student__item--multiline {
	align-items: flex-start !important;
	height: 71px;
	padding-top: 13px;

	.contact-student__text {
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding-top: 2px;
	}
}
</style>