<template>
	<div :class="{ 'heading--no-top-margin': noTopMargin, 'heading': true }">
		<template v-if="!loading">
			<ion-icon color="primary" class="heading__icon" :src="icon" size="large" v-if="icon"></ion-icon>
			<m-d-i-book-check class="heading__icon heading__icon--mdi" v-if="mdiIcon === 'mdiBookCheck'"></m-d-i-book-check>
			<m-d-i-cash class="heading__icon heading__icon--mdi" v-if="mdiIcon === 'mdiCash'"></m-d-i-cash>
			<m-d-i-cash-multiple class="heading__icon heading__icon--mdi" v-if="mdiIcon === 'mdiCashMultiple'"></m-d-i-cash-multiple>
			<m-d-i-clipboard-text-clock class="heading__icon heading__icon--mdi" v-if="mdiIcon === 'mdiClipboardTextClock'"></m-d-i-clipboard-text-clock>
			<m-d-i-speedometer class="heading__icon heading__icon--mdi" fill="primary" v-if="mdiIcon === 'mdiSpeedometer'"></m-d-i-speedometer>
			<MaterialDesignIcon icon="listStatus" v-if="mdiIcon === 'listStatus'" />
		</template>
		<IonSkeletonText :animated="true" class="heading__icon heading__icon--mdi" v-else />
		<ion-text color="primary">
			<h2 class="heading__title ion-no-margin" v-if="!loading">{{ title }}</h2>
			<IonSkeletonText animated style="width: 130px; height: 21px;" v-else />
		</ion-text>

	</div>
</template>

<script>
import { defineComponent } from "vue";
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import MDIBookCheck from '@/view/components/icons/MDIBookCheck.vue';
import MDICash from '@/view/components/icons/MDICash.vue';
import MDICashMultiple from '@/view/components/icons/MDICashMultiple.vue';
import MDIClipboardTextClock from '@/view/components/icons/MDIClipboardTextClock.vue';
import MDISpeedometer from '@/view/components/icons/MDISpeedometer.vue';
import { IonSkeletonText } from "@ionic/vue";


export default defineComponent({
	name: "Heading",
	components: {
		IonSkeletonText,
		MaterialDesignIcon,
		MDIBookCheck,
		MDICash,
		MDICashMultiple,
		MDIClipboardTextClock,
		MDISpeedometer
	},
	props: {
		icon: {
			type: String
		},
		loading: {
			default: false,
			type: Boolean
		},
		mdiIcon: {
			default: '',
			type: String
		},
		noTopMargin: {
			default: false,
			type: Boolean
		},
		title: {
			type: String
		}
	}
});
</script>

<style lang="scss" scoped>
.heading {
	align-items: center;
	display: flex;
	gap: 5px;
	margin-top: 20px;

	&--no-top-margin {
		margin-top: 0;
	}

	&__icon {
		margin-left: 2px;
		margin-right: 10px;

		&--mdi {
			fill: var(--ion-color-primary);
			height: 30px;
			width: 30px;
		}
	}

	&__title {
		font-size: 21px;
		font-weight: 700;
	}
}
</style>