<template>
	<div class="notice-message">
		<MaterialDesignIcon :icon="icon" />
		<p>{{ text }}</p>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';

export default defineComponent({
	components: {
		MaterialDesignIcon
	},
	name: 'NoticeMessage',
	props: {
		icon: {
			default: '',
			type: String
		},
		text: {
			default: '',
			type: String
		}
	}
});
</script>

<style lang="scss" scoped>
.notice-message {
	margin-top: 30px;
	text-align: center;

	.material-design-icon {
		margin-bottom: 15px;
	}
}
</style>