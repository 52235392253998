<template>
	<ion-loading v-if="loading" show-backdrop />
	<ion-item
		:class="['ion-margin-bottom ion-no-padding timeslot-item', disabled ? 'timeslot-item__disabled' : '']"
		@click="selectStudent"
		lines="none"
	>
		<div class="timeslot-item__details" v-if="student">
			<p><MaterialDesignIcon icon="account" color="black" /> <span>{{ student.firstName }} {{ student.lastName }}</span></p>
			<p><MaterialDesignIcon icon="school" color="black" /> <span>{{ student?.school?.name || '' }}</span></p>
			<p><MaterialDesignIcon icon="phone" color="black" /> <span>{{ student.phone }}</span></p>
			<p v-if="disabled"><MaterialDesignIcon icon="alert" color="black" /> <span>{{ disabled }}</span></p>
		</div>
	</ion-item>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
	location,
	time,
	speedometer,
	eye,
	call,
	mail
} from "ionicons/icons";
import { useStore } from "vuex";
import { IonIcon, IonItem, IonLoading, menuController } from "@ionic/vue";
import { useRouter } from 'vue-router';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';

export default defineComponent({
	name: "StudentSelector",
	emits: ['studentAdd'],
	components: {
		IonIcon,
		IonItem,
		IonLoading,
		MaterialDesignIcon,
	},
	props: {
		student: {
			type: Object,
			required: true
		},
		disabled: {
			type: String,
			default: null
		}
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const loading = ref(false);

		const closeMenu = async (menu) => {
			await menuController.close(menu);
		}

		return {
			closeMenu,
			loading,
			location,
			speedometer,
			eye,
			router,
			store,
			time,
			call,
			mail
		};
	},
	computed: {
		institutionTZ() {
			return this.store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		},
		buttonColor() {
			if (!this.store.getters?.selectedSessionStudent?.userID || !this?.student?.user) { return 'light'; }
			return this.store.getters.selectedSessionStudent.userID === this.student.user.userID ? 'primary' : 'light';
		},
	},
	methods: {
		selectStudent() {
			if (!this.disabled) this.$emit('studentAdd', this.student);
		}
	}
})
</script>

<style lang="scss" scoped>
ion-item::part(native) {
	background-color: #FFFFFF;
}

.timeslot-item {
	border-radius: 7px;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: row;
	background-color: #FFFFFF;

	&__icons,
	&__details {
		display: flex;
		flex-direction: column;
		padding: 12px;
		font-weight: 500;
	}

	&__icons {
		background-color: rgba(255, 255, 255, 0.3);
		height: 100%;
		justify-content: space-around;

		ion-icon {
			font-size: 25px;
		}
	}

	&__details {
		span {
			padding-left:10px;
		}

		justify-content: center;

		p {
			display: flex;
			margin: 0 0 8px 0;

			ion-icon {
				font-size: 19px;
				margin-right: 8px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}


</style>