<template>
	<ion-loading v-if="loading" show-backdrop />
	<ion-item
		:button="isHomePageOveriew"
		class="ion-margin-bottom ion-no-padding timeslot-item"
		@click="goToTimeSlotDetails"
		:color="statusIconAndColor.color"
		lines="none"
		style="box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);"
	>
		<div class="timeslot-item__icons">
			<ion-icon v-if="hasDriveSession || isDriveSession" :icon="speedometer"></ion-icon>
			<ion-icon v-if="hasObserveSession || isObserveSession" :icon="eye"></ion-icon>
		</div>
		<div class="timeslot-item__details">
			<p v-if="isAppointmentHistory"><ion-icon :icon="calendarNumber" /> {{ format(parseISO(timeSlot.instance.date), 'EEEE, MMMM d, yyyy') }}</p>
			<p v-if="isHomePageOveriew"><ion-icon :icon="calendarNumber" /> {{ format(parseISO(timeSlot.instance.date), 'MMMM d') }} - {{ sessionTitle }}</p>
			<p v-if="!isHomePageOveriew"><ion-icon :icon="time" /> {{ `${formatTime(timeSlot.scheduledSessions[0].sessionStart)} - ${formatTime(timeSlot.scheduledSessions[timeSlot.scheduledSessions.length-1].sessionEnd)}` }}</p>
			<p v-if="!store.getters.institutionSettings.schedulerSettings.hideInstructors"><ion-icon :icon="person" /> {{ timeSlot.instructor.firstName }} {{ timeSlot.instructor.lastName }}</p>
			<p v-if="timeSlot.scheduledSessions[0].customPickupLocation?.name || (timeSlot.location?.name && timeSlot.location?.locationID !== 'none')"><ion-icon :icon="location" /> {{ timeSlot.scheduledSessions[0].customPickupLocation?.name || timeSlot.location?.name }}</p>
			<p v-if="isAppointmentHistory"><ion-icon :icon="statusIconAndColor.icon" /> {{ timeSlot.scheduledSessions[0].status }} - {{ formatInTimeZone(new Date(timeSlot.scheduledSessions[0].sessionStart), institutionTZ, 'EEEE, MMMM d, yyyy \'at\' hh:mm aa') }}</p>
		</div>
	</ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import {
	eye,
	location,
	person,
	speedometer,
	time,
	ban,
	checkbox,
	readerOutline,
	eyeOff,
	calendarNumber,
	checkmarkCircle
} from "ionicons/icons";
import { useStore } from "vuex";
import { IonIcon, IonItem, IonLoading } from "@ionic/vue";
import { useRouter } from 'vue-router';
import { format, parseISO } from 'date-fns';
import { formatTime } from "@/core/util/helpers";
import { TimeSlotVM } from "@/core/store/store";
import { formatInTimeZone } from "date-fns-tz";

export default defineComponent({
	name: "TimeSlotItem",
	components: {
		IonIcon,
		IonItem,
		IonLoading,
	},
	props: {
		type: {
			required: true,
			type: String
		},
		timeSlot: {
			type: Object as PropType<TimeSlotVM>,
			required: true
		},
		studentID: {
			type: String,
			required: true
		}
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const loading = ref(false);

		return {
			eye,
			loading,
			location,
			person,
			router,
			speedometer,
			store,
			time,
			ban,
			checkbox,
			readerOutline,
			eyeOff,
			calendarNumber,
			checkmarkCircle
		};
	},
	computed: {
		isHomePageOveriew() {
			return this.type === 'home'
		},
		isAppointmentHistory() {
			return this.type === 'history'
		},
		isDriveSession() {
			return this.timeSlot.scheduledSessions.some(({sessionType}) => sessionType === 'drive');
		},
		startOfFirstSession() {
			return this.timeSlot.scheduledSessions[0].sessionStart;
		},
		isObserveSession() {
			return this.timeSlot.scheduledSessions.some(({sessionType}) => sessionType === 'observe');
		},
		statusIconAndColor() {
			switch (this.timeSlot.scheduledSessions[0].status) {
				case 'SCHEDULED': {
					return {
						icon: this.checkmarkCircle,
						color: 'tertiary'
					}
				}
				case 'COMPLETED': {
					return {
						icon: this.checkbox,
						color: 'success'
					}
				}
				case 'CANCELED': {
					return {
						icon: this.ban,
						color: 'warning'
					}
				}
				case 'CANCELED_WITH_FEE': {
					return {
						icon: this.ban,
						color: 'danger'
					}
				}
				case 'NO_SHOW_WITHOUT_FEE': {
					return {
						icon: this.eyeOff,
						color: 'warning'
					}
				}
				case 'NO_SHOW_WITH_FEE': {
					return {
						icon: this.eyeOff,
						color: 'danger'
					}
				}
				default: {
					return {
						icon: '',
						color: 'medium'
					}
				}
			}
		},
		hasDriveSession() {
			return this.timeSlot.hasOpenDriveSessions;
		},
		hasObserveSession() {
			return this.timeSlot.hasOpenObserveSessions;
		},
		selectedUser() {
			return this.store.getters.selectedUser ? this.store.getters.selectedUser : this.store.getters.user.userID;
		},
		institutionTZ() {
			return this.store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		},
		sessionTitle() {
			if (this.store.getters.institutionSettings.schedulerSettings?.timeSlotTitle === 'TIME_LABEL' && this.timeSlot.label) {
				return `${this.timeSlot.label} (${this.formatTime(this.startOfFirstSession)})`
			} else if (this.store.getters.institutionSettings.schedulerSettings?.timeSlotTitle === 'LABEL' && this.timeSlot.label) {
				return this.timeSlot.label;
			} else {
				return this.formatTime(this.startOfFirstSession);
			}
		}
	},
	methods: {
		format,
		parseISO,
		formatInTimeZone,
		goToTimeSlotDetails() {
			if(this.isHomePageOveriew) {
				this.$router.push(`/scheduler/${this.timeSlot.instance.date}`);
			}
		},
		formatTime
	}
})
</script>

<style lang="scss" scoped>
.reserve-cancel-button {
	height: 90%;
	width: 25%;
	margin: 0;
	padding: 0;
}


.timeslot-item {
	border-radius: var(--ion-border-radius, 6px);
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

	&__icons,
	&__details {
		display: flex;
		flex-direction: column;
		padding: 12px;
	}

		&__icons {
			background-color: rgba(255, 255, 255, 0.3);
			height: 100%;
			justify-content: space-around;

			ion-icon {
				font-size: 25px;
			}
		}

		&__details {

			justify-content: center;

			p {
				display: flex;
				margin: 0 0 8px 0;

				ion-icon {
					font-size: 19px;
					margin-right: 8px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
}
</style>