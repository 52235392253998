import { Module } from 'vuex';
import { storage } from '@/core/storage/Storage';



export type State = {
	darkMode: string;
	themeInitialized: boolean;
}


export const theme: Module<State, State> = {
	state() {
		return {
			darkMode: 'default',
			themeInitialized: false
		};
	},
	actions: {
		async initializeTheme({commit}) {
			const {value = 'default'} = await storage.get({key: 'darkMode'});
			commit('setDarkMode', value || 'default');
			commit('setInitialize');
		},
		async setDarkMode({commit, state}, darkMode: string) {
			commit('setDarkMode', darkMode);
			storage.set({key: 'darkMode', value: state.darkMode});
		}
	},
	mutations: {
		setDarkMode(state, darkMode) {
			state.darkMode = darkMode;
		},
		setInitialize(state) {
			state.themeInitialized = true
		},
	},
	getters: {
		themeInitialized(state) {
			return state.themeInitialized;
	}
	}
}