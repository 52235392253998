<template>
	<ion-modal
		canDismiss="false"
		ref="modal"
		:is-open="attemptHistoryModalOpen"
	>
        <ion-header>
            <div class="attempt-modal__header">
                <p><span>{{historyData.title}} Failed Attempts</span></p>
            </div>
        </ion-header>
        <ion-content scroll-y class="ion-padding attempt-modal">
            <template v-for="(item, index) in historyData.attempts">
                <ion-card
                    color="light"
                    detail="false"
                    class="ion-no-margin ion-margin-bottom ion-no-padding attempt-card"
                >
                    <div class="attempt-card">
                        <div class="attempt-card__header">
                            <div slot="start">
                                <p>
                                    Attempt {{ (historyData.attempts.length - index) }} - {{ format(item.date, 'MMM dd, yyyy') }}
                                </p>
                                <p>{{ item.instructor }}</p>
                            </div>
                            <MaterialDesignIcon icon="pencil" color="white" />
                        </div>
                        <div class="session-time-container">
                            <ion-item><MaterialDesignIcon icon="commentQuote" /><span class="attempt-modal__text"><b>{{ item.notes }}</b></span></ion-item>
                            <div v-for="attempt in item.feedbackItems">
                                <ion-item><MaterialDesignIcon icon="closeThick" color="red" /><span class="attempt-modal__text"><b>{{ attempt.description }}</b></span></ion-item>
                            </div>
                        </div>
                    </div>
                </ion-card>
            </template>

        </ion-content>
        <ion-footer>
            <ion-button
                @click="closeModal"
                class="cancel-button"
            >
                CLOSE
            </ion-button>
        </ion-footer>
        
    </ion-modal>
</template>

<script>
import { IonModal } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useStore } from "vuex";
import { format } from 'date-fns';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
export default defineComponent({
	components: { IonModal, MaterialDesignIcon },
	props: {
		attemptHistory: {
			type: Object,
			default: () => {},
		},
		attemptHistoryModalOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
        }
	},
	setup() {
		const store = useStore();

		return {
			store,
            format
		}
	},
	methods: {
		closeModal() {
			this.$emit('closeAttemptHistoryModal');
		},
        viewAttempts() {
        }

	},
    computed: {
        historyData() {
			return this.attemptHistory;
		},
    }
});
</script>

<style scoped lang="scss">
    .attempt-card {
        border-radius: 6px;
        background: var(--ion-color-primary-tint);
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 12px;

        &__header {
            color: var(--ion-color-light, #FFF);
            font-size: 14px;
            line-height: normal;
            font-weight: 700;
            padding: 10px;
            align-items: stretch;
            display: flex;
            justify-content: space-between;
            width: 100%;


            &--base-color {
                background-color: var(--ion-color-primary-tint);
            }
        }
    }

    .attempt-modal {
        height: calc(100% - 83px);
        &__header {
            background-color: var(--ion-color-primary);
            color: #ffffff;
            z-index: 10;
            padding: 10px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
        }

        &__notes {
            display: flex;
            padding: 10px 5px;

            > span {
                display: flex;
                align-items: center;
            }
            
        }
        
        &__text {
            padding-left: 10px;
        }

    }
    .material-design-icon {
        padding-right: 5px;
    }

    ion-footer {
        display: flex;
        justify-content: center;
    }
</style>

<style>
    .material-design-icon--red path {
        padding-right: 5px;
        fill: var(--ion-color-danger) !important;
    }

    .material-design-icon--white path {
        fill: var(--ion-color-light) !important;
    }
</style>