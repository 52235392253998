<template>
	<ion-page v-if="!isAuthenticated" id="login-page" key="login">
		<ion-card class="ion-padding-horizontal ion-padding-bottom login-card" :color="darkMode === 'dark' ? '' : 'light-shade'">
			<ion-card-header>
				<AppLogo />
			</ion-card-header>
			<ion-card-content class="ion-no-padding">
				<ion-row>
					<ion-col>
						<ion-list>
							<ion-item class="ion-margin-bottom">
								<ion-input
									color="primary"
									type="text"
									placeholder="Email"
									v-model.trim="email"
									:disabled="loading"
								/>
								<ion-icon color="primary" :ios="person" :md="person" slot="start"></ion-icon>
							</ion-item>
							<ion-item class="ion-margin-bottom">
								<ion-icon color="primary" :ios="lockClosed" :md="lockClosed" slot="start"></ion-icon>
								<ion-input
									color="primary"
									ref="password"
									:type="(maskPassword) ? 'password' : 'text'"
									placeholder="Password"
									v-model="password"
									@keypress.enter="logIn"
									:disabled="loading"
								/>
								<ion-button class="ion-no-padding no-ripple" @click="maskPassword = !maskPassword" fill="clear" size="default">
									<ion-icon colors="dark" :ios="(maskPassword) ? eyeOff : eye" :md="(maskPassword) ? eyeOff : eye" slot="icon-only"></ion-icon>
								</ion-button>
							</ion-item>
						</ion-list>
					</ion-col>
				</ion-row>

				<ion-row class="ion-align-items-center ion-justify-content-between">
					<ion-col size="auto">
						<a class="reset-password" :href="forgotPasswordURL" style="border-bottom: 1px solid #2B4051;" target="_blank">
							<strong>FORGOT YOUR PASSWORD?</strong>
						</a>
					</ion-col>
					<ion-col size="auto">
						<ion-button
							class="ion-no-margin"
							color="primary"
							@click="logIn"
							:disabled="loading"
						>
							<ion-spinner v-if="loading" name="bubbles"></ion-spinner>
							<template v-else>LOGIN</template>
						</ion-button>
					</ion-col>
				</ion-row>
			</ion-card-content>
		</ion-card>
	</ion-page>
</template>

<script>
import AppLogo from "@/view/components/graphics/AppLogo.vue";
import DriveTrakLogo from "@/view/components/DriveTrakLogo.vue";
import { computed, defineComponent } from "vue";
import envConfig from "@/core/config/env.config";
import { mapGetters, useStore } from 'vuex';
import { toastController, IonCardContent, IonCardHeader } from '@ionic/vue';
import { api } from '../../core/api/api';
import { useRouter } from "vue-router";
import { InvalidCredentialsException } from "../../core/api/InvalidCredentialsException";
import {
	eye,
	eyeOff,
	lockClosed,
	person,
} from "ionicons/icons";

export default defineComponent({
	name: "Login",
	setup() {
		const store = useStore();

		return {
			darkMode: computed(() => store.state.theme.darkMode),
			eye,
			eyeOff,
			store,
			router: useRouter(),
			lockClosed,
			person,
		};
	},
	components: {
		AppLogo,
		DriveTrakLogo,
		IonCardContent,
		IonCardHeader
	},
	data() {
		return {
			email: '',
			password: '',
			loading: false,
			maskPassword: true
		};
	},
	methods: {
		async logIn() {
			const email = this.email.replace(/\s+/, '');
			const password = this.password;

			if (!email) return this.displayLoginError('Please enter your email address', 'warning');
			if (!password) return this.displayLoginError('Please enter your password', 'warning');

			try {
				this.loading = true;
				const token = await api.logIn({ email, password });

				this.email = '';
				this.password = '';

				await this.store.dispatch('getFeatureFlags'),
				await this.store.dispatch('getBrowserSupport'),
				await this.store.dispatch('logIn', token);
				await Promise.all([
					this.store.dispatch('getInstitutionSettings'),
					this.store.dispatch('getNotifications')
				]);

				this.store.dispatch('initialize');
				this.router.push('/home');
				this.store.dispatch('setAttemptedRoute', '');
				this.logData('Success', email);
			}
			catch (error) {
				this.logData('Failure', email);
				if (error instanceof InvalidCredentialsException) {
					return this.displayLoginError('Invalid Email or Password', 'danger');
				}

				return this.displayLoginError('Unable to log in at this time', 'danger');
			}
			finally {
				this.loading = false;
			}
		},
		async displayLoginError(message, color) {
			const toast = await toastController.create({
				color,
				duration: 2000,
				message
			});

			toast.present();
		},
		logData(status, email) {
			const logData = {
				institution: this.store.getters.institutionSettings.slug,
				event: 'Login ' + status,
				affectedUserID: this.store.getters.user.userID,
				additionalData:{
					email,
					activeUser: this.store.getters.selectedUser,
					currentAppVersions: this.store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Authentication'],
					status: status
				}
			};
			api.logMobileActivity(logData);
		}
	},
	computed: {
		forgotPasswordURL() {
			const hostname = window.location.hostname;

			const isDriveTrakDomain = [
				'localhost',
				'mobile-qa.drivetrak.io',
				'mobile-uat.drivetrak.io',
				'mobile.drivetrak.io'
			].includes(window.location.hostname);

			if (!isDriveTrakDomain) {
				switch(hostname) {
					case 'dashboard-qa.crashcoursewi.com':
						return 'https://enroll-qa.crashcoursewi.com/password-reset/';
					case 'dashboard-uat.crashcoursewi.com':
						return 'https://enroll-uat.crashcoursewi.com/password-reset/';
					case 'dashboard.crashcoursewi.com':
						return 'https://enroll.crashcoursewi.com/password-reset/';
				}
			}

			let returnURL = 'https://register.drivetrak.io/password-reset/';
			switch (envConfig.name) {
				case 'dev':
					returnURL = 'https://register-dev.drivetrak.io/password-reset/';
					break;
				case 'uat':
					returnURL = 'https://register-uat.drivetrak.io/password-reset/';
					break;
				case 'qa':
					returnURL = 'https://register-qa.drivetrak.io/password-reset/';
					break;
				case 'Local':
					returnURL = 'http://localhost:8000/password-reset/';
					break;
			}
			return returnURL;
		},
		...mapGetters(['customBrandingClass', 'institutionSettings', 'isAuthenticated'])
	},
	ionViewWillEnter() {
		this.store.dispatch('checkCustomHostname');
	}
});
</script>

<style lang="scss">
#login-page {
	align-items: center;
	background-color: var(--ion-color-secondary, #EFF0F2);
	display: flex;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	width: 100%;

	.app-logo {
		max-height: 150px !important;

		.drivetrak-logo,
		img {
			height: auto;
			max-height: 150px !important;
			width: 100%;
		}
	}
}
</style>

<style lang="scss" scoped>
.login-card {
	background: transparent;
	box-shadow: none;
	max-width: 360px;
	text-align: center;
	width: 100%;

	ion-card-header {
		padding-bottom: 32px;
		padding-top: 0;
	}

	.drivetrak-logo {
		height: auto;
		margin: 0;
		width: 100%;

		img, svg {
			height: auto;
			width: 100%;
		}
	}

	ion-list {
		background: none;
		padding: 0;

		ion-item {
			--background: var(--login-input-background, transparent);
			--border-radius: var(--ion-border-radius);
			--border-color: var(--ion-color-primary);
			--border-width: 1px;
			--color: var(--ion-color-primary)
			margin-bottom: 16px;
		}
	}

	ion-button {
		--border-radius: var(--ion-border-radius);
	}

	.no-ripple {
		--background-hover: transparent;
		--ripple-color: transparent;
	}

	ion-input {
		--color: var(--ion-color-light);
		--padding-bottom: 18px;
		--padding-top: 18px;
	}

	.reset-password {
		font-size: 16px;
		text-decoration: none;
	}
}
</style>