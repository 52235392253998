import { InstitutionSettings, SessionDetails, store } from "@/core/store/store";
import { formatInTimeZone } from "date-fns-tz";

/**
	* @function deepCopy() - returns a deepCopy of an object or array
	* @param {Object|Array} value
	* @returns {Object|Array}
*/
export function deepCopy<T>(value: T) : T {
	return (value !== undefined) ? JSON.parse(JSON.stringify(value)) : undefined;
}

export function getSessionsTimeSlotLoggingDetails(sessions: Array<SessionDetails>, institutionSettings: InstitutionSettings): String {
	const dateString = formatInTimeZone(new Date(sessions[0].sessionStart), institutionSettings.timeZone, 'MM/dd/yyyy');
	const timeStrings = sessions.map(({sessionStart}) => formatInTimeZone(new Date(sessionStart), institutionSettings.timeZone, 'h:mmaaa'))
	return `${dateString} - ${timeStrings.join(', ')}`;
}

/**
 * Formats an iso time string with the institutions timezone
 * @param time - The date string to be formatted
 * @returns A time formatted string, like '9:00am'
*/
export function formatTime(time: string): string {
	if (!time) return '';
	const timeZone = store.getters.institutionSettings.timeZone;

	return formatInTimeZone(new Date(time), timeZone, 'h:mmaaa');
}

/**
 * Determines whether or not the given object/array contains at least one value
*/
export function isPopulated(target: Array<any> | Record<any, any>): boolean {
	if (typeof target !== 'object') return false;
	if (Array.isArray(target)) return !!target.length;

	for (const key in target) {
		if (Object.prototype.hasOwnProperty.call(target, key)) return true;
	}

	return false;
}


const createDurationFormatter = (locale: string, unitDisplay: 'short' | 'long' = 'long', minutes: number = 0) =>{
	const divMod = (n: number, m: number) => [Math.floor(n / m), n % m];

	const timeUnitFormatter = (locale: string, unit: string, unitDisplay: 'short' | 'long') => new Intl.NumberFormat(locale, { style: 'unit', unit, unitDisplay }).format,
		fmtHours = timeUnitFormatter(locale, 'hour', unitDisplay),
		fmtMinutes = timeUnitFormatter(locale, 'minute', unitDisplay),
		fmtList = new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' });
	return (minutes = 0) => {
		const [hrs, mins] = divMod(minutes, 60);
		return fmtList.format([
			hrs ? fmtHours(hrs) : null,
			mins ? fmtMinutes(mins) : null
		].filter(v => v !== null));
	}
};

export const durationFormatterEn = createDurationFormatter('en-US');