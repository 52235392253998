<template>
	<div :class="['drivetrak-logo', color, size]">
		<svg viewBox="0 0 292 68" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
			<g id="Artboard1" transform="matrix(0.788603,0,0,0.598029,10.4429,9.95375)">
				<clipPath id="_clip1">
					<rect x="-13.242" y="-16.644" width="370.275" height="113.707"/>
				</clipPath>
				<g clip-path="url(#_clip1)">
					<g transform="matrix(1.16952,0,0,1.6841,2.24488,11.3863)">
						<g transform="matrix(0.45484,0,0,0.416521,194.518,-21.7258)">
							<path d="M169.8,127C155.4,153.9 135.2,173.9 135.2,173.9L114.7,173.9C114.7,173.9 138,153.2 156.6,127L169.8,127Z" class="fill-rule"/>
						</g>
						<g transform="matrix(0.45484,0,0,0.416521,194.518,-21.7258)">
							<path d="M166.5,111.5C171.1,103.4 174.8,95 177,86.9L183.6,86.9C182.6,95.2 180.3,103.5 177.2,111.4L166.5,111.5Z" class="fill-rule"/>
						</g>
						<g transform="matrix(0.45484,0,0,0.416521,194.518,-21.7258)">
							<path d="M178.5,78.5C178.6,77.5 178.7,76.4 178.8,75.4C179.3,67.5 177.2,60.1 173.8,53.5L177.2,53.5C181,60.1 183.6,67.4 184,75.3C184.1,76.4 184.1,77.5 184.1,78.6L178.5,78.5Z" class="fill-rule"/>
						</g>
						<g transform="matrix(0.45484,0,0,0.416521,194.518,-21.7258)">
							<path d="M171.8,49.9C169.1,45.4 165.9,41.3 162.5,37.7L165.1,37.7C168.5,41.3 172,45.4 175,49.9L171.8,49.9Z" class="fill-rule"/>
						</g>
						<g transform="matrix(0.45484,0,0,0.416521,194.518,-21.7258)">
							<path d="M160.9,36C158.1,33.1 155.3,30.6 152.8,28.5L155.2,28.5C157.8,30.6 160.6,33.1 163.5,36L160.9,36Z" class="fill-rule"/>
						</g>
						<g transform="matrix(0.45484,0,0,0.416521,194.518,-21.7258)">
							<path d="M151.2,27.1C146.5,23.4 143.2,21.3 143.2,21.3L124.8,21.3C124.8,21.3 165.1,43.1 157.4,75C149.7,106.8 70.4,174.3 70.4,174.3L30.4,174.3C30.4,174.3 125.8,109.5 147.6,74.4C165,46.3 120.3,21.4 120.3,21.4L95.5,86.7L118.1,86.7L107.1,95.6L81.7,95.6L114.3,12.2L239.3,12.2L221.5,60.1L215.6,50.7L227.1,21.4L165.3,21.4C165.3,21.4 198,38.4 212.4,77.1C228.9,121.6 213.1,174.2 213.1,174.2L178.2,174.2C178.2,174.2 213.8,123.8 202.6,73.3C195,39 161.9,21.3 161.9,21.3L145.4,21.3C145.4,21.3 148.8,23.4 153.6,27.1L151.2,27.1Z" class="fill-rule"/>
						</g>
					</g>
					<g transform="matrix(1.16952,0,0,1.6841,2.24488,11.3863)">
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M10.3,215.4L17,180.8L26,180.8C31.1,180.8 35,182.1 37.8,184.7C40.6,187.3 42,191 42,195.8C42,201.7 40.2,206.5 36.6,210C33,213.5 28.2,215.4 22.2,215.4L10.3,215.4ZM26,185.4L21,185.4L16.1,210.8L22.2,210.8C26.6,210.8 30.1,209.5 32.8,206.8C35.5,204.1 36.8,200.6 36.8,196.3C36.8,192.8 35.9,190.1 34,188.2C32.2,186.3 29.6,185.4 26,185.4Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M43.8,215.4L48.7,189.2L52.9,189.2L52.4,191.7C53.6,190.6 54.8,189.9 56.2,189.3C57.6,188.8 59.1,188.5 60.7,188.5L61.8,188.5L60.8,193.1C57.7,193.2 55.4,193.9 54,195.1C52.6,196.3 51.5,199.1 50.7,203.2L48.3,215.2L43.8,215.2L43.8,215.4Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M66.1,189.2L71,189.2L65.9,215.4L61,215.4L66.1,189.2ZM71.6,186.3L66.7,186.3L67.8,180.8L72.7,180.8L71.6,186.3Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M77.2,215.4L72.8,189.2L77.4,189.2L80.6,209.7L90.8,189.2L95.7,189.2L82.2,215.4L77.2,215.4Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M121.3,206.7C120.1,209.8 118.3,212.2 116,213.8C113.7,215.4 110.9,216.2 107.6,216.2C103.9,216.2 100.9,215 98.5,212.7C96.2,210.4 95,207.3 95,203.6C95,201.4 95.4,199.3 96.3,197.3C97.2,195.3 98.4,193.6 100.1,192.1C101.4,191 102.8,190.1 104.4,189.5C106,188.9 107.7,188.6 109.5,188.6C113.3,188.6 116.3,189.8 118.6,192.2C120.9,194.6 122,197.8 122,201.9C122,202.3 122,202.9 121.9,203.7L121.9,203.8L99.9,203.8C100,206.2 100.8,208 102.1,209.4C103.4,210.8 105.2,211.5 107.4,211.5C109.3,211.5 111,211.1 112.3,210.3C113.6,209.5 114.8,208.3 115.7,206.6L121.3,206.6L121.3,206.7ZM117,199.7C116.6,197.5 115.8,195.8 114.5,194.7C113.2,193.6 111.5,193 109.4,193C107.4,193 105.6,193.6 104.1,194.7C102.6,195.8 101.5,197.5 100.7,199.6L117,199.6L117,199.7Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M128.8,215.4L134.6,185.5L127,185.5L127.9,180.7L147.9,180.7L147,185.5L139.7,185.5L133.9,215.4L128.8,215.4Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M142,215.4L146.9,189.2L151.1,189.2L150.6,191.7C151.8,190.6 153,189.9 154.4,189.3C155.8,188.8 157.3,188.5 158.9,188.5L160,188.5L159,193.1C155.9,193.2 153.6,193.9 152.2,195.1C150.8,196.3 149.7,199.1 148.9,203.2L146.5,215.2L142,215.2L142,215.4Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M189.2,189.2L184.1,215.4L179.4,215.4L179.9,212.8C178.9,213.8 177.8,214.6 176.5,215.1C175.2,215.6 173.7,215.9 172.1,215.9C168.7,215.9 165.8,214.7 163.6,212.4C161.3,210 160.2,207.1 160.2,203.5C160.2,201.5 160.6,199.5 161.3,197.6C162,195.7 163.1,194.1 164.5,192.7C165.8,191.4 167.3,190.3 169,189.6C170.7,188.9 172.5,188.5 174.4,188.5C176.5,188.5 178.3,188.9 179.9,189.6C181.5,190.4 182.8,191.5 183.8,193L184.5,189.1L189.2,189.1L189.2,189.2ZM182.3,201.3C182.3,198.8 181.6,196.8 180.1,195.3C178.6,193.8 176.8,193 174.5,193C171.8,193 169.5,194 167.7,196C165.9,198 164.9,200.4 164.9,203.3C164.9,205.8 165.6,207.7 167.1,209.2C168.6,210.7 170.5,211.4 172.9,211.4C175.5,211.4 177.7,210.4 179.5,208.5C181.4,206.7 182.3,204.2 182.3,201.3Z" class="fill-rule"/>
						</g>
						<g transform="matrix(1.08728,0,0,0.995678,-24.4412,-177.49)">
							<path d="M201.2,180.8L197.3,201.1L197.7,201.1L208.3,189.3L214.1,189.3L202.8,201.7L211.1,215.5L205.6,215.5L197.6,201.9L197.1,201.9L194.5,215.5L189.7,215.5L196.4,180.9L201.2,180.9L201.2,180.8Z" class="fill-rule"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'DriveTrakLogo',
	props: {
		color: {
			default: '',
			type: String
		},
		size: {
			default: '',
			type: String
		}
	}
}
</script>

<style scoped lang="scss">

.drivetrak-logo {
	align-items: center;
	display: block;

	svg {
		display: block;
		height: 68px;
		width: 292px;

		.fill-rule {
			fill: #2B4051;
			fill-rule:nonzero;
		}
	}

	&.small svg {
		height: 30px;
		width: 128.8px;
	}

	&.white {

		.fill-rule {
			fill: #FFFFFF;
			fill-rule: nonzero;
		}
	}
}
</style>