import { toastController } from '@ionic/vue';

export default {
	methods: {
		copyToClipboard(text) {

			try {
				navigator.clipboard.writeText(text);

				toastController.create({
					color: 'success',
					duration: 2000,
					message: 'Copied to clipboard',
					position: 'top'
				}).then((toast) => toast.present());

			} catch (error) {

				toastController.create({
					color: 'danger',
					duration: 2000,
					message: 'Unable to copy',
					position: 'top'
				}).then((toast) => toast.present());

			}
		}
	}
}