<template>
	<p :class="[
		'count-tally',
		(previousDifferent) ? 'count-tally--different-previous' : ''
	]">
		<span class="count-tally__tally">
			<span v-if="current !== previous" class="count-tally__previous">{{ previous }}</span>
			<span><MaterialDesignIcon class="count-tally__arrow" v-if="current !== previous" icon="arrowRightThin" color="success"/></span>
			<span class="count-tally__current">{{ current }}</span>
		</span>
		<MaterialDesignIcon :icon="mdiIcon" color="primary" />
	</p>
</template>

<script>
import { defineComponent } from 'vue';
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";

export default defineComponent({
	components: {
		MaterialDesignIcon
	},
	computed: {
		previousDifferent() {
			return (this.current !== this.previous);
		}
	},
	name: "CountTally",
	props: {
		current: {
			default: 0,
			type: Number
		},
		mdiIcon: {
			default: '',
			type: String
		},
		previous: {
			default: 0,
			type: Number
		}
	},
	setup() {
		return {
		}
	}
});
</script>

<style lang="scss" scoped>
.count-tally {
	align-items: center;
	color: var(--ion-color-primary);
	display: flex;
	flex-wrap: none;
	gap: 5px;

	svg {
		height: 20px;
		width: 20px;
	}

	&--different-previous {
		.count-tally {
			&__tally {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				gap: 2px;
			}

			&__arrow {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__previous {
				text-decoration: line-through;
			}

			&__current {
				color: var(--ion-color-success);
			}
		}
	}
}
</style>