<template>
	<ion-page id="main-content">
		<app-header customBackButton="/lessons" />
		<ion-loading v-if="loading || processing"></ion-loading>
		<lesson-progress-bar
			:hideProgressBar="true"
			:inline="true"
			:lesson="lesson"
			:title="(routeTopicID) ? ' - Quiz' : ' - Test'"
			:topicsCompleted="topicsCompleted"
		/>
		<ion-content class="ion-padding" fullscreen scrollY="true">
			<question-card
				:oQuestion="question"
				@updated="$event => updateAnswer($event)"
				v-for="question in questions"
			></question-card>
			<ion-button
				class="ion-margin-bottom"
				@click="submit()"
				color="success"
				:disabled="disableSubmit"
				expand="block"
			>
				Submit
			</ion-button>
		</ion-content>
	</ion-page>
</template>

<script>
import AppHeader from '@/view/components/AppHeader.vue';
import LessonCard from '@/view/components/LessonCard.vue';
import LessonProgressBar from '@/view/components/LessonProgressBar.vue';
import ProgressCircle from '@/view/components/ProgressCircle.vue';
import PastQuizTestCard from '@/view/components/PastQuizTestCard.vue';
import QuestionCard from '@/view/components/QuestionCard.vue';
import Heading from '@/view/components/Heading.vue';
import {
	IonButton,
	IonIcon,
	IonLoading,
	useIonRouter,
} from '@ionic/vue';
import { computed, defineComponent, ref } from "vue";
import {
	arrowForward,
	caretDownOutline,
	clipboard,
	informationCircle,
	trophy
} from 'ionicons/icons';
import { toast } from '../../../core/toast/Toast';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { DailyLimitMetError } from '@/core/api/api';


/**
 * NOTES: Test and quiz should not be blocked.
 */
export default defineComponent({
	name: "QuizTest",
	components: {
		AppHeader,
		Heading,
		IonButton,
		IonIcon,
		IonLoading,
		LessonCard,
		LessonProgressBar,
		PastQuizTestCard,
		ProgressCircle,
		QuestionCard
	},
	computed: {
		classroomUserProgress() {
			return this.store.getters.selectedClassroomProgress;
		},
		topicsCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			// If we don't find the current lesson return an empty array.
			if (!currentLessonProgress) { return []; }

			// Otherwise return a list of topics completed.
			return currentLessonProgress.progressItems.filter(item => item.type === 'TOPIC');
		},
		loading() {
			return this.questions.length === 0
		},
		disableSubmit() {
			if (this.processing || this.loading) return true;
			if (this.questionAnswerInput.length < this.questions.length) return true;
		}
	},
	data() {
		return {
			processing: false,
			questionAnswerInput: []
		}
	},
	methods: {
		getMostRecentResultsQuizTestURL(response) {
			if (!response) { return false; }
			const lessonProgressItem = response.lessons.find(l => l.lessonID === this.routeLessonID);

			let mostRecentQuizTest = false;

			if (lessonProgressItem) {
				lessonProgressItem.progressItems.sort((a,b) => a.completedDate > b.completedDate ? -1 : a.completedDate < b.completedDate ? 1 : 0);

				if (this.routeTopicID) {
					mostRecentQuizTest = lessonProgressItem.progressItems.find(pi => ((pi.reference === this.routeTopicID) && (pi.type === 'QUIZ')));
				} else {
					mostRecentQuizTest = lessonProgressItem.progressItems.find(pi => ((pi.reference === this.routeLessonID) && (pi.type === 'TEST')));
				}
			} else {
				return false;
			}

			// Create our url.
			if (this.routeTopicID) {
				return `/results/${this.routeLessonID}/${this.routeTopicID}/${mostRecentQuizTest.id}`;
			} else {
				return `/results/${this.routeLessonID}/${mostRecentQuizTest.id}`;
			}
		},
		async submit() {
			this.processing = true;
			if (this.questionAnswerInput.length === this.questions.length) {
				const progressItemInput = {
					type: this.routeTopicID ? 'QUIZ' : 'TEST',
					lessonID: this.routeLessonID,
					itemProgressed: this.routeTopicID ? this.routeTopicID : this.routeLessonID,
				};

				progressItemInput.details = this.questionAnswerInput.map((qai) => {
					return {
						question: qai.questionID,
						chosenAnswerID: qai.answerID
					}
				})

				const response = await this.store.dispatch('submitClassroomProgress', [progressItemInput]);
				if (response instanceof DailyLimitMetError) {
					this.processing = false;
					this.ionRouter.navigate('/lessons', 'forward', 'replace');
					return false;
				}
				const mostRecentResultsQuizTestURL = this.getMostRecentResultsQuizTestURL(response);

				if (mostRecentResultsQuizTestURL) {
					this.ionRouter.navigate(mostRecentResultsQuizTestURL, 'forward', 'replace')
				} else {
					this.processing = false;
					toast.error({
						message: 'Unable to find Quiz/Test results',
						duration: 7500
					});
				}
			} else {
				this.processing = false;
				toast.error({
					message: 'You must answer all questions in order to advance',
					duration: 7500
				});
			}
		},
		summaryURL() {
			if (this.routeTopicID) {
				return `/summary/${this.routeLessonID}/${this.routeTopicID}`;
			} else {
				return `/summary/${this.routeLessonID}`;
			}
		},
		updateAnswer(value) {;
			let currentAnswerIndex = this.questionAnswerInput.findIndex(qa => qa.questionID === value.questionID);
			if (currentAnswerIndex > -1) {
				this.questionAnswerInput.splice(currentAnswerIndex, 1);
			}
			this.questionAnswerInput.push(value);
		}
	},
	setup() {

		const queryLoading = ref(false);
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const ionRouter = useIonRouter();

		const routeLessonID = (route.params.lessonID) ? route.params.lessonID : '';
		const routeTopicID = (route.params.topicID) ? route.params.topicID : '';

		const lesson = ref({});

		let topic = null;
		if (routeTopicID) {
			topic = computed(() => lesson.value?.topics?.find(t => t.topicID === routeTopicID));
		} else {
			topic = computed(() => {});
		}

		const questions = computed(() => {
			let questions = [];
			if (routeTopicID) {
				questions = topic?.value?.quiz ?? [];
			} else {
				questions = lesson?.value?.test ?? [];
			}
			return questions;
		});

		return {
			arrowForward,
			caretDownOutline,
			clipboard,
			informationCircle,
			ionRouter,
			topic,
			lesson,
			router,
			routeLessonID,
			routeTopicID,
			store,
			trophy,
			questions
		};
	},
	ionViewWillEnter() {
		const attemptNum = this.store.getters.getQuizTestAttempts({lessonID: this.routeLessonID, topicID: this.routeTopicID});
		this.store.dispatch('fetchLessonQuestions', {lessonID: this.routeLessonID, attemptNum}).then(res => this.lesson = res);
	}
});
</script>

<style lang="scss" scoped>
.past-test-summary {
	background-color: #FFFFFF;
	box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
	display: flex;
	column-gap: 15px;
	justify-content: stretch;
	padding: 5px 10px 5px 5px;
	z-index: 1000;

	&__actions {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 100%;

		ion-button {
			margin: 0;
		}
	}
}
</style>