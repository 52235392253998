<template>
	<ion-page>
		<InstructorHeader />
		<ion-content fullscreen :scrollY="false">

			<ion-header collapse="fade" id="app-toolbar" class="tray-sidebar__header">
				<ion-toolbar class="tray-sidebar__toolbar">
					<ion-button
						color="primary-lighten-1"
						slot="start"
						icon
						@click="addSubDay(1)"
					>
						<MaterialDesignIcon icon="chevronLeft" color="white" />
					</ion-button>
					<ion-title class="ion-text-center ion-no-padding" size="small" color="light"><b>{{ currentSelectedDate || selectedDate }}</b></ion-title>
					<ion-button
						color="primary-lighten-1"
						slot="end"
						icon
						@click="addSubDay(-1)"
					>
						<MaterialDesignIcon icon="chevronRight" color="white" />
					</ion-button>
				</ion-toolbar>
			</ion-header>
			<ion-progress-bar type="indeterminate" v-if="loading" style="--background: var(--ion-color-secondary);" />
			<div class="session-list">
				<template v-if="groupedSessions && groupedSessions.length !== 0">
					<InstructorSessionCard
						:sessionGroup="sessionGroup.sessions"
						:instructorTimeSlotData="sessionGroup.timeSlotData"
						:isHidden="sessionGroup.timeSlotData.isHidden"
						:sessionProximityAndStatus="sessionProximityAndStatus(sessionGroup, institutionTZ)"
						v-for="(sessionGroup, index) in groupedSessions"
						:key="sessionGroup.sessions.map(s => s.sessionID).join('') + index"
					/>
				</template>
				<div class="ion-margin ion-text-center" v-else-if="!loading">
					<ion-icon class="ion-margin-bottom" :icon="calendarClearOutline" size="large"></ion-icon>
					<p>You do not have any scheduled appointments today.</p>
				</div>
				<template v-if="groupedSessions.length === 0 && loading">
					<SessionCardSkeletonVue detailed v-for="item of 3" />
				</template>
			</div>
		</ion-content>

	</ion-page>
</template>

<script>
import { subDays, parseISO, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
	sessionProximityAndStatus
} from './helpers/sessionProximityAndStatus.js';
import {
	GET_INSTRUCTOR_TIME_SLOTS_QUERY,
	USERS_FOR_SCHEDULER_QUERY,
	GET_INSTITUTION_SKILL_SETS_QUERY,
	GET_INSTITUTION_SKILLS_QUERY,
} from '@/core/api/drivingApi';
import { useQuery } from '@vue/apollo-composable';
import InstructorHeader from '../../components/instructor/InstructorHeader.component.vue';
import SessionCardSkeletonVue from "@/view/components/skeletons/SessionCardSkeleton.vue";
import InstructorSessionCard from "@/view/components/cards/InstructorSessionCard.vue";
import SkillFeedbackAttemptModal from '../../components/instructor/SkillFeedbackAttemptModal.vue'
import SkillFeedbackModal from '../../components/instructor/SkillFeedbackModal.vue'
import SkillInfoModal from '../../components/instructor/SkillInfoModal.vue'
import AttemptHistoryModal from '../../components/instructor/AttemptHistoryModal.vue'
import { calendarClearOutline, close } from "ionicons/icons";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { toast } from '../../../core/toast/Toast';
import {
	IonButton,
	IonIcon,
	IonContent,
	IonHeader,
	IonMenu,
	IonMenuToggle,
	IonPage,
	IonSearchbar,
	IonTitle,
	IonToolbar,
} from '@ionic/vue';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import SkillListItem from '@/view/components/SkillListItem.vue';
import { Preferences } from '@capacitor/preferences';

export default {
	components: {
		InstructorHeader,
		IonButton,
		IonIcon,
		IonContent,
		IonHeader,
		IonMenu,
		IonMenuToggle,
		IonPage,
		IonSearchbar,
		IonTitle,
		IonToolbar,
		MaterialDesignIcon,
		InstructorSessionCard,
		SessionCardSkeletonVue,
		SkillListItem,
		SkillFeedbackAttemptModal,
		SkillFeedbackModal,
		SkillInfoModal,
		AttemptHistoryModal,
		ref,
		onMounted,
		subDays,
	},
	setup() {
		const getStudentCourseIDsFromTimeSlots = (timeSlots) => {
			const studentCourseIDs = [];

			timeSlots.forEach((timeSlot) => {
				timeSlot.instances.forEach((instance) => {
					instance.sessions.forEach((session) => {
						if (!studentCourseIDs.includes(session.studentCourseID) && session.studentCourseID) {
							studentCourseIDs.push(session.studentCourseID);
						}
					});
				});
			});
			return studentCourseIDs;
		};

		const store = useStore();
		const queryLoading = ref(false);
		const institutionTZ = store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		const enableTimeSlotQuery = ref(false);

		// get start of day for today for the current institution
		const dayStart = ref(formatInTimeZone(new Date(), institutionTZ, 'yyyy-MM-dd'));

		const currentSelectedDate = computed(() => {
			return format(parseISO(dayStart.value), 'EEEE, MMM d');
		});

		const instructorTimeSlotsQueryVariables = computed(() => ({
			institutionSlug: store.getters.user.institutions[0],
			instructorID: store.getters.activeInstructor?.userID,
			date: dayStart.value
		}));
		const timeSlotQueryEnabled = computed(() => {
			return enableTimeSlotQuery.value
				&& !!store.getters.activeInstructor?.userID
				&& !!store.getters.user.institutions?.[0]
				&& !!dayStart.value;
		})
		const instructorTimeSlotQuery = useQuery(
			GET_INSTRUCTOR_TIME_SLOTS_QUERY, () => ({
				...instructorTimeSlotsQueryVariables.value
			}),
			{
				fetchPolicy: 'cache-and-network',
				enabled: timeSlotQueryEnabled
			})
		instructorTimeSlotQuery.onError(error => {
			queryLoading.value = false;
			console.error(error);
		});
		const instructorTimeSlotsData = computed(() => {
			return instructorTimeSlotQuery.result?.value?.timeSlots || [];
		});

		store.commit('setInstructorHomeTimeSlots', instructorTimeSlotsData);

		// Not used, but run to cache the data as early as possible
		const usersForSchedulerQuery = useQuery(
			USERS_FOR_SCHEDULER_QUERY, () => ({
				institution: store.getters.user.institutions[0],
				studentCourseStatus: ['registered']
			}),
			{
				fetchPolicy: 'cache-and-network',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => !!store.getters.user?.institutions?.[0])
			}
		);

		usersForSchedulerQuery.onResult(({data}) => {
			if (data?.usersForScheduler?.length) {
				store.commit('setSchedulerStudents', data.usersForScheduler);
			}
		});

		// Setup for skills offline
		const institutionSkillSets = useQuery(
			GET_INSTITUTION_SKILL_SETS_QUERY, () => ({
				institutionSlug: store.getters.user.institutions[0],
			}),
			{
				fetchPolicy: 'cache-and-network',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => !!store.getters.isAuthenticated)
			}
		);

		// Setup for skill offline
		const institutionSkillsQuery = useQuery(
			GET_INSTITUTION_SKILLS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
			}),
			{
				fetchPolicy: (store.getters.connectionStatus.connected) ? 'cache-and-network' : 'cache-only',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => !!store.getters.isAuthenticated)
			}
		);


		return {
			close,
			calendarClearOutline,
			instructorTimeSlotsQueryVariables,
			instructorTimeSlotQuery,
			loading: instructorTimeSlotQuery.loading,
			store,
			queryLoading,
			currentSelectedDate,
			dayStart,
			instructorTimeSlotsData,
			enableTimeSlotQuery,
			institutionTZ,
		}
	},
	computed: {
		groupedSessions() {
			const data = this.instructorTimeSlotsData.reduce((agg, curr) => {
				const sessions = [];
				curr.instances.forEach((i) => {
					if(i.date === this.instructorTimeSlotsQueryVariables.date) {
						i.timeGroupedSessions.forEach((gs) => {
							const groupedSessions = [];
							gs.sessions.forEach(session => {
								const newSessionObject = {
									...session,
									instanceDate: i.date,
									location: gs.customPickupLocation || curr.location
								};
								groupedSessions.push(newSessionObject);
							});

							sessions.push({
								timeSlotData: {
									location: gs.customPickupLocation || curr.location,
									vehicle: curr.vehicle,
									isHidden: curr.isHidden,
									instructor: curr.instructor,
									instanceID: i.id,
									date: i.date,
									institution: curr.institution,
									timeSlotID: curr.timeSlotID,
									note: curr.note,
								},
								sessions: groupedSessions
							});
						});
					}
				});
				agg = agg.concat(sessions);
				return agg;
			}, []);

			return data;
		},
		institutionTZ() {
			return this.store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		}
	},
	ionViewWillLeave() {
		this.enableTimeSlotQuery = false;
	},
	ionViewDidEnter() {
		this.store.dispatch('clearActiveSession');
		this.store.dispatch('removeAddedSkillAttempts');
		this.enableTimeSlotQuery = true;

		if (this.store.getters.connectionStatus.connected) {
			this.instructorTimeSlotQuery.refetch();
		}
	},
	methods: {
		addSubDay(num) {
			this.dayStart = format(subDays(parseISO(this.dayStart), num), 'yyyy-MM-dd');
		},
		sessionProximityAndStatus
	},
	data() {
		return {
		}

	}
};
</script>

<style scoped lang="scss">

.session-list {
	height: calc(100% - 56px);
	overflow-y: scroll;
	padding: 15px;
}

.skills-toolbar {
	--background: var(--ion-color-grey);
	--padding-bottom: 0;
	--padding-end: 0;
	--padding-start: 0;
	--padding-top: 0;

	& > ion-button::part(native) {
		padding: 16px 8px;
	}
}

ion-header {
	position: initial;
}

.skill-set-selector-modal {
	@media(max-width: 767px) {
		bottom: 0;
		height: calc(100% - 152px);
		top: auto;
	}

	ion-header {
		@media(max-width: 767px) {
			display: none;
		}
	}

	ion-toolbar {
		border: none;
	}

	ion-content::part(scroll) {
		--offset-bottom: -57px;

		@media(max-width: 767px) {
			--offset-bottom: 0px;
		}
	}

	ion-list {
		background: #FFFFFF;
		padding: 0;
	}

	ion-searchbar.skill-set-selector-modal__search {
		--background: #FFFFFF;
		--box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
		padding: 0;
	}
}
	ion-content {
		ion-header {
			ion-toolbar {
				border: 0;
			}
		}
	}

	.content {

		&::part(background) {
			background-color: var(--ion-color-grey-darken-1);
		}

		&__toolbar {
			align-items: center;
			background-color: var(--ion-color-primary-tint);
			color: #ffffff;
			display: flex;
			justify-content: space-around;
			padding: 10px 5px;
			width: 100%;

			> span {
				display: flex;
				align-items: center;
			}
		}
	}

	.session-student-select {
		--border-radius: 10px 0 0 10px;
		margin: 10px 0;
	}

	.session-group-select {
		--border-radius: 0 10px 10px 0;
		margin: 10px 0;
	}

	.session-select {
		flex: 0 0 75%;
		border-right: 3px solid var(--ion-color-primary-tint);
		max-width: 450px;
		margin: 10px;
	}

	.tray-sidebar {
		z-index: 100;
		flex: 0 0 25%;
		padding: 15px 0;
		--max-width: none;
		--background: none;
		--width: 100%;
		&::part(container) {
			display: flex;
			flex-direction: row;
		}

		ion-toolbar {
			background: var(--ion-color-primary-tint);
			--box-shadow: none;
		}

		&__container {
			width:80%;
			max-width: 450px;
		}
			&__top {
				border-right: 3px solid var(--ion-color-primary-tint);
				height: 10px;
				width: 100%;
				background-color: #ffffff;
			}

			&__toolbar {
				--border-radius: 0 10px 10px 0;
				--background: var(--ion-color-primary-tint);

				ion-button {
					--border-radius: 0 !important;
					--box-shadow: none;
					height: 56px;
					margin: 0;
				}
			}

				&__left-content {
					flex: 0 0 75%;
					border-right: 3px solid var(--ion-color-primary-tint);
					max-width: 450px;

					@media(max-width: 1400px) {
						flex: 0 0 100%;
						max-width: 100%;

					}
				}

				&__header {
					background: var(--ion-color-primary-tint);
					display: flex;
					align-items: center;
				}

				ion-content::part(scroll) {
					--padding-bottom: 85px;
				}

				&__right-content {
					flex: 0 0 75%;
					border-left: 3px solid var(--ion-color-primary-tint);
					max-width: 400px;

					@media(max-width: 1400px) {
						flex: 0 0 100%;
						max-width: 100%;

					}
				}
		&__dismissal-area {
			padding-top: 5px;
			width: 20%;
			opacity: 1;
		}
			&__left-icon-container {
				background-color: var(--ion-color-primary-tint);
				border-radius: 0 10px 10px 0;
				height: 75px;
				margin: 5px 0;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
				&__icon {
					width: 28px;
					height:28px;
				}
			&__right-icon-container {
				background-color: var(--ion-color-primary-tint);
				border-radius: 10px 0 0 10px;
				height: 75px;
				margin: 5px 33px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
				&__icon {
					width: 28px;
					height:28px;
				}
	}

	.select-tabs {
		display: flex;
		flex: 1;
		padding: 10px 0;
		align-items: center;
	}

	.date-select {
		height: 100%;
	}



	:root {
		--ion-color-instructor-header: #405362;
		--ion-color-instructor-header-rgb: 64,81,229;
		--ion-color-instructor-header-contrast: #ffffff;
		--ion-color-instructor-header-contrast-rgb: 255,255,255;
		--ion-color-instructor-header-shade: #3847ca;
		--ion-color-instructor-header-tint: #5362e8;
	}

	.ion-color-instructor-header {
		--ion-color-base: var(--ion-color-instructor-header);
		--ion-color-base-rgb: var(--ion-color-instructor-header-rgb);
		--ion-color-contrast: var(--ion-color-instructor-header-contrast);
		--ion-color-contrast-rgb: var(--ion-color-instructor-header-contrast-rgb);
		--ion-color-shade: var(--ion-color-instructor-header-shade);
		--ion-color-tint: var(--ion-color-instructor-header-tint);
		--box-shadow:none;
	}
</style>