<template>
	<div :class="['progress-circle', (size === 'sm') ? 'progress-circle--small' : '']">
		<span class="progress-circle__percent" v-if="!hidePercentage">
			<ion-text :color="status">{{ percentageText }}<template v-if="!hidePercentSign">%</template></ion-text>
		</span>
		<span class="progress-circle__quantity" v-if="!hideAmount">
			<ion-text :color="status">{{ amount }}/{{ maxAmount }}</ion-text>
		</span>
		<svg
			:class="['progress-circle__gauge-progress', 'progress-circle__gauge-progress--' + status]"
			width="100%"
			height="100%"
			viewBox="0 0 512 512"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			xml:space="preserve"
			xmlns:serif="http://www.serif.com/"
		>
			<g transform="matrix(1.47836,0,0,1.47836,-49.7621,-119.794)">
				<circle
					cx="206.825"
					cy="254.196"
					r="173.165"
					:stroke-dashoffset="dashOffset"
					:stroke-width="strokeWidth"
					style="
						fill: transparent;
						stroke-dasharray: 1088;
					"
				/>
			</g>
		</svg>
		<svg
			class="progress-circle__gauge"
			width="100%"
			height="100%"
			viewBox="0 0 512 512"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			xml:space="preserve"
			xmlns:serif="http://www.serif.com/"
		>
			<g transform="matrix(1.47836,0,0,1.47836,-49.7621,-119.794)">
				<circle
					cx="206.825"
					cy="254.196"
					r="173.165"
					:stroke-width="strokeWidth"
					style="
						fill: rgba(255,255,255, 1);
						stroke-dasharray: 1088;
						stroke-dashoffset: 0;
					"
				/>
			</g>
		</svg>
	</div>
</template>

<script>
import {
	IonText
} from '@ionic/vue'

export default {
	name: 'ProgressCircle',
	components: {
		IonText
	},
	computed: {
		percentageText() {
			if (!this.percentage) { return 0; }

			let roundedNumber = Math.round(this.percentage * 100);

			return roundedNumber;
		},
		percentage() {
			if(!this.amount || !this.maxAmount) { return 0; }

			let percentage = this.amount / this.maxAmount;

			return percentage.toFixed(2);
		},
		dashOffset() {
			return 1088 - (1088 * this.percentage);
		},
		status() {
			if (this.color) { return this.color; }

			if (this.percentage >= this.passingScore) {
				return 'success';
			} else if (this.percentage > (this.passingScore - 0.15)) {
				return 'warning';
			} else {
				return 'danger';
			}
		}
	},
	props: {
		amount: {
			default: 0,
			type: Number
		},
		color: {
			default: '',
			type: String
		},
		passingScore: {
			default: 0,
			type: Number
		},
		hideAmount: {
			default: false,
			type: Boolean
		},
		hidePercentSign: {
			default: false,
			type: Boolean
		},
		hidePercentage: {
			default: false,
			type: Boolean
		},
		maxAmount: {
			default: 0,
			type: Number
		},
		size: {
			default: '',
			type: String
		},
		strokeWidth: {
			default: 45,
			type: Number
		}
	}
}
</script>

<style lang="scss" scoped>
.progress-circle {
	align-items: center;
	-webkit-border-radius: 150px;
	-moz-border-radius: 150px;
	border-radius: 150px;
	box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 135px;
	justify-content: center;
	position: relative;
	width: 135px;

	&__percent {
		font-size: 2.35em;
		font-weight: 700;
		position: relative;
		z-index: 100;
	}

	&__quantity {
		font-size: 1.5em;
		font-weight: 700;
		position: relative;
		z-index: 150;
	}

	svg {
		-webkit-border-radius: 150px;
		-moz-border-radius: 150px;
		border-radius: 150px;
		clip-rule: evenodd;
		fill-rule: evenodd;
		left: 0;
		position: absolute;
		stroke-linejoin: round;
		stroke-miterlimit: 2;
		top: 0;
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		transform: rotate(-90deg);
		z-index: 50;

		&.progress-circle__gauge-progress {
			z-index: 20;
			stroke: var(--ion-color-success);

			&--tertiary {
				stroke: var(--ion-color-tertiary);
			}

			&--warning {
				stroke: var(--ion-color-warning);
			}

			&--danger {
				stroke: var(--ion-color-danger);
			}
		}

		&.progress-circle__gauge {
			z-index: 10;

			g circle {
				stroke: var(--ion-color-primary-lighten-2);
			}
		}
	}

	&--small {
		height: 85px;
		width: 85px;

		.progress-circle__percent {
			font-size: 1.5em;
		}

		.progress-circle__quantity {
			font-size: 1.2em;
		}
	}
}
</style>