<template>
	<div class="skillItem">

		<AttemptHistoryItem></AttemptHistoryItem>

		<RatingScale
			@input="setScore"
			:ratingRangeMax="skillSettings.skillRatingScale"
			:value="this.skillAttempt.score"
			v-if="skillSettings.allowSkillRatings"
		/>

		<ion-item class="skillItem__mark-complete" v-if="skillSettings.allowSkillCompletion">
			<ion-label>Mark Skill Complete</ion-label>
			<ion-checkbox
				:modelValue="skillCompleted"
				@update:modelValue="skillCompleted = $event"
			></ion-checkbox>
		</ion-item>

		<div class="skillItem__feedback-tabs" v-if="skillSettings.allowPositiveNegativeAttempts">
			<ion-button
				fill="solid"
				class="skillItem__feedback-tabs--fail"
				@click="submitAttempt(false)"
				:disabled="skillCompleted"
			>
				<div class="button-content">
					<MaterialDesignIcon icon="closeThick" color="white" />
					<span>Fail</span>
				</div>
			</ion-button>
			<ion-button
				class="skillItem__feedback-tabs--pass"
				@click="submitAttempt(true)"
			>
				<div class="button-content">
					<MaterialDesignIcon icon="checkBold" color="white" />
					<span>Success</span>
				</div>
			</ion-button>
			<ion-button
				class="skillItem__feedback-tabs--feedback"
				@click="gotoFeedback('feedback')"
			>
				<div class="button-content">
					<MaterialDesignIcon icon="commentQuote" color="white" />
					<span>Feedback</span>
				</div>
			</ion-button>
		</div>
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import {
	IonButton,
	IonItem,
	IonLabel
} from '@ionic/vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { ref, onMounted } from "vue";
import AttemptHistoryItem from '@/view/components/AttemptHistoryItem.vue';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import RatingScale from '@/view/components/buttons/RatingScale.vue';

export default {
	components: {
		AttemptHistoryItem,
		IonButton,
		IonItem,
		IonLabel,
		MaterialDesignIcon,
		RatingScale
	},
	computed: {
		skillSettings() {
			return this.store.getters.skillSettings;
		}
	},
	data() {
		return {
			skillCompleted: this.skillItem?.completedDate,
			skillAttempt: {
				id: null,
				date: null,
				success: false,
				completedDate: null,
				score: this.skillItem.score || null,
				instructor: this.store.getters.activeInstructor,
				instanceID: this.store.getters.activeSession.instanceID,
				sessionID: this.store.getters.activeSession.sessionID,
				skillSetID: this.skillSetID,
				note: null,
				feedbackItems: [],
			},
		}
	},
	methods: {
		setScore(score) {
			this.skillAttempt.score = score;
		},
		submitAttempt(success) {
			this.skillAttempt.id = uuidv4();
			this.skillAttempt.success = success;
			this.skillAttempt.date = Date.now();
			if (this.skillCompleted) {
				this.skillAttempt.completedDate = Date.now();
				this.skillAttempt.completedBy = this.store.getters.activeInstructor.userID;
			} else {
				this.skillAttempt.completedDate = null;
				this.skillAttempt.completedBy = null;
			}

			const studentCourseID = this.store.getters.activeSession.driverStudentCourseID;
			const userID = this.store.getters.activeSession.driverUserID;
			const skillID = this.skillItem.id;
			this.store.dispatch('addSkillAttemptToLocalProgress', {
				studentCourseID,
				userID,
				skillID,
				skillAttempt: this.skillAttempt
			});

			this.$emit('attemptSubmit');
			this.skillAttempt.id = uuidv4();
		},
		gotoFeedback() {
			this.$emit('openAttemptCRUDModal');
		}
	},
	props: {
		skillItem: {
			default: {},
			type: Object
		},
		skillSetID: {
			default: null,
			type: String
		}
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const queryLoading = ref(false);
		// TODO: replace with setting;
		const institutionRatingScale = 7;

		return {
			store,
			router,
			queryLoading,
			ref,
			onMounted,
			institutionRatingScale
		}
	},
	watch: {
		'skillItem'() {
			this.skillAttempt.score = this.skillItem.score || null;
			this.skillAttempt.completed = this.skillItem.completed;
			this.skillCompleted = this.skillItem.completedDate;
		},
	}
}
</script>

<style scoped lang="scss">
	.skillItem {
		background-color: var(--ion-color-grey-darken-1);

		&__mark-complete {
			--background: var(--ion-color-primary-lighten-3, #6B7985);
			--border-style: none;

			ion-label {
				color: var(--ion-color-light);
				font-weight: 700;
			}

			ion-checkbox {
				--border-color: var(--ion-color-light);
			}
		}

		&__feedback-tabs {
			align-items: center;
			color: var(--ion-color-light, #FFFFFF);
			display: flex;
			font-weight: 700;
			height: 52px;
			justify-content: space-evenly;
		}

		&__feedback-tabs--fail {
			padding: 0;
			margin: 0;
			height: 52px;
			color: var(--ion-color-light, #FFFFFF);
			--background: var(--ion-color-danger, #ED5564);
			--border-radius: none;
			--box-shadow: none;
		}

		&__feedback-tabs--pass {
			margin: 0;
			padding: 0;
			height: 52px;
			color: var(--ion-color-light, #FFFFFF);
			--background: var(--ion-color-success);
			--border-radius: none;
			--box-shadow: none;
		}

		&__feedback-tabs--feedback {
			margin: 0;
			padding: 0;
			height: 52px;
			color: var(--ion-color-light, #FFFFFF);
			--background: var(--ion-color-primary-tint);
			--border-radius: none;
			--box-shadow: none;
		}
	}

	.button-content {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		gap: 2px;
		padding: 10px 0;
		height: 100%;
		width: 100%;
		font-weight: 700;
		text-transform: none;
	}
</style>