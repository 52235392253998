<template>
	<ion-page>
		<ion-loading v-if="loading || removeCreditCardLoading" show-backdrop />
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button v-if="store.state.showSettingsData?.type !== 'billing'" @click="goBack()">
						<ion-icon color="light" :md="arrowBack" :ios="arrowBack"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title v-if="!loading" color="light">Billing - {{ user.firstName }} {{ user.lastName }}</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="hideSettingsModal()">
						<ion-icon color="light" :md="close" :ios="close"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<div class="course-select" v-if="!loading && !removeCreditCardLoading">
			<ion-select
				label="Select Course"
				label-placement="floating"
				fill="solid"
				v-model="selectedStudentCourseID"
				:disabled="requiringPayment"
			>
				<ion-select-option
					v-bind:key="studentCourse.studentCourseID"
					v-for="studentCourse in user.courses"
					:value="studentCourse.studentCourseID"
				>
					{{studentCourse.course.name}}
				</ion-select-option>
			</ion-select>
		</div>
		<ion-content class="billing-settings" v-if="!initialLoading && !removeCreditCardLoading">
			<ion-card color="light" class="drop-shadow">
				<ion-card-content>
					<div class="billing-settings__current-balance" v-if="!requiringPayment">
						<p class="current-balance-text">Balance</p>
						<p class="current-balance-text">{{ userBalances[selectedStudentCourseID] > 0 ? `(${formatDollarAmount(userBalances[selectedStudentCourseID])})` : `${formatDollarAmount(Math.abs(userBalances[selectedStudentCourseID]) || 0)}` }}</p>
					</div>
					<div v-if="showMakePayment">
						<!-- <ion-item v-if="!isStripeGateway && !newCardData.useCardOnFile">
							<ion-label>Save card for future use.</ion-label>
							<ion-checkbox v-model="newCardData.saveCard"></ion-checkbox>
						</ion-item>
						<ion-item v-if="!isStripeGateway && hasPaymentMethod">
							<ion-label>Use saved card.</ion-label>
							<ion-checkbox v-model="newCardData.useCardOnFile"></ion-checkbox>
						</ion-item> -->
						<ion-label position="floating">Amount</ion-label>
						<ion-item
							ref="cardPaymentAmount"
							:class="[validationErrors.cardAmount ? 'payment-form-item--invalid' : 'payment-form-item--valid', 'form-item']"
						>
							<ion-input
								fill="outline"
								v-model="cardPaymentAmount"
								type="number"
								placeholder="0.00"
								@ionBlur="validateCardData($event, 'cardPaymentAmount')"
								:disabled="requiringPayment"
							></ion-input>
							<ion-note slot="error">{{ errorMessages.invalidAmountMessage }}</ion-note>
						</ion-item>
						<div v-if="!newCardData.useCardOnFile">
							<ion-label position="stacked">First Name</ion-label>
							<ion-item
								ref="cardFirstName"
								:class="[validationErrors.cardFirstName ? 'payment-form-item--invalid' : 'payment-form-item--valid', 'form-item']"
							>
								<ion-input
									v-model.trim="newCardData.cardFirstName"
									type="string"
									:maxlength="50"
									@ionBlur="validateCardData($event, 'cardFirstName')"
								></ion-input>
								<ion-note slot="error">{{ errorMessages.invalidFirstNameMessage }}</ion-note>
							</ion-item>
							<ion-label position="stacked">Last Name</ion-label>
							<ion-item
								ref="cardLastName"
								:class="[validationErrors.cardLastName ? 'payment-form-item--invalid' : 'payment-form-item--valid', 'form-item']"
							>
								<ion-input
									v-model.trim="newCardData.cardLastName"
									type="string"
									:maxlength="50"
									@ionBlur="validateCardData($event, 'cardLastName')"
								></ion-input>
								<ion-note slot="error">{{ errorMessages.invalidLastNameMessage }}</ion-note>
							</ion-item>
							<ion-label v-if="!isSquareGateway && !shouldShowStripeCardElement" position="stacked">Card Number</ion-label>
							<ion-item
								ref="cardNumber"
								v-if="!isSquareGateway && !shouldShowStripeCardElement"
								:class="[validationErrors.cardNumber ? 'payment-form-item--invalid' : 'payment-form-item--valid', 'form-item']"
							>
								<ion-input
									type="text"
									v-maska="cardNumberOptions.mask"
									v-model="newCardData.cardNumber"
									:placeholder="cardNumberOptions.placeholder"
									@ionBlur="validateCardData($event, 'cardNumber')"
								></ion-input>
								<ion-note slot="error">{{ errorMessages.invalidCardNumberMessage }}</ion-note>
							</ion-item>
							<div class="expiration-cvv">
								<div>
									<ion-label v-if="!isSquareGateway && !shouldShowStripeCardElement" position="stacked">Expiration Date</ion-label>
									<ion-item
										ref="cardExp"
										v-if="!isSquareGateway && !shouldShowStripeCardElement"
										:class="[validationErrors.cardExp ? 'payment-form-item--invalid' : 'payment-form-item--valid', 'form-item-expcvv']"
									>
										<ion-input
											v-model="newCardData.cardExp"
											type="string"
											v-maska="expirationDateComputedMask"
											placeholder="MM/YY"
											@ionBlur="validateCardData($event, 'cardExp')"
										></ion-input>
										<ion-note slot="error">{{ errorMessages.invalidExpMessage }}</ion-note>
									</ion-item>
								</div>
								<div>
									<ion-label v-if="!isSquareGateway && !shouldShowStripeCardElement" position="stacked">Security Code</ion-label>
									<ion-item
										ref="cardCVC"
										v-if="!isSquareGateway && !shouldShowStripeCardElement"
										:class="[validationErrors.cardCVC ? 'payment-form-item--invalid' : 'payment-form-item--valid', 'form-item-expcvv']"
									>
										<ion-input
											v-model="newCardData.cardCVC"
											type="string"
											v-maska="cvvOptions.mask"
											@ionBlur="validateCardData($event, 'cardCVC')"
										></ion-input>
										<ion-note slot="error">{{ errorMessages.invalidCVCMessage }}</ion-note>
									</ion-item>
								</div>
							</div>
							<div id="square-form">
							</div>
							<div id="stripe-form" v-if="shouldShowStripeCardElement">
							</div>
						</div>
					</div>

					<ion-button
						v-if="payTrakSettings.allowCreditCardPayment"
						@click="!showMakePayment ? openMakePayment() : makeCreditCardPayment()"
						expand="block"
						:color="showMakePayment ? 'success' : 'tertiary'"
						:disabled="!requiringPayment && (userBalances[selectedStudentCourseID] >= 0 || makePaymentWithCardOnFileLoading)"
					>Make Payment</ion-button>
				</ion-card-content>
			</ion-card>
			<!-- credit card on file -->
			<!-- <div class="billing-settings__card-section-label" v-if="payTrakSettings.allowCreditCardPayment && selectedStudentCourse.payment?.cardLastFour">
				<ion-icon :ios="card" :md="card" slot="start" color="dark"></ion-icon>
				<ion-label color="dark">
					<h2>Credit Card</h2>
				</ion-label>
			</div>
			<ion-card color="light" v-if="selectedStudentCourse.payment?.cardLastFour && payTrakSettings.allowCreditCardPayment">
				<ion-card-content class="existing-card">
					<div class="existing-card__details">
						<p>{{ selectedStudentCourse.payment.cardFirstName }} {{ selectedStudentCourse.payment.cardLastName }}</p>
						<p>**** **** **** {{ selectedStudentCourse.payment.cardLastFour }}</p>
						<p class="expiration-text">Exp {{ selectedStudentCourse.payment.cardExpMonth }}/{{selectedStudentCourse.payment.cardExpYear.slice(-2)}} </p>
					</div>
					<ion-button
						expand="full"
						color="danger"
						@click="deletePaymentMethod()"
					>
						<ion-icon color="light" :md="closeCircle" :ios="closeCircle"></ion-icon>
					</ion-button>
				</ion-card-content>
			</ion-card> -->
			<!-- <ion-card class="add-card" v-if="!selectedStudentCourse.payment?.cardLastFour && !showAddCard && payTrakSettings.allowCreditCardPayment" button @click="openAddCard()">
				<ion-card-content>
					<p>Add Credit Card</p>
					<ion-icon :ios="addCircle" :md="addCircle" slot="start" color="dark"></ion-icon>
				</ion-card-content>
			</ion-card> -->
			<!-- <ion-card v-if="showAddCard">
				<ion-card-content>
					<ion-item>
						<ion-label position="stacked">First Name</ion-label>
						<ion-input
							v-model="newCardData.cardFirstName"
							type="string"
							:maxlength="50"
						></ion-input>
					</ion-item>
					<ion-item>
						<ion-label position="stacked">Last Name</ion-label>
						<ion-input
							v-model="newCardData.cardLastName"
							type="string"
							:maxlength="50"
						></ion-input>
					</ion-item>
					<ion-item v-if="!isSquareGateway && !shouldShowStripeCardElement">
						<ion-label position="stacked">Card Number</ion-label>
						<ion-input
							v-model="newCardData.cardNumber"
							type="string"
							:maxlength="16"
							:mask="['9999 9999 9999 9999']"
						></ion-input>
					</ion-item>
					<ion-item v-if="!isSquareGateway && !shouldShowStripeCardElement">
						<ion-label position="stacked">Expiration Date</ion-label>
						<ion-input
							v-model="newCardData.cardExp"
							type="string"
							:maxlength="5"
							:mask="['99/99']"
						></ion-input>
					</ion-item>
					<ion-item v-if="!isSquareGateway && !shouldShowStripeCardElement">
						<ion-label position="floating">CVV</ion-label>
						<ion-input
							v-model="newCardData.cardCVC"
							type="number"
							:maxlength="3"
							:mask="['999']"
						></ion-input>
					</ion-item>
					<div id="square-form">
					</div>
					<ion-button
						id="submit-authorize"
						@click="addCard()"
						expand="block"
						color="tertiary"
						:disabled="cardSubmitDisabled"
					>Add Card</ion-button>
				</ion-card-content>
			</ion-card> -->
			<div class="billing-settings__payment-history-label" v-if="!requiringPayment">
				<m-d-i-cash-clock class="mdi-icon"></m-d-i-cash-clock>
				<ion-label color="dark">
					<h2 class="font-bold">Transaction History</h2>
				</ion-label>
			</div>
			<ion-list class="billing-settings__payment-history" v-if="!requiringPayment">
				<payment-history-item
					class="drop-shadow"
					v-for="transaction in userTransactionsOfSelectedCourse.slice().reverse()"
					:key="transaction.id"
					:transaction="transaction"
				/>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import PaymentHistoryItem from '../PaymentHistoryItem.vue';
import { ADD_CARD_MUTATION, api, MAKE_PAYMENT_WITH_CARD_ON_FILE_MUTATION, MAKE_PAYMENT_WITH_NEW_CARD_MUTATION, REMOVE_CARD_MUTATION } from '@/core/api/api';
import envConfig from "@/core/config/env.config";
import { defineComponent, ref } from "vue";
import MDICashClock from '@/view/components/icons/MDICashClock.vue';
import {
	arrowBack,
	addCircle,
	checkmarkCircle,
	card,
	cash,
	close,
	closeCircle,
} from 'ionicons/icons';
import { toast } from '@/core/toast/Toast';
import { getYear } from 'date-fns';
import { useMutation } from '@vue/apollo-composable';
import {
	IonContent,
	IonHeader,
	IonIcon,
	IonTitle,
	IonToolbar,
	IonLoading,
	alertController,
} from '@ionic/vue';
import { useStore } from 'vuex';
import { maska } from 'maska'

export default defineComponent({
	name: "settingsBilling",
	components: {
		IonContent,
		IonHeader,
		IonIcon,
		IonTitle,
		IonToolbar,
		IonLoading,
		MDICashClock,
		PaymentHistoryItem
	},
	directives: {
		maska
	},
	computed: {
		userBalances() {
			return this.store.getters.balanceDueMap;
		},
		userTransactionsOfSelectedCourse() {
			if (!this.userTransactions) return [];
			return this.userTransactions.filter(transaction => transaction.studentCourseID === this.selectedStudentCourse.studentCourseID);
		},
		hasPaymentMethod() {
			return this.selectedStudentCourse.payment?.cardLastFour &&
				this.selectedStudentCourse.payment?.cardExpMonth &&
				this.selectedStudentCourse.payment?.cardExpYear;
		},
		cardSubmitDisabled() {
			const customInputsSatisfied = !this.newCardData.cardFirstName ||
				!this.newCardData.cardLastName ||
				!this.newCardData.cardNumber ||
				!this.newCardData.cardExp ||
				!this.newCardData.cardCVC;
			const minimalInputsSatisfied = !this.newCardData.cardFirstName ||
				!this.newCardData.cardLastName

			return this.isSquareGateway || this.shouldShowStripeCardElement ? minimalInputsSatisfied : customInputsSatisfied;
		},
		isSquareGateway() {
			return this.payTrakSettings.gateway === 'Square';
		},
		isStripeGateway() {
			return this.payTrakSettings.gateway === 'Stripe';
		},
		shouldShowStripeCardElement() {
			if (this.payTrakSettings.gateway !== 'Stripe') return false;
			const payTrakSDKVersion = this.payTrakSettings.payTrakSDKVersion || '1.0.7';
			return payTrakSDKVersion.localeCompare('1.1.1', undefined, { numeric: true }) >= 0;
		},
		expirationDateComputedMask() {
			return this.newCardData.cardExp ? { mask: '##/##'} : '';
		},
		/**
		 * If this modal is being used only to process a specific payment and then close
		 */
		requiringPayment() {
			return this.store.state.showSettingsData?.requirePayment;
		},
		cardNumberOptions() {
			if (this.cardType === 'amex') {
				return {
					placeholder: '#### ###### #####',
					mask: this.newCardData.cardNumber && {mask: '####-######-#####'} || '',
					validationRegex: this.validationRegexes.amexCardNumber
				}
			} else {
				return {
					placeholder: '#### #### #### ####',
					mask: this.newCardData.cardNumber && {mask: '####-####-####-####'} || '',
					validationRegex: this.validationRegexes.creditCardNumber
				}
			}
		},
		cvvOptions() {
			if (this.cardType === 'amex') {
				return {
					placeholder: '####',
					mask: this.newCardData.cardCVC ? {mask: '####'} : '',
					validationRegex: this.validationRegexes.amexCVV
				}
			} else {
				return {
					placeholder: '###',
					mask: this.newCardData.cardCVC ? {mask:'###'} : '',
					validationRegex: this.validationRegexes.creditCardCVV
				}
			}
		},
	},
	data() {
		return {
			payTrakSettings: this.$store.state?.institutionSettings?.registrationInstitutionConfig?.payment || null,
			payTrakLib: null,
			cardType: '',
			newCardData: {
				cardFirstName: '',
				cardLastName: '',
				cardNumber: '',
				cardExp: '',
				cardCVC: '',
				useSavedCard: false,
				cardZip: '',
				useCardOnFile: false
			},
			cardPaymentAmount: null,
			showAddCard: false,
			user: {},
			userTransactions: [],
			loading: false,
			// Separate loading flag so that the square form doesn't get removed from the DOM on loading
			initialLoading: false,
			validationErrors: {
				cardAmount: false,
				cardFirstName: false,
				cardLastName: false,
				cardNumber: false,
				cardExp: false,
				cardCVC: false,
			},
			errorMessages: {
				invalidAmountMessage: 'Please enter a valid amount',
				invalidCardNumberMessage: 'Please enter a valid card number',
				invalidFirstNameMessage: 'Please enter a valid first name',
				invalidLastNameMessage: 'Please enter a valid last name',
				invalidExpMessage: 'Please enter a valid expiration date',
				invalidCVCMessage: 'Please enter a valid security code',
			},
			validationRegexes: {
				amexCardNumber: /^\d{4}-\d{6}-\d{5}$/gi,
				creditCardNumber: /^\d{4}-\d{4}-\d{4}-\d{4}$/gi,
				amexCVV: /\d{4}/gi,
				creditCardCVV: /\d{3}/gi,
			}
		}
	},
	methods: {
		buildPaymentMessage() {
			const surchargeSettings = this.store.state.institutionSettings?.paymentSettings?.surcharge;
			const surchargeEnabled = surchargeSettings?.enabled;
			const surchargePercent = surchargeSettings?.percent;
			const surchargeMinAmount = surchargeSettings?.minAmount;
			const paymentBaseAmount = this.cardPaymentAmount;
			const useCardOnFile = this.newCardData.useCardOnFile;

			let surchargeAmountString = '';
			if (surchargeEnabled && (surchargePercent || surchargeMinAmount)) {
				if (surchargeMinAmount) {
					surchargeAmountString = `A minimum of ${this.formatDollarAmount(surchargeMinAmount)} or ${surchargePercent}% surcharge will be added.`;
				} else {
					surchargeAmountString = `A ${surchargePercent}% surcharge will be added.`;
				}
			}
			const baseTextHTML = `<p>${surchargeAmountString}</p>`;

			let amountsString = `Total: ${this.formatDollarAmount(paymentBaseAmount)}`;
			if (surchargeEnabled && (surchargePercent || surchargeMinAmount)) {
				const surchargeAmount = this.calculateSurchargeAmount(surchargeMinAmount, surchargePercent, paymentBaseAmount);
				amountsString = `
					<p><b>Payment:</b> ${this.formatDollarAmount(paymentBaseAmount)}</p>
					<p><b>Surcharge:</b> ${this.formatDollarAmount(surchargeAmount)}</p>
					<p><b>Total:</b> ${this.formatDollarAmount(Number(paymentBaseAmount) + Number(surchargeAmount))}</p>
				`;
			}

			return `${baseTextHTML} ${amountsString}`;
		},
		calculateSurchargeAmount(minSurchargeAmount, surchargePercent, paymentBaseAmount) {
			let amount = minSurchargeAmount || 0;
			if (surchargePercent) {
				const amountFromPercent = paymentBaseAmount * (surchargePercent / 100);
				amount = Math.max(amount, amountFromPercent);
			}
			return amount;
		},
		logData(type, status, details) {
			api.logMobileActivity({
				institution: this.store.getters.institutionSettings.slug,
				event: type,
				affectedUserID: this.store.getters.user.userID,
				additionalData:{
					email: this.store.getters.user.email,
					activeUser: this.store.getters.selectedUser,
					currentAppVersions: this.store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status,
					details
				}
			});
		},
		formatDollarAmount(amount) {
			return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amount);
		},
		makeCreditCardPayment() {
			try {
				if (!this.validatePaymentFields()) {
					return;
				}

				this.loading = true;
				if (this.newCardData.useCardOnFile) {
					this.showPaymentConfirmation();
				} else {
					const [month, year] = this.newCardData.cardExp.split('/');
					this.payTrakLib.runCharge({
						card: this.newCardData.cardNumber.replace(/-/g, ''),
						cvc: this.newCardData.cardCVC,
						month: month,
						year: getYear(new Date()).toString().slice(0, 2) + year,
						cardFirstName: this.newCardData.cardFirstName,
						cardLastName: this.newCardData.cardLastName,
						cardZip: this.newCardData.cardZip
					}, this.showPaymentConfirmation);
				}
			} catch (err) {
				this.loading = false;
				toast.error({
					message: err,
					duration: 5000
				});
			}
		},
		validateCardData(ev, refName) {
			// reset field validation
			this.$refs[refName].$el.classList.remove('ion-valid');
			this.$refs[refName].$el.classList.remove('ion-invalid');

			switch (refName) {
				case 'cardPaymentAmount':
					if (!this.validateCardPaymentAmount()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardFirstName':
					if (!this.validateCardFirstName()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardLastName':
					if (!this.validateCardLastName()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardNumber':
					if (!this.validateCardNumber()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardExp':
					if (!this.validateExpirationDate()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardCVC':
					if (!this.validateCardCVC()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
			}
		},
		validateCardPaymentAmount() {
			if (this.requiringPayment) return true;
			const cardPaymentAmount = Number(this.cardPaymentAmount);
			const cardPaymentAmount0orLess = cardPaymentAmount <= 0;
			const cardPaymentAmountGreaterThanBalance = cardPaymentAmount > Math.abs(this.userBalances?.[this.selectedStudentCourseID] || 0);
			if (cardPaymentAmount0orLess || cardPaymentAmountGreaterThanBalance) {
				this.errorMessages.invalidAmountMessage = cardPaymentAmount0orLess ? 'Please enter an amount greater than $0' : 'Please enter an amount less than or equal to the balance';
				this.validationErrors.cardAmount = true;
				return false;
			}
			this.validationErrors.cardAmount = false;
			return true;
		},
		validateCardFirstName() {
			const cardFirstNameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
			if (!this.newCardData.cardFirstName || !cardFirstNameRegex.test(this.newCardData.cardFirstName)) {
				this.validationErrors.cardFirstName = true;
				return false;
			}
			this.validationErrors.cardFirstName = false;
			return true;
		},
		validateCardLastName() {
			const cardLastNameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
			if (!this.newCardData.cardLastName || !cardLastNameRegex.test(this.newCardData.cardLastName)) {
				this.validationErrors.cardLastName = true;
				return false;
			}
			this.validationErrors.cardLastName = false;
			return true;
		},
		validateCardNumber() {
			if (!this.newCardData.cardNumber?.match(this.cardNumberOptions.validationRegex)) {
				this.validationErrors.cardNumber = true;
				return false;
			}
			this.validationErrors.cardNumber = false;
			return true;
		},
		validateCardCVC() {
			// 3 or 4 digit cvc
			if (!this.newCardData.cardCVC?.match(this.cvvOptions.validationRegex)) {
				this.validationErrors.cardCVC = true;
				return false;
			}
			this.validationErrors.cardCVC = false;
			return true;
		},
		validatePaymentFields() {
			// Fields Required for all gateways
			const invalidFields = [
				this.validateCardData(null, 'cardPaymentAmount'),
				this.validateCardData(null, 'cardFirstName'),
				this.validateCardData(null, 'cardLastName'),
			];

			// non-square validation fields
			if (!this.isSquareGateway && !this.shouldShowStripeCardElement) {
				// card number regex validation
				invalidFields.push(this.validateCardData(null, 'cardNumber'));
				// expiration regex validation
				invalidFields.push(this.validateCardData(null, 'cardExp'));
				// cvc regex validation
				invalidFields.push(this.validateCardData(null, 'cardCVC'));
			}
			if (invalidFields.includes(false)) {
				toast.error({
					message: 'Invalid payment information. Please check your payment fields and try again.',
					duration: 5000
				});
				return false;
			}
			return true;
		},
		validateExpirationDate() {
			if (!this.newCardData.cardExp) {
				this.validationErrors.cardExp = true;
				return false;
			}
			const [month, year] = this.newCardData.cardExp.split('/');
			if (!year || !month) {
				this.validationErrors.cardExp = true;
				return false;
			}
			if (Number(month) > 12 || Number(month) < 1) {
				this.validationErrors.cardExp = true;
				return false;
			}
			const expirationDate = new Date(getYear(new Date()).toString().slice(0, 2) + year, month);
			const currentDate = new Date();
			if (expirationDate < currentDate) {
				this.validationErrors.cardExp = true;
				return false;
			}
			this.validationErrors.cardExp = false;
			return true;
		},
		deletePaymentMethod() {
			try {
				const removeCardVariables = {
					institution: this.$props.institution ?? this.store.state.institutionSettings.slug,
					userID: this.$props.userID ?? this.store.state.selectedUser,
					studentCourseID: this.selectedStudentCourseID
				};
				this.removeCreditCard(removeCardVariables);
			} catch (err) {
				toast.error({
					message: err,
					duration: 5000
				});
			}
		},
		// TODO: DRIV-2142 add this back in and change naming when we adjust PayTrak to store cards for all gateways
		// async authorizeCreditCard(success, token_or_err, response) {
		// 	if (!success) {
		// 		let err = token_or_err.error && token_or_err.error.message ? token_or_err.error.message : token_or_err
		// 		toast.error({
		// 			message: err,
		// 			duration: 5000,
		// 		});
		// 		return;
		// 	}
		// 	const cardLastFour = this.newCardData.cardNumber.slice(-4) || null;
		// 	const authorizeCreditCardVariables = {
		// 		institution: this.$props.institution ?? this.store.state.institutionSettings.slug,
		// 		userID: this.$props.userID ?? this.store.state.selectedUser,
		// 		studentCourseID: this.selectedStudentCourseID,
		// 		paymentInfo: {
		// 			cardFirstName: this.newCardData.cardFirstName,
		// 			cardLastName: this.newCardData.cardLastName,
		// 			cardZip: this.newCardData.cardZip,
		// 			cardLastFour: cardLastFour,
		// 			token: token_or_err,
		// 			saveCard: true
		// 		},
		// 	};

		// 	const results = await this.addCreditCard(authorizeCreditCardVariables);
		// 	this.selectedStudentCourse.payment = results.data.addCardPaymentMethod;
		// 	this.showAddCard = false;
		// },
		openMakePayment() {
			this.showMakePayment = true;
			this.setupPayTrak();
		},
		closeMakePayment() {
			this.showMakePayment = false;
			this.newCardData = {
				cardFirstName: '',
				cardLastName: '',
				cardNumber: '',
				cardExp: '',
				cardCVC: '',
				useSavedCard: false,
				cardZip: '',
				useCardOnFile: false
			};
		},
		async makePaymentWithCardOnFile() {
			try {
				this.loading = true;
				this.showMakePayment = false;
				if (Number(this.cardPaymentAmount) <= 0) {
					toast.error({
						message: 'Please enter a valid amount',
						duration: 5000
					});
					return;
				}
				// make api call for payment
				const makePaymentVariables = {
					institution: this.$props.institution ?? this.store.state.institutionSettings.slug,
					userID: this.$props.userID ?? this.store.state.selectedUser,
					studentCourseID: this.selectedStudentCourseID,
					amount: Number(this.cardPaymentAmount)
				};
				if(this.store.state.showSettingsData?.addCorrespondingFee) makePaymentVariables.addCorrespondingFee = this.store.state.showSettingsData?.addCorrespondingFee;
				const results = await this.makePaymentWithCardOnFileMutation(makePaymentVariables);
				const creditTransaction = results.data?.makeCreditCardPaymentWithCardOnFileAndReturnCreatedTransactions?.find(transaction => {return transaction.__typename === 'Credit';});
				if (creditTransaction && creditTransaction.success) {
					toast.success({
						message: 'Payment Successful',
						duration: 5000
					});
					this.logData('Credit Card Payment On File', 'Success', creditTransaction.description);
				} else {
					toast.error({
						message: 'Payment Failed: Enter a new Credit Card or try again later',
						duration: 5000
					});
					this.logData('Credit Card Payment On File', 'Failure', creditTransaction.description);
				}
				this.showMakePayment = false;
				this.cardPaymentAmount = null;
				this.userTransactions.push(...results.data?.makeCreditCardPaymentWithCardOnFileAndReturnCreatedTransactions);
				this.loading = false;
				this.user = await api.getUserDetails(this.$props.userID ?? this.store.state.selectedUser);
				this.store.dispatch('checkBalanceDue', this.user);
				this.closeMakePayment();
			} catch (err) {
				toast.error({
					message: err,
					duration: 5000
				});
				this.loading = false;
			}
		},
		async showPaymentConfirmation(success, token_or_err, response) {
			this.loading = false;

			const messageText = this.buildPaymentMessage();
			if (this.newCardData.useCardOnFile) {
				const alert = await alertController.create({
					backdropDismiss: false,
					buttons: [
						{
							text: 'No',
							role: 'cancel'
						},
						{
							text: 'Yes',
							handler: this.makePaymentWithCardOnFile
						}
					],
					cssClass: 'ion-text-center',
					header: 'Confirm Payment',
					message: messageText
				});
				await alert.present();
			} else {
				if (!success) {
					let err = token_or_err.error && token_or_err.error.message ? token_or_err.error.message : token_or_err
					toast.error({
						message: err,
						duration: 5000
					});
					return;
				}
				const alert = await alertController.create({
					backdropDismiss: true,
					buttons: [
						{
							text: 'No',
							role: 'cancel'
						},
						{
							text: 'Yes',
							handler: () => this.makePaymentWithNewCard(success, token_or_err, response)
						}
					],
					cssClass: 'ion-text-center',
					header: 'Confirm Payment',
					message: messageText
				});
				await alert.present();
			}
		},
		async makePaymentWithNewCard(success, token_or_err, response) {
			try {
				// dismiss alert
				alertController.dismiss();
				// show loading
				this.loading = true;
				// hide payment modal
				this.showMakePayment = false;
				const [month, year] = this.newCardData.cardExp.split('/');
				// make api call for payment
				const makePaymentVariables = {
					institution: this.$props.institution ?? this.store.state.institutionSettings.slug,
					userID: this.$props.userID ?? this.store.state.selectedUser,
					studentCourseID: this.selectedStudentCourseID,
					amount: Number(this.cardPaymentAmount),
					paymentInfo: {
						cardFirstName: this.newCardData.cardFirstName,
						cardLastName: this.newCardData.cardLastName,
						cardZip: this.newCardData.cardZip || null,
						cardExpMonth: month || null,
						cardExpYear: year ? getYear(new Date()).toString().slice(0, 2) + year : null,
						cardLastFour: this.newCardData?.cardNumber?.slice(-4) || null,
						token: token_or_err,
						saveCard: this.newCardData.saveCard || false
					},
				};
				if(this.store.state.showSettingsData?.addCorrespondingFee) makePaymentVariables.addCorrespondingFee = this.store.state.showSettingsData?.addCorrespondingFee;
				const results = await this.makePaymentWithNewCardMutation(makePaymentVariables);
				const creditTransaction = results.data?.makeCreditCardPaymentWithNewCardAndReturnCreatedTransactions?.find(transaction => {return transaction.__typename === 'Credit';});

				if (creditTransaction && creditTransaction.success) {
					toast.success({
						message: 'Payment Successful',
						duration: 5000
					});
					this.logData('Credit Card Payment', 'Success', creditTransaction.description);

					if (this.requiringPayment) {
						try {
							this.store.state.showSettingsData.paymentCallback(creditTransaction)
						} catch (e) {
							console.error(e)
						}
					}
				} else {
					toast.error({
						message: 'Payment Failed: Enter a new Credit Card or try again later',
						duration: 5000
					});
					this.logData('Credit Card Payment', 'Failure', creditTransaction.description);
				}
				this.closeMakePayment();
				this.cardPaymentAmount = null;
				this.userTransactions.push(...results.data?.makeCreditCardPaymentWithNewCardAndReturnCreatedTransactions);
				this.loading = false;
				this.user = await api.getUserDetails(this.$props.userID ?? this.store.state.selectedUser);
				this.store.dispatch('checkBalanceDue', this.user);

				if (this.requiringPayment) {
					this.store.commit('hideSettingsModal')
				}
			} catch (err) {
				toast.error({
					message: err,
					duration: 5000
				});
				this.loading = false;
			}
		},
		// openAddCard() {
		// 	this.showAddCard = true;
		// 	this.setupPayTrak();
		// },
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		},
		setupPayTrak() {
			let payTrakScript = document.createElement('script');
			const payTrakSDKVersion = this.payTrakSettings.payTrakSDKVersion || '1.0.7';
			const gateway = this.payTrakSettings.gateway;
			const formIds = {
				Square: 'square-form',
				Stripe: 'stripe-form'
			};

			payTrakScript.setAttribute('src', `https://cdn.compknowhow.com/paytrak/${payTrakSDKVersion}/paytrak.min.js`);
			payTrakScript.onload = () => {

				const payTrackConfig = {
					gateway,
					mode: this.payTrakSettings.mode,
					testApiKey: this.payTrakSettings.testApiKey,
					liveApiKey: this.payTrakSettings.liveApiKey,
					locationID: this.payTrakSettings.locationID,
					paymentElementId: formIds[gateway] ?? '',
					submitButtonId: 'submit-authorize',
					manual: true
				};

				// validate key base on mode
				const modeKeyPairing = {
					live: 'liveApiKey',
					test: 'testApiKey'
				};

				if (!payTrackConfig[modeKeyPairing[this.payTrakSettings.mode]]) {
					console.error('Invalid Paytrak config');
					toast.error({
						message: 'We\'re sorry. There was an error. Please try again later.',
						duration: 3000
					});
				}

				this.payTrakLib = new PayTrak(payTrackConfig);
			};
			document.head.appendChild(payTrakScript);
		},
	},
	async mounted() {
		try {
			this.loading = true;
			// Separate loading flag so that the square form doesn't get removed from the DOM on loading
			this.initialLoading = true;
			this.user = await api.getUserDetails(this.$props.userID ?? this.store.state.selectedUser);
			this.user.courses.sort((a, b) => {
				if (a.studentCourseStatus === 'registered') return -1;
				if (['canceled', 'withdrawn'].includes(a.studentCourseStatus)) return 1;
				return 0;
			});

			if (!this.requiringPayment) {
				this.userTransactions = await api.getUserTransactions(this.$props.userID ?? this.store.state.selectedUser, this.$props.institution ?? this.store.state.institutionSettings.slug);
			}

			if (this.user.courses?.length >= 1) {
				this.selectedStudentCourseID = this.store.state.showSettingsData?.studentCourseID || this.user.courses[0].studentCourseID;
			}

			if (this.requiringPayment) {
				this.openMakePayment();
				if (this.store.state.showSettingsData?.type === 'billing' && this.store.state.showSettingsData?.requirePayment) {
					this.cardPaymentAmount = this.store.state.showSettingsData.paymentAmount;
				}
			}

			this.loading = false;
			// Separate loading flag so that the square form doesn't get removed from the DOM on loading
			this.initialLoading = false;
		} catch (e) {
			this.loading = false;
			// Separate loading flag so that the square form doesn't get removed from the DOM on loading
			this.initialLoading = false;
			console.error(e);
		}
	},
	props: [
		"modalNav",
		"userID",
		"institution"
	],
	watch: {
		selectedStudentCourseID() {
			this.selectedStudentCourse = this.user.courses.find(course => course.studentCourseID === this.selectedStudentCourseID);
		},
		'newCardData.useCardOnFile'() {
			this.setupPayTrak();
		},
		'newCardData.cardNumber'() {
			if (this.newCardData.cardNumber.startsWith('34') || this.newCardData.cardNumber.startsWith('37')) {
				this.cardType = 'amex';
			} else if (this.newCardData.cardNumber.startsWith('4')) {
				this.cardType = 'visa';
			} else if (this.newCardData.cardNumber.startsWith('5') || this.newCardData.cardNumber.startsWith('2')) {
				this.cardType = 'mastercard';
			} else if (this.newCardData.cardNumber.startsWith('6')) {
				this.cardType = 'discover';
			} else {
				this.cardType = '';
			}
		}
	},
	setup(props) {
		const store = useStore();
		const settingsIonNav = document.querySelector('ion-nav');
		const selectedStudentCourse = ref({});
		const selectedStudentCourseID = ref(null);
		const showMakePayment = ref(false);

		// addCreditCard mutation
		// TODO: DRIV-2142 needs more work on PayTrak side
		// const {
		// 	mutate: addCreditCard,
		// 	onDone: addCreditCardOnDone,
		// 	onError: addCreditCardOnError,
		// 	loading: addCreditCardLoading,
		// } = useMutation( ADD_CARD_MUTATION );

		// addCreditCardOnDone(async (result) => {
		// 	toast.success({
		// 		message: 'Credit card successfully added.',
		// 		duration: 5000
		// 	});
		// });

		// addCreditCardOnError(error => {
		// 	toast.error({
		// 		message: 'Unable to add card at this time. Please try again later.',
		// 		duration: 5000
		// 	});
		// });

		// make payment with new card
		const {
			mutate: makePaymentWithNewCardMutation,
			loading: makePaymentWithNewCardLoading,
		} = useMutation( MAKE_PAYMENT_WITH_NEW_CARD_MUTATION );


		// remove payment method mutation
		const {
			mutate: removeCreditCard,
			onDone: removeCreditCardOnDone,
			onError: removeCreditCardOnError,
			loading: removeCreditCardLoading,
		} = useMutation( REMOVE_CARD_MUTATION );

		removeCreditCardOnDone(() => {
			selectedStudentCourse.value.payment = {};
			toast.success({
				message: 'Credit card successfully removed.',
				duration: 5000
			});
		});

		removeCreditCardOnError(error => {
			toast.error({
				message: 'Unable to remove card at this time. Please try again later.',
				duration: 5000
			});
		});

		// make payment mutation
		const {
			mutate: makePaymentWithCardOnFileMutation,
			loading: makePaymentWithCardOnFileLoading,
		} = useMutation( MAKE_PAYMENT_WITH_CARD_ON_FILE_MUTATION );

		const goBack = () => {
			const nav = props.modalNav.value;
			nav.pop();
		}

		return {
			arrowBack,
			card,
			cash,
			addCircle,
			closeCircle,
			checkmarkCircle,
			close,
			goBack,
			store,
			settingsIonNav,
			selectedStudentCourse,
			selectedStudentCourseID,
			showMakePayment,
			// addCreditCard,
			removeCreditCard,
			makePaymentWithCardOnFileMutation,
			// addCreditCardLoading,
			makePaymentWithNewCardMutation,
			makePaymentWithNewCardLoading,
			removeCreditCardLoading,
			makePaymentWithCardOnFileLoading,
		};
	}
});
</script>

<style lang="scss" scoped>
ion-content,
ion-item,
ion-item ion-note,
ion-header {
	ion-toolbar {
		background-color: var(--ion-color-primary);
		border: 0;
	}
}

ion-card {
	box-shadow: none;
}

.expiration-text {
	color: var(--ion-color-dark);
	font-size: 14px;
	font-weight: 700;
}

.existing-card {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 10px 15px;
	}

	> ion-button {
		padding: 0;
		margin: 0;
		height: auto;
		display: flex;
		align-self: stretch;
		align-items: center;
		flex-direction: column;
	}
}

.add-card {
	border: 1px dashed black;

	> ion-card-content {
		display: flex;
		align-content: center;
		justify-content: center;

		> ion-icon {
			font-size: 20px;
			padding-left: 5px;
		}

	}
}

.course-select {
	background-color: white;
}

#square-form {
	padding-top: 15px;
}

#stripe-form {
	border: 1px solid var(--ion-color-primary);
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 15px;
}

.billing-settings {
	height: auto;
	background-color: var(--ion-color-step-200) !important;

	&__card-section-label,
	&__payment-history-label {
		padding-left: 15px;
		padding-bottom: 10px;
		display: flex;
		align-items: center;

		> ion-icon {
			font-size: 25px;
			padding: 5px;
		}
	}

	&__current-balance {
		display: flex;
		align-items: center;
		gap: 80px;
		justify-content: center;
		padding: 15px;
		color: var(--ion-color-dark);

		> p {
			font-weight: bold;
			font-size: 16px;
		}
	}

	&__payment-history {
		background-color: var(--ion-color-step-200) !important;
		height: 75%;
		overflow-y: scroll;
	}
}

.drop-shadow {
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.expiration-cvv {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;

	> div {
		width: 100%;
	}
}

.payment-form-item--valid {
	--background: transparent;
	--border-radius: var(--ion-border-radius);
	--border-color: var(--ion-color-primary);
	--border-width: 1px;
	--color: var(--ion-color-primary)

	> ion-input {
		max-height: 30px;
	}
}

.payment-form-item--invalid {
	--background: transparent;
	--border-radius: var(--ion-border-radius);
	--border-color: var(--ion-color-danger);
	--border-width: 1px;
	--color: var(--ion-color-primary)
}

.mdi-icon {
	margin-left: 2px;
	margin-right: 10px;
	fill: var(--ion-color-primary);
	height: 30px;
	width: 30px;
}

.form-item {
	min-height: 70px;
}

.form-item-expcvv {
	min-height: 100px;
}

.font-bold {
	font-weight: bold;
}

ion-select::part(text) {
	color: var(--ion-color-primary);
}

ion-select::part(icon) {
	color: var(--ion-color-primary);
	opacity: 1;
}
</style>