import { toastController, ToastOptions as ToastControllerOptions } from '@ionic/vue';

export interface ToastOptions {
	position: 'top' | 'bottom' | 'middle';
	message: string;
	duration?: number;
}

const defaultDurationInMs = 2500;

class Toast {
	#queue: ToastControllerOptions[] = [];
	#toast: HTMLIonToastElement | null = null;
	#presenting = false;
	#dismissed = false;

	general(options: ToastOptions): void {
		return this.#show({
			...options,
			color: 'primary'
		});
	}

	error(options: ToastOptions): void {
		return this.#show({
			...options,
			color: 'danger'
		});
	}

	success(options: ToastOptions): void {
		return this.#show({
			...options,
			color: 'success'
		});
	}

	warning(options: ToastOptions): void {
		return this.#show({
			...options,
			color: 'warning'
		});
	}

	#show = (options: ToastControllerOptions): void => {
		const {
			message,
			position = 'top',
			duration = defaultDurationInMs,
			color
		} = options;

		this.#queue.push({
			message,
			position,
			duration,
			color,
			cssClass: 'toast'
		});

		if (this.#presenting) {
			this.#dismiss();
		}
		else {
			this.#present();
		}
	}

	#dismiss = (): void => {
		this.#dismissed = true;

		if (this.#toast) this.#toast!.dismiss();
	}

	#present = async (): Promise<void> => {
		if (this.#presenting) return;
		this.#presenting = true;

		while (this.#queue.length) {
			const nextToast = this.#queue.pop()!;
			this.#queue = [];

			if (!this.#dismissed) {
				const toast = await toastController.create({
					...nextToast,
					buttons: [{
						text: 'Close',
						role: 'cancel',
						handler: () => {
							toast.dismiss();
						}
					}]
				});

				this.#toast = toast;
			}
			if (!this.#dismissed) await this.#toast?.present();
			if (!this.#dismissed) await this.#toast?.onWillDismiss();

			this.#toast = null;
			this.#dismissed = false;
		}

		this.#presenting = false;
	}
}

export const toast = new Toast();