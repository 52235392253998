<template>
	<div :class="['alert', colorClass]">
        <m-d-i-notebook-edit class="alert--icon"/>
		<span class="alert--note" v-html="htmlText"></span>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { defineComponent } from 'vue';
import MDINotebookEdit from '@/view/components/icons/MDINotebookEdit.vue';

export default defineComponent({
	name: "AlertBar",
    components: {
        MDINotebookEdit
    },
	props: {
		htmlText: {
			required: true,
			type: String
		},
        type: {
            default: 'INFO',
            type: String
        }
	},
	methods: {
		format
	},
    computed: {
        colorClass() {
            switch (this.type) {
                case 'INFO': {
                    return 'alert--color-info'
                }
                case 'WARNING': {
                    return 'alert--color-warning'
                }
                case 'ERROR': {
                    return 'alert--color-error'
                }
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-size: 16px;

    &--icon {
        height: 24px;
        width: 24px;
    }

    &--note {
        text-align: left;
    }

	&--date {
        font-weight: 700;
	}

    &--color-info {
        background-color: #DCE8FC;
        border-bottom: 2px solid #508AF1;
    }

    &--color-warning {
        background-color: #FFEEDD;
        border-bottom: 2px solid #FFAB53;
    }

    &--color-error {
        background-color: #FBDDE0;
        border-bottom: 2px solid #ED5564;
    }
}
</style>

<style lang="scss">
    .alert--note {
        h1, h2, h3, h4, h5, h6 {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
</style>