<template>
	<ion-page>
		<ion-content class="ion-padding" :fullscreen="true">
			<ion-grid class="ion-text-center error-content">
				<ion-row>
					<ion-col size="12">
						<ion-img :src="errorImagePath"></ion-img>
					</ion-col>
					<ion-col size="12">
						<h5>UH OH, something went wrong...</h5>
					</ion-col>
					<ion-col size="12">
						<ion-button @click="reload">
							<ion-icon
								class="ion-padding-end"
								:ios="refresh"
								:md="refresh"
							></ion-icon>
							RELOAD
						</ion-button>
					</ion-col>
					<ion-col size="12" class="ion-margin-top">
						<p class="ion-margin-top">If you still experience issues,<br/> try <a class="sign-out --ion-color-primary" @click="logOutUser">signing out</a> and back in.</p>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
	</ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonModal, IonNav } from "@ionic/vue";
import { useStore } from 'vuex';
import {
	refresh
} from "ionicons/icons";

export default defineComponent({
	components: {
		IonModal,
		IonNav,
	},
	setup() {
		const errorImagePath = '/assets/broken-down-car.svg'
		const store = useStore();

		function closeModal() {
			const v = document.getElementById('ion-settings-modal');
			if (v) {
				v.dismiss();
			}
		}

		closeModal();

		return {
			store,
			refresh,
			errorImagePath
		}
	},
	methods: {
		async reload() {
			if (this.$store.getters.authError) {
				this.logOutUser();
			} else {
				this.$router.go('/home');
			}
		},
		async logOutUser() {
			await this.store.dispatch('logOut');
			this.$router.go('/login'); // App needs to route first before dispatching logOut otherwise error will occur.
		}
	}
});
</script>

<style scoped lang="scss">
.error-content {
	height: 100%;
	display: grid;
	align-items: center;
	justify-content: center;

}
.sign-out {
	color: var(--ion-color-primary-shade);
	font-weight: 600;
	text-decoration: underline;
}
</style>