<!-- ion card showing a payment history item with date, description, and amount -->

<template>
	<ion-card color="light" class="transaction">
		<div v-if="!transaction?.success" class="transaction--failure">
			<p>FAILED</p>
		</div>
		<div :class="['transaction-item', transaction.success ? 'transaction--success-background' : 'transaction--failure-background']">
			<div class="transaction-item__date-and-amount">
				<div>
					<p>{{ format(transaction.createdAt, 'MMMM d, yyyy') }}</p>
				</div>
				<div>
					<s v-if="!transaction?.success">{{ formatDollarAmount(Math.abs(transaction.modificationInfo?.find(mi => mi.type === 'FAILED' ).originalAmount)) }}</s>
					<p :class="isPositive(transaction.totalAmount) ? 'positive-amount' : 'negative-amount'">{{ formatDollarAmount(Math.abs(transaction.totalAmount)) }}</p>
				</div>
			</div>
			<div class="transaction-item__description">
				<p>{{ transactionDescription }} </p>
			</div>
			<div class="transaction-item__card-holder" v-if="transaction.transactionMethodInfo?.cardHolder">
				<p>{{ transaction.transactionMethodInfo?.cardHolder || '' }}</p>
			</div>
			<div class="transaction-item__transaction-type">
				<ion-chip disabled v-if="transaction.transactionMethodInfo" class="chip--payment">Payment</ion-chip>
				<ion-chip disabled v-else class="chip--fee">Fee</ion-chip>
				<p>{{ transactionType }}</p>
			</div>
		</div>
	</ion-card>
</template>

<script>
import { format } from 'date-fns';
import { defineComponent } from 'vue';
import {
	IonChip,
} from '@ionic/vue';

export default defineComponent({
	components: {
		IonChip,
	},
	setup() {
		const TRANSACTION_METHOD_TYPES = {
			CARD: 'Card',
			REFUND: 'Refund',
			CHECK: 'Check',
			CASH: 'Cash',
			ADMIN_CREDIT: 'Admin Credit'
		};

		return {
			format,
			TRANSACTION_METHOD_TYPES,
		}
	},
	methods: {
		formatDollarAmount(amount) {
			return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amount);
		},
		isPositive(amount) {
			return amount > 0;
		}
	},
	computed: {
		transactionDescription() {
			let description = this.transaction?.rawDescription || this.transaction.description || '';
			const cardBrand = this.transaction.transactionMethodInfo?.cardBrand?.toUpperCase() || '';
			const cardLastFour = this.transaction.transactionMethodInfo?.cardLastFour || '';
			if (cardBrand || cardLastFour) {
				description += `: ${cardBrand} ${cardLastFour}`;
			}
			return description;
		},
		transactionType() {
			return this.transaction.transactionMethodInfo?.type?.replace('_', ' ') || '';
		},
	},
	props: {
		transaction: {
			type: Object,
			default: () => {}
		},
	}
})
</script>


<style lang="scss" scoped>
.transaction {
	display: flex;
	align-items: flex-start;
	align-self: stretch;
	box-shadow: none;
	border-radius: 0;

	&--failure {
		display: flex;
		writing-mode: vertical-rl;
		transform: rotate(180deg);
		justify-content: center;
		align-items: center;
		align-self: stretch;
		color: var(--ion-color-light, #FFF);
		font-weight: 700;
		font-size: 12px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		background-color: var(--ion-color-danger);
	}

	&--success-background {
		background-color: var(--ion-color-light);
	}

	&--failure-background {
		background-color: var(--ion-color-danger-lighten-9);
	}
}
.transaction-item {
	display: flex;
	padding: 10px 15px 5px 15px;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	display: flex;


	&__date-and-amount,
	&__description,
	&__studentName,
	&__transaction-type,
	&__card-holder {
		padding: 0.2rem 0;
	}


	&__date-and-amount {
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__transaction-type {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
	}

	&__card-holder {
		font-weight: 700;
	}
}
.chip--payment {
	background-color: var(--ion-color-success);
	color: var(--ion-color-light);
	opacity: 1;
	max-height: 20px;
	margin: 0;
}
.chip--fee {
	background-color: var(--ion-color-danger);
	color: var(--ion-color-light);
	opacity: 1;
	max-height: 20px;
	margin: 0;
}
</style>