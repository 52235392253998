<template>
	<div :class="[
		'lesson-progress-bar',
		(inline) ? 'lesson-progress-bar--inline' : '',
		(hideProgressBar) ? 'lesson-progress-bar--hide-progress-bar' : '',
		(menuOpen) ? 'lesson-progress-bar--active' : ''
	]">
		<ion-button class="lesson-progress-bar__button" color="light" @click="menuToggle()">
			<div class="lesson-progress-bar__details">
				<span class="ion-text-wrap" v-if="lesson.name || title">{{ lesson.name }}{{ title }}</span>
				<ion-progress-bar
					:class="(topicsCompleted.length >= lessonTopics.length) ? 'complete' : 'in-progress'"
					:value="percentComplete"
					buffer="1"
					v-if="!hideProgressBar && lessonTopics && topicsCompleted"
				></ion-progress-bar>
			</div>
			<ion-icon :icon="(menuOpen) ? caretUpOutline : caretDownOutline" slot="end" color="primary" />
		</ion-button>
		<div :class="['lesson-progress-bar__menu', (menuOpen) ? 'lesson-progress-bar__menu--show' : '']" v-if="menuOpen">
			<ion-list lines="full" class="ion-no-padding">
				<template v-for="topic in topicItems">
					<ion-item button @click="listTopicClick(lesson, topic)" :disabled="!topic.unlocked">
						<ion-label class="ion-text-wrap">
							<ion-icon color="success" :icon="checkmarkCircle" size="small" slot="start" v-if="topicComplete(topic.topicID)" />
							<ion-icon color="primary" :icon="ellipseOutline" size="small" slot="start" v-else />
							{{ topic.name }}
						</ion-label>
					</ion-item>
					<ion-item
						button
						@click="quizLink(lesson.lessonID, topic.topicID)"
						:disabled="!topic.unlocked || (store.getters.getQuizTestAttempts({topicID: topic.topicID, lessonID: lesson.lessonID}) === 0)"
						style="padding-left: 15px;"
						v-if="topic.hasQuiz"
					>
						<ion-label class="ion-text-wrap">
							<material-design-icon slot="start" icon="clipboardEditOutlined"></material-design-icon>
							Quiz
						</ion-label>
					</ion-item>
				</template>
				<ion-item button @click="listItemClick('/quiztest/' + lesson.lessonID)" v-if="lesson.hasTest" :disabled="!testUnlocked">
					<ion-label class="ion-text-wrap">
						<material-design-icon slot="start" icon="clipboardEdit"></material-design-icon>
						Test
					</ion-label>
				</ion-item>
				<ion-item button @click="listItemClick('/summary/' + lesson.lessonID)" :disabled="!testUnlocked && !lessonCompleted">
					<ion-label class="ion-text-wrap">
						<ion-icon color="primary" :icon="informationCircle" size="small" slot="start"></ion-icon>
						Summary
					</ion-label>
				</ion-item>
			</ion-list>
		</div>
	</div>
</template>

<script>
import {
	IonIcon,
	IonItem,
	IonLabel,
	IonModal,
	IonProgressBar,
	useIonRouter
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import {
  caretDownOutline,
  caretUpOutline,
  checkmarkCircle,
  clipboard,
  clipboardOutline,
  ellipseOutline,
  informationCircle
} from 'ionicons/icons';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { useStore } from "vuex";

export default defineComponent({
	name: "LessonProgressBar",
	components: {
		IonIcon,
		IonItem,
		IonLabel,
		IonModal,
		IonProgressBar,
		MaterialDesignIcon
	},
	computed: {
		percentComplete() {
			return +((this.topicsCompleted.length / this.lessonTopics.length).toFixed(2));
		},
		lessonTopics() {
			if (!this.lesson) { return []; }
			return this.lesson.topics;
		},
		testUnlocked() {
			if (
				this.topicsCompleted.length >= this.lessonTopics.length
				&& this.store.getters.getQuizTestAttempts({ lessonID: this.lesson.lessonID }) < this.store.getters.selectedSyllabus?.testSettings?.attemptsAllowed
			) {
				return true;
			} else {
				return false;
			}
		},
		topicItems() {
			if (this.lessonTopics.length === 0) return [];

			let items = [];
			items.push({
				name: this.lessonTopics[0].name,
				topicID: this.lessonTopics[0].topicID,
				hasQuiz: this.lessonTopics[0].hasQuiz,
				resources: this.lessonTopics[0].resources,
				unlocked: true
			});

			for (let i = 1; i < this.lessonTopics.length; i += 1) {
				items.push({
					hasQuiz: this.lessonTopics[i].hasQuiz,
					name: this.lessonTopics[i].name,
					topicID: this.lessonTopics[i].topicID,
					resources: this.lessonTopics[i].resources,
					unlocked: this.topicComplete(this.lessonTopics[i-1].topicID)
				});
			}

			return items;
		},
	},
	data() {
		return {
			menuOpen: false
		}
	},
	methods: {
		listTopicClick(lesson, topic) {
			let path = '/lessons/' + lesson.lessonID + '/' + topic.topicID;

			if (!this.topicComplete(topic.topicID) && this.resourcesCompleted?.length) {
				// add the next resource to complete
				const lastOfCompletedResources = this.resourcesCompleted[this.resourcesCompleted?.length - 1];
				const lastResourceIndex = topic.resources.findIndex((r) => r.resourceID === lastOfCompletedResources?.reference);
				const nextResource = topic.resources.find((r, index) => (index > lastResourceIndex || lastResourceIndex === -1) && r.content.type !== 'BREAK');
				if (nextResource) {
					path += '/' + nextResource.resourceID;
				}
			}
			this.listItemClick(path);
		},
		listItemClick(path) {
			if (path === this.$route.path) {
				this.menuToggle();
			} else {
				this.ionRouter.navigate(path, 'forward', 'replace');
			}
		},
		menuToggle() {
			this.menuOpen = !this.menuOpen;
			this.$emit('menuToggle', this.menuOpen);
		},
		quizLink(lessonID, topicID) {
			let quizAttempts = this.store.getters.getQuizTestAttempts({topicID: topicID, lessonID: lessonID});
			let path = '';
			if (
				quizAttempts > 0
				&& quizAttempts >= this.store.getters.selectedSyllabus?.quizSettings?.attemptsAllowed)
			{
				path = '/summary/' + lessonID + '/' + topicID;
			} else {
				path = '/quiztest/' + lessonID + '/' + topicID;
			}
			if (path === this.$route.path) {
				this.menuToggle();
			} else {
				this.ionRouter.navigate(path, 'forward', 'replace');
			}
		},
		topicComplete(topicID) {
			if (this.topicsCompleted.length === 0) { return false; }

			if (this.topicsCompleted.some(tc => tc.reference === topicID)) {
				return true;
			} else {
				return false;
			};
		},
	},
	props: {
		hideProgressBar: {
			default: false,
			type: Boolean
		},
		inline: {
			default: false,
			type: Boolean
		},
		lesson: {
			required: true,
			type: Object
		},
		lessonCompleted: {
			default: false,
			type: Boolean
		},
		title: {
			default: '',
			type: String
		},
		topicsCompleted: {
			required: true,
			type: Array
		},
		resourcesCompleted: {
			required: false,
			type: Array
		},
	},
	setup() {
		const ionRouter = useIonRouter();
		const store = useStore();

		return {
			caretDownOutline,
			caretUpOutline,
			checkmarkCircle,
			clipboard,
			clipboardOutline,
  			ellipseOutline,
			informationCircle,
			ionRouter,
			store
		};
	},
	ionViewWillEnter() {
		this.menuOpen = false;
	}
});
</script>

<style lang="scss">
.lesson-progress-bar {
	&__button {
		--border-radius: 0;
		--box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.35);
		cursor: pointer;
		display: flex;
		height: 45px;
		justify-content: space-between;
		margin: 0;
		--padding-bottom: 0;
		--padding-top: 0;
		--ripple-color: var(--ion-color-primary);
		position: fixed;
		text-align: left;
		width: 100%;
		z-index: 100;
	}

	&__details {
		align-items: start;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 30px;
		padding: 0;
		row-gap: 5px;
		width: 100%;

		span {
			color: var(--ion-color-primary);
			font-size: 0.8em;
			font-weight: 700;
			margin-bottom: 0;
		}

		ion-progress-bar {
			-webkit-border-radius: 150px;
			-moz-border-radius: 150px;
			border-radius: 150px;
			height: 8px;

			&.complete {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-success);
			}

			&.in-progress {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-warning);
			}
		}
	}

	&__arrow {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 50px;
	}

	&__menu {
		background-color: #FFFFFF;
		box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25) inset;
		-webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25) inset;
		-moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.25) inset;
		height: calc(100% - 100px);
		overflow-y: scroll;
		pointer-events: all;
		position: fixed;
		touch-action: auto;
		top: 101px;
		width: 100%;
		z-index: 100;

		ion-list {
			background: transparent;

			.item,
			ion-item {
				--background: transparent;

				ion-icon {
					min-height: 24px;
					min-width: 24px;
				}

				ion-label {
					align-items: center;
					display: flex;
					column-gap: 10px;
				}
			}
		}
	}

	&--inline {
		.lesson-progress-bar__button {
			position: relative;
		}
	}

	&--hide-progress-bar {
		.lesson-progress-bar__details {
			padding-bottom: 10px;
			padding-top: 10px;

			span {
				font-size: 0.8em;
			}
		}
	}
}
</style>