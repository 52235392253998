<template>
	<ion-modal
		canDismiss="false"
		:class="['balance-scheduler-payment-modal', processingClass]"
		ref="modal"
		:is-open="balanceSchedulerPaymentOpen"
		@didPresent="onDidPresent"
		@didDismiss="onDidDismiss"
	>
		<ion-content class="balance-scheduler-payment-content">
			<div
				v-if="processingState === 'PROCESSING'"
				class="balance-scheduler-payment-content__spinner"
			>
				<ion-spinner color="light" name="circular"></ion-spinner>
			</div>
			<div
				v-if="processingState === 'CONFIRM'"
				class="balance-scheduler-payment-content__confirm"
			>
				<div class="confirmation-title">Confirm Payment</div>
				<div
					class="surcharge-message"
					v-show="
						confirmationPaymentTotals.surchargeEnabled &&
						confirmationPaymentTotals.surchargeMinAmount
					"
				>
					A minimum of
					{{
						this.formatDollarAmount(
							confirmationPaymentTotals.surchargeMinAmount
						)
					}}
					or {{ confirmationPaymentTotals.surchargePercent }}%
					surcharge will be added.
				</div>
				<div class="confirmation-grid">
					<div class="confirmation-grid__label darker-child">
						Payment
					</div>
					<div class="confirmation-grid__amount darker-child">
						{{
							this.formatDollarAmount(
								confirmationPaymentTotals.paymentBaseAmount
							)
						}}
					</div>
					<div
						class="confirmation-grid__label darker-child"
						v-if="confirmationPaymentTotals.surchargeEnabled"
					>
						Surcharge ({{
							this.confirmationPaymentTotals.surchargePercent
						}}%)
					</div>
					<div
						class="confirmation-grid__amount darker-child"
						v-if="confirmationPaymentTotals.surchargeEnabled"
					>
						{{
							formatDollarAmount(
								confirmationPaymentTotals.surchargeAmount
							)
						}}
					</div>
					<div class="confirmation-grid__total-label lighter-child">
						Total
					</div>
					<div class="confirmation-grid__amount lighter-child">
						{{
							formatDollarAmount(
								confirmationPaymentTotals.totalAmount
							)
						}}
					</div>
				</div>
				<div class="confirmation-buttons">
					<ion-button
						expand="block"
						class="confirmation-buttons__no"
						@click="cancelPayment"
					>
						No
					</ion-button>
					<ion-button
						expand="block"
						class="confirmation-buttons__yes"
						color="success"
						@click="confirmPayment"
					>
						Yes
					</ion-button>
				</div>
			</div>
			<div
				v-if="processingState === 'COMPLETED'"
				class="balance-scheduler-payment-content__completed"
			>
				<div class="completed-icon-container">
					<div class="completed-icon-container__icon">
						<SuccessCircleCheckmark />
					</div>
					<div class="completed-icon-container__text">
						Payment Successful
					</div>
					<ion-button
						expand="block"
						class="close-button"
						@click="$emit('closeModal')"
					>
						Close
					</ion-button>
				</div>
			</div>
			<div
				v-if="processingState === 'FAILED'"
				class="balance-scheduler-payment-content__failed"
			>
				<div class="failed-icon-container">
					<div class="failed-icon-container__icon">
						<StopSignError />
					</div>
					<div class="failed-icon-container__text">
						Payment Failed
					</div>
					<ion-button
						expand="block"
						class="back-button"
						@click="processingState = 'START'"
					>
						Back
					</ion-button>
				</div>
			</div>
			<div
				key="balance-scheduler-payment-content"
				v-show="processingState === 'START'"
				class="balance-scheduler-payment-content__form"
			>
				<payment-element
					v-model:loadPayTrak="loadPayTrak"
					v-model:processingState="processingState"
					@closeModal="$emit('closeModal')"
					@updatePaymentTotals="updatePaymentTotals"
					:confirm-payment="confirmPaymentTrigger"
					:initial-amount="
						store.getters.blockingSchedulerInfo
							?.minimumPaymentAmount
					"
					:maximum-payment-amount="
						store.getters.blockingSchedulerInfo
							?.behindTheWheelStudentCourseTotals?.balance
					"
					:studentCourseID="
						store.getters.blockingSchedulerInfo
							?.behindTheWheelStudentCourseTotals?.studentCourseID
					"
				>
					<template v-slot:other-info>
						<div class="balance-scheduler-payment-items">
							<div
								class="balance-scheduler-payment-items__label darker-child"
							>
								Current Balance
							</div>
							<div
								class="balance-scheduler-payment-items__amount darker-child"
							>
								{{
									Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD',
									}).format(
										Math.abs(
											store.getters.blockingSchedulerInfo
												?.behindTheWheelStudentCourseTotals
												?.balance
										)
									)
								}}
							</div>
							<div
								class="balance-scheduler-payment-items__label lighter-child"
							>
								Minimum Payment To Schedule
							</div>
							<div
								class="balance-scheduler-payment-items__amount lighter-child"
							>
								{{
									Intl.NumberFormat('en-US', {
										style: 'currency',
										currency: 'USD',
									}).format(
										Math.abs(
											store.getters.blockingSchedulerInfo
												?.minimumPaymentAmount
										)
									)
								}}
							</div>
						</div>
					</template>
				</payment-element>
			</div>
		</ion-content>
	</ion-modal>
</template>

<script>
import { IonModal } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import PaymentElement from './PaymentElement.vue';
import MaterialDesignIcon from './MaterialDesignIcon.vue';
import StopSignError from './icons/StopSignError.vue';
import SuccessCircleCheckmark from './icons/SuccessCircleCheckmark.vue';

export default defineComponent({
	components: {
		IonModal,
		PaymentElement,
		MaterialDesignIcon,
		StopSignError,
		SuccessCircleCheckmark,
	},
	props: {
		balanceSchedulerPaymentOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loadPayTrak: false,
			processingState: 'START',
			confirmationPaymentTotals: null,
			confirmPaymentTrigger: false,
		};
	},
	setup(props, context) {
		const store = useStore();

		return {
			store,
		};
	},
	methods: {
		closeModal() {
			this.$emit('closeConfirmModal');
		},
		onDidPresent() {
			this.loadPayTrak = true;
		},
		onDidDismiss() {
			this.loadPayTrak = false;
		},
		updatePaymentTotals(paymentTotals) {
			this.confirmationPaymentTotals = paymentTotals;
		},
		confirmPayment() {
			this.processingState = 'PROCESSING';
			this.confirmPaymentTrigger = true;
			setTimeout(() => {
				this.confirmPaymentTrigger = false;
			}, 2000);
		},
		cancelPayment() {
			this.processingState = 'START';
			this.confirmPaymentTrigger = false;
		},
		formatDollarAmount(amount) {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			}).format(amount);
		},
	},
	computed: {
		processingClass() {
			switch (this.processingState) {
				case 'PROCESSING':
					return 'balance-scheduler-payment-modal--processing';
				case 'FAILED':
					return 'balance-scheduler-payment-modal--failed';
				case 'COMPLETED':
					return 'balance-scheduler-payment-modal--completed';
				case 'CONFIRM':
					if (
						this.confirmationPaymentTotals?.surchargeEnabled &&
						this.confirmationPaymentTotals?.surchargeMinAmount
					)
						return 'balance-scheduler-payment-modal--confirm-with-surcharge-and-message';
					else if (this.confirmationPaymentTotals?.surchargeEnabled)
						return 'balance-scheduler-payment-modal--confirm-with-surcharge';
					else return 'balance-scheduler-payment-modal--confirm';
				case 'default':
					return '';
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.balance-scheduler-payment-modal {
	--max-width: 365px;
	--max-height: 545px;
	--border-radius: 6px;
	box-shadow: var(--box-shadow);

	ion-content {
		height: 100%;
	}

	&--processing {
		--max-width: 365px;
		--max-height: 365px;
	}

	&--failed {
		--max-width: 365px;
		--max-height: 300px;
	}

	&--completed {
		--max-width: 365px;
		--max-height: 300px;
	}

	&--confirm {
		--max-width: 365px;
		--max-height: 250px;
	}

	&--confirm-with-surcharge {
		--max-width: 365px;
		--max-height: 310px;
	}

	&--confirm-with-surcharge-and-message {
		--max-width: 365px;
		--max-height: 380px;
	}
}

ion-content::part(scroll) {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
}

.balance-scheduler-payment-content {
	--background: var(--ion-color-primary);

	&__spinner {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		ion-spinner {
			height: 150px;
			width: 150px;
		}
	}

	&__form {
		height: 100%;
		overflow: scroll;

		.balance-scheduler-payment-items {
			border-radius: var(--ion-border-radius);
			display: grid;
			margin-bottom: 5px;
			grid-template-columns: 4fr 1fr;
			grid-template-rows: 1fr 1fr;
			grid-gap: 1px;
			border: 1px solid var(--ion-color-primary-lighten-3, #6b7985);
			background-color: var(--ion-color-primary-lighten-3, #6b7985);
			color: var(--ion-color-light, #fff);
			font-weight: 700;
			overflow: hidden;

			.darker-child {
				padding: 15px;
				background-color: var(--ion-color-primary-tint, #405362);
			}

			.lighter-child {
				padding: 15px;
				background-color: var(--ion-color-primary-lighten-2, #556674);
			}

			&__label {
				display: flex;
				align-items: center;
				text-align: left;
			}

			&__amount {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
		}
	}

	&__confirm {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		width: 100%;

		.confirmation-title {
			color: var(--ion-color-light, #fff);
			font-weight: 700;
			font-size: 20px;
			padding-bottom: 20px;
			top: 0;
			left: 0;
			right: 0;
			text-align: center;
		}

		.surcharge-message {
			padding: 0 20px 20px 20px;
			text-align: center;
			color: var(--ion-color-light, #fff);
		}

		.confirmation-grid {
			display: grid;
			grid-template-columns: 3fr 1fr;
			grid-template-rows: repeat(auto-fill, 1fr);
			grid-gap: 1px;
			color: var(--ion-color-light, #fff);
			font-weight: 700;
			width: 100%;
			border: 1px solid var(--ion-color-primary-lighten-3, #6b7985);
			border-radius: var(--ion-border-radius);
			background-color: var(--ion-color-primary-lighten-3, #6b7985);
			overflow: hidden;

			.darker-child {
				padding: 15px;
				background-color: var(--ion-color-primary-tint, #405362);
			}

			.lighter-child {
				padding: 15px;
				background-color: var(--ion-color-primary-lighten-2, #556674);
			}

			&__total-label {
				text-align: right;
			}

			&__amount {
				text-align: center;
			}
		}

		.confirmation-buttons {
			position: fixed;
			margin-top: 20px;
			bottom: 20px;
			left: 20px;
			right: 20px;
			display: flex;
			gap: 10px;
			align-items: center;

			&__no {
				--background: var(--ion-color-primary-lighten-2, #556674);
				width: 100%;
			}

			&__yes {
				width: 100%;
			}
		}
	}

	&__failed {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;

		.failed-icon-container {
			height: calc(100% - 50px);

			&__icon {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__text {
				color: var(--ion-color-light, #fff);
				font-weight: 700;
			}
		}

		.back-button {
			position: fixed;
			bottom: 20px;
			left: 20px;
			right: 20px;
			margin-top: 20px;
			--background: var(--ion-color-primary-lighten-2, #556674);
		}
	}

	&__completed {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 100%;

		.completed-icon-container {
			height: calc(100% - 50px);

			&__icon {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__text {
				color: var(--ion-color-light, #fff);
				font-weight: 700;
			}
		}

		.close-button {
			position: fixed;
			bottom: 20px;
			left: 20px;
			right: 20px;
			margin-top: 20px;
			--background: var(--ion-color-primary-lighten-2, #556674);
		}
	}
}
</style>
