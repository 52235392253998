
import gql from 'graphql-tag';

export const CLASSROOM_USER_PROGRESS = gql`
	query classroomUserProgress ($studentCourseID: ID!) {
		classroomUserProgress (studentCourseID: $studentCourseID) {
			syllabus {
				name
				id
				version
				duration
				quizSettings {
					numberOfQuestions
					attemptsAllowed
					showCorrectAnswers
					showCorrectIncorrectQuestions
					requiredPassingScore
				}
				testSettings {
					numberOfQuestions
					attemptsAllowed
					showCorrectAnswers
					showCorrectIncorrectQuestions
					requiredPassingScore
				}
				extensionSettings {
					enabled
					additionalDuration
					eLearningExtensionUpcoming
					immediatePaymentRequired
					price
					appExtensionAllowed
				}
				lessons {
					lessonID
					name
					order
					inPerson
					customOnly
					topics {
						topicID
						name
						order
						estimatedMinutes
						resources {
							resourceID
							content {
								... on TextResourceContent {
									type
									text
								}
								... on ImageResourceContent {
									type
									image
								}
								... on VideoResourceContent {
									type
									video
								}
								... on BreakResourceContent {
									type
									text
								}
							}
						}
						hasQuiz
					}
					hasTest
				}
			}
			lessons {
				lessonID
				completeDate
				progressItems {
					id
					type
					reference
					completedDate
					... on TestAttempt {
						score
						details {
							id
							question {
								questionID
							}
							chosenAnswerID
						}
					}
					... on QuizAttempt {
						score
						details {
							id
							question {
								questionID
							}
							chosenAnswerID
						}
					}
				}
			}
			timeSpent {
				date: dateTZ
				timeSpent
			}
			completedDate
			lockDate
			isExtended
		}
	}
`;

export const LESSON_WITH_QUESTIONS_QUERY = gql`
	query LessonWithQuestions ($lessonID: ID!, $syllabus: ID!, $attemptNum: Int) {
		lessonWithQuestions (lessonID: $lessonID, syllabus: $syllabus, attemptNum: $attemptNum) {
			name
			order
			lessonID
			topics {
				name
				order
				topicID
				estimatedMinutes
				resources {
					resourceID
					content {
						... on TextResourceContent {
							type
							text
						}
						... on ImageResourceContent {
							type
							image
						}
						... on VideoResourceContent {
							type
							video
						}
						... on BreakResourceContent {
							type
							text
						}
					}
				}
				hasQuiz
				quiz {
					questionID
					questionText
					questionImage
					answers {
						answerID
						text
						correct
					}
				}
			}
			hasTest
			test {
				questionID
				questionText
				questionImage
				answers {
					answerID
					text
					correct
				}
			}
		}
	}
`;

export const SUBMIT_PROGRESS_MUTATION = gql `
	mutation submitClassroomProgressions($studentCourseID: ID!, $progressions: [ProgressItemInput!]) {
		submitClassroomProgressions(studentCourseID: $studentCourseID, progressions: $progressions) {
			lessons {
				lessonID
				completeDate
				progressItems {
					id
					type
					reference
					completedDate
					... on TestAttempt {
						score
						details {
							id
							question {
								questionID
							}
							chosenAnswerID
						}
					}
					... on QuizAttempt {
						score
						details {
							id
							question {
								questionID
							}
							chosenAnswerID
						}
					}
				}
			}
			timeSpent {
				date: dateTZ
				timeSpent
			}
			completedDate
		}
	}
`;

export const SET_ELEARNING_START_DATE_MUTATION = gql `
	mutation setELearningStartDate ($studentCourseID: ID!) {
		setELearningStartDate (studentCourseID: $studentCourseID) {
			created
			registration
			payment
			studentContractSigned
			parentGuardianContractSigned
			instructorContractSigned
			eLearningActivation
			eLearningStart
			eLearningCompletion
			classStart
			classCompletion
			driversLicenseApplicationSigned
			courseCompletion
			scheduledDate
			roadTestScheduled
		}
	}
`;

export const DAILY_ELEARNING_LIMIT_QUERY = gql `
	query getDailyELearningLimit($userID: UUID!) {
		getDailyELearningLimit(userID: $userID)
	}
`;

export const EXTEND_ELEARNING = gql `
	mutation extendELearning($studentCourseID: ID!, $transactionID: ID!) {
		extendELearning(studentCourseID: $studentCourseID, transactionID: $transactionID)
	}
`;