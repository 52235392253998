<template>
	<ion-page>
		<ion-tabs class="navigation-tabs">
			<ion-router-outlet
				id="app__content"
				class="app__content"
				animated="false"
			></ion-router-outlet>

			<ion-tab-bar slot="bottom" mode="md" v-if="route?.path !== '/login' && route?.path !== '/minimumVersionError' && showFooter">

				<ion-tab-button
					tab="home"
					@click="ionRouter.navigate('/home', 'forward', 'replace')"
				>
					<ion-icon :icon="home"></ion-icon>
					<ion-label>Home</ion-label>
				</ion-tab-button>

				<ion-tab-button
					v-if="isCompatibleBrowser && hasELearningAccess && classroomUserProgress && store.state.haseLearningCourse && store.state.courseIsActive && !store.state.courseComplete"
					tab="lessons"
					@click="ionRouter.navigate('/lessons', 'forward', 'replace')"
				>
					<ion-icon :icon="book"></ion-icon>
					<ion-label>Lessons</ion-label>
				</ion-tab-button>

				<ion-tab-button
					id="schedularNav"
					v-if="isCompatibleBrowser && hasSchedulerAccess && store.state.hasBTWCourse && store.state.courseIsActive  && !store.state.courseComplete"
					tab="scheduler"
					@click="ionRouter.navigate('/scheduler', 'forward', 'replace')"
				>
					<ion-icon :icon="calendar"></ion-icon>
					<ion-label>Scheduler</ion-label>
				</ion-tab-button>

				<ion-tab-button
					v-if="hasNotificationAccess"
					tab="notifications"
					:class="[
						// Re-adding the tab classes since they are being removed for some reason upon custom classes being added.
						// TODO: Fix class addition to not require the restating of classes.
						'md tab-has-label tab-has-icon tab-layout-icon-top ion-activatable ion-selectable ion-focusable'
					]"
					@click="ionRouter.navigate('/notifications', 'forward', 'replace')"
				>
					<ion-icon :icon="notifications"></ion-icon>
					<ion-label>Notifications</ion-label>
					<ion-badge
						color="danger"
						v-if="notificationsForTab.length"
					>{{ notificationsForTab.length }}</ion-badge>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
	personCircleOutline,
	easelOutline,
	settingsOutline,
	book,
	notifications,
	home,
	personOutline,
	calendar
} from "ionicons/icons";
import { useStore } from "vuex";
import { IonBadge, IonIcon, IonLabel, IonTabs, IonTabBar, IonTabButton, IonRouterOutlet, useIonRouter } from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
	name: "Tabs",
	components: {
		IonBadge,
		IonIcon,
		IonLabel,
		IonTabs,
		IonTabBar,
		IonTabButton,
		IonRouterOutlet,
	},
	computed: {
		classroomUserProgress() {
			return this.store.getters.studentsClassroomProgress.find(scp => scp.userID === this.store.getters.selectedUser) ?? false;
		},
		hasELearningAccess() {
			return this.store.getters.institutionSettings.moduleSettings.eLearning;
		},
		notificationsForTab() {
			return this.store.getters.notifications.filter(({showOnNotificationsTab}) => showOnNotificationsTab);
		},
		hasNotificationAccess() {
			return true;
		},
		hasSchedulerAccess() {
			return this.store.getters.institutionSettings.moduleSettings.schedulerAccess;
		},
		showFooter() {
			return !this.store.getters.hideTabFooter;
		},
		isCompatibleBrowser() {
			return this.store.getters.isCompatibleBrowser;
		}
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const ionRouter = useIonRouter();

		return {
			home,
			router,
			route,
			store,
			personCircleOutline,
			easelOutline,
			book,
			calendar,
			ionRouter,
			notifications,
			personOutline,
			settingsOutline
		};
	},
})
</script>

<style lang="scss" scoped>
ion-tabs {
	--background: var(--ion-color-light);
}

ion-tab-bar {
	--background: var(--ion-color-primary);
}
.navigation-tabs {
	.tab-has-icon {
		color: var(--ion-color-primary-contrast, #FFFFFF);

		&.tab-selected {
			background-color: var(--ion-color-primary-lighten-3, #6B7985);
		}
	}



}

ion-tab-button {
	ion-badge {
		border-radius: 150px;
		font-size: 14px;
		height: 20px;
		transform: translate3d(2px, -4px, 0);
		width: 20px;
	}

	&:hover {
		background-color: var(--ion-color-primary-lighten-3, #6B7985);
		ion-icon {
			fill: var(--ion-color-primary-contrast);
		}

		ion-label {
			color: var(--ion-color-primary-contrast);
		}
	}
}
</style>