import { parse, format, isPast } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

/**
 * Function to calculate session proximity and status based on the session group.
 *
 * @param {Object} sessionGroup - The session group for which proximity and status need to be calculated.
 * @param {string} institutionTZ - The timezone of the institution
 * @return {Object} An object containing button color, session relation to now, and session group status.
 */
export function sessionProximityAndStatus(sessionGroup, institutionTZ) {
	if (!sessionGroup?.timeSlotData?.date) return {};
	const sessionRelationToNow = getSessionRelationshipToNow(sessionGroup, institutionTZ);
	const sessionGroupStatus = getSessionGroupStatus(sessionGroup, sessionRelationToNow.pastOrFuture);
	const buttonColor = getButtonColor(sessionGroupStatus);

	return {
		buttonColor,
		sessionRelationToNow,
		sessionGroupStatus
	};
}

/**
 * Returns the color of the button based on the session group status.
 *
 * @param {string} sessionGroupStatus - The status of the session group
 * @return {string} The color of the button
 */
export function getButtonColor(sessionGroupStatus) {
	const buttonColorStatusMapping = {
		AVAILABLE: 'tertiary',
		AWAITING_PROCESSING: 'warning',
		ALL_PROCESSED: 'success',
		SOME_PROCESSED: 'warning',
		FULL: 'medium',
	};

	return buttonColorStatusMapping[sessionGroupStatus];
}

/**
 * Calculates the session group status based on the session group and the relation to the current time.
 *
 * @param {Object} sessionGroup - The session group object containing session information.
 * @param {string} sessionRelationToNow - The relation of the session to the current time ('past', 'present', 'future').
 * @return {string} The status of the session group ('ALL_PROCESSED', 'AWAITING_PROCESSING', 'AVAILABLE', 'FULL').
 */
export function getSessionGroupStatus(sessionGroup, sessionRelationToNow) {
	const allProcessed = sessionGroup.sessions.every(s => (s.userID && s.status !== 'SCHEDULED') || !s.userID);
	const someProcessed = sessionGroup.sessions.some(s => s.userID && s.status !== 'SCHEDULED');
	const hasOpenSession = sessionGroup.sessions.some(s => !s.userID);
	const noOpenSession = sessionGroup.sessions.every(s => s.userID);

	if (sessionRelationToNow === 'past') {
		if (allProcessed) {
			return 'ALL_PROCESSED';
		} else {
			return 'AWAITING_PROCESSING';
		}
	} else {
		if (someProcessed && !allProcessed) {
			return 'SOME_PROCESSED';
		}
		if (hasOpenSession) {
			return 'AVAILABLE';
		}
		if (allProcessed) {
			return 'ALL_PROCESSED';
		}
		if (noOpenSession) {
			return 'FULL';
		}
	}
}

/**
 * Function to determine the relation of a session group to the present moment.
 *
 * @param {Object} sessionGroup - The session group to analyze
 * @return {Object} Object containing the past or future status, and a boolean indicating if the session is on the same date as the present moment
 */
export function getSessionRelationshipToNow(sessionGroup, institutionTZ) {
	const startOfFirstSession = new Date(sessionGroup.sessions[0].sessionStart);
	const pastOrFuture = isPast(startOfFirstSession) ? 'past' : 'future';

	const dateForInstitution = formatInTimeZone(new Date(), institutionTZ, 'yyyy-MM-dd');
	const dateForSession = formatInTimeZone(startOfFirstSession, institutionTZ, 'yyyy-MM-dd');

	return {
		pastOrFuture,
		dateComparedToSession: dateForInstitution === dateForSession ? 'SAME_DATE'
			: isPast(startOfFirstSession) ? 'IN_THE_PAST' : 'IN_THE_FUTURE'
	}
}