<template>
	<ion-modal class="skill-attempt-crud-modal" :is-open="show" ref="skill-summary-dialog">
		<ion-header>
			<ion-toolbar color="primary">
				<ion-title>
					<div
						class="skill-title"
						@click="$emit('showSkillDetailsModal', skillItem.id)"
						style="align-items: center; display: flex; gap: 5px;"
					>
						{{ skillItem.name }}
						<MaterialDesignIcon color="white" icon="information-outline" size="small" />
					</div>
				</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="$emit('showSkillSummaryModal', skillItem.id)">
						<MaterialDesignIcon color="white" icon="listStatus" />
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="skill-attempt-crud-modal__editing" v-if="selectedAttemptID && skillAttempt?.date">
				<MaterialDesignIcon color="text" icon="information" />Editing {{ format(skillAttempt.date, 'MMM dd, yyyy') }} Attempt
			</div>
			<ion-list class="ion-no-padding skill-attempt-crud-modal__skill-list">
				<ion-item lines="full" v-for="feedbackItem in skillItem.feedbackItems" :key="feedbackItem.id">
					<ion-checkbox
						:modelValue="checkedFeedbackItems[feedbackItem.id]"
						@ionChange="toggleCheckedFeedbackItem($event, feedbackItem)"
					/>
					<ion-label>{{ feedbackItem.feedbackMessage }}</ion-label>
				</ion-item>
			</ion-list>
			<div slot="fixed">
				<div class="skill-attempt-crud-modal__toggle">
					<DataBar alignCenter :disabled="!!skillCompleted" :color="(skillAttempt.success === false) ? 'danger' : 'primary'" @click="toggleSuccess('fail')" icon="closeThick" title="Fail" />
					<DataBar alignCenter :color="(skillAttempt.success === true) ? 'success' : 'primary'" @click="toggleSuccess('success')" icon="checkBold" title="Success" />
				</div>
				<RatingScale
					:ratingRangeMax="7"
					:value="skillAttempt.score"
					@input="updateScore"
				/>
				<ion-list class="ion-no-padding">
					<ion-item>
						<ion-label position="stacked">Notes</ion-label>
						<ion-input
							v-model="skillAttempt.note"
							placeholder="Enter A Custom Note"
						></ion-input>
					</ion-item>
					<ion-item lines="none">
						<ion-label>Mark Skill Complete</ion-label>
						<ion-checkbox
							:disabled="skillAttempt.success === null"
							:modelValue="skillCompleted"
							@update:modelValue="skillCompleted = $event"
						/>
					</ion-item>
				</ion-list>
				<ion-toolbar class="skill-attempt-crud-modal__action-bar">
					<ion-buttons slot="start">
						<ion-button
							@click="$emit('closeModal')"
							color="dark"
							expand="full"
							slot="fixed"
						>
							{{ (selectedAttemptID) ? 'Cancel' : 'Close' }}
						</ion-button>
					</ion-buttons>
					<ion-buttons slot="end">
						<ion-button
							v-if="selectedAttemptID"
							@click="submitAttempt(true)"
							color="danger"
							fill="solid"
							expand="full"
							slot="fixed"
							style="margin-right: 10px;"
						>
							Delete
						</ion-button>
						<ion-button
							@click="submitAttempt(false)"
							color="success"
							:disabled="skillAttempt.success === null"
							fill="solid"
							expand="full"
							slot="fixed"
						>
							{{ (selectedAttemptID) ? 'Update' : 'Submit' }}
						</ion-button>
					</ion-buttons>
				</ion-toolbar>
			</div>
		</ion-content>
	</ion-modal>
</template>

<script>
import { format } from 'date-fns';
import { computed, defineComponent, ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'vuex';
import DataBar from "@/view/components/content/DataBar.vue";
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import RatingScale from '@/view/components/buttons/RatingScale.vue';

export default defineComponent({
	components: {
		DataBar,
		MaterialDesignIcon,
		RatingScale
	},
	setup() {
		const store = useStore();

		return {
			store
		};
	},
	data() {
		return {
			skillAttempt: this.selectedAttemptObject(this.selectedAttemptID),
			skillCompleted: (this.selectedAttemptID) ? this.selectedAttemptID.completedDate : (this.skillItem?.completedDate) ? this.skillItem?.completedDate : false,
			checkedFeedbackItems: this.getCheckedFeedbackItems(this.selectedAttemptID),
		}
	},
	watch: {
		'skillItem'() {
			this.skillAttempt.score = this.skillItem.score || null;
			this.skillAttempt.completed = this.skillItem.completed;
			this.skillCompleted = this.skillItem.completedDate;
		},
		'skillCompleted'() {
			if (this.skillCompleted) {
				this.skillAttempt.success = true;
			}
		}
	},
	methods: {
		format,
		getCheckedFeedbackItems(selectedAttemptID) {

			const selectedAttempt = (selectedAttemptID) ? this.skillItem.attempts.find((attempt) => attempt.id === this.selectedAttemptID) : '';

			let feedbackItems = this.skillItem?.feedbackItems?.reduce((accumulator, currentValue) => {
				if (!accumulator[currentValue.id]) {
					accumulator[currentValue.id] = (selectedAttempt) ? selectedAttempt.feedbackItems.some(fi => fi.id === currentValue.id) : false;
				}
				return accumulator;
			}, {});

			return feedbackItems;
		},
		selectedAttemptObject(selectedAttemptID) {
			if (!selectedAttemptID) {
				return {
					id: null,
					date: null,
					success: null,
					completedDate: null,
					score: this.skillItem?.score || null,
					instructor: this.store.getters.activeInstructor,
					instanceID: this.store.getters.activeSession.instanceID,
					sessionID: this.store.getters.activeSession.sessionID,
					skillSetID: this.skillSetID,
					note: null,
					feedbackItems: [],
				};
			}

			const selectedAttempt = this.skillItem.attempts.find((attempt) => attempt.id === this.selectedAttemptID);

			let updatedAttempt = JSON.parse(JSON.stringify(selectedAttempt));
			updatedAttempt.instructor = selectedAttempt.instructor.userID;

			return updatedAttempt;
		},
		toggleCheckedFeedbackItem(event, feedbackItem) {
			if (event.target.checked && !this.skillAttempt.feedbackItems?.find(item => item.id === feedbackItem.id)) {
				if (!this.skillAttempt.feedbackItems) {
					this.skillAttempt.feedbackItems = [];
				}
				this.skillAttempt.feedbackItems.push(feedbackItem);
				this.checkedFeedbackItems[feedbackItem.id] = true;
			} else {
				this.skillAttempt.feedbackItems = this.skillAttempt.feedbackItems.filter(item => item.id !== feedbackItem.id);
				this.checkedFeedbackItems[feedbackItem.id] = false;
			}
		},
		toggleSuccess(failOrSuccess) {
			this.skillAttempt.success = failOrSuccess === 'success';
		},
		updateScore(value) {
			this.skillAttempt.score = value;
		},
		submitAttempt(deleteAttempt) {
			this.attemptSubmittedTimeout = true;
			this.skillAttempt.id = (this.selectedAttemptID) ? this.selectedAttemptID : uuidv4();
			this.skillAttempt.date = (this.selectedAttemptID) ? this.skillAttempt.date : Date.now();
			if (deleteAttempt) {
				this.skillAttempt.delete = true;
			}
			if (this.skillCompleted) {
				this.skillAttempt.completedDate = Date.now();
				this.skillAttempt.completedBy = this.store.getters.activeInstructor?.userID;
			} else {
				this.skillAttempt.completedDate = null;
				this.skillAttempt.completedBy = null;
			}

			const studentCourseID = this.store.getters.activeSession.driverStudentCourseID;
			const userID = this.store.getters.activeSession.driverUserID;
			const skillID = this.skillItem.id;
			this.store.dispatch('addSkillAttemptToLocalProgress', {
				studentCourseID,
				userID,
				skillID,
				skillAttempt: this.skillAttempt
			});

			this.$emit('closeModal')
		},
	},
	name: 'SkillAttemptCRUDModal',
	props: {
		show: {
			default: false,
			type: Boolean
		},
		selectedAttemptID: {
			default: '',
			type: String
		},
		showDelete: {
			default: true,
			type: Boolean
		},
		skillItem: {
			type: Object
		},
		skillSetID: {
			default: null,
			type: String,
		}
	}
})
</script>

<style lang="scss" scoped>
.skill-title {
	cursor: pointer;
}

.skill-attempt-crud-modal {

	ion-toolbar {
		border: none;

		.toolbar-title {
			height: 500px;
		}
	}

	ion-content {
		height: calc(100% - 323px);
		position: relative;
	}

		&__editing {
			align-items: center;
			background-color: var(--ion-color-warning-lighten-8);
			border-bottom: 1px solid var(--ion-color-warning);
			color: var(--ion-text-color);
			display: flex;
			font-weight: 700;
			gap: 15px;
			padding: 15px;
		}

		&__skill-list {
			ion-item {
				ion-checkbox {
					margin-right: 15px;
				}
			}
		}

	div[slot='fixed'] {
		bottom: -267px;
		height: 267px;
		left: 0;
		position: absolute;
		width: 100%;
	}

		&__toggle {
			display: flex;
			gap: 1px;

			div {
				flex: 1;
			}
		}

		&__action-bar {
			border-top: 1px solid var(--ion-color-grey-darken-1);

			ion-button {
				border-radius: 6px;
				height: 38px;
				overflow: hidden;
				--padding-end: 12px;
				--padding-start: 12px;
			}
		}
}

</style>