<template>
	<ion-card
		:class="[
			'lesson-card',
			(lesson.hasTest) ? 'lesson-card--w-icon' : '',
			(disabled) ? 'disabled' : ''
		]"
		@click="onClick"
	>
		<ion-card-header>
			<ion-card-subtitle color="primary">Lesson {{ lessonNumber }}</ion-card-subtitle>
			<ion-card-title color="primary">{{ lesson.name }}</ion-card-title>
		</ion-card-header>

		<ion-card-content v-if="!lesson.inPerson">
			<div class="lesson-card__progress-status">
				<span class="lesson-card__progress">
					<template v-if="!lessonProgress?.completeDate && lesson.topics.length">
						( {{ topicsCompleted.length }} of {{ lesson.topics.length }} )
					</template>
					<template v-else-if="lessonProgress?.completeDate">
						Completed: {{ format(lessonProgress.completeDate, 'M/d/yyyy - h:mm aa') }}
					</template>
				</span>
				<span class="lesson-card__status" v-if="pastTestAttempts && !hasPassingTestAttempt && lesson.hasTest">
					{{ `${retakesLeft} Test Attempt${retakesLeft !== 1 ? 's' : ''} Left` }}
				</span>
				<span class="lesson-card__status lesson-card__status--success" v-else-if="lesson.hasTest && hasPassingTestAttempt && bestTestAttempt">
					Best Test: {{ bestTestAttempt.percent }}%
				</span>
			</div>
			<ion-progress-bar
				:class="(topicsCompleted.length >= lesson.topics.length) ? 'complete' : 'in-progress'"
				:value="percentComplete"
				buffer="1"
			/>
			<material-design-icon
				v-if="lesson.hasTest"
				:class="[testIconArgs.class]"
				:color="testIconArgs.color"
				icon="clipboardEdit"
			></material-design-icon>
		</ion-card-content>
		<ion-card-content v-else>
			<div class="lesson-card__in-person">IN PERSON</div>
		</ion-card-content>

		<span class="lesson-card__duration" v-if="!lesson.inPerson">
			<ion-icon
				color="light"
				:ios="time"
				:md="time"
			></ion-icon>
			{{ lessonDuration }}
		</span>
	</ion-card>

	<ion-alert
		cssClass="unable-to-access-modal"
		:is-open="showParentAlert"
		header="Unable To Access"
		message="Parent accounts can only view student progress and results. To view eLearning content please login as a student."
		:buttons="['Close']"
		@didDismiss="this.showParentAlert = !this.showParentAlert;"
	/>
</template>

<script>
import {
	IonAlert,
	IonIcon,
	IonCard,
	IonProgressBar,
	useIonRouter
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { format, fromUnixTime } from 'date-fns';
import {
	checkmark,
	clipboard,
	close,
	time
} from 'ionicons/icons';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { maxBy } from "lodash";

export default defineComponent({
	name: "LessonCard",
	components: {
		IonAlert,
		IonIcon,
		IonCard,
		IonProgressBar,
		MaterialDesignIcon
	},
	computed: {
		bestTestAttempt() {
			if(!this.testProgressItems.length) return null;
			const best = maxBy(this.testProgressItems, (i) => i.score);

			return {
				amount: best.score,
				completedDate: best.completedDate,
				maxAmount: best.details.length,
				percent: (best.score / best.details.length).toFixed(2) * 100
			}
		},
		cardLink() {
			if (this.lesson.topics.length && this.topicsCompleted.length < this.lesson.topics.length) {
				const inProgressTopic = this.lesson.topics.find((t) => {
					return !this.topicsCompleted.some((tc) => tc.reference === t.topicID)
				});
				if (!inProgressTopic) throw new Error('Missing in progress topic');

				const resourcesComplete = this.lessonProgress?.progressItems?.find((pi) => pi.type === 'RESOURCES' && pi.reference === inProgressTopic.topicID);
				const latestResource = this.lessonProgress?.progressItems?.findLast((pi) => pi.type === 'RESOURCE' );
				const latestResourceIndex = inProgressTopic.resources.findIndex((r) => r.resourceID === latestResource?.reference);
				const inProgressResource = inProgressTopic.resources.find((r, index) => (index > latestResourceIndex || latestResourceIndex === -1) && r.content.type !== 'BREAK');


				if (resourcesComplete) {
					return `/quiztest/${this.lesson.lessonID}/${inProgressTopic.topicID}`;
				} else  if (inProgressResource) {
					return `/lessons/${this.lesson.lessonID}/${inProgressTopic.topicID}/${inProgressResource.resourceID}`;
				} else {
					const latestCompletedResourceIndex = inProgressTopic.resources.findLastIndex((resource) => !!this.lessonProgress?.progressItems?.find((pi) => pi.reference === resource.resourceID));
					const latestBreakResourceIndex = inProgressTopic.resources.findLastIndex((resource, index) => resource.content.type === 'BREAK' && index <= latestCompletedResourceIndex);
					const inProgressResource = inProgressTopic.resources[latestBreakResourceIndex+1];
					let path = `/lessons/${this.lesson.lessonID}/${inProgressTopic.topicID}`;
					if (inProgressResource) {
						path += `/${inProgressResource.resourceID}`;
					}
					return path;
				}
			} else {
				if (this.lesson.hasTest) {
					if (this.lessonProgress?.progressItems?.find((i) => i.type === 'TEST')) {
						return `/summary/${this.lesson.lessonID}`;
					} else {
						return `/quiztest/${this.lesson.lessonID}`;
					}
				} else {
					return `/summary/${this.lesson.lessonID}`;
				}
			}
		},
		hasPassingTestAttempt() {
			// A passing test attempt sets the completion of a lesson, on lessons with tests
			return this.lessonProgress && this.lessonProgress.completeDate && this.lesson.hasTest;
		},
		lessonNumber() {
			return (this.lesson) ? this.lesson.number : '';
		},
		pastTestAttempts() {
			if (!this.lessonProgress?.progressItems) { return 0; }
			let latestRetryMarker = this.lessonProgress.progressItems.findLastIndex((pi) => pi.type === 'RETRY' && pi.reference === this.lesson.lessonID);

			const attempts = this.lessonProgress.progressItems.slice(latestRetryMarker === -1 ? 0 : latestRetryMarker).filter((pi) => pi.type === 'TEST' && pi.reference === this.lesson.lessonID).length;

			return attempts;
		},
		testProgressItems() {
			const items = [];
			for (const pi of this.lessonProgress?.progressItems ?? []) {
				if (pi.type === 'TEST') {
					items.push(pi);
				}
			}

			return items;
		},
		topicsCompleted() {
			return this.lessonProgress?.progressItems?.filter(item => item.type === 'TOPIC') ?? [];
		},
		percentComplete() {
			return +((this.topicsCompleted.length / this.lesson.topics.length).toFixed(2));
		},
		retakesLeft() {
			return this.numRetakes - this.pastTestAttempts;
		},
		testIconArgs() {
			if (this.hasPassingTestAttempt) {
				return {
					class: 'success',
					color: 'light',
					icon: checkmark
				};
			}

			if (this.pastTestAttempts && !this.hasPassingTestAttempt) {
				return {
					class: 'danger',
					color: 'light',
					icon: close
				};
			}

			if (this.topicsCompleted.length === this.lesson.topics.length) {
				return {
					class: 'warning',
					color: 'light',
					icon: clipboard
				};
			}

			// TODO: Decide if this color is necessary
			// if (this.topicsCompleted.length > 0) {
			// 	return {
			// 		class: 'warning-light',
			// 		color: 'primary',
			// 		icon: clipboard
			// 	};
			// }

			return {
				class: 'default',
				color: 'primary',
				icon: clipboard
			};
		},
		lessonDuration() {
			const totalMinutes = this.lesson.topics.map(({estimatedMinutes}) => estimatedMinutes).reduce((a,b)=>a+b, 0);

			return `${totalMinutes >= 60 ? `${Math.floor(totalMinutes / 60)}h ` : ''}${totalMinutes % 60}m`;
		}
	},
	data() {
		return {
			showParentAlert: false
		}
	},
	methods: {
		format,
		fromUnixTime,
		onClick() {
			if (!this.disabled && !this.showParentDetails) {
				this.ionRouter.navigate(this.cardLink, 'forward', 'replace')
			} else if (this.showParentDetails) {
				this.showParentAlert = !this.showParentAlert;
			}
		}
	},
	props: {
		disabled: {
			default: false,
			type: Boolean
		},
		lesson: {
			type: Object
		},
		lessonProgress: {
			type: Object
		},
		numRetakes: {
			default: 3,
			type: Number
		},
		showParentDetails: {
			default: false,
			type: Boolean
		}
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const ionRouter = useIonRouter();

		return {
			checkmark,
			clipboard,
			close,
			time,
			ionRouter,
			route,
			router
		};
	}
});
</script>

<style lang="scss" scoped>
.lesson-card {
	box-shadow: var(--box-shadow);
	position: relative;

	&:not(.disabled) {
		cursor: pointer;
	}

	&.disabled {
		opacity: 0.45;
	}

	&--no-click {
		cursor: auto !important;
	}

	ion-card-header {
		padding: 15px;
	}

		ion-card-subtitle {
			font-weight: 700;
		}

		ion-card-title {
			font-size: 1.5em;
		}

	ion-card-content {
		padding: 0 15px 15px 15px;
	}

		&__progress-status {
			display: flex;
			justify-content: space-between;
			margin-bottom: 2px;
			width: 100%;

			@media(max-width: 470px) {
				flex-direction: column;
			}
		}

			&__progress {
				color: var(--ion-color-primary);
				font-weight: 700;
				height: 25px
			}

			&__status {
				-webkit-border-radius: 150px;
				-moz-border-radius: 150px;
				border-radius: 150px;
				background-color: var(--ion-color-danger-lighten-9);
				color: var(--ion-color-primary);
				font-weight: 700;
				padding: 2px 44px 2px 7px;

				@media(max-width: 470px) {
					transform: translateX(-2px);
				}

				&--success {
					background-color: var(--ion-color-success-lighten-9);
					color: var(--ion-color-primary);
				}
			}

		&__in-person {
			width: 100%;
			height: 23px;
			background-color: var(--ion-color-tertiary);
			color: white;
			font-weight: 700;
			border-radius: 10px;
			line-height: 22px;
			display: flex;
			align-items: center;
			flex-direction: column;
			font-size: 14px;
		}

		ion-progress-bar {
			-webkit-border-radius: 150px;
			-moz-border-radius: 150px;
			border-radius: 150px;
			height: 9px;

			&.complete {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-success);
			}

			&.in-progress {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-warning);
			}
		}

		&--w-icon {
			ion-progress-bar {
				width: calc(100% - 7px);
			}
		}

		.material-design-icon {
			box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.15);
			-webkit-border-radius: 150px;
			-moz-border-radius: 150px;
			border-radius: 150px;
			height: 42px;
			padding: 6px;
			position: absolute;
			right: 9px;
			top: -6px;
			width: 42px;

			@media(max-width: 470px) {
				bottom: 12px;
				top: auto;
			}

			&.default {
				background-color: #FFFFFF;
			}

			&.success {
				background-color: var(--ion-color-success);
			}

			&.warning {
				background-color: var(--ion-color-warning);
			}

			&.warning-light {
				background-color: var(--ion-color-warning-tint);
			}

			&.danger {
				background-color: var(--ion-color-danger);
			}
		}

		.lesson-card__duration {
			position: absolute;
			right: 10px;
			top: 14px;
			display: flex;
			align-items: center;

			background: var(--ion-color-primary-lighten-2);
			padding: 2px 8px 2px 3px;
			padding-right: 8px;
			border-radius: 15px;
			color: var(--ion-color-primary-contrast);
			font-weight: 700;
			font-size: 12px;

			ion-icon {
				height: 15px;
				width: 15px;
				margin-right: 2px;
			}
		}

	&.disabled {

		ion-icon,
		.material-design-icon {

			&.default {
				background-color: #CCCCCC;
			}

			&.warning {
				background-color: var(-ion-color-warning-darken);
			}
		}
	}
}
</style>

<style lang="scss">
.unable-to-access-modal .alert-wrapper {
	--background: var(--ion-color-primary-tint);

	.alert-title,
	.alert-message {
		color: #FFFFFF;
	}

	.alert-button {
		background-color: var(--ion-color-primary-lighten-2);
		color: #FFFFFF;
	}
}
</style>