<template>
	<ion-item :button="true"
		:class="[
		'skill-list-item',
		(skill.completedDate) ? 'skill-list-item--completed' : '',
		(isEven) ? 'skill-list-item--even' : ''
	]">
		<ion-icon class="skill-list-item__completed-icon" color="success" :icon="checkmarkCircleOutline" size="large" slot="start" v-if="skill.completedDate" />

		<div class="skill-list-item__content" @click="$emit('openFeedback', skill)">
			<div class="skill-list-item__details">
				<h3>{{ skill.title }}</h3>
				<p v-if="skill.completedDate">{{ format(skill.completedDate, 'MM/dd/yy') }}</p>
			</div>
			<div class="skill-list-item__ratings">
				<div class="skill-list-item__positives" v-if="skill.positives">
					<ion-icon color="success" :icon="checkmarkSharp" /> <ion-text color="success">{{ skill.positives }}</ion-text>
					<span class="skill-list-item__meter skill-list-item__meter--positive" :style="{ width: (skill.positives * meterScale) + 'px' }"></span>
				</div>
				<div class="skill-list-item__negatives" v-if="skill.negatives">
					<ion-icon color="danger" :icon="closeSharp" /> <ion-text color="danger">{{ skill.negatives }}</ion-text>
					<span class="skill-list-item__meter skill-list-item__meter--negative" :style="{ width: (skill.negatives * meterScale) + 'px' }"></span>
				</div>
			</div>
		</div>

		<ion-icon class="skill-list-item__button" color="light" :icon="add" size="large" slot="end" @click="$emit('openFeedbackAttempt', skill)" />
	</ion-item>
</template>

<script>
import { add, checkmarkCircleOutline, checkmarkSharp, closeSharp } from "ionicons/icons";
import { format } from 'date-fns';

export default {
	computed: {
		isEven() {
			return this.index % 2;
		}
	},
	methods: {
		format
	},
	props: {
		index: {
			default: 1,
			type: Number
		},
		skill: {
			default: {
				completedDate: null,
				negatives: 0,
				positives: 0,
				title: ''
			},
			type: Object
		}
	},
	setup() {
		const meterScale = 10;

		return {
			add,
			checkmarkCircleOutline,
			checkmarkSharp,
			closeSharp,
			meterScale
		};
	}
}
</script>

<style lang="scss" scoped>
.skill-list-item {
	--background: #FFFFFF;
	--padding-bottom: 8px;
	--padding-top: 8px;

	&__completed-icon {
		margin-right: 5px;
	}

	&__content {
		align-items: stretch;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

		&__details,
		&__ratings {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

			&__details {
				position: relative;
				z-index: 10;

				h3 {
					font-size: 1.1em;
					margin: 0;
				}

				p {
					color: var(--ion-color-success);
					font-size: 0.9em;
					font-weight: 700;
					margin: 0;
				}
			}

			&__ratings {
				align-items: flex-end;
				display: flex;
				font-size: 0.9em;
				font-weight: 700;
				height: 36px;
				justify-content: flex-start;
				overflow: hidden;
				width: 70px;
			}

				&__negatives,
				&__positives {
					align-items: center;
					display: flex;
					gap: 2px;
					height: 18px;
					justify-content: flex-end;
					overflow-x: hidden;
					padding: 0 4px;
					position: relative;
					text-align: right;
					width: 70px;

					ion-icon,
					ion-text {
						position: relative;
						z-index: 5;
					}
				}

					&__meter {
						content: '';
						display: block;
						height: 18px;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 1;

						&--positive {
							background-color: var(--ion-color-success-lighten-8);
						}

						&--negative {
							background-color: var(--ion-color-danger-lighten-8);
						}
					}

	&__button {
		background: var(--ion-color-primary);
		height: 36px;
		margin-left: 4px;
		width: 36px;
	}

	&::part(detail-icon) {
		display: none;
	}

	/**
	*	Even Styles
	**/
	&--even {
		--background: var(--ion-color-grey);
	}

	/**
	*	Completed Styles
	**/
	&--completed {
		.skill-list-item {
			&__button {
				background: var(--ion-color-success);
			}
		}
	}
}
</style>