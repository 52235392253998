<template>
	<div class="rating-scale">
		<ion-button
			:class="[(rating <= value) ? 'button--highlighted' : '']"
			@click="updateValue(rating)"
			:key="rating" v-for="rating in ratingRangeMax"
		>
			{{ rating }}
		</ion-button>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	emits: ['input'],
	methods: {
		updateValue(value) {
			this.$emit('input', value);
		}
	},
	name: 'RatingScale',
	props: {
		value: {
			default: 0,
			type: Number
		},
		ratingRangeMax: {
			default: 0,
			type: Number
		}
	}
});
</script>

<style lang="scss" scoped>
.rating-scale {
	background: var(--ion-color-grey-darken-1);
	display: flex;
	justify-content: space-around;
	padding: 10px 5px;

	ion-button {
		--background: transparent;
		--border-color: var(--ion-color-primary);
		--border-radius: 155px;
		--border-style: solid;
		--border-width: 1px;
		--box-shadow: none;
		--color: var(--ion-color-primary);
		height: 36px;
		margin: 0;
		width: 36px;

		&.button--highlighted {
			--background: var(--ion-color-primary);
			--border-color: var(--ion-color-primary);
			--border-style: solid;
			--border-width: 1px;
			--color: var(--ion-color-light);
		}
	}
}
</style>