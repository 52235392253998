<template>
	<ion-modal id="ion-connection-modal" :is-open="showConnectionModal" @willDismiss="hideConnectionModal()">
		<ion-content scroll-y="false" class="no-connection-modal-content">
			<div class="no-connection-modal-content__logo">
				<MaterialDesignIcon icon="wifi-alert" color="light" size="large"/>
			</div>
			<ion-text color="light">
				<p class="no-connection-paragraph">You do not currently have an internet connection.</p>
				<p class="no-connection-paragraph">Scheduler reservations and classroom progress will not be submitted.</p>
			</ion-text>
			<ion-button
				class="ion-margin-vertical close-button"
				expand="block"
				part="native"
				@click="hideConnectionModal()"
			>
				CLOSE
			</ion-button>
		</ion-content>
	</ion-modal>
</template>

<script>
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { defineComponent, computed } from 'vue';
import { IonButton, IonButtons, IonCard, IonIcon, IonTextarea } from "@ionic/vue";
import { useStore } from 'vuex';

export default defineComponent({
	components: {
		IonButton,
		IonButtons,
		IonCard,
		IonIcon,
		IonTextarea,
		MaterialDesignIcon,
	},
	methods: {
		hideConnectionModal() {
			this.store.commit('hideConnectionModal')
		}
	},
	setup() {
		const store = useStore();

		return {
			showConnectionModal: computed(() => store.state.showConnectionModal),
			store
		}
	}
});
</script>

<style lang="scss" scoped>

#ion-connection-modal {
	--background: var(--ion-color-primary-tint);
	--max-height: 275px;
	--max-width: 80%;
	--border-radius: 6px;
}

ion-content::part(scroll) {
	display: flex;
	height: 275px;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 15px;
}

.no-connection-modal-content {
	--background: var(--ion-color-primary-tint);

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100px;
	}
}

.no-connection-paragraph {
	margin-bottom: 15px;
	font-size: 14px;
	font-weight: 700;
}
.close-button {
	--background: var(--ion-color-primary-lighten-3, #6B7985);
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.78px;
}
</style>