<template>
	<div class="alert-bar">
		<div class="message">
			<div class="message__icon">
				<MaterialDesignIcon :icon="messageIcon" color="light" />
			</div>
			<div class="message__text">
				<p>{{ messageText }}</p>
			</div>
		</div>
		<ion-button
			class="alert-bar__button"
			@click="$emit('openBalanceSchedulerPaymentOpen')"
		>Make Payment</ion-button>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
	name: "SchedulerAlertBar",
	components: {
		MaterialDesignIcon
	},
	props: {
		messageIcon: {
			type: String
		},
		messageText: {
			type: String
		}
	},
	methods: {},
	computed: {},
});
</script>

<style lang="scss" scoped>
.alert-bar {
	display: flex;
	flex-direction: column;
	padding: 15px;
	gap: 5px;
	background-color: var(--ion-color-danger, #ED5564);

	.message {
		display: flex;
		gap: 10px;
		color: var(--ion-color-light, #FFF);

		&__icon {
			display: flex;
			justify-content: left;
			align-items: center;
			min-width: 24px;
			min-height: 24px;
			margin: 10px 0;
		}

		&__text {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 10px;
			font-size: 14px;
			font-weight: 700;
		}
	}

	&__button {
		--color: var(--ion-color-danger, #ED5564);
		--background: var(--ion-color-light, #FFF);
		--background-hover: var(--ion-color-danger, #ED5564);
	}
}
</style>
