<template>
	<ion-app :class="customBrandingClass" id="app">
		<ion-loading v-if="loading" show-backdrop />
		<div v-if="(!store.getters.networkError || route?.path.includes('instructor')) && !loading">
			<Tabs v-if="route?.path !== '/date'"></Tabs>
			<settings /><!-- Settings Panel -->
			<HelpSupportModal /><!-- Help / Bugs Panel -->
			<NoConnectionModal /><!-- No Connection Modal -->
		</div>
		<div v-if="store.getters.networkError && !route?.path.includes('instructor')">
			<error-page />
		</div>
	</ion-app>
</template>

<script>
import { computed, defineComponent } from "vue";
import {
	IonLoading,
} from '@ionic/vue';
import {
	checkmarkCircleOutline,
	personCircle,
	archiveOutline,
	archiveSharp,
	bookmarkOutline,
	bookmarkSharp,
	heartOutline,
	heartSharp,
	library,
	mailOutline,
	mailSharp,
	paperPlaneOutline,
	paperPlaneSharp,
	trashOutline,
	trashSharp,
	warningOutline,
	warningSharp,
} from "ionicons/icons";
import Tabs from './view/components/Tabs.vue';
import { mapGetters, mapState, useStore } from "vuex";
import Settings from './view/components/settings/Settings.vue';
import HelpSupportModal from '@/view/components/HelpSupportModal.vue';
import NoConnectionModal from '@/view/components/NoConnectionModal.vue';
import { useRoute, useRouter } from "vue-router";
import ErrorPage from './view/components/ErrorPage.vue';
import { handleQuerySearch } from "./core/util/queryParams";

export default defineComponent({
	name: "App",
	components: {
		HelpSupportModal,
		NoConnectionModal,
		Settings,
		Tabs,
		ErrorPage,
		IonLoading
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();

		store.dispatch('checkCustomHostname');

		handleQuerySearch(route.query);

		return {
			archiveOutline,
			archiveSharp,
			bookmarkOutline,
			bookmarkSharp,
			checkmarkCircleOutline,
			heartOutline,
			heartSharp,
			library,
			darkMode: computed(() => store.state.theme.darkMode),
			store,
			router,
			route,
			mailOutline,
			personCircle,
			mailSharp,
			paperPlaneOutline,
			paperPlaneSharp,
			trashOutline,
			trashSharp,
			warningOutline,
			warningSharp,
		};
	},
	computed: {
		...mapGetters(['hasCustomDomain', 'isAuthenticated', 'customBrandingClass']),
		...mapState([]),
		loading() {
			if (this.isAuthenticated) {
				return !this.store.getters.initialized;
			}

			// TODO: Figure out if this can be done better. Current setup is to address an issue with the drivetrak theme appearing for a brief moment before the custom branding theme.
			// Only keep loading if we know we have a custom domain.
			if (this.hasCustomDomain) {

				// Since we know we have a custom domain check that we have the custom branding.
				if (!this.customBrandingClass) {

					// Otherwise keep loading.
					return true;
				} else {

					// If we do have a custom branding class make sure it has processed and is not DriveTrak.
					return this.customBrandingClass === 'driveTrak';
				}
			}

			return !this.store.getters.authInitialized;
		}
	},
	methods: {
		setOpen(state) {
			this.isOpenRef = state;
		}
	},
	watch: {
		'route.name'(route) {
			if (!this.isAuthenticated) return;
		}
	}
});
</script>