<template>
	<ion-card :class="[
		'question-card',
		(questionAnswer) ? 'question-card--results' : '',
		(questionAnswer && showCorrectIncorrectQuestions) ? (questionCorrect) ? 'question-card--correct' : 'question-card--wrong' : ''
	]">
		<div class="question-card__image" v-if="oQuestion.questionImage" >
			<img :src="oQuestion.questionImage"/>
		</div>
		<h2 class="question-card__question">{{ oQuestion.questionText }}</h2>
		<ion-list lines="none">
			<ion-radio-group @ionChange="updateValue($event)" :value="questionAnswer ? questionAnswer.chosenAnswerID: value">
				<ion-item
					v-for="answer in oQuestion.answers"
					:key="answer.answerID"
					:class="[
						((showCorrectIncorrectQuestions && showCorrectAnswers) || (showCorrectIncorrectQuestions && answer.answerID === questionAnswer?.chosenAnswerID)) && answer.correct ? 'answer--correct' : '',
						showCorrectIncorrectQuestions && !answer.correct &&answer.answerID === questionAnswer?.chosenAnswerID ? 'answer--incorrect' : ''
					]"
				>
					<div class="contents">
						<div class="div-radio">
							<ion-radio mode="md" slot="start" :value="answer.answerID" ></ion-radio>
							<ion-icon color="light" :icon="resultAnswerIcon(answer)" v-if="resultAnswerIcon(answer)"></ion-icon>
						</div>
						<div class="div-label">
							<ion-label class="response-label" v-if="resultAnswerLabel(answer)">{{ resultAnswerLabel(answer) }}</ion-label>
							<ion-label class="text-label ion-text-wrap">{{ answer.text }}</ion-label>
						</div>
					</div>
				</ion-item>
			</ion-radio-group>
		</ion-list>
	</ion-card>
</template>

<script>
import {
	IonCard,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonRadio,
	IonRadioGroup,
} from '@ionic/vue';
import {
	checkmarkSharp,
	closeSharp,
	ellipseOutline,
} from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
	name: "question-card",
	components: {
		IonCard,
		IonIcon,
		IonItem,
		IonLabel,
		IonList,
		IonRadio,
		IonRadioGroup,
	},
	data() {
		return {
			value: ''
		}
	},
	computed: {
		questionCorrect() {
			return this.oQuestion?.answers?.find((a) => a.answerID === this.questionAnswer.chosenAnswerID)?.correct ?? false;
		}
	},
	setup() {
		return {
			checkmarkSharp,
			closeSharp,
			ellipseOutline
		};
	},
	methods: {
		resultAnswerLabel(answer) {
			if (!this.questionAnswer) { return ''; }
			if (this.showCorrectIncorrectQuestions && this.showCorrectAnswers) {
				if (answer.correct) {
					return 'CORRECT ANSWER';
				} else if (answer.answerID === this.questionAnswer.chosenAnswerID && !answer.correct) {
					return 'YOUR ANSWER';
				}
			} else if (this.showCorrectIncorrectQuestions && !this.showCorrectAnswers) {
				if (answer.answerID === this.questionAnswer.chosenAnswerID) {
					if (answer.correct) {
						return 'CORRECT';
					} else {
						return 'INCORRECT';
					}
				}
			}
			return '';
		},
		resultAnswerIcon(answer) {
			if (!this.questionAnswer) { return null; }
			if ((this.showCorrectIncorrectQuestions && this.showCorrectAnswers) || (this.showCorrectIncorrectQuestions && answer.answerID === this.questionAnswer.chosenAnswerID)) {
				if (answer.correct) {
					return checkmarkSharp;
				} else if (answer.answerID === this.questionAnswer.chosenAnswerID && !answer.correct) {
					return closeSharp;
				}
			}
			return null;
		},
		updateValue(event) {
			this.$emit('updated', {
				questionID: this.oQuestion.questionID,
				answerID: event.detail.value
			})
		}
	},
	props: {
		questionAnswer: {
			type: Object
		},
		oQuestion: {
			required: true,
			type: Object
		},
		validateAnswer: {
			default: false,
			type: Boolean
		},
		showCorrectAnswers: {
			default: true,
			type: Boolean
		},
		showCorrectIncorrectQuestions: {
			default: true,
			type: Boolean
		}
	}
});
</script>

<style lang="scss">
.question-card {
	border-left: 5px solid var(--ion-color-primary);
	background-color: var(--ion-color-light);
	-webkit-border-top-left-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-topleft: 0;
	-moz-border-radius-bottomleft: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin: 0 0 30px 0;

	&__question {
		color: var(--ion-color-primary);
		font-size: 1em;
		font-weight: 700;
		margin: 0;
		padding: 15px;
	}

	&__image {
		min-height: 300px;
		margin: 0;
		padding: 15px;
		display: flex;
    	align-items: center;
	}

	div.contents {
		display: flex;
		width: 100%;
	}

	.div-radio {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 15px;
		max-height: 60px;

		margin: 10px 15px 10px 10px;

		ion-radio {
			margin: 0px;
		}
	}

	.div-label {
		display: flex;
		flex-direction: column;
		margin: 10px 0px;

		ion-label.response-label {
			font-size: 12px;
			font-weight: 700;
			line-height: 14px;
		}

		ion-label.text-label {
			margin-top: auto;
			margin-bottom: auto;
		}
	}

	ion-list {
		padding: 0;
		background-color: var(--ion-color-light);

		.item {
			--background: var(--ion-color-light);
			padding-bottom: 5px;
		}
	}

	ion-radio {
		-webkit-border-radius: 150px;
		-moz-border-radius: 150px;
		border-radius: 150px;
		height: 30px;
		width: 30px;

		&::part(mark) {
			height: 18px;
			width: 18px;
		}
	}

	&--correct {
		border-left-color: var(--ion-color-success);
	}

	&--wrong {
		border-left-color: var(--ion-color-danger);
	}

	&--results {
		ion-radio {
			background: var(--surface, #FFFFFF);
			pointer-events: none;
		}

		ion-item {
			--background-hover: transparent;
			--ripple-color: transparent;
			padding-bottom: 10px !important;
		}

		ion-icon {
			font-size: 24px;
			margin-bottom: 3px;
			margin-top: 3px;
		}

		ion-item.answer--correct {
			.contents {
				background-color: var(--ion-color-success-lighten-9);
				border: 1px solid var(--ion-color-success-lighten-8);
				border-radius: 10px;
			}

			.div-radio {
				background-color: var(--ion-color-success);
			}

			ion-label.response-label {
				color: var(--ion-color-success)
			}
		}
		ion-item.answer--incorrect {
			.contents {
				background-color: var(--ion-color-danger-lighten-9);
				border: 1px solid var(--ion-color-danger-lighten-8);
				border-radius: 10px;
			}

			.div-radio {
				background-color: var(--ion-color-danger);
			}

			ion-label.response-label {
				color: var(--ion-color-danger)
			}
		}
	}
}
</style>