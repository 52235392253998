<template>
	<ion-page id="main-content">
		<app-header customBackButton="/lessons" />
		<progress-message
			:icon="alarm"
			color="warning"
			:message="`You have ${eLearningDaysFromLockDate} day${eLearningDaysFromLockDate === 1? '' : 's'} to complete your course before it locks on ${format(classroomUserProgress.lockDate, 'MMM do, yyyy')}`"
			:isButton="true"
			type="eLearningCountdownWarning"
			v-if="showeLearningExtensionWarning"
		></progress-message>
		<div class="past-test-summary">
			<div class="past-test-summary__actions">
				<ion-button v-if="!retryData.disabled" class="ion-margin-bottom past-test-summary__btn" @click="ionRouter.navigate(retryData.url, 'back', 'replace')" color="warning" expand="block" :disabled="retryData.disabled">
					<div>
						<ion-icon elevation="1" :icon="refresh" style="transform: scaleX(-1);" size="large"></ion-icon>
						<span>{{ retryData.label }}</span>
					</div>
				</ion-button>
				<ion-button class="ion-margin-bottom past-test-summary__btn" @click="ionRouter.navigate(summaryButtonData, 'back', 'replace')" color="primary" expand="block">
					<div>
						<ion-icon elevation="1" :icon="informationCircle" size="large"></ion-icon>
						<span>Summary</span>
					</div>
				</ion-button>
				<div v-if="retryData.disabled" class="past-test-summary__btn"></div>
				<ion-button class="ion-margin-bottom past-test-summary__btn" @click="ionRouter.navigate(continueButtonData.url, 'forward', 'replace')" color="success" expand="block" :disabled="!testQuizCompleted">
					<div>
						<ion-icon elevation="1" :icon="arrowForward" size="large"></ion-icon>
						<span>Continue</span>
					</div>
				</ion-button>
			</div>
			<ion-list class="best-test-result__warnings ion-no-padding" v-if="redoLessonActive">
				<ion-item color="danger">
					<ion-icon color="light" :icon="alertCircleOutline" slot="start" />
					<ion-label color="light" class="ion-text-wrap">You did not pass the {{ (this.routeTopicID) ? 'quiz' : 'test' }} within the allowed number of attempts. Please redo the {{ (this.routeTopicID) ? 'topic' : 'lesson' }} and try again.</ion-label>
				</ion-item>
			</ion-list>
		</div>
		<ion-content fullscreen scrollY="true">
			<div class="top-past-quiz-test-card">
				<past-quiz-test-card
					:horizontal="true"
					:lessonID="routeLessonID"
					:topicID="routeTopicID"
					:progressItem="progressItem"
					:noBackground="true"
					:noBoxShadow="true"
				></past-quiz-test-card>
			</div>
			<div class="ion-padding">
				<question-card
					v-bind:key="data.question.questionID"
					:questionAnswer="data.detail"
					:oQuestion="data.question"
					:showCorrectIncorrectQuestions="showCorrectIncorrectQuestions"
					:showCorrectAnswers="showCorrectAnswers"
					v-for="data in detailsWithQuestion"
				></question-card>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import AppHeader from '@/view/components/AppHeader.vue';
import LessonCard from '@/view/components/LessonCard.vue';
import LessonProgressBar from '@/view/components/LessonProgressBar.vue';
import ProgressCircle from '@/view/components/ProgressCircle.vue';
import ProgressMessage from '@/view/components/ProgressMessage.vue';
import PastQuizTestCard from '@/view/components/PastQuizTestCard.vue';
import QuestionCard from '@/view/components/QuestionCard.vue';
import Heading from '@/view/components/Heading.vue';
import {
	IonButton,
	IonIcon,
	IonList,
	IonItem,
	useIonRouter
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import {
	alertCircleOutline,
	arrowForward,
	caretDownOutline,
	clipboard,
	informationCircle,
	refresh,
	trophy,
	alarm
} from 'ionicons/icons';
import { toast } from '../../../core/toast/Toast';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { format } from 'date-fns';

export default defineComponent({
	name: "Results",
	components: {
		AppHeader,
		Heading,
		IonButton,
		IonIcon,
		LessonCard,
		LessonProgressBar,
		PastQuizTestCard,
		ProgressCircle,
		ProgressMessage,
		QuestionCard
	},
	methods: {
		format
	},
	computed: {
		continueButtonData() {
			return this.$store.getters.getNextStepUrl({topicID: this.routeTopicID, lessonID: this.routeLessonID});
		},
		eLearningDaysFromLockDate() {
			return this.store.getters.eLearningDaysFromLockDate;
		},
		showeLearningExtensionWarning() {
			return this.store.getters.showeLearningExtensionWarning;
		},
		testQuizCompleted() {
			return (!this.routeTopicID && this.lessonProgress.completeDate) || this.topicComplete;
		},
		progressItem() {
			const lessonProgress = this.classroomUserProgress.lessons.find((lp) => lp.lessonID === this.routeLessonID);
			if (!lessonProgress) throw new Error("Missing lessonProgress on userProgress");

			const progressItem = lessonProgress.progressItems.find((pi) => pi.id === this.progressItemID);

			if (!progressItem) throw new Error("Missing progressItem on lessonProgress");

			return progressItem;
		},
		quiztestQuestions() {
			if (this.routeTopicID) {
				const topic = this.lesson?.topics?.find((t) => t.topicID === this.routeTopicID) ?? {};
				return topic?.quiz ?? [];
			} else {
				return this.lesson?.test ?? [];
			}
		},
		detailsWithQuestion() {
			return this.quiztestQuestions.map((q) => {
				const detail = this.progressItem.details.find((d) => d.question.questionID === q.questionID);

				return {
					detail,
					question: q
				}
			});
		},
		summaryButtonData() {
			if (this.routeTopicID) {
				return `/summary/${this.routeLessonID}/${this.routeTopicID}`;
			} else {
				return `/summary/${this.routeLessonID}`;
			}
		},
		topicComplete() {
			return this.routeTopicID && this.lessonProgress.progressItems && this.lessonProgress.progressItems.find((pi) => pi.type === 'TOPIC' && pi.reference === this.routeTopicID)
		},
		redoLessonActive() {
			const attempts = this.store.getters.getQuizTestAttempts({topicID: this.routeTopicID, lessonID: this.routeLessonID});

			return (!attempts && this.lesson.topics?.length);
		},
		// Duplicated in Summary.page.vue
		retryData() {
			if (!this.lessonProgress?.progressItems) {
				return {
					label: 'Incomplete Lesson',
					url: `/lessons`,
					disabled: true
				}
			}

			const referenceID = this.routeTopicID ? this.routeTopicID : this.routeLessonID;
			let latestRetryMarker = this.lessonProgress.progressItems.findLastIndex((pi) => pi.type === 'RETRY' && pi.reference === referenceID);

			const attempts = this.store.getters.getQuizTestAttempts({topicID: this.routeTopicID, lessonID: this.routeLessonID});

			if (this.routeTopicID) {
				if (this.topicComplete) {
					return {
						label: 'Retry Quiz',
						url: `/quiztest/${this.routeLessonID}/${this.routeTopicID}`,
						disabled: (attempts < this.syllabus.quizSettings?.attemptsAllowed) ? false : true
					}
				} else {
					// If there is a retry marker and no quiz/test attempts, that means the marker was just added, signalling a redo
					if (latestRetryMarker !== -1 && !attempts) {
						return {
							label: 'Redo Topic',
							url: `/lessons/${this.routeLessonID}/${this.routeTopicID}`
						}
					} else {
						return {
							label: 'Retry Quiz',
							url: `/quiztest/${this.routeLessonID}/${this.routeTopicID}`
						}
					}
				}
			} else {
				if (this.lessonProgress.completeDate) {
					if (attempts < this.syllabus.testSettings?.attemptsAllowed) {
						return {
							label: 'Retry Test',
							url: `/quiztest/${this.routeLessonID}`
						}
					} else {
						return {
							label: 'Retry Test',
							url: `/lessons`,
							disabled: true
						}
					}
				} else {
					if (!attempts && this.lesson.topics?.length) {
						if (latestRetryMarker !== -1) {
							return {
								label: 'Redo Lesson',
								url: `/lessons/${this.routeLessonID}/${this.lesson.topics[0].topicID}`
							}
						} else {
							return {
								label: 'Incomplete Lesson',
								url: `/lessons`,
								disabled: true
							}
						}
					} else {
						return {
							label: 'Retry Test',
							url: `/quiztest/${this.routeLessonID}`
						}
					}
				}
			}
		},
		syllabus() {
			return this.store.getters.selectedSyllabus;
		},
		showCorrectAnswers() {
			if (this.routeTopicID) {
				return this.syllabus.quizSettings?.showCorrectAnswers;
			} else {
				return this.syllabus.testSettings?.showCorrectAnswers;
			}
		},
		showCorrectIncorrectQuestions() {
			if (this.routeTopicID) {
				return this.syllabus.quizSettings?.showCorrectIncorrectQuestions;
			} else {
				return this.syllabus.testSettings?.showCorrectIncorrectQuestions;
			}
		},
		lessonProgress() {
			return this.classroomUserProgress?.lessons?.find((l) => l.lessonID === this.routeLessonID) ?? {};
		},
		classroomUserProgress() {
			return this.store.getters.selectedClassroomProgress;
		}
	},
	setup() {
		const queryLoading = ref(false);
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const ionRouter = useIonRouter();

		const routeLessonID = (route.params.lessonID) ? route.params.lessonID : '';
		const routeTopicID = (route.params.topicID) ? route.params.topicID : '';
		const progressItemID = (route.params.progressItemID) ? route.params.progressItemID : '';

		const lesson = ref({});

		return {
			alertCircleOutline,
			arrowForward,
			caretDownOutline,
			clipboard,
			informationCircle,
			ionRouter,
			refresh,
			router,
			routeLessonID,
			routeTopicID,
			progressItemID,
			lesson,
			store,
			trophy,
			alarm
		};
	},
	ionViewWillEnter() {
		const attemptNum = this.store.getters.getQuizTestAttempts({lessonID: this.routeLessonID, topicID: this.routeTopicID, progressItemID: this.progressItemID});
		this.store.dispatch('fetchLessonQuestions', {lessonID: this.routeLessonID, attemptNum}).then(res => this.lesson = res);
	}
});
</script>

<style lang="scss">
.past-test-summary {
	background-color: #FFFFFF;
	box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
	z-index: 1000;
	width: auto;

	&__actions {
		align-items: stretch;
		display: flex;
		column-gap: 7px;
		justify-content: stretch;
		padding: 7px;
		width: 100%;
	}

		&__btn {
			display: block;
			height: auto;
			margin: 0;
			width: 33%;

			div {
				align-items: center;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				margin: 0;
				padding: 10px 0;

				ion-icon {
					display: block;
					margin-bottom: 5px;
				}

				span {
					display: block;
					overflow-wrap: break-word;
				}
			}
		}
}

.top-past-quiz-test-card {
	padding: 15px 0 0 0;

	.past-quiz-test-card {
		background-color: transparent;
		padding-bottom: 5px;
	}
}
</style>
