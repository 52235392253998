
import gql from 'graphql-tag';
import omitDeep from 'omit-deep-lodash';
import { apolloClient } from '../apollo/apolloClient';
import { provideApolloClient, useSubscription } from "@vue/apollo-composable";

export function timeSlotSubscription(variables) {
	provideApolloClient(apolloClient);
	return useSubscription(TIME_SLOT_UPDATED_SUB, variables);
}

export function futureDrivingDataSubscription(variables) {
	provideApolloClient(apolloClient);
	return useSubscription(FUTURE_DRIVING_DATA_UPDATED_SUB, variables);
}

export function eLearningExtendedSubscription(variables) {
	provideApolloClient(apolloClient);
	return useSubscription(ELEARNING_EXTENDED_SUB, variables);
}

export function notificationsUpdatedSubscription(variables) {
	provideApolloClient(apolloClient);
	return useSubscription(NOTIFICATIONS_UPDATED_SUB, variables);
}

export const TIME_SLOT_FRAGMENT = gql`
	fragment timeSlotFragment on TimeSlot {
		label
		location {
			locationID
			name
			address
		}
		instructor {
			firstName
			lastName
			userID
		}
		sessionDefinitions {
			sessionStartTime
			sessionEndTime
			observe
			drive
		}
		timeSlotID
		instances {
			id
			date: dateTZ
			groupedSessions {
				sessions {
					sessionID
					sessionType
					sessionStart
					sessionEnd
					userID
					studentCourseID
					note
					status
					customPickupLocation {
						locationID
						name
						address
					}
				}
			}
			sessions {
				sessionID
				sessionType
				sessionStart
				sessionEnd
				userID
				studentCourseID
				note
				status
				customPickupLocation {
					locationID
					name
					address
				}
			}
			status
		}
		schools {
			name
			locationID
		}
		zones {
			name
			id
		}
	}
`;

export const APPOINTMENT_HISTORY_QUERY = gql`
	query appointmentHistory ($userIDs: [ID!]) {
		appointmentHistory (userIDs: $userIDs) {
			sessions {
				sessionID
				sessionType
				sessionStart
				sessionEnd
				userID
				studentCourseID
				status
				note
				internalNote
				customPickupLocation {
					locationID
					name
					address
				}
			}
			date
			user {
				firstName
				lastName
				userID
			}
			timestamp
			timeSlotID
			institution
			location {
				locationID
				name
				address
			}
			instructor {
				firstName
				lastName
			}
		}
	}
`;

export const GET_TIME_SLOTS_QUERY = gql`
	query timeSlots($institutionSlug: String!, $timePeriod: TimePeriodFilter) {
		timeSlots(institutionSlug: $institutionSlug, timePeriod: $timePeriod) {
			...timeSlotFragment
		}
	}
	${TIME_SLOT_FRAGMENT}
`;

export const SLOT_VISIBILITY_QUERY = gql `
	query institutionSettings($slug: ID!) {
		institutionSettings(slug: $slug) {
			notifications {
				endDate
				message
				startDate
				title
				type
				showOnHome
				showOnLessons
				showOnScheduler
				showOnNotificationsTab
			}
			schedulerSettings {
				timeSlotVisibility
			}
		}
	}
`;

export const TIME_SLOT_QUERY = gql`
	query timeSlot($institutionSlug: String!, $timeSlotID: ID!) {
		timeSlot(institutionSlug: $institutionSlug, timeSlotID: $timeSlotID) {
			...timeSlotFragment
		}
	}
	${TIME_SLOT_FRAGMENT}
`;

export const FUTURE_DRIVING_DATA_UPDATED_SUB =  gql`
	subscription futureDrivingDataUpdated($institution: String!, $studentCourseID: ID!) {
		futureDrivingDataUpdated(institution: $institution, studentCourseID: $studentCourseID) {
			mandatoryGapSpans {
				start
				end
			}
		}
	}
`;

export const TIME_SLOT_UPDATED_SUB =  gql`
	subscription timeSlotUpdated($institution: String, $timeSlotID: ID) {
		timeSlotUpdated(institution: $institution, timeSlotID: $timeSlotID) {
			...timeSlotFragment
		}
	}
	${TIME_SLOT_FRAGMENT}
`;

export const ELEARNING_EXTENDED_SUB =  gql`
	subscription eLearningExtended($studentCourseID: ID!) {
		eLearningExtended(studentCourseID: $studentCourseID)
	}
`;

export const NOTIFICATIONS_UPDATED_SUB =  gql`
	subscription notificationsUpdated($institution: ID!) {
		notificationsUpdated(institution: $institution) {
			notificationID
			startDate
			endDate
			message
			title
			type
			showOnHome
			showOnLessons
			showOnScheduler
			showOnNotificationsTab
		}
	}
`;

export const SCHEDULED_TIME_SLOTS_QUERY = gql`
	query timeSlotsWithUserSessionInstances($institutionSlug: String!, $userIDs: [ID!]!, $timePeriod: TimePeriodFilter) {
		timeSlotsWithUserSessionInstances(institutionSlug: $institutionSlug, userIDs: $userIDs, timePeriod: $timePeriod) {
			...timeSlotFragment
		}
	}
	${TIME_SLOT_FRAGMENT}
`;

export const CREATE_APPOINTMENT_MUTATION = gql`
	mutation createAppointment($userID: ID, $timeSlotID: ID!, $institution: String!, $date: LocalDate!, $sessions: [EditSessionDetails!]!, $note: String) {
		createAppointment(userID: $userID, timeSlotID: $timeSlotID, institution: $institution, date: $date, sessions: $sessions, note: $note) {
			...timeSlotFragment
		}
	}
	${TIME_SLOT_FRAGMENT}
`;

export const CANCEL_APPOINTMENT_MUTATION = gql`
	mutation cancelAppointment($userID: ID!, $timeSlotID: ID!, $institution: String!, $date: LocalDate!, $sessions: [EditSessionDetails!]!, $note: String) {
		cancelAppointment(userID: $userID, timeSlotID: $timeSlotID, institution: $institution, date: $date, sessions: $sessions, note: $note) {
			transactions {
				... on Debit {
					id
					success
				}
				... on Credit {
					id
					success
				}
			}
		}
	}
`;

export const INSTRUCTOR_QUERY = gql`
	query users($institution: ID, $roles: [String]) {
		users(institution: $institution, roles: $roles) {
			userID
			firstName
			lastName
		}
	}
`;

export const CHECK_PAYMENT_REQUIRED_BEFORE_SCHEDULING_QUERY = gql`
	query checkPaymentRequiredBeforeScheduling($institution: NonEmptyString!, $userID: UUID!) {
		checkPaymentRequiredBeforeScheduling(institution: $institution, userID: $userID) {
			formattedMessage
			minimumPaymentAmount
			behindTheWheelStudentCourseTotals {
				studentCourseID
				balance
				fees
				payments
			}
		}
	}
`;