<template>
	<ul :class="['session-card', (light) ? 'session-card--white' : '']">
		<li v-if="date">
			<MaterialDesignIcon icon="calendar" :color="(light) ? 'primary' : 'white'" />
			<span v-if="!loading && date">{{ format(parseISO(date), 'MMM dd, yyyy') }}</span>
			<IonSkeletonText :animated="true" color="light" style="height: 16px; width: 200px;" v-else />
		</li>
		<li>
			<MaterialDesignIcon icon="clock" :color="(light) ? 'primary' : 'white'" />
			<span v-if="!loading">{{ formatTime(sessionStart) }} - {{ formatTime(sessionEnd) }}</span>
			<IonSkeletonText :animated="true" color="light" style="height: 16px; width: 200px;" v-else />
		</li>
		<li v-if="oInstructor && oInstructor.firstName && oInstructor.lastName">
			<MaterialDesignIcon icon="account" :color="(light) ? 'primary' : 'white'" />
			<span v-if="!loading">{{ oInstructor.firstName + ' ' + oInstructor.lastName }}</span>
			<IonSkeletonText :animated="true" color="light" style="height: 16px; width: 200px;" v-else />
		</li>
		<li>
			<MaterialDesignIcon icon="car" :color="(light) ? 'primary' : 'white'" />
			<span v-if="!loading">{{ oVehicle?.name }} {{ oVehicle?.year }} {{ oVehicle?.make }} {{ oVehicle?.model }}</span>
			<IonSkeletonText :animated="true" style="height: 16px; width: 200px;" v-else />
		</li>
		<li v-if="oLocation?.name">
			<MaterialDesignIcon icon="mapMarker" :color="(light) ? 'primary' : 'white'" />
			<span>
				<template v-if="oLocation?.name && !loading">{{ oLocation?.name }}</template>
				<template v-if="oLocation?.address && !loading">
					<br />{{ oLocation?.address }}
				</template>
				<template v-if="loading">
					<IonSkeletonText :animated="true" style="height: 16px; margin-bottom: 5px; width: 200px;" />
					<IonSkeletonText :animated="true" style="height: 16px; width: 200px;" />
				</template>
			</span>
		</li>
	</ul>
</template>

<script>
import { defineComponent } from "vue";
import { format, parseISO} from 'date-fns';
import { formatTime } from "@/core/util/helpers";
import {
	IonSkeletonText
} from '@ionic/vue';
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";

export default defineComponent({
	components: {
		IonSkeletonText,
		MaterialDesignIcon
	},
	methods: {
		formatTime,
		format,
		parseISO
	},
	name: "SessionCard",
	props: {
		date: {
			type: String
		},
		light: {
			default: false,
			type: Boolean
		},
		loading: {
			default: false,
			type: Boolean
		},
		oInstructor: {
			default: {},
			type: Object
		},
		oLocation: {
			default: {},
			type: Object
		},
		oVehicle: {
			default: {},
			type: Object
		},
		sessionEnd: {
			default: '',
			type: String
		},
		sessionStart: {
			default: '',
			type: String
		}
	}
});
</script>

<style lang="scss" scoped>
.session-card {
	background: var(--ion-color-primary-lighten-2);
	color: var(--ion-color-light);
	display: flex;
	flex-direction: column;
	font-weight: 700;
	gap: 5px;
	list-style: none;
	margin: 0;
	padding: 10px;

	ion-skeleton-text {
		margin: 0;
	}

	li {
		align-items: flex-start;
		display: flex;
		gap: 10px;
		justify-content: flex-start;

		svg {
			height: 18px;
			margin-top: 1px;
			width: 18px;
		}
	}

	&--white {
		background: var(--ion-color-light);
		color: var(--ion-color-primary);
	}
}
</style>