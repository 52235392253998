<template>
<div class="popup-modal" v-if="showModal">
	<div class="popup-modal__text" v-if="reservationMessage">
		<p>{{reservationMessage}}</p>
	</div>
	<div class="popup-modal__buttons">
		<ion-button
			@click="closeModal"
			expand="block"
			:class="['cancel-button']"
		>
			CANCEL
		</ion-button>
		<ion-button
			@click="reserveSlots"
			:disabled="!confirmEnabled"
			expand="block"
			:class="['reserve-button', confirmEnabled && 'reserve-button--enabled']"
		>
			RESERVE
		</ion-button>
	</div>
</div>
</template>

<script>
export default {
	name: 'ReservationPopUpModal',
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		reservationMessage: {
			type: String,
			default: ''
		},
		confirmEnabled: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		closeModal() {
			this.$emit('closeModal');
		},
		reserveSlots() {
			this.$emit('reserveSlots');
		}
	}
};
</script>

<style lang="scss" scoped>
.popup-modal {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	border-radius: 6px 6px 0px 0px;
	background: var(--ion-color-primary-tint);
	display: flex;
	padding: 15px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;

	&__text {
		color: var(--ion-color-light, #FFF);
		align-self: stretch;
		text-align: center;
		line-height: 147%;
		font-weight: 700;
		font-size: 13px;
		letter-spacing: 0.78px;

	}

	&__buttons {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 10px;

		.cancel-button {
			width: 100%;
			font-weight: 700;
			font-size: 13px;
			line-height: 147%;
			letter-spacing: 0.78px;
			--border-radius: 6px;
			--color: var(--ion-color-light, #FFF);
			--background: var(--ion-color-primary-lighten-3, #6B7985);
			--box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
		}

		.reserve-button {
			width: 100%;
			font-weight: 700;
			font-size: 13px;
			line-height: 147%;
			letter-spacing: 0.78px;
			--border-radius: 6px;
			--color: var(--ion-color-light, #FFF);
			--background: var(--ion-color-light-darken-2, #BFC0C2);
			--box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

			&--enabled {
				--background: var(--ion-color-success, #00B494);
			}
		}
	}
}

.modal-content {
}
</style>
