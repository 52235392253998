import { addMonths, parseISO } from "date-fns";
import { useStore } from "vuex";

export default {
	methods: {
		permitExpirationDate(permitDate, permitState, birthdate) {
			if (!permitDate) {
				throw new Error('No permit date provided.');
			}

			if (!permitState) {
				throw new Error('Missing permit state.');
			}

			if (!birthdate) {
				throw new Error('Missing birthdate');
			}

			// Grab the chosen state settings.
			const stateSettings = this.store.getters.stateSettings.find(settings => settings.state === permitState);

			// Parse dates for manipulation.
			let permitDateParsed = parseISO(permitDate);

			// Get expiration date based on state settings, if setting is not found return very high expiration.
			let expirationDate = addMonths(
				permitDateParsed,
				stateSettings?.schedulerSettings?.permitExpiration?.months ?? 999
			);

			return expirationDate;
		},
		permitIsExpired(permitDate, permitState, birthdate) {
			if (!permitDate) {
				throw new Error('No permit date provided.');
			}

			if (!permitState) {
				throw new Error('Missing permit state.');
			}

			if (!birthdate) {
				throw new Error('Missing birthdate');
			}

			const dateToday = new Date();
			let expirationDate = this.permitExpirationDate(permitDate, permitState, birthdate);

			return dateToday > expirationDate;
		}
	},
	setup() {
		const store = useStore();

		return {
			store
		}
	}
}