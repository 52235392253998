<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="light">
				<ion-buttons slot="start">
					<ion-button @click="goBack()">
						<ion-icon color="light" :md="arrowBack" :ios="arrowBack"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title>Appearance</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="hideSettingsModal()">
						<ion-icon color="light" :md="close" :ios="close"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-list>
				<ion-radio-group :value="darkMode">
					<ion-list-header>
						<ion-label>Theme</ion-label>
					</ion-list-header>
					<ion-item v-for="option in options" :key="option.value">
						<ion-label v-html="option.label" text-wrap />
						<ion-radio
							:value="option.value"
							@click="changeSelection(option.value)"
							mode="md"
							slot="start"
						/>
					</ion-item>
				</ion-radio-group>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import { api } from '@/core/api/api';
import { computed, defineComponent } from "vue";
import {
	IonContent,
	IonHeader,
	IonIcon,
	IonTitle,
	IonToolbar
} from '@ionic/vue';
import {
	arrowBack,
	close,
	sunny
} from 'ionicons/icons';
import { useStore } from 'vuex';

export default defineComponent({
	name: "SettingsAppearance",
	components: {
		IonContent,
		IonHeader,
		IonIcon,
		IonTitle,
		IonToolbar
	},
	data() {
		return {
			options: [
				{'label':'Light', 'value':'light'},
				{'label':'Dark', 'value':'dark'}
			],
			user: {}
		}
	},
	methods: {
		changeSelection(option) {
			this.store.dispatch('setDarkMode', option);
		},
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		}
	},
	async mounted() {
		try {
			this.user = await api.getUserDetails(this.$store.getters.user.userID);
		} catch (e) {
			console.error(e);
		}
	},
	props: [
		"modalNav"
	],
	setup(props) {
		const store = useStore();
		const goBack = () => {
			const nav = props.modalNav.value;
			nav.pop();
		}

		return {
			arrowBack,
			close,
			darkMode: computed(() => store.state.theme.darkMode),
			goBack,
			store,
			sunny
		};
	}
});
</script>