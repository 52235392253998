import { InMemoryCache, ApolloClient, HttpLink, ApolloLink, split, defaultDataIdFromObject, NormalizedCacheObject } from  '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { store } from '../store/store';
import Environment from '../config/env.config';
import { onError } from '@apollo/client/link/error';
import router from '../router';
import { toastController } from '@ionic/vue';

const allowedOfflineOperations = [
	'timeSlots',
	'usersForScheduler',
	'skillSets',
	'institutionSettings',
];

// Handle errors
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	if ((graphQLErrors || []).some((e) => e.message.includes('Invalid JWT Token'))) {
		store.dispatch('logOut').then(async () => {
			await store.dispatch('logOut');
			await router.push('/login')
		}).then(async () => {
			setTimeout(() => {
				toastController.create({
					color: 'danger',
					duration: 2000,
					message: 'Invalid login, please try again',
					position: 'top'
				}).then((toast) => toast.present());
			}, 150)
		})
	}

	let isAuthError: boolean = false;
	let isNetworkError: boolean = false;
	if (graphQLErrors)
		graphQLErrors.map(({ extensions, message, locations, path }) =>{
			if (extensions.code === 'UNAUTHENTICATED') isAuthError = true;
			console.error(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			)
		}
	)
	store.commit('setAuthError', isAuthError);

	if (networkError) {
		console.error(`[Network error]: ${networkError}`);
		if (networkError.name === 'TypeError' && !allowedOfflineOperations.includes(operation.operationName)) {
			isNetworkError = true;
		}
	}
	store.commit('setNetworkError', isNetworkError || isAuthError);
});


const httpLink = new HttpLink({
	uri: `${Environment.baseURL}:${Environment.port}/graphql`
});

const wsLink = new GraphQLWsLink(
	createClient({
		url:`${Environment.wsURL}:${Environment.port}/ws/graphql`,
		connectionParams: {
			authentication: store.getters.token || '',
		},
		retryAttempts: 10,
		shouldRetry: () => true,
		on: {
			error: (event) => {
				console.error(event);
			}
		}
	}),
);

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			authorization: store.getters.token || '',
		}
	}
});

// TODO: Check on caching the studentClassroomProgress
const cache = new InMemoryCache({
	possibleTypes: {
		ResourceContent: ['TextResourceContent', 'ImageResourceContent', 'VideoResourceContent', 'BreakResourceContent'],
		Bookmark: ['LessonBookmark', 'TopicBookmark']
	},
	typePolicies: {
		Query: {
			fields: {
				timeSlots: {
					merge(existing = [], incoming) {
						return incoming;
					}
				},
				timeSlotsWithUserSessionInstances: {
					merge(existing, incoming, policies) {
						return incoming;
					}
				}
			}
		},
		TimeSlot: {
			keyFields: ['timeSlotID'],
			fields: {
				instances: {
					merge(existing, incoming) {
						return incoming;
					}
				}
			}
		},
		Vehicle: {
			keyFields: ['vehicleID'],
		},
		TimeSlotInstance: {
			fields: {
				sessions: {
					merge(existing, incoming) {
						return incoming;
					}
				},
				timeGroupedSessions: {
					merge(existing, incoming) {
						return incoming;
					}
				},
				groupedSessions: {
					merge(existing, incoming) {
						return incoming;
					}
				}
			}
		},
		SchedulerUser: {
			keyFields: ['userID'],
		},
		SessionDetails: {
			keyFields: ['sessionID'],
		},
		StudentInstructorAppData: {
			keyFields: ['id'],
			fields: {
				completedDriveSessions: {
					merge(existing, incoming) {
						return incoming;
					}
				},
				studentSkillProgress: {
					merge(existing, incoming, policies) {
						const merged = new Map();
						for (const exItem of existing || []) {
							const id = policies.readField('id', exItem);
							merged.set(id, exItem);
						}
						const incomingMap = new Map();
						for (const incItem of incoming || []) {
							const id = policies.readField('id', incItem);
							incomingMap.set(id, incItem);
						}

						for (const [key, value] of incomingMap) {
							if (!merged.has(key)) {
								merged.set(key, value);
							}
						}

						return Array.from(merged.values());
					}
				},
				scheduledSessions: {
					merge(existing, incoming) {
						return incoming;
					}
				},
			},
		},
		User: {
			keyFields: ['userID']
		},
		StudentCourse: {
			keyFields: ['studentCourseID']
		},
		SkillProgress: {
			keyFields: ['id', 'studentCourseID'],
			fields: {
				attempts: {
					merge(existing, incoming, policies) {
						const merged = new Map();
						for (const exItem of existing || []) {
							const id = policies.readField('id', exItem);
							merged.set(id, exItem);
						}
						const incomingMap = new Map();
						for (const incItem of incoming || []) {
							const id = policies.readField('id', incItem);
							incomingMap.set(id, incItem);
						}

						for (const [key, value] of incomingMap) {
							if (!merged.has(key)) {
								merged.set(key, value);
							}
						}
						return Array.from(merged.values()).sort((a, b) => {
							const aDate = policies.readField('date', a);
							const bDate = policies.readField('date', b);
							return Number(aDate) - Number(bDate);
						});
					}
				}
			}
		},
		SkillAttempt: {
			fields: {
				feedbackItems: {
					merge(existing, incoming) {
						return incoming;
					}
				}
			}
		}
	}
});

export const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
	link: split(
		({ query }) => {
			const definition = getMainDefinition(query)
			return definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
		},
		wsLink,
		ApolloLink.from([
			authLink,
			errorLink,
			httpLink,
		])
	),
	cache
});