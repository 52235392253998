<template>
	<div
		:class="[
			'progress__amount',
			type === 'success'
				? 'progress__amount--success'
				: 'progress__amount--failure',
		]"
	>
		<div class="progress__amount-inner" v-if="count > 0">
			<MaterialDesignIcon :icon="getIcon" :color="getIconColor" />
			<span>{{ count }}</span>
		</div>
		<div
			class="progress__progress-bar"
			:style="{
				width: calculateWidth,
				'background-color': backgroundColor,
				'border-radius': borderRadius,
			}"
		></div>
	</div>
</template>

<script>
import { format } from "date-fns";
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";
export default {
	components: { MaterialDesignIcon },
	props: {
		type: {
			default: "",
			type: String,
		},
		count: {
			default: 0,
			type: Number,
		},
		showButton: {
			default: false,
			type: Boolean,
		},
	},
	setup() {
		return {};
	},
	computed: {
		backgroundColor() {
			return this.type === "success" ? "#CCF0EA" : "#FBDDE0";
		},
		calculateWidth() {
			return `${this.count * 6}%`;
		},
		getIcon() {
			return this.type === "success" ? "checkBold" : "closeThick";
		},
		getIconColor() {
			return this.type === "success" ? "green" : "red";
		},
		borderRadius() {
			if (!this.showButton) {
				return this.type === "success" ? "0 6px  0 0" : "0  0 6px 0";
			}
		},
	},
	methods: {
		format,
	},
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.progress {
	flex: 1 0 100%;
	height: 100%;

	&__amount {
		display: flex;
		flex-direction: row;
		font-size: 14px;
		font-weight: 700;
		height: 100%;
		min-height: 25px;
		position: relative;
		width: 100%;
		z-index: 2;

		.material-design-icon {
			height: 12px;
			width: 12px;
		}
	}

	&__amount--success {
		color: #00b494;
	}

	&__amount--failure {
		color: #ed5564;
	}

	&__amount-inner {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 2px;
		position: relative;
		min-height: 25px;
		z-index: 2;
		width: 100%;
		justify-content: flex-end;
		font-weight: 500;

		span {
			padding-right: 5px;
		}
	}

	&__progress-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		height: 100%;
	}
}
</style>
