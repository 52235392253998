import { isPast } from 'date-fns';
import { computed, reactive } from 'vue';
import { SessionType } from '../../../core/SessionType';
import { Location, School, store, TimeSlot, TimeSlotVM, User, Zone } from '../../../core/store/store';

export function createInstanceVMs(timeSlots: TimeSlot[], userID: string): TimeSlotVM[] {
	return timeSlots
		.flatMap((timeSlot) => createInstanceVM(timeSlot, userID))
		.sort((a, b) => new Date(a.instance.sessions[0].sessionStart).getTime() - new Date(b.instance.sessions[0].sessionStart).getTime());
}

export function createInstanceVM(timeSlot: TimeSlot, userID: string): TimeSlotVM[] {
	const { instances, ...timeSlotDetails } = timeSlot;

	return instances.map((instance) => {
		const { sessions, ...instanceDetails} = instance;
		const sortedSessions = [...sessions].sort((a, b) => (new Date(a.sessionStart).getTime() - new Date(b.sessionStart).getTime()) || (Number(a.sessionType === SessionType.observe) - Number(b.sessionType === SessionType.observe)));
		const instanceVM: TimeSlotVM = reactive({
			...timeSlotDetails,
			instance: {
				...instanceDetails,
				sessions: sortedSessions
			},
			scheduledSessions: computed(() => sortedSessions.filter((session) => session.userID === userID)),
			isPast: computed(() => {
				return isPast(new Date(instanceVM.instance.sessions[0].sessionStart));
			}),
			isOpen: computed(() => {
				return instanceVM.instance.groupedSessions.some((sessionG) => sessionG.sessions.every(session => !session.userID)) ||
					(store.getters.institutionSettings.schedulerSettings?.sessionSelection?.granularSessionSelection &&
						instanceVM.instance.sessions.some((session) => (!session.userID && session.sessionType === SessionType.drive) ||
							(session.sessionType === SessionType.observe && !session.userID && timeSlot.sessionDefinitions.length === 1)));
			}),
			hasOpenDriveSessions: computed(() => instanceVM.instance.sessions.some((session) => session.sessionType === SessionType.drive && !session.userID)),
			hasOpenObserveSessions: computed(() => instanceVM.instance.sessions.some((session) => session.sessionType === SessionType.observe && !session.userID)),
			isScheduled: computed(() => instanceVM.instance.sessions.some((session) => session.userID === userID)),
			hasStudents: computed(() => instanceVM.instance.sessions.some((session) => session.userID)),
			hasInstructor: (instructor: User) => instanceVM.instructor.userID === instructor.userID,
			isAtLocation: (location: Location) => {
				const locationFilter = location.locationID;
				return instanceVM.location?.locationID === locationFilter ||
				instanceVM.instance.sessions.some((sesh) => sesh.customPickupLocation?.locationID === locationFilter && instanceVM.isOpen);
			},
			isForSchool: (school: School) => {
				const matchOnNoFilter = store.getters.timeSlotVisibility !== 'SCHOOL_ZONE_RESTRICTED';
				if (!school) return matchOnNoFilter;
				return instanceVM.schools?.some(vmSchool => vmSchool.locationID == school.locationID);
			},
			isForZones: (zones: Zone[]) => {
				const matchOnNoFilter = store.getters.timeSlotVisibility !== 'SCHOOL_ZONE_RESTRICTED';
				if (!zones?.length) return matchOnNoFilter;
				return instanceVM.zones?.some(vmZone => zones.some(zone => vmZone.id == zone.id))
			},
			matchesSchoolOrZone: (school: School, zones: Zone[]) => {
				const schoolsAreSet = Boolean(instanceVM.schools && instanceVM.schools.length > 0);
				const zonesAreSet = Boolean(instanceVM.zones && instanceVM.zones.length > 0);
				const schoolsMatched = !schoolsAreSet || instanceVM.isForSchool(school)
				const zonesMatched = !zonesAreSet || instanceVM.isForZones(zones)
				if (schoolsAreSet && zonesAreSet) return schoolsMatched || zonesMatched;
				if (schoolsAreSet) return schoolsMatched;
				if (zonesAreSet) return zonesMatched;
				return true;
			}
		});

		return instanceVM;
	});
}
