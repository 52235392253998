import { Dictionary } from '../common-types/CommonTypes';
import { Environment } from './Environment';

const environments: Dictionary<Environment> = {};

environments['dev'] = {
	name: 'Local',
	baseURL: 'http://localhost',
	wsURL: 'ws://localhost',
	platform: APP_PLATFORM,
	port: 3000,
	version: APP_VERSION
};

environments['development'] = {
	name: 'dev',
	baseURL: 'https://api-dev.drivetrak.io',
	wsURL: 'wss://api-dev.drivetrak.io',
	platform: APP_PLATFORM,
	port: 443,
	version: APP_VERSION
};


environments['qa'] = {
	name: 'qa',
	baseURL: 'https://api-qa.drivetrak.io',
	wsURL: 'wss://api-qa.drivetrak.io',
	platform: APP_PLATFORM,
	port: 443,
	version: APP_VERSION
};

environments['uat'] = {
	name: 'uat',
	baseURL: 'https://api-uat.drivetrak.io',
	wsURL: 'wss://api-uat.drivetrak.io',
	platform: APP_PLATFORM,
	port: 443,
	version: APP_VERSION
};

environments['production'] = {
	name: 'Prod',
	baseURL: 'https://api.drivetrak.io',
	wsURL: 'wss://api.drivetrak.io',
	platform: APP_PLATFORM,
	port: 443,
	version: APP_VERSION
};

//@ts-ignore
// Inserted by Vite during the build process.  See vite.config.js for more details
export default environments[VUE_APP_ENVIRONMENT];