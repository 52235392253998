<template>
	<div class="app-logo">
		<img
			:alt="institutionSettings.name"
			class="header-logo"
			:src="institutionSettings.logo"
			v-if="institutionSettings.logo && (hasCustomDomain || institutionSettings.moduleSettings.customBranding)"
		/>
		<DriveTrakLogo :color="driveTrakLogoColor" slot="start" v-else />
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from "vuex";
import DriveTrakLogo from '@/view/components/DriveTrakLogo.vue';
import { useStore } from "vuex";

export default defineComponent({
	components: {
		DriveTrakLogo
	},
	computed: {
		...mapGetters(['customBrandingClass', 'hasCustomDomain', 'institutionSettings']),
	},
	props: {
		driveTrakLogoColor: {
			default: '',
			type: String
		}
	},
	setup() {
		return {
			store: useStore()
		}
	}
});
</script>

<style lang="scss" scoped>
.app-logo {

	.drivetrak-logo,
	img {
		display: inline-block;
		height: auto;
		width: auto;
	}

	.drivetrak-logo {
		max-height: 50px;
	}
}
</style>