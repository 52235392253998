<template>
	<ion-page id="main-content">
		<app-header customBackButton="/lessons" />
		<progress-message
			:icon="alarm"
			color="warning"
			:message="`You have ${eLearningDaysFromLockDate} day${eLearningDaysFromLockDate === 1? '' : 's'} to complete your course before it locks on ${format(classroomUserProgress.lockDate, 'MMM do, yyyy')}`"
			:isButton="true"
			type="eLearningCountdownWarning"
			v-if="showeLearningExtensionWarning"
		></progress-message>
		<lesson-progress-bar
			:hideProgressBar="true"
			:inline="true"
			:lesson="lesson"
			:lessonCompleted="lessonProgress.completeDate ? true : false"
			:title="summaryTitle"
			:topicsCompleted="topicsCompleted"
		/>
		<ion-content class="ion-padding" fullscreen scrollY="true">
			<template v-if="hasQuizTest">
				<ion-card
					class="best-test-result"
					@click="ionRouter.navigate(bestQuizTest.url, 'forward', 'replace')"
					v-if="bestQuizTest"
				>
					<ion-card-content class="best-test-result__result">
						<div class="ion-text-center" v-if="bestQuizTest">
							<ion-text color="primary">
								<ion-icon :icon="trophy" style="font-size: 48px;"></ion-icon>
								<h2 class="ion-no-margin">{{ (routeTopicID) ? 'Best Quiz Result' : 'Best Test Result' }}</h2>
								<h3 class="ion-margin-bottom ion-no-margin">{{ format(bestQuizTest.completedDate, 'MMM d, yyyy') }}</h3>
							</ion-text>
						</div>
						<progress-circle
							:amount="bestQuizTest.amount"
							:maxAmount="bestQuizTest.maxAmount"
							:passingScore="passingScore"
							v-if="bestQuizTest"
						/>
					</ion-card-content>
					<ion-list class="best-test-result__warnings" v-if="!hasPassingQuizTestAttempt">
						<ion-item color="warning">
							<ion-icon color="light" :icon="alertOutline" slot="start" />
							<ion-label color="light" class="ion-text-wrap">You must achieve a score of at least {{ requiredPassingScore }}% to advance</ion-label>
						</ion-item>
					</ion-list>
					<ion-list class="best-test-result__warnings" v-if="redoLessonActive">
						<ion-item color="danger">
							<ion-icon color="light" :icon="alertCircleOutline" slot="start" />
							<ion-label color="light" class="ion-text-wrap">You did not pass the {{ (this.routeTopicID) ? 'quiz' : 'test' }} within the allowed number of attempts. Please redo the {{ (this.routeTopicID) ? 'topic' : 'lesson' }} and try again.</ion-label>
						</ion-item>
					</ion-list>
				</ion-card>
				<heading
					class="ion-margin-bottom"
					mdiIcon="mdiClipboardTextClock"
					:title="(routeTopicID) ? 'Past Quizzes' : 'Past Tests'"
				/>
				<div class="ion-margin-bottom" style="display: flex; justify-content: space-around; flex-wrap: wrap; row-gap: 15px; width: 100%;">
					<past-quiz-test-card
						:lessonID="routeLessonID"
						:topicID="routeTopicID"
						:progressItem="progressItem"
						v-for="progressItem in quiztestProgressItems"
					/>
					<div class="ion-margin ion-text-center" v-if="quiztestProgressItems.length === 0">
						<ion-icon class="ion-margin-bottom" :icon="alertCircleOutline" size="large"></ion-icon>
						<p class="ion-margin-bottom">You do not have any previous {{ (routeTopicID) ? 'quizzes' : 'tests' }}.</p>
					</div>
				</div>
				<ion-button v-if="!retryData.disabled" class="ion-margin-bottom" @click="ionRouter.navigate(retryData.url, 'forward', 'replace')" color="warning" expand="block" :disabled="!!retryData.disabled">
					{{ retryData.label }}
					<ion-icon slot="end" :icon="refresh" style="transform: scaleX(-1);"></ion-icon>
				</ion-button>
			</template>
			<template v-else>
				<div class="ion-text-center ion-padding-vertical" v-if="summaryIsComplete">
					<ion-text color="primary">
						<ion-icon :icon="ribbon" style="font-size: 64px;"></ion-icon>
						<h2 class="ion-no-margin">{{ (routeTopicID) ? 'Topic' : 'Lesson' }} Completed!</h2>
					</ion-text>
				</div>
				<div class="ion-text-center ion-padding-vertical" v-else>
					<ion-text color="primary">
						<ion-icon :icon="alertCircleOutline" style="font-size: 64px;"></ion-icon>
						<h2 class="ion-no-margin">You still have topics to <br />complete in this lesson.</h2>
					</ion-text>
				</div>
			</template>
			<ion-button
				class="ion-margin-bottom"
				color="success"
				expand="block"
				@click="ionRouter.navigate(continueData.url, 'forward', 'replace')"
				:disabled="!enableContinueButton"
				v-if="!isSecondToLastLessonOfSyllabus || (isSecondToLastLessonOfSyllabus && !eLearningMinimumFinishDate)"
			>
				{{ (continueData) ? continueData.label : '' }}
				<ion-icon slot="end" :icon="arrowForward"></ion-icon>
			</ion-button>
			<template v-else>
				<progress-message
					:icon="alarm"
					class="ion-margin-bottom"
					color="warning"
					:message="'Nice work so far! You will need to wait until ' + format(eLearningMinimumFinishDate, 'MMMM d, yyyy') + ' to complete your final lesson to meet Wisconsin DOT guidelines.'"
				></progress-message>
				<ion-button
					class="ion-margin-bottom"
					color="tertiary"
					expand="block"
					@click="ionRouter.navigate('/lessons', 'back', 'replace')"
				>
					Back to Lessons
				</ion-button>
			</template>
		</ion-content>
	</ion-page>
</template>

<script>
import AppHeader from '@/view/components/AppHeader.vue';
import LessonCard from '@/view/components/LessonCard.vue';
import LessonProgressBar from '@/view/components/LessonProgressBar.vue';
import ProgressCircle from '@/view/components/ProgressCircle.vue';
import ProgressMessage from '@/view/components/ProgressMessage.vue';
import PastQuizTestCard from '@/view/components/PastQuizTestCard.vue';
import Heading from '@/view/components/Heading.vue';
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonIcon,
	IonList,
	IonItem,
	IonLabel,
	IonText,
	IonToolbar,
	useIonRouter
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import {
	alarm,
	alertCircleOutline,
	alertOutline,
	arrowForward,
	caretDownOutline,
	clipboard,
	closeCircleOutline,
	informationCircle,
	refresh,
	ribbon,
	trophy
} from 'ionicons/icons';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { maxBy } from "lodash";
import { format } from 'date-fns';

export default defineComponent({
	name: "LessonSummary",
	components: {
		AppHeader,
		Heading,
		IonButton,
		IonCard,
		IonCardContent,
		IonIcon,
		IonItem,
		IonLabel,
		IonList,
		IonText,
		IonToolbar,
		LessonCard,
		LessonProgressBar,
		PastQuizTestCard,
		ProgressMessage,
		ProgressCircle
	},
	methods: {
		format
	},
	computed: {
		redoLessonActive() {
			const attempts = this.store.getters.getQuizTestAttempts({topicID: this.routeTopicID, lessonID: this.routeLessonID});

			return (!attempts && this.lesson.topics.length);
		},
		summaryTitle() {
			return ` - Summary`;
		},
		quiztestProgressItems() {
			const type = this.routeTopicID ? 'QUIZ' : 'TEST';

			// return this.lessonProgress?.progessItems.filter(pi => pi.type === type);
			const items = [];
			for (const pi of this.lessonProgress?.progressItems ?? []) {
				if (pi.type === type) {
					if (pi.type === 'QUIZ' && pi.reference === this.routeTopicID) {
						items.push(pi);
					} else if (pi.type === 'TEST') {
						items.push(pi);
					}
				}
			}

			return items;
		},
		bestQuizTest() {
			if(!this.quiztestProgressItems.length) return null;
			const best = maxBy(this.quiztestProgressItems, (i) => i.score);

			let url = '';
			if (best.__typename === "QuizAttempt") {
				url = `/results/${this.routeLessonID}/${this.routeTopicID}/${best.id}`
			} else {
				url = `/results/${this.routeLessonID}/${best.id}`;
			}

			return {
				amount: best.score,
				maxAmount: best.details.length,
				completedDate: best.completedDate,
				url: url
			}
		},
		hasQuizTest() {
			if (this.routeTopicID) {
				return this.lesson.topics.find((t) => t.topicID === this.routeTopicID).hasQuiz;
			} else {
				return this.lesson.hasTest;
			}
		},
		isSecondToLastLessonOfSyllabus() {
			if (this.syllabus.lessons.length === 1) return false;

			const lastLessonID = this.syllabus.lessons[this.syllabus.lessons.length - 2].lessonID;

			return this.routeLessonID === lastLessonID;
		},
		passingScore() {
			return this.store.getters.passingScore({topicID: this.routeTopicID, lessonID: this.routeLessonID});
		},
		topicComplete() {
			return this.routeTopicID && this.lessonProgress.progressItems && this.lessonProgress.progressItems.find((pi) => pi.type === 'TOPIC' && pi.reference === this.routeTopicID)
		},
		topicsCompleted() {
			return this.lessonProgress?.progressItems?.filter(item => item.type === 'TOPIC') ?? [];
		},
		eLearningMinimumFinishDate() {
			return this.store.getters.eLearningMinimumFinishDate ?? false;
		},
		eLearningDaysFromLockDate() {
			return this.store.getters.eLearningDaysFromLockDate;
		},
		showeLearningExtensionWarning() {
			return this.store.getters.showeLearningExtensionWarning;
		},
		summaryIsComplete() {
			if (this.routeTopicID) {
				return this.topicComplete
			} else {
				return !!this.lessonProgress.completeDate
			}
		},
		// Duplicated in Results.page.vue
		retryData() {
			if (!this.lessonProgress?.progressItems) {
				return {
					label: 'Incomplete Lesson',
					url: `/lessons`,
					disabled: true
				}
			}

			const referenceID = this.routeTopicID ? this.routeTopicID : this.routeLessonID;
			let latestRetryMarker = this.lessonProgress.progressItems.findLastIndex((pi) => pi.type === 'RETRY' && pi.reference === referenceID);

			const attempts = this.store.getters.getQuizTestAttempts({topicID: this.routeTopicID, lessonID: this.routeLessonID});

			if (this.routeTopicID) {
				if (this.topicComplete) {
					if (attempts < this.syllabus.quizSettings?.attemptsAllowed) {
						return {
							label: 'Retry Quiz',
							url: `/quiztest/${this.routeLessonID}/${this.routeTopicID}`
						}
					} else {
						return {
							label: 'Retry Quiz',
							url: `/lessons`,
							disabled: true
						}
					}
				} else {
					// If there is a retry marker and no quiz/test attempts, that means the marker was just added, signalling a redo
					if (latestRetryMarker !== -1 && !attempts) {
						return {
							label: 'Redo Topic',
							url: `/lessons/${this.routeLessonID}/${this.routeTopicID}`
						}
					} else {
						return {
							label: 'Retry Quiz',
							url: `/quiztest/${this.routeLessonID}/${this.routeTopicID}`
						}
					}
				}
			} else {
				if (this.lessonProgress.completeDate) {
					if (attempts < this.syllabus.testSettings?.attemptsAllowed) {
						return {
							label: 'Retry Test',
							url: `/quiztest/${this.routeLessonID}`
						}
					} else {
						return {
							label: 'Retry Test',
							url: `/lessons`,
							disabled: true
						}
					}
				} else {
					if (this.redoLessonActive) {
						if (latestRetryMarker !== -1) {
							return {
								label: 'Redo Lesson',
								url: `/lessons/${this.routeLessonID}/${this.lesson.topics[0].topicID}`
							}
						} else {
							return {
								label: 'Incomplete Lesson',
								url: `/lessons`,
								disabled: true
							}
						}
					} else {
						return {
							label: 'Retry Test',
							url: `/quiztest/${this.routeLessonID}`
						}
					}
				}
			}
		},
		enableContinueButton() {
			if (!this.lessonProgress) return false;
			if (this.routeTopicID) {
				return !!this.lessonProgress.progressItems.find((pi) => pi.type === 'TOPIC' && pi.reference === this.routeTopicID);
			} else {
				return !!this.lessonProgress.completeDate;
			}
		},
		continueData() {
			if (this.routeTopicID) {
				const topicIndex = this.lesson.topics.findIndex((t) => t.topicID === this.routeTopicID);

				if (topicIndex === -1) {
					throw new Error('Missing topic in syllabus lesson');
				}

				if (topicIndex === this.lesson.topics.length - 1) {
					if (this.lesson.hasTest) {
						return {
							label: 'Continue to Lesson Test',
							url: `/quiztest/${this.routeLessonID}`
						}
					} else {
						return {
							label: 'Continue to Lesson Summary',
							url: `/summary/${this.routeLessonID}`
						}
					}
				} else {
					const nextTopicID = this.lesson.topics[topicIndex+1].topicID
					return {
						label: 'Continue to Next Topic',
						url: `/lessons/${this.routeLessonID}/${nextTopicID}`
					}
				}
			} else {
				const lessonIndex = this.syllabus.lessons.findIndex((l) => l.lessonID === this.routeLessonID);
				if (lessonIndex === -1) throw new Error('Missing lesson on syllabus');

				if (lessonIndex !== this.syllabus.lessons.length - 1) {
					if (this.syllabus.lessons[lessonIndex+1].inPerson) {
						return {
							label: 'Return Home',
							url: '/lessons'
						}
					} else if (this.syllabus.lessons[lessonIndex+1].topics.length) {
						return {
							label: 'Continue to Next Lesson',
							url: `/lessons/${this.syllabus.lessons[lessonIndex+1].lessonID}/${this.syllabus.lessons[lessonIndex+1].topics[0].topicID}`
						}
					} else {
						if (this.syllabus.lessons[lessonIndex+1].hasTest) {
							return {
								label: 'Continue to Next Lesson Test',
								url: `/quiztest/${this.syllabus.lessons[lessonIndex+1].lessonID}`
							}
						} else {
							return {
								label: 'Continue to Next',
								url: `/summary/${this.syllabus.lessons[lessonIndex+1].lessonID}`
							}
						}
					}
				} else {
					return {
						label: 'Complete Final Lesson',
						url: `/lessons`
					}
				}
			}
		},
		hasPassingQuizTestAttempt() {
			// A passing quiz/test attempt sets the completion of a topic/lesson, on topics/lessons with quiz/tests
			return this.lessonProgress && this.hasQuizTest && this.summaryIsComplete;
        },
		requiredPassingScore() {
			if (this.routeTopicID) {
				return this.syllabus.quizSettings?.requiredPassingScore;
			} else {
				return this.syllabus.testSettings?.requiredPassingScore;
			}
		},
		syllabus() {
			return this.store.getters.selectedSyllabus;
		},
		topicsCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			// If we don't find the current lesson return an empty array.
			if (!currentLessonProgress) { return []; }

			// Otherwise return a list of topics completed.
			return currentLessonProgress.progressItems.filter(item => item.type === 'TOPIC');
		},
		classroomUserProgress() {
			return this.store.getters.selectedClassroomProgress;
		},
		lesson() {
			return this.syllabus?.lessons?.find((l) => l.lessonID === this.routeLessonID);
		},
		lessonProgress() {
			return this.classroomUserProgress?.lessons?.find((l) => l.lessonID === this.routeLessonID) ?? {};
		}
	},
	setup() {
		const queryLoading = ref(false);
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const ionRouter = useIonRouter();

		const routeLessonID = (route.params.lessonID) ? route.params.lessonID : '';
		const routeTopicID = (route.params.topicID) ? route.params.topicID : '';

		return {
			alarm,
			alertCircleOutline,
			alertOutline,
			arrowForward,
			caretDownOutline,
			clipboard,
			closeCircleOutline,
			informationCircle,
			ionRouter,
			refresh,
			route,
			router,
			routeLessonID,
			routeTopicID,
			ribbon,
			store,
			trophy
		};
	}
});
</script>

<style lang="scss" scoped>
.best-test-result {
	cursor: pointer;
	margin: 0;
	padding: 0;
	text-align: center;

	&__result {
		align-items: center;
		background-color: var(--ion-color-light);
		display: flex;
		justify-content: space-around;

		h2 {
			font-size: 1.4em;
		}

		h3 {
			font-size: 1.3em;
		}

		.progress-circle {
			margin: 0;
		}
	}

	&__warnings {
		margin-top: 0;
		padding: 0;

		ion-item {
			font-weight: 700;
		}
	}
}

.progress-message {
	border-radius: 6px;
}

ion-button {
	--box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
}
</style>
