<template>
	<div class="attempt-card">
		<div class="attempt-card__header">
			<div class="attempt-card__header-details">
				<h2>Attempt {{ attemptNumber }} - {{ format(attempt.date, 'MMM dd, yyyy') }}</h2>
				<p>{{ attempt.instructor.firstName }} {{ attempt.instructor.lastName }}</p>
			</div>
			<ion-button
				class="attempt-card__edit-button"
				@click="$emit('editAttempt', attempt.id)"
				v-if="ownsAttempt(attempt.instructor.userID)"
			>
				<MaterialDesignIcon color="light" icon="pencilFilled" size="large" />
			</ion-button>
		</div>
		<div class="attempt-card__comments">
			<DataBar
				:color="(attempt.success) ? 'success' : 'danger'"
				:icon="(attempt.success) ? 'checkBold' : 'closeThick'"
				:title="(attempt.success) ? 'Success' : 'Failed'"
			/>
			<DataBar
				icon="commentQuote"
				:title="attempt.note"
				v-if="attempt.note"
			/>
			<DataBar :title="item.feedbackMessage" v-for="item in attempt.feedbackItems" />
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DataBar from '@/view/components/content/DataBar.vue';
import { format } from 'date-fns';
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";
import { useStore } from 'vuex';

export default defineComponent({
	components: {
		DataBar,
		MaterialDesignIcon
	},
	data() {
		return {
			show: false
		}
	},
	methods: {
		format,
		ownsAttempt(userID) {
			return (userID === this.store.getters.user.userID) ? true : false;
		}
	},
	name: 'AttemptCard',
	props: {
		attempt: {
			default: {},
			type: Object
		},
		attemptNumber: {
			default: 0,
			type: Number
		}
	},
	setup() {
		const store = useStore();

		return {
			store
		};
	}
});
</script>

<style lang="scss" scoped>
.attempt-card {
	border-radius: 6px;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	overflow: hidden;

	&__header {
		align-items: center;
		background: var(--ion-color-primary);
		color: var(--ion-color-light);
		display: flex;
		justify-content: space-between;
		padding: 15px;

		h2 {
			font-size: 20px;
			margin: 0 0 5px 0;
			padding: 0;
		}
	}

		&__edit-button {
			--box-shadow: none;
			height: 48px;
			margin: 0;
			width: 60px;
		}

	&__comments {
		display: flex;
		flex-direction: column;
		gap: 1px;
	}
}
</style>