<template>
	<ion-card
		:class="[
			'past-quiz-test-card',
			(noBackground) ? 'past-quiz-test-card--no-background' : '',
			(noBoxShadow) ? 'past-quiz-test-card--no-box-shadow' : '',
			(horizontal) ? 'past-quiz-test-card--horizontal' : ''
		]"
		@click="ionRouter.navigate(cardLink, 'forward', 'replace')"
	>
		<progress-circle
			:amount="questionDetails.correct"
			:hideAmount="horizontal"
			:maxAmount="questionDetails.total"
			:passingScore="passingScore"
			size="sm"
			:strokeWidth="(horizontal) ? 75 : 70"
		/>
		<span class="past-quiz-test-card__date-amount">
			<ion-text class="past-quiz-test-card__date">{{ format(progressItem.completedDate, 'MMM d, yyyy') }}</ion-text>
			<ion-text class="past-quiz-test-card__amount" :color="status" v-if="horizontal">
				{{ questionDetails.correct }} / {{ questionDetails.total }}
			</ion-text>
		</span>
	</ion-card>
</template>

<script>
import {
	IonCard,
	useIonRouter
} from '@ionic/vue';
import ProgressCircle from '@/view/components/ProgressCircle.vue';
import { useStore } from "vuex";
import { format } from 'date-fns';

export default {
	name: 'PastQuizTestCard',
	components: {
		IonCard,
		ProgressCircle
	},
	computed: {
		cardLink() {
			if (this.topicID) {
				return `/results/${this.lessonID}/${this.topicID}/${this.progressItem.id}`
			} else {
				return `/results/${this.lessonID}/${this.progressItem.id}`
			}
		},
		percentage() {
			if(!this.questionDetails.correct || !this.questionDetails.total) { return 0; }

			let percentage = this.questionDetails.correct / this.questionDetails.total;

			return +percentage.toFixed(2);
		},
		passingScore() {
			return this.store.getters.passingScore({topicID: this.topicID, lessonID: this.lessonID});
		},
		questionDetails() {
			let questions = {
				correct: 0,
				total: 0
			};

			if (!this.progressItem) { return questions; }

			questions.total = this.progressItem?.details?.length ? this.progressItem.details.length : 0;
			questions.correct = this.progressItem?.score;

			return questions;
		},
		status() {
			if (this.percentage >= this.passingScore) {
				return 'success';
			} else if (this.percentage > (this.passingScore - 0.15)) {
				return 'warning';
			} else {
				return 'danger';
			}
		}
	},
	methods: {
		format
	},
	props: {
		horizontal: {
			default: false,
			type: Boolean
		},
		lessonID: {
			required: true,
			type: String
		},
		topicID: {
			default: '',
			type: String
		},
		progressItem: {
			required: true,
			type: Object
		},
		noBackground: {
			default: false,
			type: Boolean
		},
		noBoxShadow: {
			default: false,
			type: Boolean
		}
	},
	setup() {
		const ionRouter = useIonRouter();
		const store = useStore();

		return {
			ionRouter,
			store
		}
	}
}
</script>

<style lang="scss" scoped>
.past-quiz-test-card {
	background-color: var(--ion-color-light);
	cursor: pointer;
	margin: 0;
	min-width: 100px;
	padding: 5px;
	text-align: center;

	&__date-amount {
		font-weight: 700;
	}

	.progress-circle {
		margin: 0 auto 5px auto;
	}

	&--no-background {
		--background: none;
	}

	&--no-box-shadow {
		box-shadow: none;
	}

	&--horizontal {
		align-items: center;
		cursor: default;
		display: flex;
		justify-content: center;
		padding: 0;

		.past-quiz-test-card {
			&__date-amount {
				display: flex;
				flex-direction: column;
			}

				&__date {
					font-size: 1.35em;
					margin-bottom: 3px;
				}

				&__amount {
					font-size: 1.65em;

					span {
						font-size: 0.85em;
					}
				}
		}

		.progress-circle {
			display: flex;
			font-size: 0.80em;
			height: 80px;
			margin: 0 15px 0 0;
			width: 80px;
		}
	}
}
</style>