<template>
	<ion-card
		button
		:class="[
			'instructor-session-card',
			'instructor-session-card--' + sessionProximityAndStatus.buttonColor,
			(sessionProximityAndStatus.buttonColor) ? '' : '',
			(isHidden) ? 'instructor-session-card--hidden' : ''
		]"
		@click="goToSession"
		v-for="session in driveSessions" v-bind:key="session.sessionID"
	>
		<ul>
			<li>
				<ion-icon :icon="time" /> {{ formatTime(session.sessionStart) }} - {{ formatTime(session.sessionEnd) }}
			</li>
			<li v-if="session?.location?.name">
				<ion-icon :icon="location" />
				<span>{{ session.location?.name }}<br /><span class="instructor-session-card__address">{{ session.location?.address }}</span></span>
			</li>
		</ul>
		<ul v-if="students.length > 0">
			<li class="instructor-session-card__student-row" v-for="student in students" :key="student.user.userID">
				<div class="instructor-session-card__student-name">
					<MaterialDesignIcon
						:icon="(student.sessionType === 'drive') ? 'gauge' : 'eye'"
						size="small"
					/>
					<span>{{ student.user.firstName }} {{ student.user.lastName }}</span>
				</div>
				<div class="instructor-session-card__student-details" v-if="showHighSchoolOnSessionCard">
					<span v-if="student.user?.school?.name">
						<MaterialDesignIcon icon="school" size="x-small" />{{ student.user.school.name }}
					</span>
				</div>
			</li>
		</ul>
	</ion-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import {
	location,
	time,
} from "ionicons/icons";
import { useStore } from "vuex";
import { formatTime, isPopulated } from '@/core/util/helpers.ts';
import { IonCard, IonIcon, IonItem, menuController } from "@ionic/vue";
import { useRouter } from 'vue-router';
import { isPast } from "date-fns";

export default defineComponent({
	name: "InstructorSession",
	components: {
		IonIcon,
		IonItem,
		MaterialDesignIcon,
		menuController,
	},
	props: {
		instructorTimeSlotData: {
			type: Object,
			required: true
		},
		isHidden: {
			type: Boolean
		},
		sessionGroup: {
			type: Array,
			required: true
		},
		sessionProximityAndStatus: {
			type: Object
		}
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const loading = ref(false);

		const closeMenu = async (menu) => {
			await menuController.close(menu);
		}

		return {
			closeMenu,
			loading,
			location,
			router,
			store,
			time
		};
	},
	computed: {
		buttonColor() {
			const startOfFirstSession = new Date(this.sessionGroup[0].sessionStart);

			let color = 'light';
			if (isPast(startOfFirstSession)) {
				color = this.sessionGroup[0].status === 'COMPLETED' ? 'success' : 'warning';
			}
			// TODO: TICKET_NEEDED fix colors
			// if (this.store.getters.selectedSession === this.sessionGroup[0].sessionID) {
			// 	color = 'primary';
			// }
			return color
		},
		institutionTZ() {
			return this.store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		},
		driveSessions() {
			return this.sessionGroup.filter((sg) => sg.sessionType === 'drive');
		},
		showHighSchoolOnSessionCard() {
			return this.store?.getters?.institutionSettings?.instructorAppSettings?.showHighSchoolOnSessionCard;
		},
		students() {
			if (!this.sessionGroup) { return []; }
			const students = this.sessionGroup.reduce((acc, curr) => {
				if (curr.studentInstructorAppData?.user) {
					acc.push({
						sessionType: curr.sessionType,
						user: curr.studentInstructorAppData.user
					});
				}
				return acc;
			}, []);

			return students;
		}
	},
	methods: {
		isPopulated: isPopulated,
		goToSession() {
			// TODO: Delete this store commit after rework.
			this.store.commit('setSelectedInstructorDrivingIDs', {
				timeSlotID: this.instructorTimeSlotData.timeSlotID,
				instanceID: this.instructorTimeSlotData.instanceID,
				sessionID: this.sessionGroup.find((sg) => sg.sessionType === 'drive').sessionID,
			});

			// NOTE: New Instructor Store.
			this.store.commit('setActiveSession', {
				timeSlotID: this.instructorTimeSlotData.timeSlotID,
				instanceID: this.instructorTimeSlotData.instanceID,
				instanceDate: this.instructorTimeSlotData.date,
				sessionID: this.sessionGroup.find((sg) => sg.sessionType === 'drive').sessionID,
				driverStudentCourseID: this.sessionGroup[0].studentCourseID,
				driverUserID: this.sessionGroup[0].userID
			});
			this.store.commit('setSessionState', 'start');
			this.$router.push(`/instructor/session`);
		},
		getSessionStudents(sessionGroup) {
			return sessionGroup.map(s => {
				return s.studentInstructorAppData?.user || null;
			}).filter(s => isPopulated(s));
		},
		formatTime
	}
})
</script>

<style lang="scss" scoped>
.instructor-session-card {

	background: var(--ion-color-light);
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	border-left: 10px solid var(--ion-color-primary);
	color: var(--ion-color-primary);
	font-size: 16px;
	margin: 0 0 20px 0;

	ul {
		display: flex;
		flex-direction: column;
		gap: 10px;
		list-style: none;
		margin: 0;
		padding: 10px;

		&:first-child {
			font-weight: 700;
			background: var(--ion-color-primary-lighten-9);
			border-bottom-style: solid;
			border-bottom-width: 1px;
		}
	}

	&__address {
		font-weight: 400;
	}

	&__student-row {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		gap: 5px;

		ion-icon,
		.material-design-icon {
			color: var(--ion-color-primary);
		}
	}

	&__student-name {
		display: flex;
		gap: 5px;
		justify-content: center;
	}

	&__student-details {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding-left: 22px;

		ion-icon {
			height: 15px;
			width: 15px;
		}

		span {
			font-size: 0.9em;
			display: flex;
			gap: 5px;
			justify-content: center;
		}
	}

	&--success {
		border-color: var(--ion-color-success);

		ul {
			border-color: var(--ion-color-success);

			&:first-child {
				background: var(--ion-color-success-lighten-9);
			}
		}
	}

	&--tertiary {
		border-color: var(--ion-color-tertiary);

		ul {
			border-color: var(--ion-color-tertiary);

			&:first-child {
				background: var(--ion-color-tertiary-lighten-9);
			}
		}
	}

	&--warning {
		border-color: var(--ion-color-warning);

		ul {
			border-color: var(--ion-color-warning);

			&:first-child {
				background: var(--ion-color-warning-lighten-9);
			}
		}
	}

	&--danger {
		border-color: var(--ion-color-danger);

		ul {
			background: var(--ion-color-danger-lighten-9);

			&:first-child {
				border-color: var(--ion-color-danger);
			}
		}
	}

	&--medium {
		border-color: var(--ion-color-grey-darken-5);

		ul:first-child {
			background: var(--ion-color-grey-darken-1);
			border-color: var(--ion-color-grey-darken-5);
			color: var(--ion-color-grey-darken-7);

			ion-icon,
			.material-design-icon {
				color: var(--ion-color-grey-darken-7);
			}
		}
		font-family: Arial, Helvetica, sans-serif;
	}

	&--hidden{
		border-right-style: dashed;
		border-top-style: dashed;

		ul:first-child,
		ul,
		ul:last-child {
			border-bottom-style: dashed;
		}
	}
}
</style>