<template>
	<ion-loading v-if="loading" show-backdrop />
	<ion-item
		:ripple="false"
		button
		color="light"
		detail="false"
		:class="['ion-margin-bottom', 'ion-no-padding', 'timeslot-item', `border-${buttonColor}`]"
		@click="adjustAppointment(userHasScheduledSession(timeSlot) && !disableButton ? 'cancel' : 'update', timeSlot)"
		:disabled="!userHasScheduledSession(timeSlot) && sessionGroup.every(sesh => sesh.userID) || disableButton || timeSlot.isPast"
	>
		<div class="timeslot-item__details">
			<div class="timeslot-item-sessions">
				<div class="timeslot-item-sessions__text" v-for="session in sessionGroup" :key="session.sessionID">
					<p>
						<ion-icon :icon="session.sessionType === 'drive' ? speedometer : eye" />
						{{ formatTime(session.sessionStart) }} - {{ formatTime(session.sessionEnd) }}
					</p>
				</div>
			</div>
			<div slot="end" class="timeslot-item-meta">
				<p v-if="!store.getters.institutionSettings.schedulerSettings.hideInstructors"><ion-icon :icon="person" /> {{ timeSlot.instructor.firstName }} {{ timeSlot.instructor.lastName }}</p>
				<p v-if="sessionGroup[0].customPickupLocation?.name || (timeSlot.location?.name && timeSlot.location?.locationID !== 'none')"><ion-icon :icon="location" /> {{ sessionGroup[0].customPickupLocation?.name || timeSlot.location?.name }}</p>
				<template v-if="!timeSlot.isPast">
					<div class="timeslot-item-meta__button">
						<ion-button v-if="!disableButton && (sessionGroup.every(sesh => !sesh.userID) || userHasScheduledSession(timeSlot))"
							:color="userHasScheduledSession(timeSlot) ? 'danger' : 'success'"
							:disabled="disableButton"
							size="small"
							class="stretch"
						>
							{{userHasScheduledSession(timeSlot) ? 'Cancel' : 'Reserve'}}
						</ion-button>
					</div>
				</template>
			</div>
		</div>
	</ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { toast } from '@/core/toast/Toast';
import { CREATE_APPOINTMENT_MUTATION, CANCEL_APPOINTMENT_MUTATION } from '@/core/api/schedulerApi';
import { useMutation } from '@vue/apollo-composable';
import {
	eye,
	location,
	person,
	speedometer,
	time,
	ban,
	checkbox,
	readerOutline,
	eyeOff,
	calendarNumber,
	checkmarkCircle
} from "ionicons/icons";
import { useStore } from "vuex";
import omitDeep from 'omit-deep-lodash';
import { api } from '@/core/api/api';
import { IonIcon, IonItem, alertController, IonLoading } from "@ionic/vue";
import { useRouter } from 'vue-router';
import envConfig from '@/core/config/env.config';
import { getSessionsTimeSlotLoggingDetails, formatTime } from "@/core/util/helpers";
import { SessionDetails, TimeSlotVM } from "@/core/store/store";
import { format, parseISO } from "date-fns";

export default defineComponent({
	name: "TimeSlotItem",
	components: {
		IonIcon,
		IonItem,
		IonLoading,
	},
	props: {
		disableButton: {
			default: false,
			type: Boolean
		},
		isClickable: {
			default: true,
			type: Boolean
		},
		isUpcoming: {
			default: false,
			type: Boolean
		},
		hideTimeSlot: {
			default: false,
			type: Boolean
		},
		isAppointmentHistory: {
			default: false,
			type: Boolean
		},
		timeSlot: {
			type: Object as PropType<TimeSlotVM>,
			required: true
		},
		sessionGroup: {
			type: Array as PropType<SessionDetails[]>,
			required: true
		},
		student: {
			type: String
		}
	},
	setup(props, context) {
		const router = useRouter();
		const store = useStore();
		const loading = ref(false);

		const logData = (type, status, details) => {
			api.logMobileActivity({
				institution: store.getters.institutionSettings.slug,
				event: type,
				affectedUserID: store.getters.user.userID,
				additionalData:{
					email: store.getters.user.email,
					activeUser: store.getters.selectedUser,
					currentAppVersions: store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status,
					details
				}
			});
		}


		const createAppointmentVariables = ref({
			sessions: []
		});

		const createAppointment = useMutation(
			CREATE_APPOINTMENT_MUTATION,
			() => {
				const sessions = createAppointmentVariables.value.sessions.map(({customPickupLocation, session}) => session);

				const loggingDetails = getSessionsTimeSlotLoggingDetails(sessions, store.getters.institutionSettings);

				logData('Scheduled Appointment', 'Success', loggingDetails);

				return {
					variables: {
						...createAppointmentVariables.value,
						sessions
					}
				}
			});

		createAppointment.onDone(result => {
			context.emit('refetchTimeSlots');
		});

		createAppointment.onError(error => {
			const sessions = createAppointmentVariables.value.sessions.map(({customPickupLocation, session}) => session);

			const loggingDetails = getSessionsTimeSlotLoggingDetails(sessions, store.getters.institutionSettings);

			loading.value = false;
			logData('Scheduled Appointment', 'Failure', loggingDetails);

			toast.error({
				message: error.message,
				duration: 7500,
				position: 'top'
			});
		});

		const cancelAppointmentVariables = ref({
			sessions: []
		});

		const cancelAppointment = useMutation(
			CANCEL_APPOINTMENT_MUTATION,
			() => {
				const sessions = cancelAppointmentVariables.value.sessions.map(({customPickupLocation, session}) => session);

				const loggingDetails = getSessionsTimeSlotLoggingDetails(sessions, store.getters.institutionSettings);

				logData('Canceled Appointment', 'Success', loggingDetails);

				return {
					variables: {
						...cancelAppointmentVariables.value,
						sessions
					}
				}
			});
		cancelAppointment.onDone(result => {
			const successfulCreditTransaction = result.data.cancelAppointment?.transactions.find(transaction => transaction.success && transaction?.__typename === 'Credit');
			const isDebitTransactions = result.data.cancelAppointment?.transactions.find(transact => transact.__typename === 'Debit');
			const unsuccessfulCreditTransaction = result.data.cancelAppointment?.transactions.find(transaction => !transaction.success && transaction?.__typename === 'Credit');
			const noCreditTransactionAttempted = (!successfulCreditTransaction && !unsuccessfulCreditTransaction);

			if (successfulCreditTransaction && isDebitTransactions) {
				toast.success({
					message: `
						Your appointment has been canceled, and the late cancelation fee payment was successful.
					`,
					duration: 7500,
					position: 'top'
				});
			}
			if (noCreditTransactionAttempted && isDebitTransactions) {
				toast.warning({
					message: `
						Your appointment has been canceled, but you will need to pay your cancelation fee.
						Please contact ${store.getters.institutionSettings.name} to make your payment.
					`,
					duration: 7500,
					position: 'top'
				});
			}
			if (unsuccessfulCreditTransaction && isDebitTransactions) {
				toast.error({
					message: `
						Your appointment has been canceled, but the late cancelation fee payment was unsuccessful.
						Please contact ${store.getters.institutionSettings.name} to make your payment.
					`,
					duration: 7500,
					position: 'top'
				});
			}
			context.emit('refetchTimeSlots');
		});

		cancelAppointment.onError(error => {
			const sessions = cancelAppointmentVariables.value.sessions.map(({customPickupLocation, session}) => session);

			const loggingDetails = getSessionsTimeSlotLoggingDetails(sessions, store.getters.institutionSettings);

			loading.value = false;
			logData('Canceled Appointment', 'Failure', loggingDetails);

			toast.error({
				message: error.message,
				duration: 7500,
				position: 'top'
			});
			context.emit('refetchTimeSlots');
		});


		return {
			eye,
			loading,
			cancelAppointmentVariables,
			createAppointmentVariables,
			cancelAppointment,
			createAppointment,
			location,
			person,
			router,
			speedometer,
			store,
			time,
			ban,
			checkbox,
			readerOutline,
			eyeOff,
			calendarNumber,
			checkmarkCircle
		};
	},
	computed: {
		buttonColor() {
			let color = "tertiary";
			if (this.isAppointmentHistory) {
				if (this.timeSlot.instance.sessions.find(sesh => sesh.status === 'COMPLETED')) {
					color = 'success';
				} else if (this.timeSlot.instance.sessions.find(sesh => ['CANCELED', 'NO_SHOW_WITHOUT_FEE'].includes(sesh.status))) {
					color = 'warning'
				} else if (this.timeSlot.instance.sessions.find(sesh => sesh.status === 'SCHEDULED')) {
					color = 'tertiary'
				} else if (this.timeSlot.instance.sessions.find(sesh => ['NO_SHOW_WITH_FEE', 'CANCELED_WITH_FEE'].includes(sesh.status))) {
					color = 'danger';
				} else {
					color = 'medium';
				}
			} else if (this.isUpcoming) {
				return color;
			} else {
				if (this.userHasScheduledSession(this.timeSlot)) {
					color = "success";
				} else if (!this.timeSlotHasOpenings(this.timeSlot)) {
					color = "medium";
				}
			}

			return color;
		},
		selectedUser() {
			return this.store.getters.selectedUser ? this.store.getters.selectedUser : this.store.getters.user.userID;
		}
	},
	methods: {
		isInCancellationPeriod(sessions: SessionDetails[]) {
			const startOfFirst = new Date(sessions[0].sessionStart);

			const cancellationPeriod = this.store.getters.institutionSettings.schedulerSettings.minimumCancellationTime;

			// If appointment slot - current time < a day in milliseconds.
			return startOfFirst.getTime() < Date.now() + cancellationPeriod
		},
		async adjustAppointment(type: string, timeSlot: TimeSlotVM) {
			let alertMessage = format(parseISO(timeSlot.instance.date), 'MMMM d, yyyy') + '<br />';

			let user = await api.getUserValidationDetails(this.store.getters.selectedUser);

			if (user.courses.find(stCourse => stCourse.course.categories.find(cat => cat.slug === 'behind-the-wheel' && stCourse.onHold))) {
				this.store.state.btwCourseOnHold = true;
				let message = `${this.store.getters.onHoldMessage}`;
				const alert = await alertController.create({
					backdropDismiss: false,
					buttons: [
						{
							text: 'Close',
							handler: () => {
								alert.dismiss().then(() => {
									this.router.push('/home');
								});
							}
						}
					],
					cssClass: 'ion-text-center',
					header: 'Administrative Hold',
					message: message
				});
				alert.present();
			} else {
				this.store.state.btwCourseOnHold = false;

				alertMessage += '<ul class="sessions">';

				for ( let i = 0; i < this.sessionGroup.length; i++ ) {

					alertMessage += '<li>';

					switch(this.sessionGroup[i].sessionType) {
						case 'drive':
							alertMessage += '<br /><strong>Behind the wheel</strong>';
							break;
						case 'observe':
							alertMessage += '<br /><strong>Observe</strong>';
							break;
					}

					alertMessage += '<br />' + formatTime(this.sessionGroup[i].sessionStart) + ' - ' + formatTime(this.sessionGroup[i].sessionEnd) + '</li>';
				}

				alertMessage += '</ul>';

				if(this.isInCancellationPeriod(this.sessionGroup) && type === 'cancel') {
					const minimumCancellationTimeHours = this.store.getters.institutionSettings.schedulerSettings.minimumCancellationTime / 1000 / 60 / 60;
					const readableTime = new Intl.NumberFormat('en-US', { style: 'unit', unit: 'hour', unitDisplay: 'long' }).format(minimumCancellationTimeHours);

					alertMessage += `<br /><br /><div class="alert-message__notice"><h5>Additional Fee</h5><p>There will be a $${this.store.getters.institutionSettings.schedulerSettings.cancellationFee} charge since this is within ${readableTime} of your appointment.</p></div>`;
				}

				const alert = await alertController.create({
					backdropDismiss: false,
					buttons: [
						{
							text: 'No',
							role: 'cancel'
						},
						{
							text: 'Yes',
							handler: async () => {
								if(type === 'update') {
									this.loading = true;
									this.createAppointmentVariables = omitDeep({
										sessions: this.sessionGroup,
										instance: timeSlot.instance,
										institution: this.store.getters.user.institutions[0],
										timeSlotID: timeSlot.timeSlotID,
										userID: this.selectedUser
									}, '__typename');
									await this.createAppointment.mutate();
									this.loading = false;
								} else {
									this.loading = true;
									this.cancelAppointmentVariables = omitDeep({
										sessions: this.sessionGroup,
										instance: timeSlot.instance,
										institution: this.store.getters.user.institutions[0],
										timeSlotID: timeSlot.timeSlotID,
										userID: this.selectedUser
									}, '__typename');
									await this.cancelAppointment.mutate();
									this.loading = false;
								}
							}
						}
					],
					cssClass: 'ion-text-center',
					header: type === 'update' ? 'Schedule Appointment?' : 'Cancel Appointment?',
					message: alertMessage
				});
				await alert.present();

				const { role } = await alert.onDidDismiss();
			}
		},
		userHasScheduledSession(instance) {
			return instance?.sessionGroup?.some(session => session.userID === this.selectedUser) || false;
		},
		timeSlotHasOpenings(instance) {
			if (!instance) return false;
			return instance.sessionGroup.every(sesh => !sesh.userID);
		},
		formatTime
	}
})
</script>

<style lang="scss" scoped>
ion-item {
	--inner-padding-end: 0;
}

.timeslot-item {
	border-radius: 7px;
	border-left: 10px solid;
	box-shadow: var(--box-shadow);

	&__icons,
	&__details {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

		&__icons {
			background-color: rgba(255, 255, 255, 0.3);
			height: 100%;
			justify-content: space-around;

			ion-icon {
				font-size: 20px;
			}
		}

		&__details {

			justify-content: center;

			p {
				display: flex;
				margin: 0 0 8px 0;
				font-size: 14px;

				ion-icon {
					font-size: 18px;
					margin-right: 8px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
}

.timeslot-item-sessions {
	width: 55%;
	border-right: 5px solid var(--ion-color-secondary);

	&__text {
		padding: 8px;
		border-bottom: 1px solid var(--ion-color-secondary);

		p {
			padding-right: 10px;
		}
	}

	&__text:last-of-type {
		border-bottom: none;
	}

}

.timeslot-item-meta {
	padding: 8px 4px 4px 4px;
	width: 45%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__button {
		display: flex;
		justify-content: stretch;
		align-items: center;

	}
}

.stretch {
	width: 100%;
}

.border-success {
	border-color: var(--ion-color-success);
}

.border-tertiary {
	border-color: var(--ion-color-tertiary)
}

.border-medium {
	border-color: var(--ion-color-medium)
}

.border-warning {
	border-color: var(--ion-color-warning)
}

.border-danger {
	border-color: var(--ion-color-danger)
}
</style>