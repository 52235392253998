<template>
	<ion-modal
		canDismiss="false"
		ref="modal"
		:is-open="feedbackModalOpen"
	>
        <ion-header>
            <div class="feedback-modal__header">
                <p slot="start"><span>{{attemptData.title}} Feedback</span></p>
            </div>
        </ion-header>
        <ion-content scroll-y class="feedback-modal">
            <ion-list lines="full" class="ion-padding-horizontal ion-padding-vertical skill-list" v-if="attemptData.attemptTotals">
                <template v-for="item in attemptData.attemptTotals">
                    <div class="feedback-modal__item">
                        <span class="feedback-modal__count"><b>{{ item.errorCount }}</b></span>
                        <span class="feedback-modal__text"><b>{{ item.description }}</b></span>
                    </div>
                </template>
            </ion-list>
        </ion-content>
        <ion-footer>
            <div class="feedback-modal__buttons">
                <ion-button
                    @click="closeModal"
                    class="cancel-button"
                >
                    CLOSE
                </ion-button>
                <ion-button color="primary" @click="$emit('openAttemptModal')">
                    VIEW ATTEMPTS
                </ion-button>
            </div>
        </ion-footer>
        
    </ion-modal>
</template>

<script>
import { IonModal } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useStore } from "vuex";
import {
	informationCircle
} from 'ionicons/icons';
export default defineComponent({
	components: { IonModal },
	props: {
		skillAttempts: {
			type: Object,
			default: () => {},
		},
		feedbackModalOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	setup() {
		const store = useStore();

		return {
			store,
            informationCircle
		}
	},
	methods: {
		closeModal() {
			this.$emit('closeSkillFeebackModal');
		},
        viewAttempts() {
        }

	},
    computed: {
        attemptData() {
			return this.skillAttempts;
		},
    }
});
</script>

<style scoped lang="scss">
.feedback-modal {
    height: calc(100% - 123px);
    &__header {
        background-color: var(--ion-color-primary);
        color: #ffffff;
        z-index: 10;
        padding: 10px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        padding: 20px;
        display: flex;
        justify-content: left;
        align-items: center;
    }

    &__text {
        padding-left: 10px;
    }

    &__count {
        font-size:x-large;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }

}
</style>