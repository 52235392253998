
<template>
	<ion-page>
		<app-header />
		<ion-content fullscreen scrollY="true">
			<ion-progress-bar type="indeterminate" v-if="loading"></ion-progress-bar>
			<NotificationCard
				:notification="notification"
				v-for="(notification, index) in notificationsForTab"
				:key="index"
				:skills="skills"
				v-if="notificationsForTab.length"
			/>
			<div class="ion-margin-vertical ion-text-center" style="margin-top: 120px;" v-else>
				<MaterialDesignIcon class="no-items-icon" icon="information" />
				<p class="no-items-text">No Notifications</p>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonPage,
	IonCardContent,
} from "@ionic/vue";
import {
	checkmarkCircle,
} from "ionicons/icons";
import { computed } from 'vue';
import { useStore } from 'vuex';
import AppHeader from '../components/AppHeader.vue';
import {
	GET_STUDENTS_SKILL_PROGRESS,
	GET_INSTITUTION_SKILLS_QUERY
} from '@/core/api/drivingApi';
import MaterialDesignIcon from "../components/MaterialDesignIcon.vue";
import NotificationCard from "@/view/components/cards/NotificationCard.vue";
import { toast } from '../../core/toast/Toast';
import { useQuery } from "@vue/apollo-composable";
import { APPOINTMENT_HISTORY_QUERY } from "@/core/api/schedulerApi";

export default {
	name: "Notifications",
	components: {
		AppHeader,
		IonCardContent,
		IonPage,
		MaterialDesignIcon,
		NotificationCard,
	},
	computed: {
		driveSessionCompletionNotifications() {
			if (!this.appointmentHistory) { return []; }

			let completedDriveSessions = this.appointmentHistory.reduce((agg, curr) => {
				if (curr?.sessions) {
					curr.sessions.forEach(session => {
						// Check that we are showing a session that is completed.
						if (session.sessionType === 'drive' && session.status === 'COMPLETED') {

							let attempts = [];
							if (this.studentsSkillProgress) {

								attempts = this.studentsSkillProgress.reduce((agg, curr) => {

									let sessionAttempt = null;

									if (curr.attempts) {
										sessionAttempt = curr.attempts.find(attempt => {

											return attempt.sessionID === session.sessionID;
										});

										if (sessionAttempt) {
											agg.push({
												skillID: curr.id,
												...sessionAttempt
											})
										}
									}

									return agg;
								}, []);
							}

							agg.push({
								attempts: attempts,
								instructor: curr.instructor,
								sessionData: session,
								startDate: curr.date,
								title: "Session Completion",
								type:"sessionCompletion"
							});
						}
					});
				}

				return agg;
			}, []);

			return completedDriveSessions;
		},
		notificationsForTab() {
			if (!this.driveSessionCompletionNotifications && !this.store.getters.notifications) { return []; }
			let notifications = [...this.store.getters.notifications.filter(({showOnNotificationsTab}) => showOnNotificationsTab)].sort((a, b) => {
				return b.startDate - a.startDate;
			});

			// remove startDate from non-driving notifications
			notifications = notifications.map((notification) => {
				const {startDate, ...rest} = notification;

				return rest;
			})

			if (this.hasDriving) {
				notifications = notifications.concat(
					this.driveSessionCompletionNotifications.sort((a, b) => {
						return b.startDate - a.startDate;
					})
				);
			}

			return notifications;
		}
	},
	setup() {
		const store = useStore();
		const studentCourseIDs = (store.getters.selectedUserDrivingStudentCourseID) ? [store.getters.selectedUserDrivingStudentCourseID] : [];
		const hasDriving = computed(() => (
			store.getters.institutionSettings.moduleSettings.instructorApp
			&& store.getters.institutionSettings.moduleSettings.registration
			&& studentCourseIDs.length
		));

		// Students Skill Progress
		const studentsSkillProgressQuery = useQuery(
			GET_STUDENTS_SKILL_PROGRESS, () => ({
				studentCourseIDs: studentCourseIDs
			}),
			{
				fetchPolicy: 'no-cache',
				enabled: hasDriving.value
			})
			studentsSkillProgressQuery.onError(error => {
				toast.error({
					message: 'Student progress details cannot be retrieved at this time',
					duration: 7500
				});
			}
		);
		const studentsSkillProgress = computed(() => studentsSkillProgressQuery?.result?.value?.multiStudentSkillProgress ?? []);

		// Appointment History
		const appointmentHistoryQuery = useQuery(
				APPOINTMENT_HISTORY_QUERY,
				() => ({
					userIDs: [store.getters.selectedStudent.userID]
				}),
				{
					fetchPolicy: 'no-cache',
					enabled: computed(() => store.getters.isAuthenticated && hasDriving.value)
				}
			);
			appointmentHistoryQuery.onError(error => {
				console.error(error);

				toast.error({
					message: 'Unfortunately the calendar information cannot be retrieved at this time',
					duration: 7500
				});
			}
		);
		const appointmentHistory = computed(() => appointmentHistoryQuery?.result?.value?.appointmentHistory ?? []);

		// Skills List
		// Appointment History
		const skillsQuery = useQuery(
				GET_INSTITUTION_SKILLS_QUERY,
				() => ({
					institutionSlug: store.getters.selectedStudent.institution
				}),
				{
					fetchPolicy: 'no-cache',
					enabled: hasDriving.value
				}
			);
			skillsQuery.onError(error => {
				console.error(error);

				toast.error({
					message: 'Unfortunately the skills information cannot be retrieved at this time',
					duration: 7500
				});
			}
		);
		const skills = computed(() => skillsQuery?.result?.value?.skills ?? []);

		return {
			appointmentHistory,
			checkmarkCircle,
			loading: appointmentHistoryQuery.loading || studentsSkillProgressQuery.loading,
			skills,
			hasDriving,
			store,
			studentsSkillProgress
		}
	}
}
</script>

<style scoped>

ion-menu-button {
	color: var(--ion-color-primary);
}
ion-item-divider {
	--background: white;
	--color: #8c8c8c;
}

.no-items-icon {
	height: 35px;
	width: 35px;
}

.no-items-text {
	font-size: 1.05em;
	font-weight: 700;
	margin-top: 15px;
}
</style>