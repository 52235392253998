<template>
	<div class="payment-form-wrapper">
		<div class="payment-form">
			<slot name="other-info"></slot>
			<div>
				<ion-label color="light" position="floating">Amount</ion-label>
				<ion-item
					ref="cardPaymentAmount"
					:class="[
						validationErrors.cardAmount
							? 'payment-form-item--invalid'
							: 'payment-form-item--valid',
						'form-item',
					]"
				>
					<ion-input
						fill="outline"
						v-model="cardPaymentAmount"
						type="number"
						placeholder="0.00"
						@ionBlur="validateCardData($event, 'cardPaymentAmount')"
					></ion-input>
					<ion-note class="form-item__error" slot="error">{{
						errorMessages.invalidAmountMessage
					}}</ion-note>
				</ion-item>
			</div>
			<div class="payment-form__name-fields">
				<div>
					<ion-label color="light" position="stacked"
						>First Name</ion-label
					>
					<ion-item
						ref="cardFirstName"
						:class="[
							validationErrors.cardFirstName
								? 'payment-form-item--invalid'
								: 'payment-form-item--valid',
							'form-item',
						]"
					>
						<ion-input
							v-model.trim="cardData.cardFirstName"
							type="string"
							fill="outline"
							:maxlength="50"
							@ionBlur="validateCardData($event, 'cardFirstName')"
						></ion-input>
						<ion-note class="form-item__error" slot="error">{{
							errorMessages.invalidFirstNameMessage
						}}</ion-note>
					</ion-item>
				</div>
				<div>
					<ion-label color="light" position="stacked"
						>Last Name</ion-label
					>
					<ion-item
						ref="cardLastName"
						:class="[
							validationErrors.cardLastName
								? 'payment-form-item--invalid'
								: 'payment-form-item--valid',
							'form-item',
						]"
					>
						<ion-input
							v-model.trim="cardData.cardLastName"
							type="string"
							:maxlength="50"
							@ionBlur="validateCardData($event, 'cardLastName')"
						></ion-input>
						<ion-note class="form-item__error" slot="error">{{
							errorMessages.invalidLastNameMessage
						}}</ion-note>
					</ion-item>
				</div>
			</div>

			<ion-label
				v-if="!isSquareGateway && !shouldShowStripeCardElement"
				position="stacked"
				>Card Number</ion-label
			>
			<ion-item
				ref="cardNumber"
				v-if="!isSquareGateway && !shouldShowStripeCardElement"
				:class="[
					validationErrors.cardNumber
						? 'payment-form-item--invalid'
						: 'payment-form-item--valid',
					'form-item',
				]"
			>
				<ion-input
					type="text"
					v-maska="cardNumberOptions.mask"
					v-model="cardData.cardNumber"
					:placeholder="cardNumberOptions.placeholder"
					@ionBlur="validateCardData($event, 'cardNumber')"
				></ion-input>
				<ion-note class="form-item__error" slot="error">{{
					errorMessages.invalidCardNumberMessage
				}}</ion-note>
			</ion-item>
			<div class="expiration-cvv">
				<div>
					<ion-label
						v-if="!isSquareGateway && !shouldShowStripeCardElement"
						position="stacked"
						>Expiration Date</ion-label
					>
					<ion-item
						ref="cardExp"
						v-if="!isSquareGateway && !shouldShowStripeCardElement"
						:class="[
							validationErrors.cardExp
								? 'payment-form-item--invalid'
								: 'payment-form-item--valid',
							'form-item-expcvv',
						]"
					>
						<ion-input
							v-model="cardData.cardExp"
							type="string"
							v-maska="expirationDateComputedMask"
							placeholder="MM/YY"
							@ionBlur="validateCardData($event, 'cardExp')"
						></ion-input>
						<ion-note class="form-item__error" slot="error">{{
							errorMessages.invalidExpMessage
						}}</ion-note>
					</ion-item>
				</div>
				<div>
					<ion-label
						v-if="!isSquareGateway && !shouldShowStripeCardElement"
						position="stacked"
						>Security Code</ion-label
					>
					<ion-item
						ref="cardCVC"
						v-if="!isSquareGateway && !shouldShowStripeCardElement"
						:class="[
							validationErrors.cardCVC
								? 'payment-form-item--invalid'
								: 'payment-form-item--valid',
							'form-item-expcvv',
						]"
					>
						<ion-input
							v-model="cardData.cardCVC"
							type="string"
							v-maska="cvvOptions.mask"
							@ionBlur="validateCardData($event, 'cardCVC')"
						></ion-input>
						<ion-note class="form-item__error" slot="error">{{
							errorMessages.invalidCVCMessage
						}}</ion-note>
					</ion-item>
				</div>
			</div>
			<div
				id="square-form"
				v-if="isSquareGateway"
				key="square-form"
			></div>
			<div
				id="stripe-form"
				v-if="shouldShowStripeCardElement"
				key="stripe-form"
			></div>
		</div>
		<div class="payment-form-buttons">
			<ion-button
				expand="block"
				class="cancel-button"
				@click="$emit('closeModal')"
			>
				CANCEL
			</ion-button>
			<ion-button
				expand="block"
				class="confirm-button"
				@click="makeCreditCardPayment"
			>
				SUBMIT
			</ion-button>
		</div>
	</div>
</template>

<script>
import { defineComponent, handleError, ref } from 'vue';
import { toast } from '@/core/toast/Toast';
import { api, MAKE_PAYMENT_WITH_NEW_CARD_MUTATION } from '@/core/api/api';
import envConfig from '@/core/config/env.config';
import { useMutation } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import { getYear } from 'date-fns';
import { alertController } from '@ionic/vue';

export default defineComponent({
	name: 'PaymentElement',
	props: {
		loadPayTrak: {
			type: Boolean,
			default: false,
		},
		processingState: {
			type: String,
		},
		initialAmount: {
			type: Number,
		},
		maximumPaymentAmount: {
			type: Number,
		},
		studentCourseID: {
			type: String,
			required: true,
		},
		confirmPayment: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const store = useStore();

		const {
			mutate: makePaymentWithNewCardMutation,
			loading: makePaymentWithNewCardLoading,
		} = useMutation(MAKE_PAYMENT_WITH_NEW_CARD_MUTATION);

		return {
			store,
			makePaymentWithNewCardMutation,
			makePaymentWithNewCardLoading,
		};
	},
	data() {
		return {
			payTrakLib: null,
			payTrakSettings:
				this.$store.state?.institutionSettings
					?.registrationInstitutionConfig?.payment || null,
			cardPaymentAmount: this.initialAmount || 0,
			cardData: {
				cardFirstName: '',
				cardLastName: '',
				cardNumber: '',
				cardExp: '',
				cardCVC: '',
				useSavedCard: false,
				cardZip: '',
				useCardOnFile: false,
			},
			validationErrors: {
				cardAmount: false,
				cardFirstName: false,
				cardLastName: false,
				cardNumber: false,
				cardExp: false,
				cardCVC: false,
			},
			errorMessages: {
				invalidAmountMessage: 'Please enter a valid amount',
				invalidCardNumberMessage: 'Please enter a valid card number',
				invalidFirstNameMessage: 'Please enter a valid first name',
				invalidLastNameMessage: 'Please enter a valid last name',
				invalidExpMessage: 'Please enter a valid expiration date',
				invalidCVCMessage: 'Please enter a valid security code',
			},
			validationRegexes: {
				amexCardNumber: /^\d{4}-\d{6}-\d{5}$/gi,
				creditCardNumber: /^\d{4}-\d{4}-\d{4}-\d{4}$/gi,
				amexCVV: /\d{4}/gi,
				creditCardCVV: /\d{3}/gi,
			},
			tokenizeReponse: null,
		};
	},
	methods: {
		formatDollarAmount(amount) {
			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			}).format(amount);
		},
		calculateSurchargeAmount(
			minSurchargeAmount,
			surchargePercent,
			paymentBaseAmount
		) {
			let amount = minSurchargeAmount || 0;
			if (surchargePercent) {
				const amountFromPercent =
					paymentBaseAmount * (surchargePercent / 100);
				amount = Math.max(amount, amountFromPercent);
			}
			return amount;
		},
		buildPaymentTotals() {
			const surchargeSettings =
				this.store.state.institutionSettings?.paymentSettings
					?.surcharge;
			const surchargeAmount = this.calculateSurchargeAmount(
				surchargeSettings?.minAmount,
				surchargeSettings?.percent,
				this.cardPaymentAmount
			);
			return {
				surchargeEnabled: surchargeSettings?.enabled,
				surchargePercent: surchargeSettings?.percent,
				surchargeMinAmount: surchargeSettings?.minAmount,
				paymentBaseAmount: this.cardPaymentAmount,
				surchargeAmount,
				totalAmount: surchargeSettings?.enabled
					? Math.round(
							(this.cardPaymentAmount + surchargeAmount) * 100
					  ) / 100
					: this.cardPaymentAmount,
			};
		},
		validateCardFirstName() {
			const cardFirstNameRegex =
				/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
			if (
				!this.cardData.cardFirstName ||
				!cardFirstNameRegex.test(this.cardData.cardFirstName)
			) {
				this.validationErrors.cardFirstName = true;
				return false;
			}
			this.validationErrors.cardFirstName = false;
			return true;
		},
		validateCardLastName() {
			const cardLastNameRegex =
				/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
			if (
				!this.cardData.cardLastName ||
				!cardLastNameRegex.test(this.cardData.cardLastName)
			) {
				this.validationErrors.cardLastName = true;
				return false;
			}
			this.validationErrors.cardLastName = false;
			return true;
		},
		validateCardNumber() {
			if (
				!this.cardData.cardNumber?.match(
					this.cardNumberOptions.validationRegex
				)
			) {
				this.validationErrors.cardNumber = true;
				return false;
			}
			this.validationErrors.cardNumber = false;
			return true;
		},
		validateCardCVC() {
			// 3 or 4 digit cvc
			if (
				!this.cardData.cardCVC?.match(this.cvvOptions.validationRegex)
			) {
				this.validationErrors.cardCVC = true;
				return false;
			}
			this.validationErrors.cardCVC = false;
			return true;
		},
		validateCardPaymentAmount() {
			const cardPaymentAmount = Number(this.cardPaymentAmount);
			const cardPaymentAmount0orLess = cardPaymentAmount <= 0;
			const cardPaymentAmountGreaterThanBalance =
				cardPaymentAmount > Math.abs(this.maximumPaymentAmount || 0);
			if (
				cardPaymentAmount0orLess ||
				cardPaymentAmountGreaterThanBalance
			) {
				this.errorMessages.invalidAmountMessage =
					cardPaymentAmount0orLess
						? 'Please enter an amount greater than $0'
						: 'Please enter an amount less than or equal to the balance';
				this.validationErrors.cardAmount = true;
				return false;
			}
			this.validationErrors.cardAmount = false;
			return true;
		},
		validateExpirationDate() {
			if (!this.newCardData.cardExp) {
				this.validationErrors.cardExp = true;
				return false;
			}
			const [month, year] = this.newCardData.cardExp.split('/');
			if (!year || !month) {
				this.validationErrors.cardExp = true;
				return false;
			}
			if (Number(month) > 12 || Number(month) < 1) {
				this.validationErrors.cardExp = true;
				return false;
			}
			const expirationDate = new Date(
				getYear(new Date()).toString().slice(0, 2) + year,
				month
			);
			const currentDate = new Date();
			if (expirationDate < currentDate) {
				this.validationErrors.cardExp = true;
				return false;
			}
			this.validationErrors.cardExp = false;
			return true;
		},
		validateCardData(ev, refName) {
			// reset field validation
			this.$refs[refName].$el.classList.remove('ion-valid');
			this.$refs[refName].$el.classList.remove('ion-invalid');

			switch (refName) {
				case 'cardPaymentAmount':
					if (!this.validateCardPaymentAmount()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardFirstName':
					if (!this.validateCardFirstName()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardLastName':
					if (!this.validateCardLastName()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardNumber':
					if (!this.validateCardNumber()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardExp':
					if (!this.validateExpirationDate()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
				case 'cardCVC':
					if (!this.validateCardCVC()) {
						this.$refs[refName].$el.classList.add('ion-invalid');
						return false;
					} else {
						this.$refs[refName].$el.classList.add('ion-valid');
						return true;
					}
			}
		},
		validatePaymentFields() {
			// Fields Required for all gateways
			const invalidFields = [
				this.validateCardData(null, 'cardPaymentAmount'),
				this.validateCardData(null, 'cardFirstName'),
				this.validateCardData(null, 'cardLastName'),
			];

			// non-square validation fields
			if (!this.isSquareGateway && !this.shouldShowStripeCardElement) {
				// card number regex validation
				invalidFields.push(this.validateCardData(null, 'cardNumber'));
				// expiration regex validation
				invalidFields.push(this.validateCardData(null, 'cardExp'));
				// cvc regex validation
				invalidFields.push(this.validateCardData(null, 'cardCVC'));
			}
			if (invalidFields.includes(false)) {
				toast.error({
					message:
						'Invalid payment information. Please check your payment fields and try again.',
					duration: 5000,
				});
				return false;
			}
			return true;
		},
		async makePaymentWithNewCard(success, token_or_err, response) {
			try {
				const [month, year] = this.cardData.cardExp.split('/');

				const makePaymentVariables = {
					institution: this.store.state.institutionSettings.slug,
					userID: this.store.getters.selectedUser,
					studentCourseID: this.studentCourseID,
					amount: Number(this.cardPaymentAmount),
					paymentInfo: {
						cardFirstName: this.cardData.cardFirstName,
						cardLastName: this.cardData.cardLastName,
						cardZip: this.cardData.cardZip || null,
						cardExpMonth: month || null,
						cardExpYear: year
							? getYear(new Date()).toString().slice(0, 2) + year
							: null,
						cardLastFour:
							this.cardData?.cardNumber?.slice(-4) || null,
						token: token_or_err,
						saveCard: this.cardData.saveCard || false,
					},
				};
				if (this.store.state.showSettingsData?.addCorrespondingFee)
					makePaymentVariables.addCorrespondingFee =
						this.store.state.showSettingsData?.addCorrespondingFee;
				const results = await this.makePaymentWithNewCardMutation(
					makePaymentVariables
				);
				const creditTransaction =
					results.data?.makeCreditCardPaymentWithNewCardAndReturnCreatedTransactions?.find(
						(transaction) => {
							return transaction.__typename === 'Credit';
						}
					);

				if (creditTransaction && creditTransaction.success) {
					this.logData(
						'Credit Card Payment',
						'Success',
						creditTransaction.description
					);
				} else {
					toast.error({
						message:
							'Payment Failed: Enter a new Credit Card or try again later',
						duration: 5000,
					});
					this.logData(
						'Credit Card Payment',
						'Failure',
						creditTransaction.description
					);
				}
				this.cardPaymentAmount = null;
				this.$emit('update:processingState', 'COMPLETED');
			} catch (err) {
				toast.error({
					message: err,
					duration: 5000,
				});
				this.$emit('update:processingState', 'FAILED');
			} finally {
				this.tokenizeReponse = null;
			}
		},
		async showPaymentConfirmation(success, token_or_err, response) {
			const paymentTotals = this.buildPaymentTotals();

			if (!success) {
				let err =
					token_or_err.error && token_or_err.error.message
						? token_or_err.error.message
						: token_or_err;
				toast.error({
					message: err,
					duration: 5000,
				});
				this.$emit('update:processingState', 'FAILED');
				return;
			}

			this.tokenizeReponse = {
				success,
				token_or_err,
				response,
			};

			this.$emit('updatePaymentTotals', paymentTotals);
			this.$emit('update:processingState', 'CONFIRM');
		},
		setupPayTrak() {
			let payTrakScript = document.createElement('script');
			const payTrakSDKVersion =
				this.payTrakSettings.payTrakSDKVersion || '1.0.7';
			const gateway = this.payTrakSettings.gateway;
			const formIds = {
				Square: 'square-form',
				Stripe: 'stripe-form',
			};

			payTrakScript.setAttribute(
				'src',
				`https://cdn.compknowhow.com/paytrak/${payTrakSDKVersion}/paytrak.min.js`
			);
			payTrakScript.onload = () => {
				const payTrackConfig = {
					gateway,
					mode: this.payTrakSettings.mode,
					testApiKey: this.payTrakSettings.testApiKey,
					liveApiKey: this.payTrakSettings.liveApiKey,
					locationID: this.payTrakSettings.locationID,
					paymentElementId: formIds[gateway] ?? '',
					submitButtonId: 'submit-authorize',
					manual: true,
				};

				// validate key base on mode
				const modeKeyPairing = {
					live: 'liveApiKey',
					test: 'testApiKey',
				};

				if (
					!payTrackConfig[modeKeyPairing[this.payTrakSettings.mode]]
				) {
					console.error('Invalid Paytrak config');
					toast.error({
						message:
							"We're sorry. There was an error. Please try again later.",
						duration: 3000,
					});
				}

				this.payTrakLib = new PayTrak(payTrackConfig);
			};
			document.head.appendChild(payTrakScript);
		},
		makeCreditCardPayment() {
			try {
				if (!this.validatePaymentFields()) {
					return;
				}

				this.$emit('update:processingState', 'PROCESSING');
				const [month, year] = this.cardData.cardExp.split('/');
				this.payTrakLib.runCharge(
					{
						card: this.cardData.cardNumber.replace(/-/g, ''),
						cvc: this.cardData.cardCVC,
						month: month,
						year: getYear(new Date()).toString().slice(0, 2) + year,
						cardFirstName: this.cardData.cardFirstName,
						cardLastName: this.cardData.cardLastName,
						cardZip: this.cardData.cardZip,
					},
					this.showPaymentConfirmation
				);
			} catch (error) {
				this.$emit('update:processingState', 'FAILED');
				console.error(error);
			} finally {
				this.tokenizeReponse = null;
			}
		},
		logData(type, status, details) {
			api.logMobileActivity({
				institution: this.store.getters.institutionSettings.slug,
				event: type,
				affectedUserID: this.store.getters.user.userID,
				additionalData: {
					email: this.store.getters.user.email,
					activeUser: this.store.getters.selectedUser,
					currentAppVersions:
						this.store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status,
					details,
				},
			});
		},
	},
	computed: {
		cvvOptions() {
			if (this.cardType === 'amex') {
				return {
					placeholder: '####',
					mask: this.cardData.cardCVC ? { mask: '####' } : '',
					validationRegex: this.validationRegexes.amexCVV,
				};
			} else {
				return {
					placeholder: '###',
					mask: this.cardData.cardCVC ? { mask: '###' } : '',
					validationRegex: this.validationRegexes.creditCardCVV,
				};
			}
		},
		cardNumberOptions() {
			if (this.cardType === 'amex') {
				return {
					placeholder: '#### ###### #####',
					mask:
						(this.cardData.cardNumber && {
							mask: '####-######-#####',
						}) ||
						'',
					validationRegex: this.validationRegexes.amexCardNumber,
				};
			} else {
				return {
					placeholder: '#### #### #### ####',
					mask:
						(this.cardData.cardNumber && {
							mask: '####-####-####-####',
						}) ||
						'',
					validationRegex: this.validationRegexes.creditCardNumber,
				};
			}
		},
		isSquareGateway() {
			return this.payTrakSettings.gateway === 'Square';
		},
		isStripeGateway() {
			return this.payTrakSettings.gateway === 'Stripe';
		},
		shouldShowStripeCardElement() {
			if (this.payTrakSettings.gateway !== 'Stripe') return false;
			const payTrakSDKVersion =
				this.payTrakSettings.payTrakSDKVersion || '1.0.7';
			return (
				payTrakSDKVersion.localeCompare('1.1.1', undefined, {
					numeric: true,
				}) >= 0
			);
		},
		expirationDateComputedMask() {
			return this.cardData.cardExp ? { mask: '##/##' } : '';
		},
	},
	watch: {
		'cardData.cardNumber'() {
			if (
				this.cardData.cardNumber.startsWith('34') ||
				this.cardData.cardNumber.startsWith('37')
			) {
				this.cardType = 'amex';
			} else if (this.cardData.cardNumber.startsWith('4')) {
				this.cardType = 'visa';
			} else if (
				this.cardData.cardNumber.startsWith('5') ||
				this.cardData.cardNumber.startsWith('2')
			) {
				this.cardType = 'mastercard';
			} else if (this.cardData.cardNumber.startsWith('6')) {
				this.cardType = 'discover';
			} else {
				this.cardType = '';
			}
		},
		loadPayTrak: {
			handler() {
				if (!this.loadPayTrak) return;
				this.setupPayTrak();
			},
			immediate: true,
		},
		confirmPayment: {
			handler() {
				if (!this.confirmPayment) return;
				const { success, token_or_err, response } =
					this.tokenizeReponse;
				this.makePaymentWithNewCard(success, token_or_err, response);
			},
			immediate: true,
		},
	},
});
</script>

<style lang="scss" scoped>
.payment-form-wrapper {
	height: 100%;
}
.payment-form {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - (50px));
	overflow: scroll;
	gap: 10px;

	&__name-fields {
		display: flex;
		gap: 10px;
	}
}

.payment-form-buttons {
	z-index: 100;
	position: fixed;
	bottom: 10px;
	background-color: var(--ion-color-primary);
	left: 0;
	right: 0;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	height: 50px;
	width: 100%;

	ion-button {
		width: 100%;
		font-size: 13px;
		font-weight: 700;
		line-height: 147%;
		letter-spacing: 0.78px;
		--border-radius: 6px;
	}

	.cancel-button {
		--background: var(--ion-color-primary-lighten-3, #6b7985);
	}
	.confirm-button {
		--background: var(--ion-color-success);
	}
}

#square-form {
	padding-top: 10px;
}

#stripe-form {
	background-color: var(--ion-color-light);
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 15px;
}

.payment-form-item--valid {
	--background: var(--ion-color-light);
	--border-radius: var(--ion-border-radius);
	--border-color: var(--ion-color-primary);
	--border-width: 1px;
	--color: var(--ion-color-primary) > ion-input {
		max-height: 30px;
	}
}

.payment-form-item--invalid {
	--background: var(--ion-color-light);
	--border-radius: var(--ion-border-radius) var(--ion-border-radius) 0 0;
	--border-color: var(--ion-color-danger);
	--border-width: 1px;
	--color: var(--ion-color-primary);
}

.form-item {
	--padding-start: 0;
	--inner-padding-end: 0;

	ion-input {
		--padding-start: 10px;
	}

	&__error {
		background-color: var(--ion-color-danger-lighten-8);
		width: 100%;
		padding: 5px 10px;
		border-radius: 0 0 var(--ion-border-radius) var(--ion-border-radius);
		border: 1px solid var(--ion-color-danger);
	}
}
</style>
