<template>
	<ion-loading v-if="loading" show-backdrop />
	<ion-card
		:class="[
			'skillSet-item',
			isSelected ? 'skillSet-item--selected' : '',
		]"
		@click="selectSkillset"
	>
		<div
			:class="[
				'skillSet-item__details',
				isSelected ? 'skillSet-item__details--selected' : '',
			]"
			v-if="skillSet"
		>
			<div class="skillSet-item__details-title">
				{{ skillSet?.name }}
			</div>
			<div class="skillSet-item__details-skills">
				{{ skillSet.skillsCompleted }} / {{ skillSet?.totalSkills }}
			</div>
		</div>
		<div
			:class="[
				'skillSet-item__progress',
				isSelected ? 'skillSet-item__progress--selected' : '',
			]"
		>
			<ion-progress-bar
				:value="skillSet.percentCompleted"
				buffer="1"
				:class="isCompleted ? 'complete' : 'in-progress'"
			></ion-progress-bar>
		</div>
	</ion-card>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import {
	IonItem,
	IonLoading,
	IonProgressBar,
	menuController,
} from "@ionic/vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "SkillsetSelector",
	emits: ["selectSkillset"],
	components: {
		IonItem,
		IonLoading,
		IonProgressBar,
	},
	props: {
		skillSet: {
			type: Object,
			required: true,
		},
		selectedSkillset: {
			type: String,
			required: true,
		},
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const loading = ref(false);

		const closeMenu = async (menu) => {
			await menuController.close(menu);
		};

		return {
			closeMenu,
			loading,
			router,
			store,
		};
	},
	computed: {
		isCompleted() {
			return (
				this.skillSet?.skillsCompleted ===
				this.skillSet?.totalSkills
			);
		},
		isSelected() {
			return (
				this.skillSet?.skillSetID === this.selectedSkillset
			);
		},
		percentComplete() {
			if (!this.skillsDone) { return 0; }

			return (
				this.skillsDone /
				this.skillSet?.totalSkills
			);
		},
		skillsDone() {
			let skillsDone = 0;

			if (this.skillSettings.allowSkillCompletion) {
				if (this.skillSet?.skillsCompleted === 0) return 0;

				skillsDone = this.skillSet?.skillsCompleted;
			} else {
				if (!this.skillSet?.skills) return 0;

				skillsDone = this.skillSet?.skills.reduce((agg, curr) => {

					if (!curr.attempts) { return agg; }

					if (curr.attempts.some((attempt) => attempt.skillSetID === this.skillSet.id)) {
						agg++;
						return agg;
					}

					return agg;
				}, 0);
			}

			return skillsDone;
		},
		skillSettings() {
			return this.store.getters.skillSettings;
		}
	},
	methods: {
		selectSkillset() {
			this.$emit("selectSkillset", this.skillSet);
		},
	},
});
</script>

<style lang="scss" scoped>
ion-item::part(native) {
	background-color: #ffffff;
}
.skillSet-item {
	align-items: center;
	background-color: #ffffff;
	border-radius: 7px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
		rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: space-between;
	padding: 20px;
	margin: 0;
	min-height: 76px;

	&__details {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		color: var(--admin-mobile-primary, #2b4051);
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.5px;
	}

	&__details-title {
		text-align: left;
	}

	&__details-subtitle {
		text-align: left;
	}

	&__details-skills {
		flex: 0 0 65px;
		text-align: right;
	}
	&__progress {
		width: 100%;
		padding: 0;
		background-color: #ffffff;

		ion-progress-bar {
			-webkit-border-radius: 150px;
			-moz-border-radius: 150px;
			border-radius: 150px;
			height: 9px;

			&.complete {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-success);
			}

			&.in-progress {
				--background: var(--ion-color-primary-lighten-2);
				--progress-background: var(--ion-color-warning);
			}
		}
	}
}

.skillSet-item--selected {
	background-color: var(--ion-color-primary);
}

.skillSet-item__progress--selected {
	background-color: var(--ion-color-primary);
}

.skillSet-item__details--selected {
	color: #ffffff;
}
</style>
