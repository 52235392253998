<template>
	<ion-card class="date-card">
		<ion-card-header class="date-card__title ion-no-padding">
			<ion-card-title>{{ title }}</ion-card-title>
		</ion-card-header>
		<ion-card-content class="date-card__date ion-no-padding">
			<ion-text>{{ date }}</ion-text>
		</ion-card-content>
	</ion-card>
</template>

<script>
export default {
	name: 'Date Card',
	props: {
		date: {
			type: String
		},
		title: {
			type: String
		}
	}
}
</script>

<style lang="scss" scoped>

.date-card {
	align-items: center;
	box-shadow: var(--box-shadow);
	display: flex;
	margin: 0;
	padding: 10px 15px;

	&__title {
		text-align: left;
		width: 40%;

		ion-card-title {
			font-size: 1em;
		}
	}

	&__date {
		text-align: right;
		width: 60%;

		ion-text {
			color: var(--ion-color-dark);
			font-size: 1.75em;
			font-weight: 700;
		}
	}
}

</style>