<template>
	<ion-modal
		canDismiss="false"
		ref="modal"
		:is-open="skillInfoModalOpen"
	>
        <ion-header>
            <div class="info-modal__header">{{ info.title }} Info</div>
        </ion-header>
        <ion-content scroll-y class="info-modal">
            <div class="info-modal__content">
                <p><ion-img :src="imagePath"></ion-img></p>
                <p>{{ info.information.text }}</p>
            </div>
        </ion-content>
        <ion-footer>
            <ion-button
                @click="closeModal"
                class="cancel-button"
            >
                CLOSE
            </ion-button>
        </ion-footer>
    </ion-modal>
</template>

<script>
import { IonModal } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useStore } from "vuex";
import {
	informationCircle
} from 'ionicons/icons';
export default defineComponent({
	components: { IonModal },
	props: {
		info: {
			type: Object,
			default: () => {},
		},
		skillInfoModalOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	setup() {
		const store = useStore();
        const imagePath = '/assets/broken-down-car.svg'

		return {
			store,
            informationCircle,
            imagePath
		}
	},
	methods: {
		closeModal() {
			this.$emit('closeSkillInfoModal');
		}

	},
    computed: {
        information() {
			return this.info;
		},
    }
});
</script>

<style scoped lang="scss">
    .info-modal {
        height: calc(100% - 108px);
        padding: 10px 10px;
        &__header {
            height: 64px;
            background-color: var(--ion-color-primary);
            color: #ffffff;
            z-index: 10;
            padding: 10px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__content {
            padding: 10px;
            p {
                padding: 10px;
                font-weight: 600;
            }
        }
    }

    ion-footer {
        display: flex;
        justify-content: center;
    }
</style>