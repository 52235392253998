<template>
	<ion-page>
		<ion-loading v-if="loading" show-backdrop />
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start" v-if="store.state.showSettingsData?.type !== 'appointmentHistory'">
					<ion-button @click="goBack()">
						<ion-icon color="light" :ios="arrowBack" :md="arrowBack"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title color="light">Appointment History</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="hideSettingsModal()">
						<ion-icon color="light" :md="close" :ios="close"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<template v-if="(store.getters.students.length > 1) && isParent">
				<ion-select
					@ionChange="updateAppointmentHistoryUser($event.detail.value)"
					:interface-options="{ header: 'Select Student' }"
					placeholder="Select a student"
					:value="selectedUser"
				>
					<ion-select-option v-for="student in store.getters.students" :key="student.userID" :value="student.userID">{{student.firstName}} {{student.lastName}}</ion-select-option>
				</ion-select>
			</template>
			<ion-list class="ion-padding" lines="none" v-if="!loading">
				<template v-if="appointmentHistories.length">
					<time-slot-item
						type="history"
						:studentID="selectedUser"
						:timeSlot="timeSlotVM"
						v-for="{timeSlotVM, timestamp} in appointmentHistories"
						:key="timestamp"
					/>
				</template>
				<div class="ion-margin ion-text-center" v-else>
					<ion-icon class="ion-margin-bottom" :icon="calendarClearOutline" size="large"></ion-icon>
					<p class="ion-margin-bottom">You haven't scheduled<br />any appointments yet.</p>
					<p>Once you have scheduled appointments they will appear here.</p>
				</div>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {
	alertController,
	IonContent,
	IonHeader,
	IonIcon,
	IonTitle,
	IonToolbar,
	IonLoading
} from '@ionic/vue';
import {
	alertCircle,
	arrowBack,
	calendarClearOutline,
	chevronBackOutline,
	chevronForwardOutline,
	close,
	person
} from 'ionicons/icons';
import { computed, defineComponent, ref } from "vue";
import { useQuery } from '@vue/apollo-composable';
import { APPOINTMENT_HISTORY_QUERY, timeSlotSubscription } from '@/core/api/schedulerApi';
import TimeSlotItem from "@/view/components/TimeslotItem.vue";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { toast } from '@/core/toast/Toast';
import { createInstanceVM, createInstanceVMs } from '@/view/pages/scheduler/createTimeSlotVMs';

export default defineComponent({
	components: {
		IonContent,
		IonHeader,
		IonIcon,
		IonTitle,
		IonToolbar,
		IonLoading,
		'time-slot-item': TimeSlotItem
	},
	name: "Appointment History",
	props: [
		"modalNav"
	],
	setup(props) {
		// Data
		const isOpenRef = ref(false);
		const data = { content: 'New Content' };
		const router = useRouter();
		const store = useStore();

		const appointmentHistoryVariables = ref({
			userIDs: [store.getters.selectedUser]
		});

		const appointmentHistoryQuery = useQuery(
				APPOINTMENT_HISTORY_QUERY,
				() => ({
				...appointmentHistoryVariables.value
				}),
				{
					fetchPolicy: 'no-cache'
				}
			);
		appointmentHistoryQuery.onError(error => {
			console.error(error);

			toast.error({
				message: 'Unfortunately the calendar information cannot be retrieved at this time',
				duration: 7500,
				position: 'top'
			});
		});

		const timeSlotSub = timeSlotSubscription({institution: store.getters.user.institutions[0]});
		timeSlotSub.onResult(result => {
			appointmentHistoryQuery.refetch();
		});

		// Methods
		const setOpen = (state) => (isOpenRef.value = state);

		const goBack = () => {
			const nav = props.modalNav.value;
			nav.pop();
		}

		return {
			alertCircle,
			arrowBack,
			calendarClearOutline,
			chevronBackOutline,
			chevronForwardOutline,
			close,
			data,
			goBack,
			isOpenRef,
			person,
			router,
			setOpen,
			store,
			appointmentHistoryVariables,
			appointmentHistoryQuery,
			timeSlotSub
		};
	},
	data() {
		return {
			user: {
				students: []
			}
		};
	},
	computed: {
		isParent() {
			return this.store.getters.user.roles?.some((role) => role === 'parent');
		},
		selectedUser() {
			return this.store.getters.selectedUser;
		},
		loading() {
			return this?.appointmentHistoryQuery?.loading?.value;
		},
		appointmentHistories() {
			return (this.appointmentHistoryQuery.result?.value?.appointmentHistory || []).flatMap((item) => {
				const {
					sessions,
					date,
					user,
					timestamp,
					...rest
				} = item;

				return createInstanceVM({
					instances: [{
						sessions,
						date,
						groupedSessions: [], // For a seldom used compute in the VM,
					}],
					...rest
				}, this.store.getters.selectedUser).map((timeSlotVM) => ({
					timeSlotVM,
					timestamp
				}));
			}).sort((a,b) => b.timestamp - a.timestamp);
		}
	},
	methods: {
		async showSelectStudentAlert() {
			const user = this.user;
			let alertInputs = [];
			for (let i = 0; i < user.students.length; i++) {
				alertInputs.push({
					type: 'radio',
					label: user.students[i].firstName + ' ' + user.students[i].lastName,
					value: user.students[i].userID,
					checked: (i === 0) ? true : false
				});
			}

			const alert = await alertController.create({
				backdropDismiss: false,
				buttons: [
					{
						text: 'Select',
						handler: async (value) => {
							this.updateAppointmentHistoryUser(value)
						}
					}
				],
				cssClass: 'ion-text-center',
				header: 'Select Student',
				inputs: alertInputs
			});
			await alert.present();
		},
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		},
		updateAppointmentHistoryUser(userID) {
			const studentsArray = [
				...this.store.getters.students
			];
			let selectedStudent = studentsArray.find(s => s.userID === userID);
			this.appointmentHistoryVariables.userIDs = [selectedStudent.userID];
			this.store.commit('setSelectedUser', userID);
		}
	},
	async ionViewWillEnter() {
		this.user = this.store.getters.user;

		if(this.isParent && !this.store.getters.selectedUser && this.user.students.length) {
			if(this.user.students.length > 1) {
				this.showSelectStudentAlert();
			} else {
				this.updateAppointmentHistoryUser(this.user.students[0].userID);
			}
		}
		// restart query when in view
		this.timeSlotSub.start();
		this.appointmentHistoryQuery.refetch();
	},
	ionViewWillLeave() {
		this.timeSlotSub.stop();
	}
});
</script>

<style scoped lang="scss">
.vc-container {
	margin: 0 auto;
	max-width:80%;
	width: 100%;
}

ion-header {
	ion-toolbar {
		background-color: var(--ion-color-primary);
		border: 0;
	}
}

.title-default {
	&__sub-title {
		font-size: 0.75em;
	}
}
</style>