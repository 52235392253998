<template>
    <div class="section-header"><span>{{format(parseISO(session[0].date), 'EEEE, MMMM do')}}</span></div>
    <div
        class="session"
        v-bind:key="index"
        v-for="(sesh, index) in session"
    >
        <div
            :class="['session-item', (sesh?.note) ? 'session-item__note' : 'session-item__no-note' ]"
        >
            <div class="session-item__details">
                <div>
                    <p class="session-item__row" style="font-weight: 700;">{{format(parseISO(sesh.date), 'EEEE, MMMM do')}} &nbsp;&nbsp; {{ formatTime(sesh.sessionDetails.sessionStart) }} - {{ formatTime(sesh.sessionDetails.sessionEnd) }}</p>
                    <p class="session-item__row">{{ sesh.sessionDetails?.customPickupLocation?.name || sesh?.location?.name }}</p>
                    <p class="session-item__row">{{ sesh.instructor.firstName }} {{ sesh.instructor.lastName }}</p>
                </div>
                <MaterialDesignIcon size="large" icon="gaugeLow" color="black" />
            </div>
            <div class="session-item__notes" v-if="sesh?.note">
                <!-- TODO: review the notes - they currently don't have a timestamp -->
                <!-- <p class="session-item__notebook-edit"><MaterialDesignIcon icon="notebookEdit" /><span>{{format(sesh?.sessionDetails.timestamp, 'MMM d, yyyy - h:mmaaa')}}</span></p> -->
                <p>{{sesh?.note}}</p>
            </div>
        </div>
    </div>

</template>

<script>
import { formatTime } from "@/core/util/helpers";
import { format, parseISO } from 'date-fns';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
export default {
	components: { MaterialDesignIcon },
    props: {
		session: {
			default: [],
			type: Array
		},
        student: {
            default: {},
			type: Object
        }
    },
	setup() {
		return {}
	},
	computed: {

    },
	methods: {
        formatTime,
        format,
        parseISO
    },
	data() {
		return {}
	}
};
</script>

<style scoped lang="scss">
    .section-header {
        position: relative;
        display: flex;
        justify-content: flex-start;
        gap: 15px;
        align-self: stretch;
        font-weight: 700;

        span {
            background-color: var(--ion-color-secondary, #EFF0F2);
            z-index: 1;
            position: relative;
            padding-right: 15px;
        }

        &::after {
            position: absolute;
            height: 1px;
            top: 50%;
            content: "";
            width: 100%;
            background-color: var(--ion-color-primary-lighten-3, #6B7985);
            z-index: 0;
        }
    }

    .session {
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
    }

    .session-item {
        width: 100%;
        background-color: #FFFFFF;

        &__details {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            padding: 10px;
        }


        &__note {
            border-radius: 4px 4px 0 0;
            width: 100%;
        }

        &__row {
            padding: 5px;
        }

        &__no-note {
            width: 100%;
            border-radius: 4px;
        }

            &__notes {
                display: flex;
                padding: 10px;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                align-self: stretch;
                background-color: #FFEEDD;
                border-bottom: 3px solid #FFAB53;
            }
    }

</style>