<template>
	<li class="session-notes-row">
		<div class="session-notes-row__container">
			<div class="session-notes-row__metadata">
				<div class="session-notes-row__date-instructor">
					<div class="session-notes-row__date">
						<MaterialDesignIcon
							icon="calendar"
							color="primary"
						/>
						<p>{{ format(parseISO(session.date), "MMM dd, yyyy") }}</p>
					</div>
					<div class="session-notes-row__instructor">
						<MaterialDesignIcon
							icon="account"
							color="primary"
						/>
						<p>{{ session.instructor.firstName }} {{ session.instructor.lastName }}</p>
					</div>
				</div>
			</div>
			<div v-if="session?.sessionDetails?.internalNote !== ''">
				<div class="session-notes-row__note-type">
					<ion-chip disabled class="chip--internalNote">Internal Note</ion-chip>
				</div>
				<div class="session-notes-row__note">
					<p> {{ session.sessionDetails.internalNote }}</p>
				</div>
			</div>
			<div v-if="session?.sessionDetails?.note !== ''">
				<div class="session-notes-row__note-type">
					<ion-chip disabled class="chip--externalNote">Parent/Student</ion-chip>
				</div>
				<div class="session-notes-row__note">
					<p> {{ session.sessionDetails.note }}</p>
				</div>
			</div>
		</div>
	</li>
</template>

<script>
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";
import { IonChip } from "@ionic/vue";
import { format, parseISO } from "date-fns";

export default {
	name: "SessionNotesRow",
	components: {
		MaterialDesignIcon,
		IonChip,
	},
	props: {
		session: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {};
	},
	computed: {},
	methods: {
		format,
		parseISO
	},
};
</script>

<style lang="scss" scoped>
.session-notes-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--ion-color-primary-lighten-3);

	&__container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 10px;
		width: 100%;
	}

	&__metadata {
		display: flex;
		flex-direction: row;
		gap: 10px;
		width: 100%;
		justify-content: space-between;
	}

	&__date-instructor {
		display: flex;
		flex-direction: column;
	}

	&__note-type ion-chip {
		margin-left: 0;
	}

	&__date {
		display: flex;
		align-content: center;
		gap: 10px;
		text-align: center;
	}

	&__instructor {
		display: flex;
		align-content: center;
		gap: 10px;
		text-align: center;
	}

	&__note {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		font-weight: normal;
	}
}

.chip--internalNote,
.chip--externalNote {
	justify-content: start;
}

.chip--internalNote {
	color: white;
	font-size: 12px;
	opacity: 1;
	background-color: var(--ion-color-danger);
	max-height: 18px;
}

.chip--externalNote {
	color: white;
	font-size: 12px;
	opacity: 1;
	background-color: var(--ion-color-tertiary);
	max-height: 18px;
}

.session-notes-row:last-child {
	border-bottom: none;
}
</style>
