<template>
	<ion-page>
		<ion-content class="ion-padding minimum-version-error" :fullscreen="true">
			<ion-grid class="ion-text-center">
				<ion-row class="ion-align-items-center ion-justify-content-center">
					<ion-col>
						<div class="minimum-version-error__container">
							<stop-sign />
							<p class="minimum-version-error__message">{{ message }}</p>
							<ion-button
								v-if="showUpdateButton"
								:href="store.getters.appUpdateURL"
								color="tertiary"
							>
								UPDATE
							</ion-button>
							<ion-button
								v-else
								:href="store.getters.appUpdateURL"
								color="tertiary"
								@click="store.dispatch('logOut')"
							>
								LOGOUT
							</ion-button>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>
	</ion-page>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { IonModal, IonNav } from "@ionic/vue";
import { useStore } from "vuex";
import StopSign from '../components/graphics/StopSign.vue';

export default defineComponent({
	components: {
		IonModal,
		IonNav,
		StopSign
	},
	setup() {
		const store = useStore();

		const hasMinimumVersion = computed(() => store.getters.hasMinimumVersion);
		const isCompatibleBrowser = computed(() => store.getters.isCompatibleBrowser);

		const message = computed(() => {
			if (!hasMinimumVersion.value) {
				return "You will not be able to continue to use the DriveTrak App until you update the app to the most recent version.";
			} else if (!isCompatibleBrowser.value) {
				return "Your browser is out of date! Please update your browser in order to use the DriveTrak App.";
			}
		});

		const showUpdateButton = computed(() => {
			return !hasMinimumVersion.value;
		});

		return {
			store,
			message,
			showUpdateButton
		}
	}
});
</script>

<style scoped lang="scss">
.minimum-version-error {
	ion-grid {
		height: 100%;

		ion-row {
			height: 100%;
		}
	}

	&__container {
		margin: 0 auto;
		max-width: 400px;

		* {
			margin-bottom: 30px;
		}

		p {
			font-size: 16px;
			font-weight: 700px;
			line-height: 1.5em;
		}
	}

	&__message {
		font-size: 1.25em;
		font-weight: 700;
	}
}
</style>