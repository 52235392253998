<template>
	<ion-page>
		<ion-alert
			:is-open="swapOpenRef"
			:header="swapAlertMessage"
			:buttons="swapAlertButtons"
			cssClass="instructor-alert"
			@didDismiss="setOpen(false, 'swap')"
		></ion-alert>
		<ion-alert
			:is-open="backwardsNavOpenRef"
			header="Back To Calendar"
			subHeader="Going back to the calendar will stop your current session and any progress of the current session and will not be saved. Are you sure you want to go back?"
			cssClass="backwards-nav instructor-alert"
			:buttons="backwardsNavAlertButtons"
			@didDismiss="setOpen(false, 'backwards')"
		></ion-alert>
		<ion-alert
			:is-open="inTheFutureOpenRef"
			header="Future Session"
			subHeader="The session you are about to start is in the future. Are you sure you would like to start this session?"
			cssClass="instructor-alert"
			:buttons="inTheFutureAlertButtons"
			@didDismiss="setOpen(false, 'inTheFuture')"
		></ion-alert>
		<InstructorModal
			v-if="instructorModalVisible"
			:showInstructorModal="instructorModalVisible"
			:data="this.studentToAdd"
			@dismissInstructorModal="dismissInstructorModal"
			@confirmStudentAdd="confirmStudentAdd"
		/>
		<InstructorHeader />
		<ion-content fullscreen scrollY="true" class="session">
			<div class="session-headers">
				<div class="session__header">
					<span class="session__toolbar">
						<div class="session__toolbar-button" @click="backwardsNav">
							<ion-button
								class="date-select"
								color="instructor-header"
								icon
								slot="start"
								style="--box-shadow: none;"
							>
								<MaterialDesignIcon icon="chevronLeft" color="white" />
							</ion-button>
						</div>
						<div class="session__toolbar-text">
							<MaterialDesignIcon icon="calendarMonth" color="white" />
							<span v-if="!loading">{{ selectedDate }}</span>
							<IonSkeletonText :animated="true" style="height: 16px; margin-left: 15px; width: 180px;" v-else />
						</div>
						<div class="session__toolbar-right"></div>
					</span>
				</div>
				<div class="session__completed-bar" v-if="sessionCompleted">
					<MaterialDesignIcon color="light" icon="checkCircle" />
					<span>Completed</span>
				</div>
				<ion-header :class="[
					'session__session-info',
					(sessionCompleted) ? 'session__session-info--completed' : ''
				]">
					<SessionCard
						:oLocation="(selectedSession?.customPickupLocation?.name) ? selectedSession?.customPickupLocation : selectedInstructorTimeSlotData?.location"
						:loading="loading"
						:sessionStart="selectedSession?.sessionStart"
						:sessionEnd="selectedSession?.sessionEnd"
						:oVehicle="selectedInstructorTimeSlotData?.vehicle"
						v-if="selectedSession"
					/>
				</ion-header>
			</div>
			<ion-progress-bar type="indeterminate" v-if="loading" style="--background: var(--ion-color-secondary);" />
			<div class="session__notes" v-if="selectedInstructorTimeSlotData && selectedInstructorTimeSlotData.note">
				<!-- TODO: need to review dates on timeSlot notes; we currently don't support this -->
				<!-- <p class="session__notebook-edit"><MaterialDesignIcon icon="notebookEdit" /><span>{{ selectedInstructorTimeSlotData }}</span></p> -->
				<p>{{ selectedInstructorTimeSlotData.note }}</p>
			</div>
			<div class="session__main-panel">
				<Heading
					class="ion-margin-bottom"
					mdiIcon="mdiSpeedometer"
					:noTopMargin="true"
					title="Driving"
				/>

				<SessionStudentCard
					:loading="loading"
					:session="session"
					:sessionID="session.sessionID"
					:sessionState="sessionState"
					:skillSets="institutionSkillSets"
					:studentInstructorAppData="session.studentInstructorAppData"
					@statusUpdate="updateSessionStatus"
					@studentSwap="swapStudent"
					:totalSkills="totalSkills"
					@openSessionSkillModal="selectTab"
					:userID="session.userID"
					v-bind:key="session.userID + session.sessionID"
					v-for="session in driveSessions"
					v-if="driveSessions.length && !loading"
				/>
				<SessionStudentCardSkeletonVue class="ion-margin-bottom" v-if="loading" />
				<AddStudentModal
					:session="driveSessionAvailable"
					:sessionType="'driver'"
					@addStudent="addStudent"
					v-if="sessionState === 'start' && driveSessionAvailable"
				/>

				<Heading
					class="ion-margin-bottom"
					:icon="eye"
					:title="'Observing'"
					v-if="observeSessions.length"
				/>

				<SessionStudentCard
					:loading="loading"
					:session="session"
					:sessionID="session.sessionID"
					:sessionState="sessionState"
					:skillSets="institutionSkillSets"
					:studentInstructorAppData="session.studentInstructorAppData"
					@statusUpdate="updateSessionStatus"
					@openSessionSkillModal="selectTab"
					@studentSwap="swapStudent"
					style="margin-bottom: 15px;"
					:totalSkills="totalSkills"
					:userID="session.userID"
					v-bind:key="session.userID + session.sessionID"
					v-for="session in observeSessions"
					v-if="observeSessions.length && !loading"
				/>
				<SessionStudentCardSkeletonVue style="margin-bottom: 15px;" v-if="loading" />
				<AddStudentModal
					:session="observeSessionAvailable"
					:sessionType="'observer'"
					@addStudent="addStudent"
					v-if="sessionState === 'start' && observeSessionAvailable"
				/>
			</div>

			<div
				class="session__session-details"
				v-if="!loading"
			>
				<ion-item fill="outline" v-if="!sessionCompleted">
					<ion-label position="floating">{{ (sessionState === 'start') ? 'Starting' : 'Ending' }} Mileage</ion-label>
					<ion-input
						placeholder="0"
						required="true"
						@ionInput="numericInputOnly($event)"
						v-model="mileage"
					/>
				</ion-item>

				<ion-item
					:class="[(sessionCompleted) ? 'disabled-input' : '']"
					fill="outline"
					style="margin-top: 15px;"
					v-if="!loading && !sessionCompleted && sessionState === 'completeSession'"
				>
					<ion-label position="floating">Internal - Admin Note</ion-label>
					<ion-textarea
						autoGrow
						v-model="internalSessionNote"
					/>
				</ion-item>
			</div>
			<div
				class="session__session-details-external-note"
				v-if="!loading && !sessionCompleted && sessionState === 'completeSession'"
			>
				<div class="external-note">
					<div class="external-note__line-one">
						<MaterialDesignIcon class="external-note-icon" icon="alertOctagon" color="primary" />
						<p> External Note</p>
					</div>
					<div class="external-note__line-two">
						<p>Parent/Student Will See This</p>
					</div>
				</div>
				<ion-item
					fill="outline"
					style="margin-top: 10px;"
				>
					<ion-textarea
						v-model="externalSessionNote"
						autoGrow
					/>
				</ion-item>
			</div>
			<div class="ion-padding ion-text-center" v-if="!loading && !selectedSession">
				<ion-icon class="ion-margin-vertical" color="primary" :icon="informationCircle" size="large" slot="start" />
				<p class="ion-margin-bottom">Session Information Not Found</p>
				<ion-button @click="router.push('/instructor/home')">
					<ion-icon slot="start" :ios="arrowBack" :md="arrowBack" />
					Go Back
				</ion-button>
			</div>
		</ion-content>
		<ion-footer v-if="!loading && !sessionCompleted">
			<div class="session__start">
				<ion-button color="success" @click="startCompleteSessionPassThrough" :disabled="driveSessions.length === 0 || submissionInFlight">
					<ion-spinner v-if="submissionInFlight" name="circular"></ion-spinner>
					<span v-else>
						<template v-if="driveSessions.length === 0">DRIVER REQUIRED TO BEGIN</template>
						<template v-else-if="sessionState !== 'completeSession'">START SESSION</template>
						<template v-else>COMPLETE SESSION</template>
					</span>
				</ion-button>
			</div>
		</ion-footer>
		<!-- TODO: work on the animation for this modal close; The default leaveAnimation was causing a flash of the modal to 100% of the vh. -->
		<!-- Must include breakpoint 0 if modal can be swiped to close. -->
		<ion-modal
			animated="false"
			class="ion-modal-session-skill"
			:handle="false"
			:is-open="showSessionSkillsModal"
			@willDismiss="showSessionSkillsModal = false"
		>
			<ion-content class="modal-bottom">
				<div class="sessions">
					<div class="driving-card">
						<button
							class="driving-card__student-details-swap"
							v-if="selectedSession.sessionType === 'observe'"
							@click="swapStudent"
						>
							<MaterialDesignIcon icon="swapHorizontal" color="white" />
						</button>
						<div class="driving-card__student-details-info">
							<h3>
								{{ selectedSession.studentInstructorAppData.user.lastName }}
							</h3>
							<p>{{ selectedSession.studentInstructorAppData.user.firstName }}</p>
						</div>
						<div class="driving-card__student-details">
							<div class="driving-card__student-details-drives">
								<p>
									<span>{{ selectedSession.studentInstructorAppData.studentCourse.driveHoursCompleted }}</span>
									<MaterialDesignIcon icon="gauge" color="black" />
								</p>
								<p>
									<span>{{ selectedSession.studentInstructorAppData.studentCourse.observationHoursCompleted}}</span>
									<MaterialDesignIcon icon="eye" color="black" />
								</p>
							</div>
							<ContactUserModal :user="selectedSession?.studentInstructorAppData?.user" />
						</div>
					</div>
					<div class="tabs">
						<div
							:class="[
								'tab',
								activeTab === 'sessions' ? 'tab-selected' : 'tab-unselected',
							]"
							@click="selectTab('sessions')"
						>
							<MaterialDesignIcon
								icon="calendarMonth"
								:color="activeTab === 'sessions' ? 'black' : 'white'"
							/><span>Sessions</span>
						</div>
						<!-- <div
							:class="[
								'tab',
								activeTab === 'skills' ? 'tab-selected' : 'tab-unselected',
							]"
							@click="selectTab('skills')"
						>
							<MaterialDesignIcon
								icon="listStatus"
								:color="activeTab === 'skills' ? 'black' : 'white'"
							/><span>Skills</span>
						</div> -->
					</div>
					<div class="session-content">
						<StudentSessionCard
							:session="session"
							v-if="showSessionsPage"
							:student="selectedSession.studentInstructorAppData.user"
							v-bind:key="index"
							v-for="(session, index) in sessionHistory"
						/>
						<div
							class="session-content__no-data"
							v-if="showSessionsPage && !sessionHistory?.length"
						>
							<ion-icon
								color="primary"
								:icon="informationCircle"
								size="small"
								slot="start"
							></ion-icon>
							<p>No Data</p>
						</div>
						<SkillCard
							:skillItem="skillItem"
							v-if="showSkillsPage"
							v-bind:key="index"
							v-for="(skillItem, index) in skillHistory"
						/>
						<div
							class="session-content__no-data"
							v-if="showSkillsPage && !skillHistory?.length"
						>
							<ion-icon
								color="primary"
								:icon="informationCircle"
								size="small"
								slot="start"
							></ion-icon>
							<p>No Data</p>
						</div>
					</div>
				</div>
			</ion-content>
		</ion-modal>
	</ion-page>
</template>

<script>
import AddStudentModal from "@/view/components/modals/AddStudentModal.vue";
import InstructorHeader from "../../components/instructor/InstructorHeader.component.vue";
import SessionStudentCard from "@/view/components/cards/SessionStudentCard.vue"
import InstructorModal from "./InstructorModal.vue";
import SessionStudentCardSkeletonVue from "@/view/components/skeletons/SessionStudentCardSkeleton.vue";
import StudentSessionCard from "./StudentSessionCard.vue";
import SkillCard from "@/view/components/cards/SkillCard.vue";

import { toast } from '../../../core/toast/Toast';
import { format, parseISO, subDays } from "date-fns";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { arrowBack, eye, location, time, informationCircle } from "ionicons/icons";
import {
	IonModal,
	IonAlert,
	IonInput,
	IonButton,
	IonIcon,
	IonContent,
	IonHeader,
	IonMenu,
	IonMenuToggle,
	IonPage,
	IonSearchbar,
	IonSkeletonText,
	IonSpinner,
	IonTitle,
	IonToolbar,
	IonFooter,
} from "@ionic/vue";
import MaterialDesignIcon from "@/view/components/MaterialDesignIcon.vue";
import { isPopulated } from "@/core/util/helpers";
import {
	readFragment,
	GET_INSTITUTION_SKILL_SETS_QUERY,
	ADD_USER_TO_SESSION_MUTATION,
	GET_INSTRUCTOR_TIME_SLOTS_QUERY,
} from "@/core/api/drivingApi";
import { useLazyQuery, useMutation, useQuery } from "@vue/apollo-composable";
import { Network } from "@capacitor/network";
import { Preferences } from "@capacitor/preferences";
import Heading from '@/view/components/Heading.vue';
import SessionCard from "@/view/components/cards/SessionCard.vue";
import { sessionProximityAndStatus } from "./helpers/sessionProximityAndStatus";
import omitDeep from "omit-deep-lodash";
import { v4 as uuidv4 } from 'uuid';
import ContactUserModal from "@/view/components/modals/ContactUserModal.vue";
import { faro } from "@grafana/faro-web-sdk";


export default {
	emits: ["openSessionSkillModal"],
	components: {
		AddStudentModal,
		Heading,
		IonModal,
		IonAlert,
		InstructorHeader,
		SessionCard,
		SessionStudentCard,
		InstructorModal,
		StudentSessionCard,
		SkillCard,
		IonInput,
		IonButton,
		IonIcon,
		IonContent,
		IonHeader,
		IonMenu,
		IonMenuToggle,
		IonPage,
		IonSearchbar,
		IonSkeletonText,
		IonSpinner,
		IonTitle,
		IonToolbar,
		IonFooter,
		MaterialDesignIcon,
		SessionStudentCardSkeletonVue,
		ContactUserModal
	},
	setup() {
		const backwardsNavOpenRef = ref(false);
		const inTheFutureOpenRef = ref(false);
		const swapOpenRef = ref(false);
		const setOpen = (state, type) =>
			type === "swap"
				? (swapOpenRef.value = state)
				: type === "inTheFuture"
					? (inTheFutureOpenRef.value = state)
						: (backwardsNavOpenRef.value = state);

		const store = useStore();
		const router = useRouter();
		const storageToCacheSet = ref(false);
		const timeSlotsQueryEnabled = ref(true);
		const institutionTZ = store.getters.institutionSettings?.timeZone ?? 'America/Chicago';

		const timeSlotQueryEnabled = computed(() => {
			return !!store.getters.activeInstructor?.userID
				&& !!timeSlotsQueryEnabled.value
				&& !!store.getters.isAuthenticated
		})

		const timeSlotsQuery = useLazyQuery(
			GET_INSTRUCTOR_TIME_SLOTS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
				instructorID: store.getters.activeInstructor?.userID,
				date: store.getters.activeSession.instanceDate,
			}),
			{
				fetchPolicy: 'cache-first',
				enabled: timeSlotQueryEnabled.value
			}
		);
		timeSlotsQuery.onResult(() => {
			if (!storageToCacheSet.value) {
				store.dispatch('applyActiveSessionStorageToCache', {
					sessionID: activeSession.sessionID
				});
				storageToCacheSet.value = true;
			}
		})

		const activeSession = store.getters.activeSession;
		const sessionState = computed(() => store.getters.sessionState);


		const switchUserToDrivingSessionVariables = ref({});

		const addUserToSessionMutation = useMutation(
			ADD_USER_TO_SESSION_MUTATION,
			() => ({
				onError: (e) => {
					console.error(e);
				},
			})
		);

		// Not used, but run to cache the data as early as possible
		const institutionSkillSetsQuery = useQuery(
			GET_INSTITUTION_SKILL_SETS_QUERY,
			() => ({
				institutionSlug: store.getters.user.institutions[0],
			}),
			{
				fetchPolicy: (store.getters.connectionStatus.connected) ? 'cache-and-network' : 'cache-only',
				// 5 minutes
				pollInterval: 300000,
				enabled: computed(() => {
					return !!store.getters.isAuthenticated
				}),
			}
		);
		const institutionSkillSets = computed(() => {
			return institutionSkillSetsQuery.result?.value?.skillSets || null;
		});

		const timeSlot = computed(() => {
			if (timeSlotsQuery.result.value?.timeSlots) {
				return timeSlotsQuery.result.value?.timeSlots?.find(
					(timeSlot) => timeSlot?.instances?.find((inst) => inst.id === store.getters.activeSession.instanceID)
				);
			} else {
				return store.getters.instructorHomeTimeSlots?.find((timeSlot) => timeSlot?.instances?.find((inst) => inst.id === store.getters.activeSession.instanceID));
			}
		});
		const savedSessionNote = computed(() => {

			if (!timeSlot.value) { return ''; }

			// Set notes after receiving our timeslot.
			const timeSlotByInstanceID = timeSlot.value;
			const selectedInstance = timeSlotByInstanceID.instances?.find((inst) => inst.id === store.getters.activeSession.instanceID);
			const selectedSession = selectedInstance.sessions?.find((sesh) => sesh.sessionID === store.getters.activeSession.sessionID);

			return selectedSession.note ?? '';
		});

		return {
			activeSession,
			addUserToSessionMutation,
			arrowBack,
			backwardsNavOpenRef,
			inTheFutureOpenRef,
			eye,
			informationCircle,
			institutionSkillSets,
			location,
			router,
			savedSessionNote,
			sessionState,
			setOpen,
			store,
			swapOpenRef,
			switchUserToDrivingSessionVariables,
			time,
			timeSlot,
			timeSlotsQuery,
			timeSlotsQueryEnabled,
			institutionTZ
		};
	},
	async ionViewWillEnter() {
		this.timeSlotsQueryEnabled = true;

		const prefStartingMileageStorage = await Preferences.get({ key: 'startingMileage' });
		this.mileage = (prefStartingMileageStorage?.value || (this.store.getters.instructorHomeTimeSlots?.find((timeSlot) => timeSlot?.instances?.find((inst) => inst.id === this.store.getters.activeSession.instanceID)))?.vehicle?.currentMileage) || null;

		const prefActiveSession = await Preferences.get({ key: 'activeSession' });
		const sessionState = await Preferences.get({ key: 'sessionState' });

		if (prefActiveSession?.value) {
			const activeSession = JSON.parse(prefActiveSession.value);
			this.store.dispatch('setActiveSession', activeSession);
		}

		this.store.commit('setSessionState', sessionState.value);

		this.timeSlotsQuery.load();
	},
	ionViewWillLeave() {
		this.timeSlotsQueryEnabled = false;
	},
	computed: {
		loading() {
			return this.timeSlotsQuery.loading.value || this.swapping || this.addingStudent;
		},
		sessionCompleted() {
			return this.sessionProximityAndStatusCalculated?.sessionGroupStatus === 'ALL_PROCESSED';
		},
		selectedSession() {
			this.swapping;
			this.addingStudent;

			const selectedSession = this.selectedSessionGroup?.find(s => s.sessionID === this.store.getters.activeSession.sessionID);
			return selectedSession;
		},
		selectedDate() {
			if (!this.selectedInstance || !this.selectedInstance.date) { return ''; }
			return this.format(parseISO(this.selectedInstance.date), "EEEE, MMMM do");
		},
		selectedInstance() {
			if (!this.timeSlot || !this.timeSlot.instances || this.timeSlot.instances.length === 0) { return {}; }
			const timeSlotInstance = this.timeSlot.instances.find((inst) => inst.id === this.store.getters.activeSession.instanceID);
			return timeSlotInstance;
		},
		selectedSessionGroup() {
			// TODO: Figure out better reactivity.
			this.statusUpdate;

			if (!this.selectedInstance?.timeGroupedSessions?.length ||
				!this.store.getters.activeSession.sessionID) {
				return null;
			}

			let sessionGroup = this.selectedInstance.timeGroupedSessions.find((seshGroup) => {
				return seshGroup?.sessions?.find((sesh) => sesh.sessionID === this.store.getters.activeSession.sessionID);
			});

			return sessionGroup?.sessions;
		},
		sessionHistory() {
			const groupedSessions =
				this.selectedSession.studentInstructorAppData.completedDriveSessions.reduce(
					(accumulator, currentValue) => {
						accumulator[currentValue.date] = [
							...(accumulator[currentValue.date] || []),
							currentValue,
						];
						return accumulator;
					},
					{}
				);

			const sortedGroupedSessions = Object.keys(groupedSessions).map((key) => {
				return groupedSessions[key].sort((a, b) => {
					return new Date(a.sessionDetails.sessionStart).getTime() - new Date(b.sessionDetails.sessionStart).getTime();
				});
			});

			sortedGroupedSessions.sort((a, b) => {
				return b[0].date.localeCompare(a[0].date);
			});

			return sortedGroupedSessions;
		},
		selectedInstructorTimeSlotData() {
			this.swapping;
			this.addingStudent;

			// const foundTimeSlotFragment = readFragment(
			// 	`TimeSlot:{"timeSlotID":"${this.store.getters.selectedInstructorDrivingIDs.timeSlotID}"}`
			// );

			if (!this.timeSlot) { return {}; }
			return this.timeSlot;
		},
		observeSessionAvailable() {
			if (!this.selectedSessionGroup) { return {} }
			return this.selectedSessionGroup.find(
				(session) => session.sessionType === "observe" && !session.userID
			);
		},
		driveSessionAvailable() {
			if (!this.selectedSessionGroup) { return {} }
			return this.selectedSessionGroup.find(
				(session) => session.sessionType === "drive" && !session.userID
			);
		},
		filteredSessions() {
			if (!this.selectedSessionGroup || !this.selectedSessionGroup.length) { return []; }
			return this.selectedSessionGroup.filter(
				(session) => session.userID
			);
		},
		driveSessions() {
			return this.filteredSessions.filter((s) => s.sessionType === 'drive');
		},
		observeSessions() {
			return this.filteredSessions.filter((s) => s.sessionType === 'observe');
		},
		sessionProximityAndStatusCalculated() {
			if (!this.selectedInstance?.date || !this.selectedSessionGroup) { return null; }
			return sessionProximityAndStatus({
				timeSlotData: {date: this.selectedInstance?.date},
				sessions: this.selectedSessionGroup
			}, this.institutionTZ);
		},
		skillHistory() {
			// TODO: All skills from all skill set progress -  best to worst, worst to best? (user preference?)
			const skillsWithAttemptSuccessesAndFailures =
				this.selectedSession.studentInstructorAppData.studentSkillProgress.reduce(
					(accumulator, currentValue) => {
						if (!accumulator[currentValue.id]) {
							const oSkill = readFragment(`Skill:${currentValue.id}`);
							accumulator[currentValue.id] = {
								name: oSkill.name,
								successes: 0,
								failures: 0,
							};
						}

						accumulator[currentValue.id].completedDate ??=
							currentValue.completedDate;
						accumulator[currentValue.id].completedBy ??=
							currentValue.completedBy;
						accumulator[currentValue.id].successes +=
							currentValue.attempts.filter((attempt) => attempt.success).length;
						accumulator[currentValue.id].failures +=
							currentValue.attempts.filter(
								(attempt) => !attempt.success
							).length;

						return accumulator;
					},
					{}
				);

			const skillHistory =
				Object.keys(skillsWithAttemptSuccessesAndFailures)
					.map((key) => {
						return {
							name: skillsWithAttemptSuccessesAndFailures[key].name,
							successes: skillsWithAttemptSuccessesAndFailures[key].successes,
							failures: skillsWithAttemptSuccessesAndFailures[key].failures,
							completedDate:
								skillsWithAttemptSuccessesAndFailures[key].completedDate,
							completedBy:
								skillsWithAttemptSuccessesAndFailures[key].completedBy,
						};
					})
					.sort((a, b) => {
						// sort by worst ratio of failures to successes
						const aRatio = a.failures / a.successes;
						const bRatio = b.failures / b.successes;
						return aRatio < bRatio ? -1 : aRatio > bRatio ? 1 : 0;
					}) ?? [];

			return skillHistory;
		},
		totalSkills() {
			if (!this.institutionSkillSets) { return 0; }

			return this.institutionSkillSets.reduce((agg, curr) => {

				agg += curr.skills.length;

				return agg;
			}, 0);
		}
	},
	methods: {
		uuidv4,
		format,
		readFragment,
		isPopulated,
		sessionProximityAndStatus,
		numericInputOnly(ev) {
			const value = ev.target.value.replace(/[^0-9]+/g, '');
			this.mileage = value;
			ev.target.value = value;
		},
		getExpectedTimeSlotData() {
			const timeSlotDataFromCache = readFragment(`TimeSlot:{"timeSlotID":"${this.store.getters.activeSession.timeSlotID}"}`);
			const timeSlotWithoutTypename = omitDeep(timeSlotDataFromCache, '__typename');
			return {
				startTime: timeSlotDataFromCache.startTime,
				endTime: timeSlotDataFromCache.endTime,
				sessionDefinitions: timeSlotWithoutTypename.sessionDefinitions,
				instances: timeSlotWithoutTypename.instances?.map(inst => {
					return {
						id: inst.id,
						date: inst.date,
						sessions: inst.sessions?.map(session => {
							return {
								sessionID: session.sessionID,
								sessionType: session.sessionType,
								sessionStart: session.sessionStart,
								sessionEnd: session.sessionEnd,
								status: session.status || 'SCHEDULED',
								userID: session.userID,
								studentCourseID: session.studentCourseID,
								note: session.note,
								internalNote: session.internalNote,
								customPickupLocation: session.customPickupLocation?.locationID
							}
						}),
						status: inst.status
					}
				})
			}
		},
		swapStudent(swapIncomingData) {
			this.swapAlertMessage = `Switch ${swapIncomingData.studentInstructorAppData.user.firstName} ${swapIncomingData.studentInstructorAppData.user.lastName} to Driving?`;
			this.swapIncomingData = swapIncomingData;
			this.setOpen(true, "swap");
		},
		addStudent(student, type) {
			this.instructorModalVisible = true;
			this.studentToAdd = student;
			this.studentToAddType = type;
		},
		backwardsNav() {
			if (this.sessionState === 'start') {
				Preferences.remove({ key: 'activeSession' });
				this.store.dispatch('setSessionState', 'default');
				this.router.replace('/instructor/home');
				return;
			}
			if (this.sessionState === 'completeSession') {
				this.store.dispatch('setSessionState', 'inProgress');
				this.$router.replace(`/instructor/session/inProgress`);
				return;
			}
			this.setOpen(true, "backwards");
		},
		async swapStudentRequest() {
			this.setOpen(false, 'swap');
			// Trigger swapping flag to get the UI to update
			this.swapping = true;

			this.switchUserToDrivingSessionVariables = {
				instanceID: this.store.getters.activeSession.instanceID,
				switchToDriveData: {
					userID: this.swapIncomingData.studentInstructorAppData.user.userID,
					studentCourseID:
						this.swapIncomingData.studentInstructorAppData.studentCourse
							.studentCourseID,
					currentSessionID: this.swapIncomingData.sessionID,
				},
				existingDriveData: {
					userID: this.selectedSession.userID,
					studentCourseID: this.selectedSession.studentCourseID,
					currentSessionID: this.selectedSession.sessionID,
				},
				expectedTimeSlot: this.getExpectedTimeSlotData(),
			};
			try {
				const connectionStatus = await Network.getStatus();

				this.store.dispatch('addSyncData', {
					localId: this.swapIncomingData.sessionID,
					status: 'pending',
					lastUpdate: Date.now(),
					orderTimestamp: Date.now(),
					dataType: 'switchUserToDrivingSession',
					rawData: this.switchUserToDrivingSessionVariables,
				});

				if (connectionStatus.connected) {
					await this.store.dispatch('runNetworkRequests');
				} else {
					await this.store.dispatch('adjustCacheWithSyncData');
				}

				// reset active session
				const currentActiveSession = this.store.getters.activeSession;
				const newActiveSession = {
					...currentActiveSession,
					driverUserID: this.switchUserToDrivingSessionVariables.switchToDriveData.userID,
					driverStudentCourseID: this.switchUserToDrivingSessionVariables.switchToDriveData.studentCourseID,
					sessionID: this.switchUserToDrivingSessionVariables.existingDriveData.currentSessionID,
				};
				this.store.dispatch('setActiveSession', newActiveSession);
			} catch (error) {
				console.error("error", error);
				let errorMessage = 'Something went wrong. Please try again.'
				if (error.message === 'Must be connected to the internet to swap students.') {
					errorMessage = error.message;
				}
				toast.error({
					message: errorMessage,
					duration: 7500
				});
			}
			this.swapping = false;
		},
		async startCompleteSessionPassThrough() {
			if (
				this.sessionProximityAndStatusCalculated?.sessionRelationToNow.dateComparedToSession === "IN_THE_FUTURE"
				&& this.sessionState !== 'completeSession'
			) {
				this.setOpen(true, "inTheFuture");
			} else {
				this.startCompleteSession();
			}
		},
		async startCompleteSession() {
			try {
				if (this.sessionState !== 'completeSession') {
					await this.store.dispatch('captureInitialCacheState', {sessionID: this.selectedSession.sessionID});

					this.store.dispatch('logFaroEvent', {name: 'startingMileage', data: this.mileage});
					Preferences.set({ key: 'startingMileage', value: this.mileage });

					this.store.dispatch('setSessionState', 'inProgress');
					this.$router.push(`/instructor/session/inProgress`);
				} else {
					const connectionStatus = await Network.getStatus();

					this.submissionInFlight = true;
					const studentInstructorAppDataCacheID = `StudentInstructorAppData:{"id":"${this.selectedSession.studentCourseID}"}`;
					const currentStudentInstructorAppData = readFragment(studentInstructorAppDataCacheID);
					const { studentSkillProgress } = currentStudentInstructorAppData;

					if (studentSkillProgress?.length) {
						const modifiedFromStorage = await Preferences.get({ key: 'modifiedSkillProgress' });
						const modified = modifiedFromStorage.value ? JSON.parse(modifiedFromStorage.value) : {};

						for (const studentSkillProgressItem of studentSkillProgress) {
							const studentSkillProgressItemCacheID = `SkillProgress:{"id":"${studentSkillProgressItem.id}","studentCourseID":"${studentSkillProgressItem.studentCourseID}"}`;
							if (modified[studentSkillProgressItem.studentCourseID]?.includes(studentSkillProgressItemCacheID)) {
								await this.store.dispatch(
									'addSyncData',
									{
										localId: studentSkillProgressItem.id,
										status: 'queued',
										lastUpdate: Date.now(),
										orderTimestamp: Date.now(),
										dataType: 'skillProgress',
										rawData: studentSkillProgressItem,
									}
								)
							}
						}

						await Preferences.remove({ key: 'modifiedSkillProgress' });
					}

					const sessionsToUpdate = [];
					for (const session of this.selectedSessionGroup) {
						const updatedSession = await Preferences.get({ key: `session::${session.sessionID}` });
						const sessionFromStorageOrCurrentData = updatedSession.value ? JSON.parse(updatedSession.value) : session;

						const {__typename, studentInstructorAppData, customPickupLocation, ...sessionData} = sessionFromStorageOrCurrentData;

							// If this is the drive session add the session note.
							if (sessionData.sessionType === 'drive') {
								sessionData.note = this.externalSessionNote;
								sessionData.internalNote = this.internalSessionNote;
							}

						if (sessionData.status) {
							if (sessionData.status === 'SCHEDULED') {
								sessionData.status = 'COMPLETED';
							}
							sessionsToUpdate.push(sessionData);

						}
					}
					await this.store.dispatch('addSyncData', {
						localId: this.store.getters.activeSession.instanceID,
						status: 'queued',
						lastUpdate: Date.now(),
						orderTimestamp: Date.now(),
						dataType: 'updateAppointments',
						rawData: {
							sessions: sessionsToUpdate,
							instanceID: this.store.getters.activeSession.instanceID,
							institution: this.store.getters.user.institutions[0],
						}
					});

					const vehicleHistoryId = this.uuidv4();
					const startingMileage = await Preferences.get({ key: 'startingMileage' });

					this.store.dispatch('logFaroEvent', {name: 'endingMileage', data: this.mileage});

					await this.store.dispatch('addSyncData', {
						localId: vehicleHistoryId,
						status: 'queued',
						lastUpdate: Date.now(),
						orderTimestamp: Date.now(),
						dataType: 'vehicleHistory',
						rawData: {
							vehicleHistory: {
								id: vehicleHistoryId,
								institution: this.store.getters.user.institutions[0],
								sessionID: this.selectedSession.sessionID,
								instanceID: this.store.getters.activeSession.instanceID,
								vehicleID: this.timeSlot.vehicle.vehicleID,
								startingMileage: Number(startingMileage?.value?.replace('[^0-9]', '') || 0),
								endingMileage: Number(this.mileage?.replace('[^0-9]', '') || 0),
								date: Date.now(),
							}
						}
					});

					if (connectionStatus.connected) {
						await this.store.dispatch('runNetworkRequests');
					} else {
						await this.store.dispatch('adjustCacheWithSyncData');
					}

					await this.store.dispatch('clearLocalProgressBySessionID', {
						sessionID: this.selectedSession.sessionID
					});

					Preferences.remove({ key: 'activeSession' });
					Preferences.remove({ key: 'sessionState' });
					Preferences.remove({ key: 'startingMileage' });
					Preferences.remove({ key: 'modifiedSkillProgress' });
					Preferences.remove({ key: 'addedSkillAttempts'});


					this.router.replace("/instructor/home");

					this.submissionInFlight = false;
				}
			} catch (error) {
				console.error("error", error);
				this.submissionInFlight = false;
			}
		},
		closeConfirmModal() {},
		dismissInstructorModal() {
			this.instructorModalVisible = false;
		},
		async confirmStudentAdd(student) {
			try {
				this.instructorModalVisible = false;
				this.addingStudent = true;

				const connectionStatus = await Network.getStatus();

				const addUserToSessionVariables = {
					instanceID: this.store.getters.activeSession.instanceID,
					addToSessionData: {
						userID: student.userID,
						studentCourseID: student.studentCourseID,
						currentSessionID: this.studentToAddType === 'driver' ? this.driveSessionAvailable.sessionID : this.observeSessionAvailable.sessionID,
					},
					expectedTimeSlot: this.getExpectedTimeSlotData(),
				}

				if (!connectionStatus.connected) {
					await this.store.dispatch('addSyncData', {
						localId: addUserToSessionVariables.addToSessionData.currentSessionID,
						status: 'pending',
						lastUpdate: Date.now(),
						orderTimestamp: Date.now(),
						dataType: 'addUserToSession',
						rawData: addUserToSessionVariables,
					});
					await this.store.dispatch('adjustCacheWithSyncData');
				} else {
					await this.addUserToSessionMutation.mutate(addUserToSessionVariables);
				}
				if (this.studentToAddType === 'driver') {
					const currentActive = this.store.getters.activeSession;
					const updatedActiveSession = {
						...currentActive,
						driverUserID: student.userID,
						driverStudentCourseID: student.studentCourseID
					}
					this.store.dispatch('setActiveSession', updatedActiveSession);
				}

				this.addingStudent = false;
				this.studentToAddType = '';
			} catch (error) {
				this.addingStudent = false;
				this.studentToAddType = '';
				console.error("error", error);
			}
		},
		selectTab(type) {
			this.showSessionSkillsModal = true;
			if (type === "sessions") {
				this.activeTab = "sessions";
				this.showSessionsPage = true;
				this.showSkillsPage = false;
			} else {
				this.activeTab = "skills";
				this.showSkillsPage = true;
				this.showSessionsPage = false;
			}
		},
		updateSessionStatus(data) {
			this.statusUpdate = true;

			// TODO: Review this during overall session edit and submission effort.
			let targetSession = this.selectedSessionGroup.find((session) => session.sessionID === data.sessionID);

			if (targetSession) {
				let sessionToStore = {...targetSession};
				sessionToStore.status = data.sessionStatus;

				Preferences.set({
					key: 'session::' + data.sessionID,
					value: JSON.stringify(sessionToStore)
				});
			}

			this.statusUpdate = false;
		}
	},
	watch: {
		'timeSlot'() {
			if (!this.mileage) {
				this.mileage = this.timeSlot?.vehicle?.currentMileage || null;
			}
		}
	},
	data() {
		return {
			mileage: 0,
			internalSessionNote: '',
			externalSessionNote: '',
			submissionInFlight: false,
			statusUpdate: false,
			showSessionSkillsModal: false,
			showSessionsPage: true,
			showSkillsPage: false,
			activeTab: "",
			searchTerm: "",
			instructorModalVisible: false,
			studentToAdd: {},
			studentToAddType: "",
			confirmModalOpen: false,
			swapAlertMessage: "",
			backwardsNavAlertMessage: "",
			swapping: false,
			addingStudent: false,
			swapAlertButtons: [
				{
					text: "CANCEL",
					role: "cancel",
					cssClass: "alert-button-cancel",
				},
				{
					text: "SWITCH",
					role: "switch",
					cssClass: "alert-button-confirm",
					handler: this.swapStudentRequest,
				},
			],
			backwardsNavAlertButtons: [
				{
					text: "NO",
					role: "no",
					cssClass: "alert-button-no",
				},
				{
					text: "YES",
					role: "yes",
					cssClass: "alert-button-confirm",
					handler: async () => {
						await this.store.dispatch('revertCacheState', {sessionID: this.selectedSession.sessionID});
						await this.store.dispatch('clearInitialCacheState', {sessionID: this.selectedSession.sessionID});
						await this.store.dispatch('clearActiveSession');
						this.router.push("/instructor/home");
					},
				},
			],
			inTheFutureAlertButtons: [
			{
					text: "CANCEL",
					role: "no",
					cssClass: "alert-button-no",
				},
				{
					text: "START",
					role: "yes",
					cssClass: "alert-button-confirm",
					handler: this.startCompleteSession,
				},
			]
		};
	}
};
</script>

<style scoped lang="scss">
.session {
	&__session-headers {
		padding-top: 50px;
	}

	&__header {
		padding-top: 2px;
		margin-bottom: 0px;
		background: var(--ion-color-primary-tint);
	}

	&__toolbar {
		background: var(--ion-color-primary);
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		align-self: stretch;
		color: #ffffff;

		ion-button {
			--padding-start:0;
			--padding-end:0;
			margin: 0;
		}

		& > ion-button::part(native) {
			box-shadow: none;
			border-radius: none;
		}
	}

	&__toolbar-button {
		height: 44px;
		width: 44px;
		align-items: center;
		background-color: var(--ion-color-primary-tint);
		cursor: pointer;
	}

	&__toolbar-text {
		display: flex;
		flex-direction: row;
		align-items: center;

		> span {
			padding-left: 5px;
		}
	}

	&__toolbar-right {
		width: 44px;
		height: 44px;
	}

	&__completed-bar {
		align-items: center;
		background: var(--ion-color-success);
		color: var(--ion-color-light);
		display: flex;
		font-size: 16px;
		font-weight: 700;
		gap: 10px;
		padding: 10px 10px 10px 10px;
	}

	&__session-info {

		&--completed {
			border-left: 5px solid var(--ion-color-success);
		}
	}

	&__details {
		display: flex;
		flex-direction: column;
		margin-top: 0px;
		background-color: var(--ion-color-primary-lighten-2);
		color: #ffffff;
		list-style: none;
		margin: 0;
		padding: 10px;

		li {
			display: flex;
			align-items: flex-start;

			> span {
				padding-top: 3px;
			}
		}

		.material-design-icon {
			height: 25px;
			width: 25px;
		}
	}

	&__details-location {
		display: flex;
		flex-direction: row;
	}
	&__details-location-text {
		display: flex;
		flex-direction: column;
	}

	&__session-info-location-text {
		display: flex;
		flex-direction: column;
	}

	&__details-info {
		ion-icon {
			padding-right: 5px;
		}
	}

	&__notes {
		display: flex;
		padding: 10px;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
		align-self: stretch;
		background-color: #ffeedd;
		border-bottom: 3px solid #ffab53;
	}

	&__notebook-edit {
		display: flex;
		align-items: center;
		justify-content: left;
		text-align: top;
		font-weight: 700;

		& span {
			padding-left: 5px;
		}
	}

	&__main-panel {
		padding: 15px;
		padding-bottom: 15px;
	}

	&__session-details {
		background-color: var(--ion-color-light);
		padding: 15px;
		& > ion-item::part(native) {
			background-color: #ffffff;
		}

		& > .mileage-label {
			--background: white;
		}

		ion-item {
			--ion-item-background: #ffffff;

			ion-input {
				font-size: 18px;
				margin-top: 10px;
				--padding-bottom: 0;
				--padding-top: 0;
			}

			&.disabled-input ion-label {
				color: var(--ion-color-grey-darken-5);
				opacity: 1;
			}
		}
	}

	&__session-details-external-note {
		background-color: var(--ion-color-danger-lighten-8);
		padding: 15px;
		border-top: 1px solid var(--ion-color-danger);

		.external-note {
			font-weight: bold;

			&__line-one {
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 5px;
				gap: 5px;

				.external-note-icon {
					width: 16px;
					height: 16px;
				}
			}

			&__line-two {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				font-size: 12px;
			}
		}
	}
}

.tabs {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.tab {
	font-weight: 700;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 10px;
	text-align: center;
	cursor: pointer;

	span {
		padding-left: 5px;
	}
}
.tab-selected {
	border-top: 3px solid var(--ion-color-primary, #2b4051);
}

.tab-unselected {
	color: #ffffff;
	background: var(--ion-color-primary, #2b4051);
}


.session-content {
	overflow-y: scroll;
	height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	border-radius: 6px 6px 0px 0px;
	background: var(--ion-color-secondary, #eff0f2);
	padding: 15px;

	&__no-data {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 5px;
		color: var(--ion-color-primary, #2b4051);
		font-weight: 700;
	}
}

ion-footer {
	background-color: #ffffff;
	ion-button {
		height: 50px;
		--border-radius: 0;
		width: 100%;
		margin: 0;
	}
}

.session__details .material-design-icon {
	padding-right: 10px;

	ion-skeleton-text {
		--background: var(--ion-color-primary-lighten-5);
	}
}

.date-select {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-bottom {
	--background: var(--ion-color-secondary);
	ion-searchbar {
		padding: 0;
	}

	.student-search {
		--box-shadow: none;
		--background: #ffffff;
	}

	ion-item {
		border-radius: 7px;
		box-shadow: var(--box-shadow);
	}
}

.student-select {
	flex: 0 0 75%;
	margin: 10px;
}

.driving-card {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
	background-color: #ffffff;
	padding: 10px;
	justify-content: space-between;
	border-radius: 4px 4px 0 0;
	gap: 10px;

	h3 {
		margin: 0;
		font-size: 16px;
		font-weight: 700;
	}

	.material-design-icon {
		width: 16px;
		height: 16px;
	}

	&__student-details {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__student-details-info {
		display: flex;
		flex-direction: column;
		width: 100%;

		> * {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 5px;
		}

		& .material-design-icon {
			margin-top: 2px;
		}

		> p {
			font-size: 12px;
		}
	}

	&__student-details-drives {
		display: flex;
		flex-direction: column;

		> * {
			display: flex;
			flex-direction: row;
			gap: 5px;
		}

		> p {
			display: flex;
			align-items: center;
		}
	}

	&__student-details-phone {
		display: flex;
		width: 45px;
		height: 45px;
		align-items: center;
		justify-content: center;
		background-color: var(--ion-color-primary-tint);
		border-radius: 150px;

		& .material-design-icon {
			width: 30px;
			height: 30px;
		}
	}
}

ion-modal.ion-modal-session-skill {
	padding-top: 170px;

	.session-content {
		height: calc(100vh - 282px);
	}
}
</style>