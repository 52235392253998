<template>
	<div class="skill-card-skeleton">
		<div>
			<IonSkeletonText :animated="true" style="height: 18px; width: 150px;" />
		</div>
	</div>
</template>

<script>
import { IonSkeletonText } from "@ionic/vue";

export default {
	components: {
		IonSkeletonText
	},
	name: 'SkillCardSkeleton'
}
</script>

<style lang="scss">
.skill-card-skeleton {
	align-items: center;
	background: var(--ion-color-light);
	border-radius: 6px;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow: hidden;
	margin-bottom: 15px;
	padding: 15px 10px;
	width: 100%;

	ion-skeleton-text {
		border-radius: 0;
	}
}
</style>