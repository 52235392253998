// import store from '@/store';
import {store} from '../../core/store/store';

export type NavParam = {
	nav: string;
}

export type HelpParam = {
	help: 'open' | 'close';
}


export function handleQuerySearch(query: string): void{
	const searchParams = new URLSearchParams(query);
	const result: Record<string, string> = {};

	for (const [key, value] of searchParams.entries()) {
		result[key] = value;
	}

	if (result.nav) {
		handleNavParams(result as NavParam);
	}
	if (result.help) {
		handleHelpParams(result as HelpParam);
	}
}


function handleNavParams(result: NavParam): void {
	switch (result.nav) {
		case 'userDetails':
			store.commit('showSettingsModal', {
				type: 'userDetails'
			});
			break;
		case 'billing':
			store.commit('showSettingsModal', {
				type: 'billing'
			});
			break;
		case 'appointmentHistory':
			store.commit('showSettingsModal', {
				type: 'appointmentHistory'
			});
			break;
		default:
			store.commit('showSettingsModal');
			break;
	}
}

function handleHelpParams(result: HelpParam): void {
	switch (result.help) {
		case 'open':
			store.commit('showHelpModal');
			break;
		case 'close':
			store.commit('hideHelpModal');
			break;
	}
}