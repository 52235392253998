<template>
	<div :class="[
		'progress-message',
		(color) ? 'progress-message--' + color : ''
		]"
		v-if="!isButton"
	>
		<div class="inner-message">
			<slot name="icon" v-if="isCustomIcon"></slot>
			<ion-icon class="ion-margin-bottom" color="white" :icon="icon" size="large" v-else></ion-icon>
			<p>{{ message }}</p>
		</div>
		<slot></slot>
	</div>
	<ion-button 
		:class="[
			'progress-button',
			(color) ? 'progress-button--' + color : '',
			'ion-text-wrap'
		]"
		style="text-transform: none;"
		expand="full"
		:color="color"
		v-if="isButton"
		@click="promptExtension(type)"
	>
		<ion-icon slot="start" class="ion-margin-bottom" :icon="icon" size="large" style="padding-right: 15px;"></ion-icon>
		{{ message }}
		<ion-icon slot="end" v-if="type" class="ion-margin-bottom" :icon="chevronForwardOutline" style="padding-left: 15px;"></ion-icon>
	</ion-button>
</template>

<script>
import {
	alertController,
	IonCard,
	IonIcon,
} from "@ionic/vue";
import {
	alarm,
	chevronForwardOutline
} from "ionicons/icons";
import { useStore } from "vuex";

export default {
	name: 'ProgressMessage',
	components: {
		alertController,
		IonCard,
		IonIcon
	},
	method: {
		moreInfo(type){
			this.promptExtension(type);
		}
	},
	computed: {
	},
	props: {
		color: {
			default: '',
			type: String
		},
		icon: {
			type: String
		},
		isCustomIcon: {
			type: Boolean,
			default: false
		},
		message: {
			type: String
		},
		isButton: {
			type: Boolean,
			default: false
		},
		type: {
			type: String
		},
	},
	setup() {
		const store = useStore();

		const promptExtension = async (type) => {
			switch(type) {
				case 'eLearningCountdownWarning':
					const alert = await alertController.create({
						backdropDismiss: true,
						buttons: [
							{
								text: 'Close',
								handler: () => {
									alert.dismiss()
								}
							}
						],
						cssClass: 'eLearning-extension-dialog',
						message: `<p>Your driving school allows a limited amount of time to complete eLearning.  If not finished by that date, the course will be locked and an extension fee will be required to continue.</p><p>If you have any questions please contact ${store.getters.institutionSettings.name}.<p>`
					});
					await alert.present();
					break;
			}
		}
		return {
			alarm,
			chevronForwardOutline,
			promptExtension
		}
	}
}
</script>

<style lang="scss">
	.progress-message {
		align-items: center;
		background-color: red;
		column-gap: 15px;
		row-gap: 15px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		font-weight: 700;
		padding: 15px;
		z-index: 100;
		@media(max-width: 1000px) {
			justify-content: center;
		}

		.inner-message {
			display: flex;
			column-gap: 15px;
			align-items: center;

			ion-icon, svg {
				flex: 0 0 30px;
				height: 30px;
				margin: 0;
				width: 30px;
			}
		}


		&--success {
			background-color: var(--ion-color-success);
			color: #FFFFFF;
		}

		&--warning {
			background-color: var(--ion-color-warning);
			color: #FFFFFF;
		}

		&--danger {
			background-color: var(--ion-color-danger);
			color: var(--ion-color-secondary);
		}
	}

	.eLearning-extension-dialog {
		.alert-wrapper {
			background-color: #405362;
			border-radius: 6px;

			.alert-message {
				color: white;
				font-weight: 700;
				padding-bottom: 5px;

				& > p {
					margin-bottom: 15px;

				}

				& > div {
					padding-top: 10px;
					padding-bottom: 10px;
					border-top: var(--ion-color-medium) solid 1px;

					& > span:nth-child(2) {
						float: right;
					}
				}
			}

			.alert-head {
				padding-top: 0px;
			}

			.alert-button-group {
				padding: 0 24px 24px;
				flex-wrap: nowrap;
				gap: 10px;

				button {
					border-radius: 6px;
					box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
					color: white;
					display: flex;
					flex-wrap: nowrap;
					width: 100%;

					.alert-button-inner {
						justify-content: center !important;
					}

					&:nth-child(1) {
						background-color: var(--ion-color-primary-lighten-3);
						margin-right: auto;
					}

					&:nth-child(2) {
						background-color: var(--ion-color-success);
						margin-right: 0px;
					}
				}
			}
		}
	}

	.progress-button{
		font-size: medium;
		font-weight: 700;
		z-index: 100;
		margin: 0;
		height: 56px;
		@media(max-width: 1000px) {
			justify-content: center;
		}

		ion-icon, svg {
			flex: 0 0 30px;
			height: 30px;
			margin: 0;
			width: 30px;
			color: white;
		}	
	}
</style>