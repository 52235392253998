<template>
	<div :class="['resource', resourceClass(resource.content.type)]">
		<img
			class="image-resource"
			:src="resource.content.image"
			v-if="resource.content.type === 'IMAGE'"
		/>
		<video-player
			:allowVideoPlay="allowVideoPlay"
			:videoPreviouslyComplete="resource.completed"
			:videoSource="resource.content.video"
			:isCompleted="isResourceComplete"
			:resourceID="resource.resourceID"
			@loaded="videoRequired=true"
			@error="logActivity"
			@videoComplete="setResourceComplete(resource.resourceID)"
			v-else-if="resource.content.type === 'VIDEO'"
		></video-player>
		<div v-else-if="resource.content.type === 'TEXT'" v-html="resource.content.text"></div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import VideoPlayer from '@/view/components/VideoPlayer.vue';
import { useStore } from "vuex";
import { Preferences } from '@capacitor/preferences';
import { api } from "@/core/api/api";
import envConfig from "@/core/config/env.config";

export default defineComponent({
	name: "resource",
	props: {
		allowVideoPlay: {
			default: true,
			type: Boolean
		},
		lessonID: {
			required: true,
			type: String
		},
		resource: {
			required: true,
			type: Object
		},
		topicID: {
			required: true,
			type: String
		}
	},
	components: {
		VideoPlayer
	},
	emits: {
		cannotAdvance: null,
		canAdvance: null,
		resourceCompleted: null
	},
	data() {
		return {
			videoRequired: !this.allVideosCompleted,
			videoFinished: false
		}
	},
	setup() {
		const store = useStore();

		return {
			store
		}
	},
	methods: {
		resourceClass(type) {
			let className = 'resource--';
			switch(type) {
				case 'VIDEO':
					return className + 'video';
				case 'BREAK':
					return className + 'break';
				case 'TEXT':
					return className + 'text';
				case 'IMAGE':
					return className + 'image';
				default:
					return '';
			}
		},
		async setResourceComplete(resourceID){
			await this.$store.dispatch('submitClassroomProgress', [{
				type: 'RESOURCE',
				lessonID: this.lessonID,
				itemProgressed: resourceID
			}]).then(() => {
				Preferences.remove({key: `video-progress-${resourceID}`});
			});
		},
		logActivity(errorData) {
			api.logMobileActivity({
				institution: this.$store.getters.institutionSettings.slug,
				event: 'Video Error',
				affectedUserID: this.$store.getters.user.userID,
				additionalData:{
					email: this.$store.getters.user.email,
					activeUser: this.$store.getters.selectedUser,
					currentAppVersions: this.$store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Video Player'],
					status: 'Failure',
					details: errorData
				}
			});
		}
	},
	computed: {
		isResourceComplete() {
			const clp = this.$store.getters.selectedClassroomProgress
			if (!clp) return false;

			const lessonProgress = clp.lessons?.find((lp) => lp.lessonID === this.lessonID);
			if (!lessonProgress) return false;

			return !!lessonProgress.progressItems.find((pi) => (pi.type === 'RESOURCE' && pi.reference === this.resource.resourceID) || (pi.type === 'RESOURCES' && pi.reference === this.topicID));
		}
	}
});
</script>

<style scoped lang="scss">
.resource {
	font-size: 1em;
	line-height: 1.5em;
	max-width: 680px;
	margin: 0 auto 30px auto;
	width: 100%;

	@media(min-width: 800px) {
		font-size: 1.25em;
	}

	&--video {

	}

	&--break {
		display: none;
	}

	&--image {
		padding: 0 30px;

		img {
			height: auto;
			width: 100%;
		}
	}

	&--text {
		padding: 0 30px;
	}
}
</style>