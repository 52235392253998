<template>
	<ion-modal :is-open="show" ref="skill-summary-dialog">
		<ion-header color="primary">
			<ion-toolbar>
				<ion-title>{{ skill.name }}</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding">
			{{ skill.description }}
			<ion-button
				@click="$emit('closeModal')"
				expand="full"
				slot="fixed"
			>
				Close
			</ion-button>
		</ion-content>
	</ion-modal>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
	components: {

	},
	data() {
		return {
		}
	},
	methods: {
	},
	props: {
		show: {
			default: false,
			type: Boolean
		},
		skill: {
			default: {},
			type: Object
		}
	}
})
</script>

<style lang="scss" scoped>

ion-toolbar {
	--background: var(--ion-color-primary);
	border: none;
	--color: #FFFFFF;
}

ion-content {
	height: calc(100% - 117px);
	padding-bottom: 10px;
}

ion-button[slot='fixed'] {
	--background: var(--ion-color-primary-tint);
	bottom: -61px;
	--color: #FFFFFF;
	height: 60px;
	left: 0;
	margin: 0;
	width: 100%;
}

</style>