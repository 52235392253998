<template>
	<div :class="[
		'notification-card',
		(notification.type === 'sessionCompletion') ? 'notification-card--white' : '',
		(notification.type === 'warning') ? 'notification-card--warning' : '',
		(notification.type === 'danger') ? 'notification-card--danger' : ''
	]">
		<template v-if="notification.type !== 'sessionCompletion'">
			<div class="notification-card__details">
				<MaterialDesignIcon
					color="dark"
					icon="alert"
				/>
				<div>
					<h2 class="notification-card__title">{{ notification.title }}</h2>
					<p class="notification-card__timestamp" v-if="notification.startDate">{{ formatInTimeZone(notification.startDate, institutionTZ, 'EEEE, MMMM d, yyyy') }}</p>
				</div>
			</div>
			<div class="notification-card__message" v-html="notification.message"></div>
		</template>

		<template v-else>
			<div class="notification-card__details notification-card__details--session-completion">
				<MaterialDesignIcon
					color="dark"
					icon="checkCircle"
				/>
				<div>
					<h2 class="notification-card__title">Session Completion</h2>
					<p class="notification-card__timestamp" v-if="notification.startDate">{{ formatInTimeZone(notification.startDate, '', 'EEEE, MMMM d, yyyy') }}</p>
				</div>
			</div>
			<div class="icon-details">
				<div class="icon-details__item">
					<MaterialDesignIcon
						color="dark"
						icon="account"
						slot="start"
					/>
					<p class="icon-details__item-text" v-if="notification.instructor.firstName">
						{{ notification.instructor.firstName }} {{ (notification.instructor.lastName) ? notification.instructor.lastName : '' }}
					</p>
				</div>
				<div class="icon-details__item" v-if="notification?.sessionData?.internalNote">
					<MaterialDesignIcon
						color="dark"
						icon="messageText"
						slot="start"
					/>
					<p class="icon-details__item-text">{{ notification.sessionData.internalNote }}</p>
				</div>
			</div>
			<IonAccordionGroup class="skills-reviewed" v-if="notification.attempts.length">
				<IonAccordion :toggle-icon="caretDownSharp" toggle-icon-slot="end" value="first">
					<IonItem
						class="skills-reviewed__heading"
						color="light"
						lines="none"
						slot="header"
					>
						<IonLabel>Skills Reviewed</IonLabel>
					</IonItem>
					<div class="skills-reviewed__content ion-padding" slot="content">
						<div class="skills-reviewed__skill" v-for="(attempt) of notification.attempts" :key="attempt.id">
							<h3>{{ skillName(attempt.skillID) }}</h3>
							<div class="icon-details">
								<div class="icon-details__item" v-for="feedbackItem of attempt.feedbackItems">
									<MaterialDesignIcon
										color="dark"
										icon="messageText"
										slot="start"
									/>
									<p class="icon-details__item-text">{{ feedbackItem.feedbackMessage }}</p>
								</div>
								<div class="icon-details__item" v-if="attempt.note">
									<MaterialDesignIcon
										color="dark"
										icon="messageText"
										slot="start"
									/>
									<p class="icon-details__item-text">{{ attempt.note }}</p>
								</div>
							</div>
						</div>
					</div>
				</IonAccordion>
			</IonAccordionGroup>
		</template>
	</div>
</template>

<script>
import {
	IonAccordionGroup,
	IonAccordion,
	IonItem,
	IonLabel
} from "@ionic/vue";
import { caretDownSharp } from "ionicons/icons";
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useStore } from 'vuex';

export default {
	components: {
		IonAccordionGroup,
		IonAccordion,
		IonItem,
		IonLabel,
		MaterialDesignIcon
	},
	computed: {
		institutionTZ() {
			return this.store.getters.institutionSettings?.timeZone ?? 'America/Chicago';
		}
	},
	data() {
		return {

		}
	},
	methods: {
		fromUnixTime,
		formatInTimeZone,
		skillName(skillID) {
			if(!skillID || !this.skills) { return 'Name cannot be found.'; }

			const skill = this.skills.find((skill) => {
				return skill.id === skillID;
			});

			return (skill) ? skill.name : '';
		}
	},
	props: {
		notification: {
			required: true,
			type: Object
		},
		skills: {
			type: Array
		}
	},
	setup() {
		const store = useStore();

		return {
			caretDownSharp,
			store,
		};
	},
};
</script>

<style lang="scss" scoped>
.notification-card {
	background-color: var(--ion-color-tertiary-lighten-8);
	border-bottom: 1px solid var(--ion-color-tertiary);
	color: var(--ion-text-color);
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 20px;
	z-index: 1;
	font-family: var(--ion-font-family, inherit);

	&__details {
		align-items: center;
		display: flex;
		gap: 10px;

		svg {
			height: 20px;
			width: 20px;
		}
	}

		&__title,
		&__timestamp {
			font-size: 0.9em;
		}

			&__title {
				font-weight: 700;
				margin: 0;
			}

		/**
		*	Icon - Details
		**/
		.icon-details {
			display: flex;
			flex-direction: column;
			gap: 5px;
			padding-left: 2px;

			&__item {
				color: var(--ion-color-primary);
				display: flex;
				gap: 10px;

				svg {
					height: 20px;
					width: 20px;
				}

				ion-label {
					margin: 0;
				}
			}

			&__item-text {
				width: 100%;
			}
		}

		/**
		*	Skill Summary Heading
		**/
		.skills-reviewed {
			&__heading {
				&::part(native) {
					background: var(--ion-color-primary-tint);
					color: var(--ion-color-light);
					font-weight: 700;
				}

				ion-label {
					margin: 0;
				}
			}

			&__content {
				h3 {
					background-color: var(--ion-color-primary-lighten-3);
					color: #FFFFFF;
					font-size: 1em;
					margin: 15px 0 5px 0;
					padding: 1px 5px;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			&__skill {
				margin-bottom: 10px;
			}
		}

	/**
	*	Notification Card - Notification Types
	**/
	&--danger {
		background-color: var(--ion-color-danger-lighten-8);
		border-bottom: 1px solid var(--ion-color-danger);
	}

	&--warning {
		background-color: var(--ion-color-warning-lighten-8);
		border-bottom: 1px solid var(--ion-color-warning);
	}

	&--white {
		background-color: var(--ion-color-light);
		border-bottom: 1px solid var(--ion-color-grey-darken-2);
	}
}
</style>

<style lang="scss">
.skills-reviewed {
	ion-accordion {
		.ion-accordion-toggle-icon {
			color: var(--ion-color-light) !important;
			height: 20px;
			width: 20px;
		}
	}
}
</style>