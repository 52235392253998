<template>
	<div class="attempt-history-item">
		<div class="attempt-history-item__details">
			<span>{{ count }}</span>
			<span>
				<MaterialDesignIcon color="light" icon="calendar" size="small" />
				{{ format(attempt.date, 'MMM dd, yyyy') }}
			</span>
			<span>
				<MaterialDesignIcon color="light" icon="account" size="small" />
				{{ attempt.instructor.firstName }} {{ attempt.instructor.lastName }}
			</span>
		</div>
		<ul class="attempt-history-item__feedback">
			<li
				class="attempt-history-item__feedback-item"
				v-if="attempt.note"
			>
				<span>{{ attempt.note }}</span>
				<MaterialDesignIcon icon="commentQuote" size="small"></MaterialDesignIcon>
			</li>
			<li
				class="attempt-history-item__feedback-item"
				v-for="(feedbackItem, index) in attempt.feedbackItems"
			>
				<span>{{ feedbackItem.feedbackMessage }}</span>
			</li>
			<li class="attempt-history-item__feedback-item attempt-history-item__feedback-item--empty" v-if="attempt.feedbackItems.length === 0 && !attempt.note">
				<span>No Feedback</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { format } from 'date-fns';
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';

export default {
	components: {
		MaterialDesignIcon
	},
	methods: {
		format
	},
	props: {
		attempt: {
			type: Object
		},
		count: {
			default: 0,
			type: Number
		},
		index: {
			default: 0,
			type: Number
		}
	}
}
</script>

<style lang="scss" scoped>
.attempt-history-item {
	&__details {
		align-items: center;
		background: var(--ion-color-primary-lighten-2);
		color: var(--ion-color-light);
		display: flex;
		gap: 10px;

		span {
			align-items: center;
			display: flex;
			font-weight: 700;
			gap: 5px;
			justify-content: center;

			&:nth-child(1) {
				background: var(--ion-color-primary-lighten-3);
				border-bottom-right-radius: 150px;
				border-top-right-radius: 150px;
				height: 25px;
				width: 25px;
			}
		}
	}

	&__feedback {
		background: var(--ion-color-light);
		border-left: 10px solid var(--ion-color-primary-lighten-3);
		color: var(--ion-color-primary);
		font-weight: 700;
		list-style: none;
		margin: 0;
		padding: 0px;
	}

		&__feedback-item {
			align-items: center;
			border-bottom: 1px solid var(--ion-color-primary-lighten-2);
			display: flex;
			justify-content: space-between;
			padding: 5px 10px;

			&:last-child {
				border-bottom: none;
			}

			&--empty {
				background-color: var(--ion-color-grey-darken-1);
			}
		}
}
</style>